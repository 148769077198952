import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})

export class FileSizePipe implements PipeTransform {
  transform(bytes: number): string {
    if (bytes === 0) return '0 MB';

    let size;
    if( bytes < 10000 ) {
      const kilobyte = 1000; /// 1 KB = 1000 bytes in base 10
      size = bytes / kilobyte;
      size = parseFloat(size.toFixed(2)) + ' KB';
    } else {
      const megabyte = 1000000; /// 1 MB = 1000000 bytes in base 10
      size = bytes / megabyte;
      size = parseFloat(size.toFixed(2)) + ' MB';
    }

    return size;
  }
}
