<form [formGroup]="violenceUseCaseForm" (ngSubmit)="onSubmit()" class="usecase-config-form">
    <div class="mb-4">
        <div class="d-flex justify-content-between mb-4">
            <div class="dimension-input usecase-form-group">
                <label for="fps">Inference FPS: <sup>*</sup></label>
                <input type="number" id="fps" name="fps" required="true" formControlName="fps" class="form-control"
                    [(ngModel)]="ifps" placeholder="Inference FPS"
                    [ngClass]="{'is-invalid': isSubmitted && fc['fps'].errors || violenceUseCaseForm.get('fps')?.hasError('digitCountExceeded')}"
                    [appDigitCount]="30" maxlength="2">

                <div *ngIf="violenceUseCaseForm.get('fps')?.hasError('required')" class="invalid-feedback">
                    Inference FPS Value Required
                </div>
                <div *ngIf="violenceUseCaseForm.get('fps')?.hasError('digitCountExceeded')" class="invalid-feedback">
                    Inference FPS Value Max 30.
                </div>
            </div>
            <div class="dimension-input usecase-form-group">
                <label for="windowsize">Fight Window Size: <sup>*</sup></label>
                <input type="number" id="windowsize" name="windowsize" formControlName="windowsize" class="form-control"
                    [(ngModel)]="windows" placeholder="Fight Window Size"
                    [ngClass]="{'is-invalid': isSubmitted && fc['windowsize'].errors}">
                <div *ngIf="isSubmitted" class="invalid-feedback">
                    Fight Window Size Value Required
                </div>
                <div *ngIf="violenceUseCaseForm.get('windowsize')?.hasError('digitCountExceeded')"
                    class="invalid-feedback">
                    Inference FPS Value Max 30.
                </div>
            </div>

        </div>
        <div class="d-flex justify-content-between mb-4">
            <div class="dimension-input usecase-form-group">
                <label for="skipframes">Skip Frames: <sup>*</sup></label>
                <input type="number" id="skipframes" name="skipframes" formControlName="skipframes" class="form-control"
                    placeholder="Enter Skip Frames" [(ngModel)]="skipframe"
                    [ngClass]="{'is-invalid': isSubmitted && fc['skipframes'].errors || violenceUseCaseForm.get('skipframes')?.hasError('floatCountExceeded')}">
                <div *ngIf="violenceUseCaseForm.get('skipframes')?.hasError('required')" class="invalid-feedback">
                    Skip Frame is Required
                </div>
                <div *ngIf="violenceUseCaseForm.get('skipframes')?.hasError('floatCountExceeded')"
                    class="invalid-feedback">
                    Skip Frame is Range 0-1
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-between mb-4">
        <div class="mb-3">
            Save Video:
            <label class="toggle-button" style="position: relative;">
                <div class="shadow-input"></div>
                <input class="form-check-input" type="checkbox" formControlName="savevideo" [(ngModel)]="isChecked1">
                <span class="slider round"></span>
            </label>
        </div>
        <div class="mb-3">
            Offline:
            <label class="toggle-button">
                <div class="shadow-input"></div>
                <input class="form-check-input" type="checkbox" formControlName="offline" [(ngModel)]="isChecked2">
                <span class="slider round"></span>
            </label>
        </div>
    </div>

    <div class="usecase-form-group me-2 custom-icon mb-4">
        <div class="auto-dropdown">
            <label for="decisionMethod">Select Decision Method:</label>
            <span class="dropdown-down" (click)="changeDecisionMethod($event)"></span>
            <select class="form-control" id="decisionMethod" formControlName="decisionMethod"
                (change)="changeDecisionMethod($event)" [(ngModel)]="selectedDecisionMethod"
                [ngClass]="{'is-invalid': isSubmitted && fc['decisionMethod'].errors}">
                <option *ngFor="let type of decisionMethod" [value]="type">{{ type }}</option>
            </select>
        </div>
    </div>

    <div class="mb-4" *ngIf="isDisplayThresholding==true">
        <div class="d-flex justify-content-between mb-4">
            <div class="dimension-input usecase-form-group">
                <label for="highlevel">High Config Level: <sup>*</sup></label>
                <input type="number" id="highlevel" name="highlevel" formControlName="highLevel" class="form-control"
                    placeholder="High Config Level"
                    [ngClass]="{'is-invalid': isSubmitted && fc['highLevel'].errors || violenceUseCaseForm.get('highLevel')?.hasError('floatCountExceeded') }">
                <div *ngIf="violenceUseCaseForm.get('highLevel')?.hasError('required')" class="invalid-feedback">
                    High Config Level Required
                </div>
                <div *ngIf="violenceUseCaseForm.get('highLevel')?.hasError('floatCountExceeded')"
                    class="invalid-feedback">
                    High Config Level is Range 0-1
                </div>
            </div>
            <div class="dimension-input usecase-form-group">
                <label for="modlevel">Moderate Config Level: <sup>*</sup></label>
                <input type="number" id="modlevel" name="modlevel" formControlName="modLevel" class="form-control"
                    placeholder="Moderate Config Level"
                    [ngClass]="{'is-invalid': isSubmitted && fc['modLevel'].errors || violenceUseCaseForm.get('modLevel')?.hasError('floatCountExceeded')}">
                <div *ngIf="violenceUseCaseForm.get('modLevel')?.hasError('required')" class="invalid-feedback">
                    Moderate Config Level Required
                </div>
                <div *ngIf="violenceUseCaseForm.get('modLevel')?.hasError('floatCountExceeded')"
                    class="invalid-feedback">
                    Moderate Config Level Range 0-1
                </div>
            </div>

        </div>
        <div class="d-flex justify-content-between mb-4">
            <div class="dimension-input usecase-form-group">
                <label for="highlevelfreq">High Level Freq: <sup>*</sup></label>
                <input type="number" id="highlevelfreq" name="highlevelfreq" formControlName="highLevelFreq"
                    class="form-control" placeholder="High Level Freq"
                    [ngClass]="{'is-invalid': isSubmitted && fc['highLevelFreq'].errors }">
                <div *ngIf="isSubmitted" class="invalid-feedback">
                    High Level Freq Required
                </div>
            </div>
            <div class="dimension-input usecase-form-group">
                <label for="modlevelfreq">Moderate Level Freq: <sup>*</sup></label>
                <input type="number" id="modlevelfreq" name="modlevelfreq" formControlName="modLevelFreq"
                    class="form-control" placeholder="Moderate Level Freq"
                    [ngClass]="{'is-invalid': isSubmitted && fc['modLevelFreq'].errors }">
                <div *ngIf="isSubmitted" class="invalid-feedback">
                    Moderate Level Freq Required
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="isDisplayThresholding==false">
        <div class="d-flex justify-content-between mb-4">
            <div class="dimension-input usecase-form-group">
                <label for="avgthreshold">Average Threshold: <sup>*</sup></label>
                <input type="number" id="avgthreshold" name="avgthreshold" formControlName="avgThreshold"
                    class="form-control" placeholder="Average Threshold"
                    [ngClass]="{'is-invalid': isSubmitted && fc['avgThreshold'].errors || violenceUseCaseForm.get('avgThreshold')?.hasError('floatCountExceeded')}">
                <div *ngIf="violenceUseCaseForm.get('avgThreshold')?.hasError('required')" class="invalid-feedback">
                    Average Threshold Required
                </div>
                <div *ngIf="violenceUseCaseForm.get('avgThreshold')?.hasError('floatCountExceeded')"
                    class="invalid-feedback">
                    Average Threshold Range 0-1
                </div>
            </div>
        </div>
    </div>

    <div class="user-form-group custom-icon mb-3">
        Status:
        <label class="toggle-button" style="position: relative;">
            <div class="shadow-input"></div>
            <input class="form-check-input" type="checkbox" formControlName="status" [(ngModel)]="isChecked">
            <span class="slider round"></span>
        </label>
    </div>
    <button type="submit" class="btn btn-add" [disabled]="isLoading">Submit</button>
</form>