import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { CommonService } from './services/common.service';
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { AlertMessagesService } from './services/alert-messages.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'scout-saas-ui';
  persistentToast: any;
  fileStatusData: any;
  fileStatusDataList: any = [];
  showErrorList: any = [];
  errorCount: number = 0;
  successImgIcon = "../../../../assets/icons/success-icon.svg";
  showCaseNotification: boolean = false;

  constructor(
    private authService: AuthService, 
    private commonService: CommonService, 
    private router: Router,
    private alertMessagesService: AlertMessagesService
  ) {}

  ngOnInit(): void {
    this.authService.checkAndStartRefreshTokenJob();
    this.listenForFileUploadProgress();
    this.checkForClosingNotification();
  }

  checkForClosingNotification() {
    this.commonService.checkForLogout$.subscribe(data => {
      if( data ) {
        this.closeToast();
        this.showCaseNotification = false;
      }
    });
  }

  listenForFileUploadProgress() {
    this.commonService.caseFileUploadProgress$.subscribe(data => {
      if( data?.length > 0 ) {
        this.fileStatusDataList = data;
        for(let i=0; i<this.fileStatusDataList.length; i++) {
          this.fileStatusData = this.fileStatusDataList[i];
          const fileCount = this.fileStatusData?.fileData?.filter((obj: any) => obj['localUpload'])?.length;
          if( fileCount == 0 && this.fileStatusData?.toShowProgress == false ) {
            const toastClass = (this.showCaseNotification) ? 'toast-position' : '';
            this.alertMessagesService.showToastMessage(`<div class="case-no">Case No: ${this.fileStatusData?.caseId?.slice(-5)}</div><div class="file-success">Files uploaded successfully.</div>`, `<img src=${this.successImgIcon} width="34" height="34" alt="" />`, toastClass);
            this.fileStatusData.toShowProgress = null;
          }
          const fileFailedCount = this.fileStatusData?.fileData?.filter((obj: any) => obj['fileUploadFailed'])?.length;
          const index = this.showErrorList.findIndex((obj: any) => obj.caseId === this.fileStatusData?.caseId);
          if( fileFailedCount > 0 && index == -1 ) {
            this.showErrorList.push({ errorCounter: fileFailedCount, caseId: this.fileStatusData?.caseId, clickedOnCard: false });
          }
        }
        let filteredErrorList = this.showErrorList?.filter((obj: any) => !obj['clickedOnCard']);
        this.errorCount = filteredErrorList?.length;
        if( filteredErrorList?.length > 0 ) {
          // Make it unique based on caseId if it comes two times
          filteredErrorList = Object.values(filteredErrorList.reduce((acc: any, cur: any) => {
            acc[cur.caseId] = cur;
            return acc;
          }, {}));
          this.showCaseNotification = false;
          this.closeToast();
          if( filteredErrorList?.length >= 3 ) {
            // Show circular error notification bar
            this.showCaseNotification = true;
          } else {
            // Show direct Notification bar
            const html = `<div class="case-list-container" id="caseContainer">${filteredErrorList.map((item: any) => `<div class="case-item" id="caseId" case-id="${item.caseId}">Case No: ${item.caseId.slice(-5)}</div>`).join('')}</div>`;
            this.showErrorCaseToastMessage(`<div>Unable to upload files for below cases</div>`, `${html}`);
          }
        }
      } else {
        this.fileStatusDataList = [];
      }
    });
  }

  // Attach beforeunload event listener to prevent user from closing the browser when file upload is in progress
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any): any {
    if( this.fileStatusData?.canWindowTabClosed == false ) {
      $event.preventDefault();
      $event.returnValue = '';
    }
  }

  notificationClickedEvent(event: any) {
    this.showCaseNotification = false;
    /// Show direct Notification bar
    let filteredErrorList = this.showErrorList?.filter((obj: any) => !obj['clickedOnCard']);
    this.errorCount = filteredErrorList?.length;
    const html = `<div class="case-list-container" id="caseContainer">${filteredErrorList.map((item: any) => `<div class="case-item" id="caseId" case-id="${item.caseId}">Case No: ${item.caseId.slice(-5)}</div>`).join('')}</div>`;
    this.showErrorCaseToastMessage(`<div>Unable to upload files for below cases</div>`, `${html}`);
  }

  showErrorCaseToastMessage(message: any, caseList: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: '<img src="../assets/icons/remove-icon.png" alt="" />',
      customClass: {
        popup: 'progress-msg-container',
        title: 'progress-msg-content d-flex align-items-center',
        container: 'progress-container',
        closeButton: 'close-button',
        htmlContainer: 'progress-html-container'
      },
      timer: 5000,
      didOpen: () => {
        Swal.stopTimer();
        const htmlContent: any = Toast.getHtmlContainer();
        htmlContent.querySelector('#caseContainer').addEventListener('click', (event: any) => {
          if (event.target && event.target.classList.contains('case-item')) {
            const caseId = event.target.getAttribute('case-id');
            this.router.navigate(["/case-management", caseId]);
            this.closeToast();
            this.showErrorList.map((item: any) => {
              if( item.caseId == caseId ) {
                item.clickedOnCard = true;
              }
              return item;
            })
          }
        });
      }
    });
    Toast.fire({
      title: message,
      html: `<div class="case-list d-flex align-items-center"><img src="../../../../assets/icons/confirmationnew.svg"  width="35px" height="35px" alt="" />${caseList}</div>`,
    }).then((result) => {
      if ( result.dismiss === Swal.DismissReason.close ) {
        this.showErrorList.map((item: any) => {
          item.clickedOnCard = true;
          return item;
        })
      }
    });
    this.persistentToast = Toast;
  }

  closeToast() {
    if (this.persistentToast)
      this.persistentToast.close();
  }

}
