<div class="p-3 row m-0 pb-0">
  <div class="store-search-box px-0">
    <div class="card search-box card-box">
      <div class="d-flex br-3 px-3">
        <div class="search-item d-flex me-4 py-3">
          <label>Country</label>
          <div class="search-selector">
            <select class="store-search" (change)="getStatesList($event)" [(ngModel)]="defaultCountry">
              <option selected value="All">- All -</option>
              <option *ngFor="let country of countriesList | orderBy:'country.countryName'"
                value="{{country.countryUID}}">
                {{country.countryName}}
              </option>
            </select>
          </div>
        </div>

        <div class="search-item d-flex me-4 py-3">
          <label>State</label>
          <div class="search-selector">
            <select class="store-search" (change)="getZipCodesList($event)" [(ngModel)]="defaultState">
              <option selected value="All">- All -</option>
              <option *ngFor="let state of statesList | orderBy:'state.stateName'" value="{{state.stateUID}}">
                {{state.stateName}}
              </option>
            </select>
          </div>
        </div>

        <div class="search-item d-flex me-4 py-3">
          <label>Zip Code</label>
          <div class="search-selector">
            <select class="store-search" (change)="getStoresList($event)" [(ngModel)]="defaultZip">
              <option selected value="All">- All -</option>
              <option *ngFor="let zip of zipCodesList | orderBy:'name'">{{zip.zipCode}}
              </option>
            </select>
          </div>
        </div>

        <div class="search-item d-flex me-4 py-3">
          <label>Store</label>
          <div class="search-selector">
            <select class="store-search" (change)="fetchStoreData($event)" [(ngModel)]="defaultStore">
              <option selected value="All">- All -</option>
              <option value="{{store.datamartId}}" *ngFor="let store of storesList | orderBy:'storeName'">
                {{store.storeName}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="transcript-table-main mt-3 table-fix-head" (scroll)="onScroll($event, scrollContainer)" #scrollContainer>
    <table class="table transcript-table" data-pagination="true" data-page-list="[10, 25, 50, 100]" data-search="true"
      data-show-refresh="true">
      <thead>
        <tr>
          <th>STORE NAME</th>
          <th>CASE ID</th>
          <th>CREATION DATE & TIME</th>
          <th>DESCRIPTION</th>
          <th>ASSIGNEE</th>
          <th>STATUS</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let case of tableData; let i = index;">
          <td class="clickable-cell" (click)="getCaseInfoPage(case)">{{case.source_data.store_name}}</td>
          <td class="clickable-cell" (click)="getCaseInfoPage(case)">{{case.cid}}</td>
          <td class="clickable-cell" (click)="getCaseInfoPage(case)"><span *ngIf="case?.source_data?.case_detected_at">
              {{case?.source_data?.case_detected_at | timeZoneDateMoment: "MMMM dd, yyyy" :
              case?.source_data?.store_timezone}} <br /> {{case?.source_data?.case_detected_at | timeZoneDateMoment:
              "hh:mm:ss a" :
              case?.source_data?.store_timezone}}</span> </td>
          <td class="clickable-cell" (click)="getCaseInfoPage(case)">{{case.description}}</td>
          <td class="clickable-cell" (click)="getCaseInfoPage(case)">
            <span *ngFor="let assignee of case.assignee;let islast = last;"> {{assignee.name}}<span *ngIf="!islast">,
              </span></span>
          </td>

          <td>
            <div class="search-selector">
              <select class="store-search" (change)="selectStatus(case,$event)"
                [disabled]="case?.status?.allow_case_update==false">
                <option *ngFor="let status of statusList" [value]="status.slug_name"
                  [selected]="case?.status?.slug_name==status.slug_name">
                  {{status.name}}
                </option>
              </select>
            </div>
          </td>
        </tr>
        <tr *ngIf="tableData?.length == 0">
          <td colspan="6" class="center-align" *ngIf="!showDefaultMessage">No Cases Available</td>
          <td colspan="6" class="center-align" *ngIf="showDefaultMessage">Loading...</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>