import { Component, OnInit } from "@angular/core";
import { EChartsOption } from "echarts";
import { LayoutService } from "src/app/services/layout.service";

@Component({
  selector: "app-strategy-insight",
  templateUrl: "./strategy-insight.component.html",
  styleUrls: ["./strategy-insight.component.scss"],
})
export class StrategyInsightComponent implements OnInit {
  strategyInteraction: EChartsOption = {};
  questionId: number;

  footFallData1: any = [
    {
      data: [3, 2, 1, 2, 1, 5, 3],
      type: "line",
      lineStyle: {
        color: "#dd4761",
        width: 2,
        type: "solid",
      },
      label: {
        show: true,
      },
      itemStyle: {
        color: "#dd4761",
      },
    },
    {
      data: [15, 13, 10, 12, 13, 13, 14],
      type: "line",
      lineStyle: {
        color: "#cd128a",
        width: 2,
        type: "solid",
      },
      label: {
        show: false,
      },
      itemStyle: {
        color: "#cd128a",
      },
    },
    {
      data: [6, 5, 5, 4, 5, 7, 6],
      type: "line",
      lineStyle: {
        color: "#e65718",
        width: 2,
        type: "solid",
      },
      label: {
        show: false,
      },
      itemStyle: {
        color: "#e65718",
      },
    },
  ];

  footFallData2: any = [
    {
      data: [13, 10, 10, 11, 15, 13, 10],
      type: "line",
      lineStyle: {
        color: "#cd128a",
        width: 2,
        type: "solid",
      },
      label: {
        show: false,
      },
      itemStyle: {
        color: "#cd128a",
      },
    },
    {
      data: [7, 6, 5, 5, 3, 2, 1],
      type: "line",
      lineStyle: {
        color: "#dd4761",
        width: 2,
        type: "solid",
      },
      label: {
        show: true,
      },
      itemStyle: {
        color: "#dd4761",
      },
    },
    {
      data: [7, 7, 8, 8, 6, 5, 5],
      type: "line",
      lineStyle: {
        color: "#e65718",
        width: 2,
        type: "solid",
      },
      label: {
        show: false,
      },
      itemStyle: {
        color: "#e65718",
      },
    },
  ];

  footFallData3: any = [
    {
      data: [3, 2, 1, 1, 6, 5, 5],
      type: "line",
      lineStyle: {
        color: "#dd4761",
        width: 2,
        type: "solid",
      },
      label: {
        show: true,
      },
      itemStyle: {
        color: "#dd4761",
      },
    },
    {
      data: [6, 5, 5, 4, 7, 8, 8],
      type: "line",
      lineStyle: {
        color: "#e65718",
        width: 2,
        type: "solid",
      },
      label: {
        show: false,
      },
      itemStyle: {
        color: "#e65718",
      },
    },

    {
      data: [15, 13, 10, 12, 10, 10, 11],
      type: "line",
      lineStyle: {
        color: "#cd128a",
        width: 2,
        type: "solid",
      },
      label: {
        show: false,
      },
      itemStyle: {
        color: "#cd128a",
      },
    },
  ];

  strategyInteractionData: any = this.footFallData1;
  strategyQuestions = [
    { question: "Why has my sales Down?", id: 1 },
    { question: "Question 2", id: 2 },
    { question: "Question 3", id: 3 },
  ];

  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
    const pageTitle = "Strategic Insights";
    this.layoutService.setPageTitle(pageTitle);
    this.questionId = 1;
    this.getStrategyInteractionData();
  }

  selectQuestion(event: any) {
    this.questionId = event.target.value;
    if (this.questionId == 1) {
      this.strategyInteractionData = this.footFallData1;
      this.getStrategyInteractionData();
    }
    if (this.questionId == 2) {
      this.strategyInteractionData = this.footFallData2;
      this.getStrategyInteractionData();
    }
    if (this.questionId == 3) {
      this.strategyInteractionData = this.footFallData3;
      this.getStrategyInteractionData();
    }
  }

  getStrategyInteractionData() {
    this.strategyInteraction = {
      xAxis: {
        type: "category",
        data: ["Jan 1", "Jan 2", "Jan 3", "Jan 4", "Jan 5", "Jan 6", "Jan 7"],
      },
      yAxis: {
        type: "value",
        show: true,
      },
      color: "#dd4761",
      series: this.strategyInteractionData,
    };
  }
}
