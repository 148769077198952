import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-email-template-preview',
  templateUrl: './email-template-preview.component.html',
  styleUrls: ['./email-template-preview.component.scss']
})
export class EmailTemplatePreviewComponent implements OnInit {

  @Input() emailData: any;
  emailSubject: string;
  emailMessage: string;
  eventDateTime: any;
  cameraLocation: string;
  useCaseName: string;
  storeName: string;
  storeAddress: string;
  threshold: number;
  appOrigin: string;
  emailHtml: string;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.appOrigin = window.location.origin;
    this.emailSubject = this.emailData?.emailSubject ?? '';
    this.emailSubject = this.replaceDynamicParams(this.emailSubject);
    this.emailMessage = this.emailData?.emailMessage ?? '';
    this.emailMessage = this.replaceDynamicParams(this.emailMessage);
    this.eventDateTime = this.emailData?.['Event Time'] ?? '';
    this.cameraLocation = this.emailData?.['Camera Name'] ?? ''; 
    this.useCaseName = this.emailData?.['Usecase Name'] ?? '';
    this.storeName = this.emailData?.['Store Name'] ?? '';
    this.storeAddress = this.emailData?.['Store Location'] ?? '';
    this.threshold = this.emailData?.['Threshold'] ?? '';
    this.initializeEmailTemplate();
  }

  initializeEmailTemplate() {
    let emailHtml = 
      `<!DOCTYPE html>
        <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title></title>
          </head>
          <body style="margin: 0; padding: 0 30px; width: 654px; background: rgb(255, 255, 255); font-family: Poppins, sans-serif;">
            <div style="background: rgb(255, 255, 255); padding: 24px 0 0; border-radius: 10px; border: 2px solid rgb(218 216 216);">
              <div style="text-align: center; padding: 0 85px 32px;">
                  <img src="https://retailsaasuat.blob.core.windows.net/$web/assets/alert-logo.svg" width="72" height="72">
              </div>
              <div style="text-align: center; padding: 0 85px 32px;">
                  <p style="margin: 0;font-size: 23px;line-height: 34.5px;font-weight: 600; color: rgb(0, 0, 0); width: 348px; margin: auto;">{{emailSubject}}</p>
              </div>
              <div style="text-align: center; padding: 0 85px 40px;">
                <div style="display: flex; justify-content: space-between; align-items: flex-end; padding-bottom: 8px; border-bottom: 1px solid rgb(105, 100, 100)">
                  <div style="display: flex; flex-direction: column; gap: 5px; align-items: flex-start;">
                    <div style="font-size: 16px;line-height: 24px;font-weight: 500; color: rgb(0, 0, 0);">Camera Location</div>
                    <div style="font-size: 16px;line-height: 24px;font-weight: 300; color: rgb(0, 0, 0);">{{cameraLocation}}</div>
                  </div>
                  <div style="font-size: 16px;line-height: 24px;font-weight: 400; color: rgb(0, 0, 0);">{{eventDateTime}}</div>
                </div>
              </div>
              <div style="text-align: center; padding: 0 85px; background: rgb(244, 244, 244);">
                <div style="padding: 40px 19px; font-size: 16px;line-height: 24px;font-weight: 400; color: rgb(0, 0, 0);">
                  {{emailMessage}}
                </div>
              </div>
              <div style="text-align: center; padding: 32px 85px 0;">
                <a href="{{appOrigin}}" target="_blank"> 
                  <img src="https://retailsaasuat.blob.core.windows.net/$web/assets/pitaya-logo.svg" width="116" height="38">
                </a>
                <p style="font-size: 16px;line-height: 24px;font-weight: 400; color: rgb(175, 170, 170);">©2024 Centific. All Rights Reserved.</p>
              </div>
            </div>
        </body>
      </html>`;

    this.emailHtml = this.replaceEmailSubjectMessage(emailHtml);
  }

  replaceEmailSubjectMessage(emailHtml: string) {
    // Regular expression to find {{emailSubject}} and {{emailMessage}}
    const regex = /\{\{emailSubject\}\}|\{\{cameraLocation\}\}|\{\{eventDateTime\}\}|\{\{emailMessage\}\}|\{\{appOrigin\}\}/g;

    // Replace placeholders with actual values
    emailHtml = emailHtml.replace(regex, (match) => {
      switch (match) {
        case '{{emailSubject}}':
          return this.emailSubject; // Replace with the actual email subject
        case '{{cameraLocation}}':
          return this.cameraLocation;
        case '{{eventDateTime}}':
          return this.eventDateTime;
        case '{{emailMessage}}':
          return this.emailMessage; // Replace with the actual email message
        case '{{appOrigin}}':
          return this.appOrigin;
        default:
          return match;
      }
    });
    return emailHtml;
  }

  replaceDynamicParams(text: string) {
    if(!text) {
      return text;
    }
    const replacements = this.emailData;
    // Regular expression to match text inside double curly braces {{}}
    const regex = /\{\{([^{}]+)\}\}/g;
    // Replace placeholders with actual values
    const replacedText = text.replace(regex, (match, placeholder) => {
      // Get the value from the replacements object
      let value = replacements[placeholder.trim()];
      // If value exists, return it, otherwise return the original match
      value = (placeholder == 'Camera Name') ? `"${value}"` : value;
      return value ? value : match;
    });
    return replacedText;
  }

}
