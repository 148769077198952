import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddDeviceComponent } from "./add-device/add-device.component";
import { CommonService } from "src/app/services/common.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: "app-device",
  templateUrl: "./device.component.html",
  styleUrls: ["./device.component.scss"],
})
export class DeviceComponent implements OnInit {
  @Input() filteredOrgs: any[] = [];
  @Input() filteredStores: any[] = [];

  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  closeResult = "";
  deviceData: any;
  totalItems: number = 0;
  pageSize: number = this.configurationService.pageSize;
  itemsPerPage: number = this.configurationService.itemsPerPage;
  currentPage: number = 1;
  paginationId = "device_items";

  selectedTenantName: string;

  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: string = "";

  selectedStoreId: string = "";
  selectedDeviceId: any;
  selecteddeviceTypeId: any;
  orgData: any[] = [];
  storeData: any[] = [];
  loggedUser: any;

  visibleOrg: boolean = false;
  visibleStore: boolean = false;
  noDataFound: boolean = false;
  selectedTenantIdDropdown: boolean = false;
  selectedStoreIdDropdown: boolean = false;
  selectedStatus: string = 'Active';
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private configurationService: ConfigurationService,
    private modalService: NgbModal,
    private commonService: CommonService
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.commonService.selectedOrganization$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(org => {
      if (org) {
        this.selectedTenantIdDropdown = true;
        this.visibleOrg = false;
        this.selectedTenantId = org.tenantId;
        this.selectedStoreId = org.storeId;
      }
    });
    this.commonService.selectedStore$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(store => {
      if (store) {
        this.selectedStoreIdDropdown = true;
        this.selectedStoreId = store.storeId;
      }
    });

    this.commonService.deviceTabClicked.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isClicked) => {
      if(isClicked){
        this.getDevicesData(this.selectedStoreId, this.selectedTenantId);
      }
    });
  }

  ngOnInit(): void {
    this.configByRole();    
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  configByRole() {
    if (this.loggedUser === "csm") {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      this.visibleOrg = true;
    }
    if (this.loggedUser === "org-admin") {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
      this.visibleOrg = false;
      this.selectedTenantId = this.defaultTenantId;
      this.getStoreData(this.selectedTenantId);
    }
  }

  changeOrg(event: any) {
    this.visibleOrg = false;
    this.visibleStore = true;
    this.noDataFound = false;
    this.currentPage = 1;
    this.selectedTenantId = event.target.value;
    this.selectedStoreId = "";
    this.getDevicesData(this.selectedStoreId, this.selectedTenantId)
    this.commonService.setSelectedOrganization({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId
    });

  }

  getStoreData(tenantId: any) {
    let defaultStatus : string = 'Active';
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((data: any) => {
        this.storeData = data.stores;
        this.storeData = data.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.filteredStores = this.storeData.filter(
          (storeData: any) => storeData.status === true
        );
      });
  }

  changeStore(event: any) {
    this.visibleOrg = false;
    this.visibleStore = false;
    this.currentPage = 1;
    this.selectedStoreId = event.target.value;
    this.commonService.setSelectedStore({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId
    });
    this.getDevicesData(this.selectedStoreId, this.selectedTenantId);
  }
  onStatusChange(){
    this.configurationService
      .getDevicesData(this.selectedStoreId, this.selectedTenantId, this.pageSize, this.currentPage, this.selectedStatus)
      .subscribe((resp: any) => {
        this.deviceData = resp.data;
        this.totalItems = resp.data.length;
        if (this.totalItems === 0) {
          this.noDataFound = true;
        }
      });
  }

  storePageChanged(event: any) {
    this.currentPage = event;
    window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
  }

  getDevicesData(storeId: any, tenantId: any) {
    this.configurationService
      .getDevicesData(storeId, tenantId, this.pageSize, this.currentPage, this.selectedStatus)
      .subscribe((resp: any) => {
        this.deviceData = resp.data;
        this.totalItems = resp.data.length;
        if (this.totalItems === 0) {
          this.noDataFound = true;
        }
      });
  }
  pageChanged(event: any) {
    this.currentPage = event;
    // window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
  }

  addDeviceData() {
    const modelRef = this.modalService.open(AddDeviceComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
    });
    modelRef.componentInstance.filteredOrgs = this.filteredOrgs;
    modelRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getDevicesData(this.selectedStoreId, this.selectedTenantId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  handleActionEvent(actionDetails: any) {
    if (actionDetails.action === 'edit') {
      this.updateDeviceData(actionDetails.data);
    } else if (actionDetails.action === 'deploy') {
      this.deployDeviceData(actionDetails.data);
    }
  }

  updateDeviceData(device: any) {
    const modalRef = this.modalService.open(AddDeviceComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.deviceData = device;
    modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getDevicesData(this.selectedStoreId, this.selectedTenantId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  deployDeviceData(iotDeviceId: any) {
    this.configurationService
      .deviceDeploy(iotDeviceId.ioTHubDeviceName)
      .subscribe((resp: any) => {
      });
  }
}
