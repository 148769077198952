import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-store-setup",
  templateUrl: "./store-setup.component.html",
  styleUrls: ["./store-setup.component.scss"],
})
export class StoreSetupComponent {
  @Input() filteredOrgs: any[] = [];
  @Input() filteredStores: any[] = [];
  
  @Output() selectedTabEvent = new EventEmitter<string>();

  cameraTab: boolean = false;
  deviceTab: boolean = false;
  usecaseTab: boolean = false;
  userAlertTab: boolean = false;
  selectedTab: string;

  constructor(private commonService: CommonService){
   
  }

  onSelectedTabClick(tabName: string) {
    this.selectedTab = tabName;
    this.selectedTabEvent.emit(this.selectedTab)
    if(tabName === 'Device') {
      this.deviceTab = true;
      this.commonService.emitDeviceTabClickedEvent(this.deviceTab)
    } else if(tabName === 'Camera') {
      this.cameraTab = true;
      this.commonService.emitCameraTabClickedEvent(this.cameraTab)
    } else if(tabName === 'Usecase') {
      this.usecaseTab = true;
      this.commonService.emitUsecaseTabClickedEvent(this.usecaseTab)
    } else if(tabName === 'Alerts') {
      this.userAlertTab = true;
      this.commonService.emitAlertTabClickedEvent(this.userAlertTab)
    }
  }

}
