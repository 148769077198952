import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

export interface configFormData {
  tenantId: string;
  tenantName: string;
  storeId: string;
  storeName: string;
  deviceId: number;
  deviceName: string;
  camId: number;
  camName: string;
  useCaseName: string;
  useCaseId: number;
}

@Component({
  selector: "app-add-usecase",
  templateUrl: "./add-usecase.component.html",
  styleUrls: ["./add-usecase.component.scss"],
})
export class AddUsecaseComponent implements OnInit {
  @Input() public useCaseConfigData: any;
  @Output() updatedUseCase: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild("autoOrgDrop") autoOrgDrop: any;
  @ViewChild("autoStoreDrop") autoStoreDrop: any;
  @ViewChild("autoDeviceDrop") autoDeviceDrop: any;
  @ViewChild("autoUseCaseDrop") autoUseCaseDrop: any;
  @ViewChild("autoCamDrop") autoCamDrop: any;

  storeData: any;
  keyword = "name";

  placeholderOrg = "Select the Organization";
  placeholderStore = "Select the Store";
  placeholderDevice = "Select the Device";
  placeholderUseCase = "Select the UseCase";
  placeholderCamName = "Select the Camera";

  selectedTenantId: any = "";
  selectedTenantName: string;
  selectedStoreId: string;
  selectedStoreName: string;
  selectedDeviceId: string;
  selectedDeviceName: string;
  selectedUseCaseId: string;
  selectedUseCaseName: string;
  selectedUCIdentifier: string;
  selectedCamId: string;
  selectedCamName: string;
  selectedCamMacAdd: string;
  selectedRTSP: string;
  dataTitle: string;

  addUsecaseForm: FormGroup;
  defaultTenantId: any = localStorage.getItem("tenantId");
  currentPage: number = 1;
  pageSize: number = this.configurationService.pageSize;

  isChecked: boolean = true;
  dwellDataVisible: boolean = false;
  peopleDataVisible: boolean = false;
  oosDataVisible: boolean = false;
  peopleStoreMovementDataVisible: boolean = false;
  violenceDataVisible: boolean = false;
  isSubmitted: boolean = false;
  isVisible: boolean = false;
  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  isEditing: boolean = false;
  visibleDevice: boolean = true;
  usecasemapExist: boolean = false;

  orgData: any[] = [];
  filteredOrgs: any[] = [];
  storesList: any[] = [];
  filteredStores: any[] = [];
  deviceList: any[] = [];
  useCaseType: any[] = [];
  useCaseList: any[] = [];
  camList: any[] = [];
  loggedUser: any;
  selectedFormData: any;
  selectedUseCaseConfig: any;
  useCaseROIData: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private configurationService: ConfigurationService
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.addUsecaseForm = this.fb.group({
      tenantId: ["", [Validators.required]],
      storeId: ["", [Validators.required]],
      deviceId: ["", [Validators.required]],
      useCaseUId: [null, [Validators.required]],
      cameraId: [null, [Validators.required]],
    });
  }
  get fc() {
    return this.addUsecaseForm.controls;
  }

  ngOnInit(): void {
    this.assignDefaultValues();
    this.configByRole();
    
  }

  getUpdatedUsecaseData() : void{
    this.peopleStoreMovementDataVisible = false;  
    this.dwellDataVisible = false;
    this.peopleDataVisible = false;
    this.oosDataVisible = false;
    this.violenceDataVisible = false;
   
    this.updatedUseCase.emit();
  }

  configByRole() {
    if (this.loggedUser == "csm") {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      this.filterOrgData();
    }
    if (this.loggedUser == "org-admin") {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
      this.selectedTenantId = this.defaultTenantId;
      this.getStoreData(this.selectedTenantId);
    }
  }

  assignDefaultValues() {
    this.isEditing = this.useCaseConfigData?.isEditing;
    if (this.isEditing) {
      this.visibleDevice = false;
      this.selectedTenantId = this.useCaseConfigData?.useCase?.tenantId;
      this.selectedTenantName = this.useCaseConfigData?.useCase?.tenantName;
      this.selectedStoreId = this.useCaseConfigData?.useCase?.storeId;
      this.selectedStoreName = this.useCaseConfigData?.useCase?.storeName;
      this.selectedDeviceName = this.useCaseConfigData?.useCase?.deviceName;
      this.selectedDeviceId = this.useCaseConfigData?.useCase?.deviceID;
      this.selectedUseCaseId = this.useCaseConfigData?.useCase?.usecaseId;
      this.selectedUseCaseName = this.useCaseConfigData?.useCase?.usecaseName;
      this.selectedCamId = this.useCaseConfigData?.useCase?.cameraId;
      this.selectedCamName = this.useCaseConfigData?.useCase?.cameraName;
      this.selectedUCIdentifier = this.useCaseConfigData?.useCase?.usecaseIdentifier;
      this.dataTitle = this.useCaseConfigData?.useCase?.usecaseName;
      this.fetchUseCaseConfig();
    }
  }

  filterOrgData() {
    this.filteredOrgs = this.useCaseConfigData.filteredOrgs;
    this.filteredOrgs?.map((org: any) => (org.name = `${org.tenantName}`));
    this.filteredOrgs = this.filteredOrgs?.filter(
      (orgData: any) => orgData.status === true
    );
  }

  changeOrganization(event: any) {
    this.selectedTenantId = event.tenantId;
    if (this.selectedTenantId) {
      this.getStoreData(this.selectedTenantId);
    } else {
      this.getStoreData(this.defaultTenantId);
    }
  }

  clearOrg() {
    this.selectedStoreId = "";
    this.selectedStoreName = "";
    this.clearStore();
  }

  getStoreData(tenantId: any) {
    let defaultStatus : string = 'Active';
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((resp: any) => {
        this.storesList = resp.stores;
        this.storeData = resp.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.storesList.map(
          (store: any) => (store.name = `${store.storeName}`)
        );
        this.filteredStores = this.storesList.filter(
          (store: any) => store.status === true
        );
      });
  }

  changeStore(event: any) {
    this.selectedStoreId = event.storeId;
    this.selectedStoreName = event.storeName;
    if (this.selectedStoreId !== "") {
      this.getDeviceList(this.selectedStoreId);
      this.getCamera(this.selectedStoreId, this.selectedTenantId);
    }
  }
 
  clearStore() {
    this.selectedStoreId = "";
    this.selectedStoreName = "";
    this.clearDeviceType();
  }

  getDeviceList(storeId: any) {
    this.configurationService
      .getStoreDeviceData(storeId, this.pageSize, this.currentPage)
      .subscribe((resp: any) => {
        this.deviceList = resp.data;
        this.deviceList.map(
          (device: any) => (device.name = `${device.deviceName}`)
        );
        // this.deviceList = this.deviceList.filter(
        //   (device: any) => device.isActive === "true"
        // );
      });
  }

  useCaseCheckUnique() {
    this.configurationService
      .useCaseCheckUnique(
        this.selectedDeviceId,
        this.selectedCamId,
        this.selectedUseCaseId
      )
      .subscribe((resp: any) => {
        if (resp === true) {
          this.usecasemapExist = true;
        } else {
          this.usecasemapExist = false;
        }
      });
  }
  
  changeDevice(event: any) {
    this.selectedDeviceId = event.deviceID;
    this.selectedDeviceName = event.deviceName;
    this.getUseCaseList();
  }

  clearDeviceType() {
    this.selectedDeviceId = "";
    this.selectedDeviceName = "";
    this.selectedCamId = "";
    this.selectedCamName = "";
    this.clearUseCaseData();
  }

  getUseCaseList() {
    this.configurationService.getAllUseCaseData().subscribe((resp: any) => {
      this.useCaseList = resp.data;
      this.useCaseList.map(
        (useCase: any) => (useCase.name = `${useCase.useCaseName}`)
      );
    });
  }

  changeUseCase(event: any) {
    this.configurationService.getFileUploadStatus(false);
    if (this.selectedDeviceId) {
      this.visibleDevice = false;
      this.selectedUseCaseId = event.useCaseUID;
      this.selectedUseCaseName = event.useCaseName;
      this.selectedUCIdentifier = event.useCaseIdentifier;
      this.dataTitle = this.selectedUseCaseName;
      // this.usecasemapExist = this.checkUsecCaseMapped(this.selectedTenantName, this.selectedStoreName, this.selectedDeviceName, this.selectedCamName, this.selectedUseCaseName)
      if (this.usecasemapExist == false) {
        this.redirectUseCaseConfig();
      }
    } else {
      this.visibleDevice = true;
    }

    this.assignValues();
    this.selectedUseCaseId, this.selectedUseCaseName, this.selectedUCIdentifier;
    this.useCaseCheckUnique();
  }
 
  redirectUseCaseConfig() {
    if (this.selectedUCIdentifier === "Dwell") {
      this.dwellDataVisible = true;
    } else if (this.selectedUCIdentifier === "PeopleCounter") {
      this.peopleDataVisible = true;
    } else if (this.selectedUCIdentifier === "OOS") {
      this.oosDataVisible = true;
    } else if (this.selectedUCIdentifier === "PeopleMovement") {
      this.peopleStoreMovementDataVisible = true;
    } else if (this.selectedUCIdentifier === "Violence") {
      this.violenceDataVisible = true;
    } else {
      this.dwellDataVisible = false;
      this.peopleDataVisible = false;
      this.oosDataVisible = false;
      this.peopleStoreMovementDataVisible = false;
      this.violenceDataVisible = false;
    }
  }

  fetchUseCaseConfig() {
    this.redirectUseCaseConfig();
    this.assignValues();
    this.selectedUseCaseId, this.selectedUseCaseName, this.selectedUCIdentifier;
  }

  clearUseCaseData() {
    this.selectedUseCaseId = "";
    this.selectedUseCaseName = "";
    this.dwellDataVisible = false;
    this.peopleDataVisible = false;
    this.oosDataVisible = false;
    this.peopleStoreMovementDataVisible = false;
    this.violenceDataVisible = false;
    this.usecasemapExist = false
    }

  getCamera(storeId: any, tenantId: any) {
    let defaultStatus: string = 'Active';
    this.configurationService.getCameraData(storeId, tenantId, defaultStatus).subscribe((resp: any) => {
      this.camList = resp.data;
      this.camList.map((cam: any) => (cam.name = `${cam.camName}`));
      this.camList = this.camList.filter(
        (camData: any) => camData.status === true
      );
    });
  }

  changeCamera(event: any) {
    this.selectedCamId = event.camUID;
    this.selectedCamName = event.camName;
    this.selectedCamMacAdd = event.camMacAdd;
    this.selectedRTSP = event.rtsp;
  }

  toggleOrgPanel(e: any): void {
    e.stopPropagation();
    this.autoOrgDrop.open();
  }

  toggleStorePanel(e: any): void {
    e.stopPropagation();
    this.autoStoreDrop.open();
  }

  toggleUseCasePanel(e: any): void {
    e.stopPropagation();
    this.autoUseCaseDrop.open();
  }

  toggleDevicePanel(e: any): void {
    e.stopPropagation();
    this.autoDeviceDrop.open();
  }

  toggleCamPanel(e: any): void {
    e.stopPropagation();
    this.autoCamDrop.open();
  }

  assignValues() {
    if (this.isEditing) {
      this.useCaseROIData = this.useCaseConfigData;
    } else {
      this.useCaseROIData = {
        tenantId: this.selectedTenantId,
        storeId: this.selectedStoreId,
        deviceId: this.selectedDeviceId,
        useCaseUId: this.selectedUseCaseId,
        useCaseName: this.selectedUseCaseName,
        camUId: this.selectedCamId,
        camMacAdd: this.selectedCamMacAdd,
        rtsp: this.selectedRTSP,
        useCaseIdentifier: this.selectedUCIdentifier,
      };
    }
  }
}
