<div class="modal-header">
    <h4 class="modal-title">
        Email Preview
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body modal-email-body">
    <section class="email-body-container">
        <p class="padding-text">This is a indicative preview of email alert.</p>
        <iframe [src]="'data:text/html;charset=utf-8,' + emailHtml | safe: 'resourceUrl'"
            style="width:100%; height: 75vh; max-height: 75vh; overflow-y: auto;" frameborder="1"></iframe>
    </section>
</div>
