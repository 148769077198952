import { Component, Input } from "@angular/core";
import { AppStateService } from "src/app/services/app-state.service";
import { ScenarioService } from "src/app/services/scenario.service";

@Component({
  selector: "app-involvement-tab",
  templateUrl: "./involvement-tab.component.html",
  styleUrls: ["./involvement-tab.component.scss"],
})
export class InvolvementTabComponent {
  involvementData: any;
  @Input() selectedTask: any;

  constructor(
    private appStateService: AppStateService,
    private ScenarioService: ScenarioService
  ) {}
  ngOnInit(): void {
    this.getInvolvementData();
  }
  getInvolvementData() {
    // this.scenarioService.getTranscriptionData()
    // .subscribe((data: any) => {
    this.involvementData = this.selectedTask.tabs.involvement;
    // let data = this.appStateService.getJsonData();
    // const taskIndex = data.taskDetails.findIndex(
    //   (task: any) => task.taskId === this.selectedTask.taskId
    // );
    // data.taskDetails[taskIndex] = this.selectedTask;
    // this.appStateService.setJsonData(data);
    // this.scenarioService.getTranscriptionData().subscribe((data: any) => {
    // });
  }
  // });
}
