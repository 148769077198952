<form [formGroup]="dwellUseCaseForm" (ngSubmit)="onSubmit()" class="usecase-config-form">
  <div class="d-flex justify-content-between mb-4">
    <div class="dimension-input usecase-form-group">
      <label for="alertIntervalId">Dwell THR: <sup>*</sup></label>
      <input type="number" id="alertIntervalId" name="alertIntervalId" formControlName="dwellThr" class="form-control"
        placeholder="Alert Interval in Seconds" [ngClass]="{'is-invalid': isSubmitted && fc['dwellThr'].errors}">

      <div *ngIf="dwellUseCaseForm.get('dwellThr')?.hasError('required')" class="invalid-feedback">
        Dwell THR is Required
      </div>
    </div>
    <div class="dimension-input usecase-form-group">
      <label for="alertTimeId">Dwell Alert Interval: <sup>*</sup></label>
      <input type="number" id="alertTimeId" name="alertTimeId" formControlName="dwellAlertInterval" class="form-control"
        placeholder="Dwell Time in Seconds" [ngClass]="{'is-invalid': isSubmitted && fc['dwellAlertInterval'].errors}">

      <div *ngIf="dwellUseCaseForm.get('dwellAlertInterval')?.hasError('required')" class="invalid-feedback">
        Dwell Alert Interval is Required
      </div>
    </div>
  </div>
  <div class="mb-4">
    <h6 class="mb-3">ROI Dimensions</h6>
    <div class="d-flex justify-content-between mb-4">

      <div class="dimension-input usecase-form-group">
        <label for="xMin">X Min: <sup>*</sup></label>
        <input type="text" id="xMin" name="xMin" required="true" [(ngModel)]="selectedProductROI.xMin"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="X Min" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.xMin}">
        <div *ngIf="isSubmitted && !selectedProductROI.xMin" class="invalid-feedback">
          X Min value Required
        </div>
      </div>
      <div class="dimension-input usecase-form-group">
        <label for="xMax">X Max: <sup>*</sup></label>
        <input type="text" id="xMax" name="xMax" required="true" [(ngModel)]="selectedProductROI.xMax"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="X Max" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.xMax}">
        <div *ngIf="isSubmitted && !selectedProductROI.xMax" class="invalid-feedback">
          X Max value Required
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-4">
      <div class="dimension-input usecase-form-group">
        <label for="yMin">Y Min: <sup>*</sup></label>
        <input type="text" id="yMin" name="yMin" required="true" [(ngModel)]="selectedProductROI.yMin"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Y Min" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.yMin}">
        <div *ngIf="isSubmitted && !selectedProductROI.yMin" class="invalid-feedback">
          Y Min value Required
        </div>
      </div>
      <div class="dimension-input usecase-form-group">
        <label for="yMax">Y Max: <sup>*</sup></label>
        <input type="text" id="yMax" name="yMax" required="true" [(ngModel)]="selectedProductROI.yMax"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Y Max" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.yMax}">
        <div *ngIf="isSubmitted && !selectedProductROI.yMax" class="invalid-feedback">
          Y Max value Required
        </div>
      </div>
    </div>
  </div>

  <div class="user-form-group custom-icon mb-3">
    Status:
    <label class="toggle-button">
      <div class="shadow-input"></div>
      <input class="form-check-input" type="checkbox" formControlName="status" [(ngModel)]="isChecked">
      <span class="slider round"></span>
    </label>
  </div>

  <button type="submit" class="btn btn-add" [disabled]="isLoading">Submit</button>
</form>