import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { CommonService } from 'src/app/services/common.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AgCellActionsComponent } from '../../../organisation/org-grid/ag-cell-actions/ag-cell-actions.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-device-grid',
  templateUrl: './device-grid.component.html',
  styleUrls: ['./device-grid.component.scss']
})
export class DeviceGridComponent {

  @Input('rowData') deviceData: any[];
  @Output() gridActionEvent: EventEmitter<any> = new EventEmitter<any>();
  private gridApi: any; 
  private ngUnsubscribe = new Subject<void>();

  constructor(private commonService: CommonService, private configuartionService: ConfigurationService) 
  { 
    this.commonService.selectedOrganization$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(org => {
      if (org) {
        this.gridApi.paginationGoToFirstPage();
      }
    });
    this.commonService.selectedStore$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(store => {
      if (store) {
        this.gridApi.paginationGoToFirstPage();
      }
    });
    this.commonService.deviceGridActionEvent.subscribe((data) => {
      this.gridActionEvent.emit(data);
    });
  }
  pagination = true;
  paginationPageSize = 10;
  suppressPaginationPanel = true;
  paginationPageSizeSelector: boolean = false;
  pageSize: number = this.configuartionService.itemsPerPage;
  rowHeight: number = 50;

  public defaultColDef: ColDef = {
    sortable: true,
    flex: 1,
    // resizable: true,
  };  
  
  colDefs: ColDef[] = [
    {
      headerName: 'Store Name',
      field: 'storeName',
      minWidth: 132,
    },
    {
      headerName: 'Device Name',
      field: 'deviceName',
      minWidth: 145,
    },
    {
      headerName: 'Device Type',
      field: 'deviceTypeName',
      minWidth: 132,
    },
    {
      headerName: 'Mac Address',
      field: 'macAddress',
      minWidth: 143,
      sortable: false,
    },
    {
      headerName: 'Ip Address',
      field: 'ipAddress',
      minWidth: 123,
      sortable: false,
    },
    {
      headerName: 'Device Description',
      field: 'description',
      minWidth: 100,
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: (params: { value: boolean; }) => {
        const statusText = params.value ? 'Active' : 'Inactive';
        const statusClass = params.value ? 'ag-cell-active' : 'ag-cell-inactive';
        return `<span class="${statusClass}">${statusText}</span>`;
      },
      cellStyle: { textAlign: 'center' },
      minWidth: 115,
    },
    {
      headerName: 'Actions',
      cellClass: 'ag-cell-actions',
      cellRenderer: AgCellActionsComponent,
      cellRendererParams: {
        gridIdentifier: 'device',
        isDelete: true,
        isDeviceDeploy: true,
      },
      minWidth: 100,
      sortable: false,
      resizable: false,
    },
  ];

  ngOnInit(): void {
  }
  onGridReady(params: { api: any }) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['deviceData'] && !changes['deviceData'].firstChange) {
      if (this.gridApi) {
        // this.gridApi.paginationGoToFirstPage();
      }
    }
  }
}
