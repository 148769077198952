<div class="d-flex justify-content-end">
  <select class="selected-item" [(ngModel)]="selectedStatus" (change)="onStatusChange()">
    <option value='Active' selected>Active</option>
    <option value='Inactive'>Inactive</option>
  </select>
  <select class="selected-item" (change)="changeOrg($event)" *ngIf="isCSAdmin && !useCaseMapping"
    [(ngModel)]="selectedOrgItem">
    <option value="" [selected]="!selectedTenantIdDropdown">All Organizations</option>
    <option *ngFor="let org of filteredOrgs" value={{org.tenantId}} [selected]="org.tenantId === selectedTenantId">{{org.tenantName}}</option>
  </select>
  <select class="selected-item" (change)="changeStore($event)" *ngIf="!useCaseMapping" [(ngModel)]="selectedStoreId">
    <option [selected]="!selectedStoreIdDropdown" value="" selected>All Stores</option>
    <option *ngFor="let store of filteredStores" value={{store.storeId}} [selected]="store.storeId === selectedStoreId">{{store.storeName}}</option>
  </select>
  <button class="btn btn-add usecase-device-btn" (click)="addUseCase()">Map Usecase</button>
</div>

<div *ngIf="!useCaseMapping">
  <div class="ag-grid">
    <app-usecase-grid [rowData]="useCaseData" (gridActionEvent)="handleActionEvent($event)"></app-usecase-grid>
  </div> 
</div>
  <!-- <div class="table-scroller">
    <table class="table device-table mt-3 datatable" data-pagination="true" data-page-list="[10, 25, 50, 100]"
      data-search="true" data-show-refresh="true">
      <thead>
        <tr class="table-scroller">
          <th>Store Name</th>
          <th>Device Name</th>
          <th>Cam Name</th>
          <th>Usecase Name</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-row no-data" *ngIf="totalItems === 0">
          <td colspan="7" *ngIf="visibleOrg">Please Select The Organization</td>
           <td colspan="7" *ngIf="visibleStore">Please Select The Store</td> 
          <td colspan="7" *ngIf="noDataFound">No Usecases Found</td>
        </tr>
        <tr class="table-row"
          *ngFor="let useCase of useCaseData | paginate: { id: paginationId, itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }">
          <td>{{useCase.storeName}}</td>
          <td>{{useCase.deviceName}}</td>
          <td>{{useCase.cameraName}}</td>
          <td>{{useCase.usecaseName}}</td>
          <td *ngIf="useCase.status === true"><span class="active">Active</span></td>
          <td *ngIf="useCase.status === false"><span class="inactive">In Active</span></td>
          <td><button class="btn-icon edit-icon" (click)="updateUsecaseData(useCase)"><img
                src="../../../../../assets/icons/editing.svg" alt=""></button>
            <button class="btn-icon edit-icon" (click)="deleteUsecaseData(useCase.ucConfigUID)"
              [disabled]="useCase.status === false"><img src="../../../../../assets/icons/delete.svg" alt=""></button>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls (pageChange)="pageChanged($event)" [id]="paginationId" [maxSize]="10" [directionLinks]="true"
      [responsive]="true" [autoHide]="true" *ngIf="totalItems > 0"></pagination-controls>
  </div>
</div> -->

<div *ngIf="useCaseMapping" class="usecase-mapping-data">
  <div class="modal-header">
    <h4 class="modal-title">Usecase Mapping</h4>
    <button type="submit" class="btn-close" aria-label="Close" (click)="dismissUseCaseMapping()"></button>
  </div>
  <app-add-usecase [useCaseConfigData]="selectedUseCaseConfig" (updatedUseCase)="getUpdatedUsecaseData()"></app-add-usecase>
</div>