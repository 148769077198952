<div class="row">
  <section class="login">
    <div class="wrapper">
      <div class="login-form form">
        <div class="form-logo mb-3">
          <img src="../../../../assets/logo.png" alt="">
        </div>
        <h3 class="text-center mb-4">Reset Your Password</h3>
        <form [formGroup]="resetForm" (ngSubmit)="submitForm()">
 
          <div class="form-control-container">
            <div class="form-group form-relative">
              <label for="password" class="form-label">New Password</label>
              <div class="password-icon">
                <input type="{{ showAuthToken ? 'text' : 'password' }}" class="form-control password" formControlName="newPassword"
                  [ngClass]="{ 'is-invalid': isSubmitting && !newPassword || valid==false }" id="newPassword" placeholder="New Password"
                  [(ngModel)]="newPassword" 
                   />
                  <span (click)="togglePasswordVisibility()" class="visible-auth"><img
                    src="../../../../../../assets/icons/hide.png" alt="visible" />
                  </span>
              </div>
              <div *ngIf="isSubmitting" class="invalid-feedback">
                <p>Please check the password.</p>
              </div>
            </div>
          </div>
 
          <div class="form-control-container">
            <div class="form-group form-relative">
              <label for="password" class="form-label">Confirm Password</label>
              <div class="password-icon">
                <input type="{{ showAuthToken2 ? 'text' : 'password' }}" class="form-control password" formControlName="newPassword"
                  [ngClass]="{ 'is-invalid': isSubmitting && !confirmPassword || valid==false}" id="confirmPassword"
                  placeholder="Confirm Password" [(ngModel)]="confirmPassword" 
                   />
                  <span (click)="togglePasswordVisibility2()" class="visible-auth"><img
                    src="../../../../../../assets/icons/hide.png" alt="visible" />
                  </span>
              </div>
              <div *ngIf="message" class="error-message">
                <p>{{ message }}</p>
              </div>
              <div *ngIf="isSubmitting" class="invalid-feedback">
                <p>Passwords must be same.</p>
              </div>
            </div>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  </section>
 
</div>