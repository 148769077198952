export const environment = {
  production: false,
  apiBaseUrl: "https://api-dev.pitaya.ai/pitaya/api/",
  apiAuthUrl: 'https://api-dev.pitaya.ai/common/authsvc',
  apiUtilityUrl: "https://api-dev.pitaya.ai/common/utilitysvc/api/v1",
  apiNotificationUrl: "https://api-dev.pitaya.ai/common/ntfsvc/api/v1",

  pitayaNotificationService: "https://api-dev.pitaya.ai/pitaya/alerts/v1",
  pitayaOrgManagementService: "https://api-dev.pitaya.ai/pitaya/api/v1",
  pitayaUserManagementService: "https://api-dev.pitaya.ai/pitaya/api/v1",
  pitayaCaseManagementService: "https://api-dev.pitaya.ai/pitaya/api/v1",
  pitayaRuleEngineService: "https://api-dev.pitaya.ai/pitaya/api/v1",
  pitayaSharedService: "https://api-dev.pitaya.ai/pitaya/api/v1",
  pitayaTaskInsightService: "https://api-dev.pitaya.ai/pitaya/api/v1",

  deviceDeployService: "https://device-onboard-test.mangosand-13fd4977.westus.azurecontainerapps.io/v1",

  pitayaApiKey:
    "LfMdq2Hrgf8qtPQKkwyP7Jmh7QByBl0ujxLmVD0XougclQ57j0kv0EeQ2mPETWRz",
  videoApiUrl: "https://52.188.82.150",
  notificationAuthToken : "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlX2lkIjoiYjZjNmVhOGUtYmZkZS00YzZhLWFjYjQtMGEwYWFhYTE0MThiIiwic2VydmljZV9uYW1lIjoiUGl0YXlhIiwiZXhwIjoxNzM1Njg5NTk5LCJwb2xpY2llcyI6eyIqIjoiKiJ9LCJyb2xlX2lkIjoiYTNlZDBlMjYtYzk2Yy00NzY5LWI0ZWEtNGE2YzZhODIwYjMyIiwicm9sZV9uYW1lIjoibWFzdGVyIiwiZXh0cmFfZGF0YSI6eyJhY2NvdW50X2lkIjoiODFkYWNkZDUtMzBmZS00NTAwLWExNDQtNjEyNGUwNzUyZDdkIn19.p899Rv3Wu0Nhv3NAM2RKFPQjOCwXSi3a93kkXKJiaMsf7MxRlUTloQyPsD8raBUnl2hjJchasvyvuQxu1-ZmZ7in8a0_IINo85cw6X2clJTVTGRCzQAwc_-nKB7KivYds3T8vISxWLj-3KLcDeJl7Eh9b1Q--crms4Xv1xJdhocihKmyKV9Ips_npo4pkyYnFL_FKxm-A4FvLG6fe5dmwIFcxO9vmQlOtoYmkVUeh8POuuNXAuxIGfXUNkpyljGZL8RRxtQQ0qXJz-H53usQlibsORpdvcxTWHFWbhRswHY5ONdQIk4_nVlT8WK9aZHMcJEJlqIAE_K8VYbGh7BKSQ",

  authConfig: {
    domain: "centific-auth.us.auth0.com",
    clientId: "lNDJC09yOUg5I8u94ifmzuoZbr9TLbtX",
    authorizationParams: {
      audience: "http://localhost:8000/validate",
      redirect_uri: window.location.origin,
    },
  },
};
