<table class="table transcript-table mt-3" data-pagination="true" data-page-list="[10, 25, 50, 100]" data-search="true"
data-show-refresh="true">
<thead>
  <tr >
    <th>CUSTOM</th>
    <th>SCO</th>
    <th>CAM#</th>
    <th>CAMPLACEMENT</th>
    <th>TIMESTAMP</th>
  </tr>
</thead>
<tbody>
 
   <tr *ngFor="let transcript of transcriptData" >
    <td>{{transcript.personId}}</td>
    <td>{{transcript.eventType}}</td>
    <td>{{transcript.camera}}</td>
    <td>{{transcript.cameraLocation}}</td>
    <td>{{transcript.activityOccuredTime}}</td>
 
  </tr>
</tbody>
</table>