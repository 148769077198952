import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LayoutService } from "src/app/services/layout.service";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {

  constructor(private router: Router, private layoutService: LayoutService) {}

  ngOnInit() {
    this.checkForSearchBarHide();
  }

  checkForSearchBarHide() {
    this.router.events.subscribe((event) => {
      if( this.router.url.includes('/store-insights') || this.router.url.includes('/case-management')
        || this.router.url.includes('/heat-map') ) {
        this.layoutService.hideSearchBar(true);
      } else {
        this.layoutService.hideSearchBar(false);
      }
    });
  }
}
