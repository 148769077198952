import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { data } from "jquery";
import { AlertMessagesService } from "src/app/services/alert-messages.service";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  @Input() public filteredOrgs: any[];
  @Input() public userData: any;
  @ViewChild("autoOrgDrop") autoOrgDrop: any;
  @ViewChild("autoStoreDrop") autoStoreDrop: any;
  @ViewChild("autoRoleDrop") autoRoleDrop: any;

  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  isEditing: boolean = false;
  addUserForm: FormGroup;
  pageSize: number = this.configurationService.pageSize;
  currentPage: number = 1;
  defaultTenantId: any = localStorage.getItem("tenantId");
  isUserExist: boolean = false;
  isEmailExist: boolean = false;
  isPhoneExist: boolean = false;
  isloggedin: boolean ;

  selectedTenantId: any;
  selectedTenantName: string;
  selectedStoreIDs: any;
  selectedUserId: any;
  selectedStoreNames: string;
  selectedRoleId: any;
  validPhoneNo: boolean = false;
  selectedRoleName: any = "";
  selectedPhoneNumber1: number;
  selectedPhoneNumber2: number;
  selectedCountryCode: any = "";

  selectedUuid: any;
  countriesList: any;

  isSubmitted: boolean = false;
  isVisible: boolean = false;
  appNumericOnly: boolean = true;
  processing: boolean = false;
  isRoleChanged: boolean = false;

  keyword = "name";
  placeholderOrg = "Select the Organization";
  placeholderStore = "Select the Store";
  placeholderRole = "Select the Role";

  orgData: any[] = [];
  storeData: any[] = [];
  filteredStores: any[] = [];
  rolesData: any[] = [];
  selectedStores: any[] = [];
  isChecked: boolean = true;
  checkUser: any;
  checkEmail: any;
  checkContact: any;
  loggedUser: any;
  orgId: any;
  countryCodeList: any = [];
  genderList = ["Female", "Male"];

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private alertMessagesService: AlertMessagesService,
    private configurationService: ConfigurationService
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.addUserForm = this.fb.group({
      tenantId: ["", [Validators.required]],
      roleId: ["", [Validators.required]],
      roleName: ["", [Validators.required]],
      storeIds: ["", [Validators.required]],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")
        ],
      ],
      countryCodeId: [null, [Validators.required]],
      phoneNo: [
        null,
        [
          Validators.required,
          this.phoneNumberMinLengthValidator(10),
          Validators.pattern("^[0-9]{10}$"),
        ],
      ],
      status: [false],
      isUpdate: [false],
    });
  }
  get fc() {
    return this.addUserForm.controls;
  }

  ngOnInit() {
    this.assignDefaultValues();
    this.configByRole();
  }

  configByRole() {
    if (this.loggedUser == "csm") {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      this.filterOrgData();
    }
    if (this.loggedUser == "org-admin") {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
      this.selectedTenantId = this.defaultTenantId;
      this.getStoreData();
      this.getRolesData();
    }
  }

  toggleOrgPanel(e: any): void {
    e.stopPropagation();
    this.autoOrgDrop.open();
  }

  toggleStorePanel(e: any): void {
    e.stopPropagation();
    this.autoStoreDrop.open();
  }

  toggleRolePanel(e: any): void {
    e.stopPropagation();
    this.autoRoleDrop.open();
  }

  assignDefaultValues() {
    if (this.userData) {
      this.isEditing = !this.isEditing;
      this.addUserForm.patchValue({
        firstName: this.userData?.firstName,
        lastName: this.userData?.lastName,
        userName: this.userData?.userName,
        email: this.userData?.email,
        phoneNo: this.userData?.mobileNumber,
        isloggedin:this.userData?.isloggedin,
      });      
      this.selectedTenantId = this.userData?.tenantUID;
      this.selectedTenantName = this.userData?.tenantName;
      this.selectedStoreNames = this.userData?.storeList;
      this.selectedStoreIDs = this.userData?.storeIDList;
      this.checkUser = this.userData?.userName;
      this.checkEmail = this.userData?.email;
      this.checkContact = this.userData?.mobileNumber;
      this.selectedRoleId = this.userData?.roleUID;
      this.selectedRoleName = this.userData?.roleName;
      this.selectedCountryCode = this.userData?.CountryUID;
      // this.selectedCountryId = this.userData?.countryUid;

      this.isChecked = this.userData?.status;
      if (this.userData?.tenantUID) {
        this.getStoreData();
        this.getRolesData();
      }
    }
    this.getCountries();
  }
  getCountries() {
    this.configurationService.getCountriesData().subscribe((resp: any) => {
      this.countriesList = resp.data;
      this.countryCodeList = resp.data;
      this.countriesList.map(
        (country: any) => (country.name = `${country.countryName}`)
      );
    });
  }

  filterOrgData() {
    this.filteredOrgs?.map((org: any) => (org.name = `${org.tenantName}`));
    this.filteredOrgs = this.filteredOrgs?.filter(
      (orgData: any) => orgData?.status === true
    );
  }

  changeOrganization(event: any) {
    this.selectedTenantId = event.tenantId;
    this.getStoreData();
    this.getRolesData();
  }

  getStoreData() {
    let defaultStatus : string = 'Active';
    this.configurationService
      .getStoresData(this.selectedTenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((data: any) => {
        this.storeData = data.stores;
        this.storeData = data.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.storeData.map((store: any) => (store.name = `${store.storeName}`));
        this.filteredStores = this.storeData.filter(
          (storeData: any) => storeData.status === true
        );
      });
  }

  changeStore(event: any) {
    this.selectedStoreIDs = event;
  }

  clearStores() {
    this.selectedStoreNames = "";
    this.filteredStores = [];
    this.selectedTenantName = "";
  }
  getRolesData() {
    if( !this.selectedTenantId ) {
      return;
    }
    this.configurationService
        .getAllRolesDataByOrgId(this.selectedTenantId)
        .subscribe((resp: any) => {
          this.rolesData = [];
          this.rolesData = resp.data;
          this.rolesData = resp.data.filter((role: any) => role.role_name !== "csm");
          this.rolesData.forEach((role: any) => {
            role.name = `${role.role_name}`;
            role.roleUID = `${role.role_id}`;
        })
        },
        (error) => {
          this.rolesData = [];
        }
        );
  }
  changeRole(event: any) {
    this.selectedRoleId = event.roleUID;
    this.isRoleChanged = true;
  }

  clearRoles() {
    this.selectedRoleName = "";
    this.selectedRoleId = null;
  }

  changeCountryCode(event: any) {
    this.selectedCountryCode = event.target.value;
  }

  emailCheckUnique() {
    if (this.isEditing) {
      if (this.userData?.email != this.addUserForm.controls["email"].value) {
        this.configurationService
          .getEmailCheckUnique(this.checkEmail)
          .subscribe((resp: any) => {
            if (resp === true && this.checkEmail !== "") {
              this.isEmailExist = true;
            } else {
              this.isEmailExist = false;
            }
          });
      }
    } else {
      this.configurationService
        .getEmailCheckUnique(this.checkEmail)
        .subscribe((resp: any) => {
          if (resp === true && this.checkEmail !== "") {
            this.isEmailExist = true;
          } else {
            this.isEmailExist = false;
          }
        });
    }
    this.isEmailExist = false;
  }

  phoneNumberMinLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (typeof control?.value === "string") {
        const phoneNumber = control.value.replace(/\D/g, ""); // Remove non-numeric characters
        if (phoneNumber.length < minLength) {
          return { minLength: true };
        }
      }
      return null;
    };
  }

  onSubmit() {
    this.isSubmitted = true;
    this.processing = true;

    if (this.userData) {
      this.addUserForm.patchValue({
        userId: this.userData?.userId,
        isUpdate: true,
        roleId: this.selectedRoleId,
        roleName: this.userData?.roleName
      });
    }

    this.addUserForm.patchValue({
      tenantId: this.selectedTenantId,
      storeIds: this.selectedStoreIDs,
      roleId: this.selectedRoleId,
      roleName: this.userData?.roleName,
      phoneNo: this.checkContact,
    });

    if(this.isRoleChanged){
      this.addUserForm.patchValue({
      roleName: this.selectedRoleName?.name,
      });
    }

    Object.keys(this.addUserForm.controls).forEach((controlName) => {
      const control = this.addUserForm.get(controlName);
      // Check for validation errors
      if (control?.invalid) {
        this.processing = false;
      }
    });

    if (this.userData?.userId && this.addUserForm.valid) {
      if (
        !this.isEmailExist &&
        !this.isPhoneExist &&
        this.selectedStoreNames.length >= 0
      ) {
        this.configurationService
          .updateUser(this.userData?.userId, this.addUserForm.value)
          .subscribe(
            (resp: any) => {
              if (resp.message === "Success") {
                this.processing = false;
                this.activeModal.close();
                this.alertMessagesService.alertWithSuccess("User Updated successfully.");
              }
            },
            (error) => {
              this.processing = false;
            }
          );
      }
    } else if (this.addUserForm.valid) {
      if (
        !this.isEmailExist &&
        !this.isPhoneExist &&
        this.selectedStoreNames.length >= 0
      ) {
        this.configurationService.createUser(this.addUserForm.value).subscribe(
          (resp: any) => {
            if (resp.message === "Success") {
              this.processing = false;
              this.activeModal.close();
              this.alertMessagesService.alertWithSuccess("User Added successfully.");
            }
          },
          (error) => {
            this.processing = false;
          }
        );
      }
    } else {
      return;
    }

    // if (
    //   !this.isEmailExist
    //   this.selectedStoreNames.length >= 0
    // ) {
    //   this.configurationService
    //     .updateUser(this.userData?.userId, this.addUserForm.value)
    //     .subscribe(
    //       (resp: any) => {
    //         if (resp.message === "Success") {
    //           this.processing = false;
    //           this.activeModal.close();
    //           Swal.fire({
    //             title: "Success!",
    //             text: "User Updated successfully.",
    //             icon: "success",
    //           });
    //         }
    //       },
    //       (error) => {
    //         this.processing = false;
    //       }
    //     );
    // } else {
    //   if (
    //     !this.isUserExist &&
    //     !this.isEmailExist &&
    //     !this.isPhoneExist &&
    //     this.checkContact?.length === 10
    //   ) {
    //     this.configurationService.createUser(this.addUserForm.value).subscribe(
    //       (resp: any) => {
    //         if (resp.message === "Success") {
    //           this.processing = false;
    //           this.activeModal.close();
    //           Swal.fire({
    //             title: "Success!",
    //             text: "User Added successfully.",
    //             icon: "success",
    //           });
    //         }
    //       },
    //       (error) => {
    //         this.processing = false;
    //       }
    //     );
    //   }
    // }
  }

}
