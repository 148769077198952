import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
  selector: "input[type=email][appNoUppercase]",
})
export class NoUppercaseDirective {
  constructor(private el: ElementRef) {}

  @HostListener("input", ["$event.target.value"])
  onInput(value: string) {
    this.el.nativeElement.value = value.toLowerCase();
  }
}
