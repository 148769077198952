<div class="feedback-modal">
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  <div class="modal-body">
    <p class="feedback-title mb-3">Enter The Hyperion Auth Token:</p>
    <div class="user-form-group mb-2">
      <label for="feedback">Enter Auth Token:</label>
      <input type="{{ showAuthToken ? 'text' : 'password' }}" id="feedback" class="form-control"
        placeholder="Enter Auth Token" required [(ngModel)]="tokenVerification" autocomplete="new-password"
        (ngModelChange)="tokenValidation()" [ngClass]="{'is-invalid': isTokenValidated && visibleAuth}" (input)="onBlurMethod()"/>
      <span (click)="togglePasswordVisibility()" class="visible-auth" *ngIf="tokenVerification != ''"><img
          src="../../../../../assets/icons/hide.png" alt="visible" /></span>
      <p class="server-error" *ngIf="visibleAuth">{{authError}}</p>
      <p class="token-validated" *ngIf="visibleAuthSuccess">{{tokenValidated}}</p>
    </div>
    <button type="button" class="btn btn-feed-submit" (click)="submitFeedback()" *ngIf="!isSubmitted">
      Submit
    </button>
    <button type="button" class="btn btn-feed-close" (click)="activeModal.dismiss('Cross click')" *ngIf="isSubmitted">
      Close
    </button>
  </div>
</div>