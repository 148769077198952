import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  public orgGridActionEvent: EventEmitter<any> = new EventEmitter<any>();
  public storeGridActionEvent: EventEmitter<any> = new EventEmitter<any>();
  public userGridActionEvent: EventEmitter<any> = new EventEmitter<any>();
  public cameraGridActionEvent: EventEmitter<any> = new EventEmitter<any>();
  public deviceGridActionEvent: EventEmitter<any> = new EventEmitter<any>();
  public userAlertGridActionEvent: EventEmitter<any> = new EventEmitter<any>();
  public useCaseGridActionEvent: EventEmitter<any> = new EventEmitter<any>();
  public roleGridActionEvent: EventEmitter<any> = new EventEmitter<any>();

  public alertTabClicked: EventEmitter<any> = new EventEmitter<any>();
  public cameraTabClicked: EventEmitter<any> = new EventEmitter<any>();
  public deviceTabClicked: EventEmitter<any> = new EventEmitter<any>();
  public storeTabClicked: EventEmitter<any> = new EventEmitter<any>();
  public rolesTabClicked: EventEmitter<any> = new EventEmitter<any>();
  public userTabClicked: EventEmitter<any> = new EventEmitter<any>();
  public usecaseTabClicked: EventEmitter<any> = new EventEmitter<any>();

  private selectedOrganizationSource = new BehaviorSubject<any>(null);
  selectedOrganization$ = this.selectedOrganizationSource.asObservable();

  private selectedStoreSource = new BehaviorSubject<any>(null);
  selectedStore$ = this.selectedStoreSource.asObservable();

  private caseFileUploadProgressData = new BehaviorSubject<any>(null);
  caseFileUploadProgress$ = this.caseFileUploadProgressData.asObservable();

  private checkForLogout = new BehaviorSubject<boolean>(false);
  checkForLogout$ = this.checkForLogout.asObservable();
  
  setSelectedOrganization(org: any) {
    this.selectedOrganizationSource.next(org);
  }

  setSelectedStore(store: any) {
    this.selectedStoreSource.next(store);
  }

  resetOrgStoreValues() {
    this.selectedOrganizationSource.next("");
    this.selectedStoreSource.next("");
  }

  setCaseFileUploadProgress(fileData: any) {
    this.caseFileUploadProgressData.next(fileData);
  }

  setCheckForLogout(flag: boolean) {
    this.checkForLogout.next(flag);
  }
  
  emitOrgGridActionEvent(data: any) {
    this.orgGridActionEvent.emit(data);
  }

  emitStoreGridActionEvent(data: any) {
    this.storeGridActionEvent.emit(data);
  }

  emitUserGridActionEvent(data: any) {
    this.userGridActionEvent.emit(data);
  }

  emitCameraGridActionEvent(data: any) {
    this.cameraGridActionEvent.emit(data);
  }

  emitRoleGridActionEvent(data: any) {
    this.roleGridActionEvent.emit(data);
  }

  emitDeviceGridActionEvent(data: any) {
    this.deviceGridActionEvent.emit(data);
  }

  emitUserAlertGridActionEvent(data: any) {
    this.userAlertGridActionEvent.emit(data);
  }
  
  emitUseCaseGridActionEvent(data: any) {
    this.useCaseGridActionEvent.emit(data);
  }

  emitCameraTabClickedEvent(isClicked: boolean){
    this.cameraTabClicked.emit(isClicked);
  }

  emitDeviceTabClickedEvent(isClicked: boolean){
    this.deviceTabClicked.emit(isClicked);
  }

  emitUsecaseTabClickedEvent(isClicked: boolean){
    this.usecaseTabClicked.emit(isClicked);
  }

  emitAlertTabClickedEvent(isClicked: boolean){
    this.alertTabClicked.emit(isClicked);
  }

  emitStoreTabClickedEvent(isClicked: boolean){
    this.storeTabClicked.emit(isClicked);
  }

  emitUserTabClickedEvent(isClicked: boolean){
    this.userTabClicked.emit(isClicked);
  }

  emitRolesTabClickedEvent(isClicked: boolean){
    this.rolesTabClicked.emit(isClicked);
  }
  
}
