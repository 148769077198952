import { Component, Input, OnInit} from "@angular/core";
import { AppStateService } from "src/app/services/app-state.service";
import { ScenarioService } from "src/app/services/scenario.service";

@Component({
  selector: "app-log-tab",
  templateUrl: "./log-tab.component.html",
  styleUrls: ["./log-tab.component.scss"],
})
export class LogTabComponent implements OnInit {
  logData: any;
  @Input() selectedTask: any;

  constructor(
    private scenarioService: ScenarioService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.getLogData();
  }

  getLogData() {
    this.logData = this.selectedTask?.tabs.log;
    // let data = this.appStateService.getJsonData();
    // const taskIndex = data?.taskDetails.findIndex(
    //   (task: any) => task.taskId === this.selectedTask.taskId
    // );
    // data.taskDetails[taskIndex] = this.selectedTask;
    // this.appStateService.setJsonData(data);

    // this.scenarioService.getTranscriptionData().subscribe((data: any) => {
    //   this.logData = this.selectedTask.tabs.log;
    //   let data1 = this.appStateService.getJsonData();
    //   const taskIndex = data1?.taskDetails.findIndex(
    //     (task: any) => task.taskId === this.selectedTask.taskId
    //   );
    //   data1.taskDetails[taskIndex] = this.selectedTask;
    //   this.appStateService.setJsonData(data1);
    // });
  }
}
