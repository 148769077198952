<div class="row">
  <section class="login">
    <div class="wrapper">
      <div class="login-form form">
        <!-- <div class="form-logo mb-3">
          <img src="../../../../assets/logo.png" alt="">
        </div> -->
        <h3 class="text-center mb-4">Reset Password</h3>
        <form [formGroup]="resetForm" (ngSubmit)="submitForm()">
          <div class="form-control-container">
            <div class="form-group form-relative">
              <label for="password" class="form-label">Current Password<sup>*</sup></label>
              <div class="password-icon">
                <input type="{{ showAuthToken ? 'text' : 'password' }}" class="form-control password" formControlName="currentPassword"
                  [ngClass]="{ 'is-invalid': isSubmitting && !currentPassword || valid==false }" id="currentPassword" placeholder="Enter Here"
                  [(ngModel)]="currentPassword" (input)="currentPasswordError = false"
                    />
                  <span (click)="togglePasswordVisibility()" class="visible-auth">
                    <img src="../../../../../../assets/icons/hide.png" alt="visible" *ngIf="!showAuthToken" />
                    <img src="../../../../../../assets/icons/show.png" alt="invisible" *ngIf="showAuthToken" />
                  </span>
              </div>
              <div *ngIf="currentPasswordError" class="error-message">
                <span>
                  <img src="../../../../../../assets/icons/information.png" alt="information"/>
                </span><p>{{ message }}</p>
              </div>
              <div *ngIf="isSubmitting" class="invalid-feedback">
                <p>Please check the password.</p>
              </div>
            </div>
          </div>

          <div class="form-control-container">
            <div class="form-group form-relative">
              <label for="password" class="form-label">New Password<sup>*</sup></label>
              <div class="password-icon">
                <input type="{{ showAuthToken1 ? 'text' : 'password' }}" class="form-control password" formControlName="newPassword"
                  [ngClass]="{ 'is-invalid': isSubmitting && !newPassword || valid==false }" id="newPassword" placeholder="Enter Here"
                  [(ngModel)]="newPassword" (blur)="comparePasswords()"
                    />
                  <span (click)="togglePasswordVisibility1()" class="visible-auth">
                    <img src="../../../../../../assets/icons/hide.png" alt="visible" *ngIf="!showAuthToken1" />
                    <img src="../../../../../../assets/icons/show.png" alt="invisible" *ngIf="showAuthToken1" />
                  </span>
              </div>
              <div *ngIf="newPasswordError" class="error-message">
                <span>
                  <img src="../../../../../../assets/icons/information.png" alt="information"/>
                </span><p>{{ message }}</p>
              </div>
              <div *ngIf="isSubmitting" class="invalid-feedback">
                <p>Please check the password.</p>
              </div>
            </div>
          </div>
  
          <div class="form-control-container">
            <div class="form-group form-relative">
              <label for="password" class="form-label">Confirm Password<sup>*</sup></label>
              <div class="password-icon">
                <input type="{{ showAuthToken2 ? 'text' : 'password' }}" class="form-control password" formControlName="newPassword"
                  [ngClass]="{ 'is-invalid': isSubmitting && !confirmPassword || valid==false}" id="confirmPassword"
                  placeholder="Enter Here" [(ngModel)]="confirmPassword" (input)="checkPasswordMatch()"
                    />
                  <span (click)="togglePasswordVisibility2()" class="visible-auth">
                    <img src="../../../../../../assets/icons/hide.png" alt="visible" *ngIf="!showAuthToken2" />
                    <img src="../../../../../../assets/icons/show.png" alt="invisible" *ngIf="showAuthToken2" />
                  </span>
              </div>
              <div *ngIf="confirmPasswordError" class="error-message">
                <span>
                  <img src="../../../../../../assets/icons/information.png" alt="information"/>
                </span><p>{{ message }}</p>
              </div>
              <div *ngIf="isSubmitting" class="invalid-feedback">
                <p>Passwords must be same.</p>
              </div>
            </div>
          </div>
          <div class="instructions">
            <p class="instructions-heading">Password Must Contain</p>
            <p><sup>*</sup>Min 10 Characters</p>
            <p><sup>*</sup>One uppercase And One lowercase</p>
            <p><sup>*</sup>One Number</p>
            <p><sup>*</sup>One Special Character (Ex: &#64;#$%)</p>
          </div>
          <div class="password-btns">
            <button class="cancel-btn" type="button" (click)="closePage()">Cancel</button>
            <button [disabled]="confirmPasswordError || newPasswordError || currentPasswordError" class="save-btn" type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>

<ng-template #otpVerification let-modal>
  <div class="modal-content custom-modal-content">
    <div class="modal-header">
      <h4 class="modal-title text-center">Reset Password</h4>
    </div>
    <div class="modal-body">
      <h6>OTP Verification</h6>
      <p class="mb-3">{{otpVerificationMessage}}</p>
      <div class="otp-input">
        <input [(ngModel)]="input1Value" [disabled]="remainingTime ===  0" type="text" class="form-control form-control-solid rounded" maxlength="1">
        <input [(ngModel)]="input2Value" [disabled]="remainingTime ===  0" type="text" class="form-control form-control-solid rounded" maxlength="1">
        <input [(ngModel)]="input3Value" [disabled]="remainingTime ===  0" type="text" class="form-control form-control-solid rounded" maxlength="1">
        <input [(ngModel)]="input4Value" [disabled]="remainingTime ===  0" type="text" class="form-control form-control-solid rounded" maxlength="1">
      </div>
      <div class="mb-4 d-flex justify-content-between">
        <p class="timer"><span><img
          src="../../../../../../assets/icons/stopwatch.png" alt="timer" />
        </span>{{ formatTime(remainingTime) }}</p>
        <button class="resend-link" (click)="ResendOTP()">Resend OTP</button>
      </div>
      <div class="password-btns">
        <button class="cancel-btn" (click)="dismissModal(modal)">Cancel</button>
        <button class="save-btn"  (click)="submitOTP(modal)">Submit</button>
      </div>
    </div>
  </div>
</ng-template>