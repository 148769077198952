import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigurationService } from "src/app/services/configuration.service";
import { AlertMessagesService } from "src/app/services/alert-messages.service";

@Component({
  selector: "app-add-organisation",
  templateUrl: "./add-organisation.component.html",
  styleUrls: ["./add-organisation.component.scss"],
})
export class AddOrganisationComponent implements OnInit {
  @Input() public orgData: any;
  @ViewChild("autoCountryDrop") autoCountryDrop: any;
  @ViewChild("autoStateDrop") autoStateDrop: any;
  @ViewChild("autoCityDrop") autoCityDrop: any;

  isEditing: boolean = false;
  countriesList: any;
  statesList: any;
  cityList: any;
  isExist: boolean = false;
  isNoValid: boolean = false;
  checkOrg: any;

  addOrgForm: FormGroup;
  selectedTenantId: any;
  selectedCountryId: any = "";
  selectedCountryName: string = "";
  selectedStateId: any = "";
  selectedStateName: string = "";
  selectedCityId: any = "";
  selectedCityName: any = "";
  selectedCountryCode: any = "";
  selectedOrgPhone: number;

  selectedPhoneNumber1: number;
  selectedPhoneNumber2: number;
  isSubmitted: boolean = false;
  isVisible: boolean = false;
  isChecked: boolean = true;
  processing: boolean = false;
  validPhoneNo: boolean = false;
  statusCheck: any = 1;

  keyword = "name";
  placeholderOrg = "Select the Organization";
  placeholderCountry = "Enter the Country Name";
  placeholderState = "Enter the State Name";
  placeholderCity = "Enter the City Name";
  placeholderZip = "Enter the Zip Code";
  placeholderTimezone = "Select The Timezone";

  countryCodeList = [
    {
      countryUID: "10123faf-9453-4bbe-aaa1-441f257647d6",
      countryCode: "(India) +91 ",
    },
    {
      countryUID: "993f9a3c-0aa5-43b0-b50a-d15c326df53e",
      countryCode: "(US) +1",
    },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private configurationService: ConfigurationService,
    private fb: FormBuilder,
    private alertMessagesService: AlertMessagesService,
  ) {
    this.addOrgForm = this.fb.group({
      tenantName: ["", [Validators.required]],
      orgAddressL1: ["", [Validators.required, Validators.max(100)]],
      orgAddressL2: ["", [Validators.maxLength(100)]],
      cityUid: ["", [Validators.required]],
      stateUid: ["", [Validators.required]],
      countryUid: ["", [Validators.required]],
      countryCodeId: ["", [Validators.required]],
      orgPhoneNo1: [
        null,
        [
          Validators.required,
          this.phoneNumberMinLengthValidator(10),
          Validators.pattern("^[0-9]{10}$"),
        ],
      ],

      orgPhoneNo2: [
        null,
        [
          this.phoneNumberMinLengthValidator(10),
          Validators.pattern("^[0-9]{10}$"),
        ],
      ],
      orgEmail1: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")     
        ],
      ],
      orgEmail2: [
        "",
        [
          Validators.email,
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")     
        ],
        
      ],
      status: [true],
    });
  }

  get fc() {
    return this.addOrgForm.controls;
  }

  ngOnInit() {
    this.assignDefaultValues();
  }

  toggleCountryPanel(e: any): void {
    e.stopPropagation();
    this.autoCountryDrop.open();
  }

  toggleStatePanel(e: any): void {
    e.stopPropagation();
    this.autoStateDrop.open();
  }

  toggleCityPanel(e: any): void {
    e.stopPropagation();
    this.autoCityDrop.open();
  }

  assignDefaultValues() {
    if (this.orgData) {
      this.isEditing = true;
      this.addOrgForm.patchValue({
        tenantName: this.orgData?.tenantName,
        orgAddressL1: this.orgData?.orgAddressL1,
        orgAddressL2: this.orgData?.orgAddressL2,
        orgPhoneNo1: this.orgData?.orgPhoneNo1,
        orgPhoneNo2: this.orgData?.orgPhoneNo2,
        orgEmail1: this.orgData?.orgEmail1,
        orgEmail2: this.orgData?.orgEmail2,
      });

      this.checkOrg = this.orgData?.tenantName;
      this.selectedCountryId = this.orgData?.countryUid;
      this.selectedCountryName = this.orgData?.countryName;
      this.selectedStateId = this.orgData?.stateUid;
      this.selectedStateName = this.orgData?.stateName;
      this.selectedCityId = this.orgData?.cityUid;
      this.selectedCityName = this.orgData?.cityName;
      this.selectedCountryCode = this.orgData?.countryCodeId;
      this.isChecked = this.orgData?.status;
    }
    this.getCountries();
  }

  getCountries() {
    this.configurationService.getCountriesData().subscribe((resp: any) => {
      this.countriesList = resp.data;
      this.countriesList.map(
        (country: any) => (country.name = `${country.countryName}`)
      );
    });
  }

  changeCountry(event: any) {
    this.selectedCountryId = event.countryUID;
    this.getStatesList(this.selectedCountryId);
    this.selectedStateName = "";
    this.selectedCityName = "";
  }

  clearCountry() {
    this.selectedCountryName = "";
    this.selectedCountryId = null;
    this.selectedStateName = "";
    this.selectedStateId = null;
    this.cityList = [];
    this.selectedCityName = "";
    this.selectedCityId = null;
  }

  getStatesList(selectedCountryId: number) {
    this.configurationService
      .getStatesData(selectedCountryId)
      .subscribe((resp: any) => {
        this.statesList = resp.data;
        this.statesList.map(
          (state: any) => (state.name = `${state.stateName}`)
        );
      });
  }
  changeState(event: any) {
    this.selectedStateId = event.stateUID;
    this.getCitiesList(this.selectedCountryId, this.selectedStateId);
    this.selectedCityName = "";
  }
  clearState() {
    this.selectedStateName = "";
    this.selectedStateId = null;
    this.selectedCityName = "";
    this.selectedCityId = null;
    this.getStatesList(this.selectedCountryId);
  }

  getCitiesList(selectedCountryId: number, selectedStateId: number) {
    this.configurationService
      .getCitiesData(selectedCountryId, selectedStateId)
      .subscribe((resp: any) => {
        this.cityList = resp.data;
        this.cityList.map((city: any) => (city.name = `${city.cityName}`));
      });
  }

  changeCity(event: any) {
    this.selectedCityId = event.cityUID;
    this.selectedCityName = event.cityName;
  }

  clearCity() {
    this.selectedCityId = null;
    this.selectedCityName = "";
    this.getCitiesList(this.selectedCountryId, this.selectedStateId);
  }
  changeCountryCode(event: any) {
    this.selectedCountryCode = event.target.value;
  }

  orgNameCheckUnique() {
    if (!this.isEditing) {
      this.configurationService
        .getOrgNameUnigue(this.checkOrg)
        .subscribe((resp: any) => {
          if (resp === true) {
            this.isExist = true;
          } else {
            this.isExist = false;
          }
        });
    }
    this.isExist = false;
  }

  phoneNumberMinLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (typeof control?.value === "string") {
        const phoneNumber = control.value.replace(/\D/g, ""); // Remove non-numeric characters
        if (phoneNumber.length < minLength) {
          return { minLength: true };
        }
      }
      return null;
    };
  }

  submitForm() {
    this.isSubmitted = true;
    this.processing = true;
    const selectedTenantId = this.orgData?.tenantId;
    this.addOrgForm.patchValue({
      tenantId: this.selectedTenantId,
      cityName: this.selectedCityName,
      cityUid: this.selectedCityId,
      countryUid: this.selectedCountryId,
      stateUid: this.selectedStateId,
    });

    if (this.orgData) {
      this.addOrgForm.patchValue({
        cityName: this.orgData?.cityName,
      });
      this.checkOrg = this.orgData?.tenantName;
    }

    Object.keys(this.addOrgForm.controls).forEach((controlName) => {
      const control = this.addOrgForm.get(controlName);
      // Check for validation errors
      if (control?.invalid) {
        this.processing = false;
      }
    });

    if (this.orgData?.tenantId && this.addOrgForm.valid) {
      if (!this.isExist) {
        this.configurationService
          .updateNewOrg(selectedTenantId, this.addOrgForm.value)
          .subscribe(
            (resp: any) => {
              if (resp.message === "Success") {
                this.processing = false;
                this.activeModal.close();
                this.alertMessagesService.alertWithSuccess("Organization Updated successfully.");
              }
            },
            (error) => {
              this.processing = false;
            }
          );
      }
    } else if (this.addOrgForm.valid) {
      if (!this.isExist) {
        this.configurationService.createNewOrg(this.addOrgForm.value).subscribe(
          (resp: any) => {
            if (resp.message === "Success") {
              this.processing = false;
              this.activeModal.close();
              this.alertMessagesService.alertWithSuccess("Organization Added successfully.");
            }
          },
          (error) => {
            this.processing = false;
          }
        );
      }
    } else {
      return;
    }
  }

}
