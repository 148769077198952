import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.scss']
})
export class HeatMapComponent implements OnInit, AfterViewInit {

  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @Input() public heatMapDataSet: any = [];
  @Input() public colorArrayLegends: any = [];
  @Input() public popUpView: boolean = false;
  @Input() public currentTime: any = '';
  @Input() public cameraData: any = [];
  @Input() public selectedCamera: any = '';
  @Input() public heatMapCameraImage: string = '';
  @Input() public storeName: any = '';
  @Output() selectedCameraValue: EventEmitter<string> = new EventEmitter<string>();
 
  constructor() {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    if( this.popUpView && this.heatMapDataSet.length > 0 && this.heatMapCameraImage ) {
      this.drawColoredSquares();
    } else {
      const canvas = this.canvas?.nativeElement;
      const ctx: any = canvas?.getContext('2d');
      ctx?.reset();
    }
  }

  ngOnChanges() {
    if( this.heatMapDataSet.length > 0 && this.heatMapCameraImage ) {
      this.drawColoredSquares();
    } else {
      const canvas = this.canvas?.nativeElement;
      const ctx: any = canvas?.getContext('2d');
      ctx?.reset();
    }
  }

  selectCamera(event:any) {
    this.selectedCameraValue.emit(event.target.value);
  }
  
  drawColoredSquares() {
    const canvas = this.canvas.nativeElement;
    const ctx : any = canvas.getContext('2d');
  
    const image = new Image();
    image.src = this.heatMapCameraImage; // Replace with the path to your image
  
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
  
      this.heatMapDataSet.forEach((item:any) => {
        if( item.color ) {
          ctx.strokeStyle = item.color; // Change stroke color as needed
          ctx.fillStyle = item.color;
          ctx.strokeRect(item.x0, item.y0, item.x1 - item.x0, item.y1 - item.y0);
          ctx.fillRect(item.x0, item.y0, item.x1 - item.x0, item.y1 - item.y0);
        }
      });
    };
  }
  
}


