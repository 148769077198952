<div class="case-info-header d-flex justify-content-between align-items-center">
  <div class="d-flex all-cases">
    <div class="all-cases-back" routerLink="/case-management">All Cases</div>
    <div class="header-container">
      <h3>{{(taskInfo?.source_data?.usecase_label) ? taskInfo?.source_data?.usecase_label : '--' }}</h3>
        <div *ngIf="taskInfo?.duplicates_count>0 " class="duplicates-info">| Duplicate
          <button [disabled]="incidentNumber <= 1"
            (click)="duplicateCaseData(incidentNumber,-1); " style="background-color: white; border: 1px solid white;">
            <img src="../../../../assets/icons/lessthan.png" alt="" style="cursor: pointer;" />
          </button>
          Incident {{  incidentNumber }} / {{taskInfo?.duplicates_count + 1}}
          <button [disabled]="incidentNumber > taskInfo?.duplicates_count"
            (click)="duplicateCaseData( incidentNumber,1); " style="background-color: white; border: 1px solid white;">
            <img src="../../../../assets/icons/greaterthan.png" alt="" style="cursor: pointer;" />
          </button>
        </div>  
    </div>
  </div>

  <div class="d-flex justify-content-center align-items-center download-section">
    <div class="inline-loading-spinner" *ngIf="downloading"></div>
    <button class="download-report" (click)="checkReportStatus()"
      [disabled]="taskInfo?.status?.allow_case_update==false || !taskInfo?.id || downloading">
      <i class="bi bi-download"></i> Download Report</button>
  </div>

</div>

<div class="row m-0 p-0 mt-2">
  <div class="col-md-6">
    <div class="case-body video-section">
      <!-- <p>Section - 1</p> -->
      <div class="video-play-section">
        <div class="video">
          <!-- Video Player Component -->
          <app-video-player [displaymeta]="false" [meta]="taskInfo" [media]="taskVideoUrl"
            [taskVideoStartTime]="taskCreatedTime" [taskVmsStartTime]="taskVmsPlayTime" [autoplay]="false"
            [controls]="true"></app-video-player>
        </div>
        <div class="video-desc">
          <div>{{(taskInfo?.description) ? taskInfo?.description : '--'}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="case-body case-info">
      <div class="info-save">
        <button class="btn btn-submit" (click)="updateCaseInfoData()" [disabled]="disableSaveBtn">Save Changes</button>
      </div>
      <ul class="task-info">
        <li class="d-flex justify-content-start align-items-center">
          <div class="case-title">Store Name</div>
          <div class="case-action">{{taskInfo?.source_data?.store_name}}</div>
        </li>
        <li class="d-flex justify-content-start align-items-center">
          <div class="case-title">Camera Location</div>
          <div class="case-action">{{taskInfo?.source_data?.camera_location}}</div>
        </li>
        <li class="d-flex justify-content-start align-items-center">
          <div class="case-title">Date &amp; Time</div>
          <div class="case-action" *ngIf="taskInfo?.source_data?.case_detected_at">
            {{taskInfo?.source_data?.case_detected_at
            | timeZoneDateMoment: "MMMM dd, yyyy hh:mm:ss a" : taskInfo?.source_data?.store_timezone }}</div>
        </li>
        <li class="d-flex justify-content-start align-items-center">
          <div class="case-title">Case Status</div>
          <div class="case-action action-dropdown">
            <select class="form-control search-input" [(ngModel)]="caseStatus" (change)="selectStatus($event)"
              [disabled]="taskInfo?.status?.allow_case_update==false || this.incidentNumber > 1">
              <option *ngFor="let status of statusList" [value]="status.slug_name">
                {{status.name}}
                <!-- [selected]="status.slug_name==taskInfo?.status?.slug_name" -->
              </option>
            </select>
          </div>
        </li>
        <li class="d-flex justify-content-start align-items-center">
          <div class="case-title">Severity</div>
          <div class="case-action action-dropdown">
            <select class="form-control search-input" [(ngModel)]="caseSeverity" (change)="selectSeverity($event)"
              [disabled]="taskInfo?.status?.allow_case_update==false || this.incidentNumber > 1">
              <option *ngFor="let severity of severityList" [value]="severity">
                {{severity}}
                <!-- [selected]="severity==taskInfo?.severity" -->
              </option>
            </select>
          </div>
        </li>

        <li class="d-flex justify-content-start align-items-center">
          <div class="case-title">Assignee</div>
          <div class="case-action">
            <ul class="assignee-list d-flex justify-content-start">
              <li *ngFor="let assignee of taskInfo?.assignee"><span class="assignee-profile"> <img
                    src="{{(assignee?.profile_image)?assignee.profile_image:'../../../../assets/user.png'}}"
                    alt="" />{{assignee.name}}</span></li>
              <!-- <li><span class="assignee-profile"> <img src="../../../../assets/user.png" alt="" />John M.</span></li> -->
              <!-- <li><button class="assignee-btn"><img src="../../../../assets/icons/round plus.png" alt="" />New</button>
              </li> -->
            </ul>
          </div>
        </li>
        <li class="d-flex justify-content-start align-items-center">
          <div class="case-title">Approver</div>
          <div class="case-action">
            <ul class="assignee-list d-flex justify-content-start">
              <li *ngFor="let approver of taskInfo?.approver"><span class="assignee-profile"> <img
                    src="{{(approver?.profile_image)?approver.profile_image:'../../../../assets/user.png'}}"
                    alt="" />{{approver.name}}</span></li>
            </ul>
          </div>
        </li>
        <li class="d-flex justify-content-start align-items-center">
          <div class="case-title">Incident Type</div>
          <div class="case-action">
            <div class="assignee-list incident-wrap d-flex justify-content-start">
              <div *ngFor="let incident of taskInfo?.primaryIncident"><button class="incident-btn"
                  [disabled]="this.incidentNumber > 1">{{incident}}</button>
              </div>
              <ng-container *ngFor="let newIncident of caseInfoPayload.newIncidentList">
                <div class="impact-notes impact-data">
                  <input type="text" class="form-control" [(ngModel)]="newIncident.name" placeholder="incident type">
                </div>
              </ng-container>
              <!-- <div (click)="addNewIncident()"><button class="assignee-btn"><img src="../../../../assets/icons/round plus.png" alt="" />New</button></div> -->
              <!-- <div><button class="assignee-btn"><img src="../../../../assets/icons/round plus.png" alt="" />New</button></div> -->
            </div>
          </div>
        </li>
        <li class="d-flex justify-content-start align-items-center">
          <div class="case-title">Impact</div>
          <div class="d-flex justify-content-start">
            <div class="case-action action-dropdown me-2">
              <select class="form-control search-input" [(ngModel)]="impactRank" (change)="selectRank($event)"
                [disabled]="taskInfo?.status?.allow_case_update==false || this.incidentNumber > 1">
                <option value="" disabled hidden>Select</option>
                <option *ngFor="let impact of impactList" [value]="impact">{{impact}}</option>
                <!-- [selected]="impact==taskInfo?.impact?.rank" -->
              </select>
            </div>
            <div class="case-action me-2">
              <div class="impact-dollar impact-data"><span>$</span><input type="number" [appNumericOnly]="true"
                  placeholder="Amount" class="form-control loss-value" [(ngModel)]="impactAmount"
                  (input)="selectAmount($event)"
                  [disabled]="taskInfo?.status?.allow_case_update==false || this.incidentNumber > 1">
              </div>
            </div>
            <div class="case-action">
              <div class="impact-notes impact-data">
                <input type="text" class="form-control" [(ngModel)]="impactNote" placeholder="Notes"
                  (input)="selectNotes($event)"
                  [disabled]="taskInfo?.status?.allow_case_update==false || this.incidentNumber > 1">
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-6">
    <div class="case-body case-transcript">
      <!-- <p>Section - 3</p> -->

      <div class="task-details-header">
        <div class="selected-task1">
          <div class="case-management">
            <div class="sub-tab align-items-top d-flex justify-content-between" role="tabpanel">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="insight active" href="#attachment_tab" data-bs-toggle="tab" role="tab"
                    aria-controls="completed" aria-selected="false" (click)="selectedTabClick('attachment_tab')">
                    Attachment
                  </a>
                </li>
              </ul>
            </div>
            <div class="sub-tab-content tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="attachment_tab" role="tabpanel"
                aria-labelledby="attachment_tab" *ngIf="taskInfo?.tabs_data">
                <app-attachment-tab [selectedTask]="taskInfo" (fileDeleteEvent)="fileDeleteEvent($event)"
                  (fileUploadEvent)="fileUploadEvent($event)"></app-attachment-tab>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="col-md-6">
    <div class="case-body case-tabs">
      <!-- <p>Section - 4</p> -->

      <div class="task-details-header">
        <div class="selected-task1">
          <div class="case-management">
            <div class="sub-tab align-items-top d-flex justify-content-between" role="tabpanel">
              <ul class="nav nav-tabs" id="myTabs" role="tablist">
                <!-- <li class="nav-item">
                  <a class="insight active" href="#involvement_tab" data-bs-toggle="tab" role="tab"
                    aria-controls="completed" aria-selected="false" (click)="selectedTabClick('involvement_tab')">
                    Involvement
                  </a>
                </li> -->
                <li class="nav-item">
                  <a class="insight active" href="#investigation_tab" data-bs-toggle="tab" role="tab"
                    aria-controls="completed" aria-selected="false" (click)="selectedTabClick('investigation_tab')">
                    Investigation
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="insight" href="#transcript_tab" data-bs-toggle="tab" role="tab" aria-controls="completed"
                    aria-selected="false" (click)="selectedTabClick('transcript_tab')">
                    Transcript
                  </a>
                </li> -->
                <!-- <li class="nav-item">
                  <a class="insight" href="#log_tab" data-bs-toggle="tab" role="tab" aria-controls="completed"
                    aria-selected="false" (click)="selectedTabClick('log_tab')">
                    Log
                  </a>
                </li> -->
              </ul>
            </div>
            <div class="sub-tab-content tab-content" id="myTabContents">
              <!-- <div class="tab-pane fade show active" id="involvement_tab" role="tabpanel"
                aria-labelledby="involvement_tab">
                <app-involvement-tab [selectedTask]="taskInfo"></app-involvement-tab>
              </div> -->
              <div class="tab-pane fade show active" id="investigation_tab" role="tabpanel"
                aria-labelledby="investigation_tab" *ngIf="taskInfo?.tabs_data">
                <app-investigation-tab [selectedTask]="taskInfo"
                  (investigationSubmitEvent)="investigationSubmitEvent($event)"></app-investigation-tab>
              </div>
              <!-- <div class="tab-pane fade" id="transcript_tab" role="tabpanel" aria-labelledby="transcript_tab">
                <app-transcript-tab [selectedTask]="taskInfo"></app-transcript-tab>
              </div> -->
              <!-- <div class="tab-pane fade" id="log_tab" role="tabpanel" aria-labelledby="log_tab">
                <app-log-tab [selectedTask]="taskInfo"></app-log-tab>
              </div> -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>