import { Component, Input } from "@angular/core";
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from "@angular/common/http";
import {
  ModalDismissReasons,
  NgbModal,
  NgbActiveModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
// import { EscalateBtnComponent } from "../escalate-btn/escalate-btn.component";
import { BehaviorSubject } from "rxjs";
import { LayoutService } from "src/app/services/layout.service";
@Component({
  selector: "app-create-case",
  templateUrl: "./create-case.component.html",
  styleUrls: ["./create-case.component.scss"],
})
export class CreateCaseComponent {
  @Input() public createdCaseInfo: any;
  caseCreation: boolean = true;
  confirmPopup: boolean = true;
  loaderPopup: boolean = false;
  error: Boolean = false;

  caseCreated: boolean = false;

  uploadProgress: number = 0;
  closeResult: string;
  errorMessage: string;
  selectedCaseId : any;
  constructor(
    private layoutService: LayoutService,
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private http: HttpClient,
    public modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {}

  createCase() {
    this.confirmPopup = false;
    this.loaderPopup = true;

    this.apiService
    .makeItAcase(this.createdCaseInfo?.taskId, this.createdCaseInfo?.CID)
    .subscribe((resp: any) => {
        this.selectedCaseId = resp.case_id;
        let uploadInterval = setInterval(() => {
          this.uploadProgress += 1;
          if (this.uploadProgress === 100) {
            clearInterval(uploadInterval);
            const completionInterval = setInterval(() => {
              if (resp.status === true) {
                this.caseCreation = false;
                this.loaderPopup = false;
                this.caseCreated = true;
              } else {
                this.caseCreation = false;
                this.loaderPopup = true;
                this.caseCreated = false;
              }
              clearInterval(completionInterval);
            }, 1000);
          }
        }, 75);
      });
  }

  goToCase() {
    this.activeModal.close();
    if (this.selectedCaseId) {
      this.layoutService.setCaseInfoData(this.selectedCaseId);
      this.router.navigate(["/case-management", this.selectedCaseId]).then(() => {
        localStorage.setItem("scenario", "Default");
      });
    }
  }
}
