<div class="overview-setup">
  <div class="d-flex justify-content-end tab-content store-container">
    <select class="selected-item" [(ngModel)]="selectedTenantId" (change)="changeOrg($event)" *ngIf="isCSAdmin">
      <option value="" [selected]="!selectedTenantIdDropdown">-Select Organization-</option>
      <option *ngFor="let org of filteredOrgs"  value={{org.tenantId}} [selected]="org.tenantId === selectedTenantId">
        {{ org.tenantName }}
      </option>
    </select>

    <button class="btn btn-add" (click)="addRolesData()">+ Add Roles</button>
  </div>
</div>
<div class="ag-grid">
  <app-role-grid [rowData]="rolesData" (gridActionEvent)="handleActionEvent($event)" [selectedOrgName]="selectedTenantName"></app-role-grid>
</div> 

