<div class="profile-screen">
  <div class="profile-section">
    <div class="profile-body">
      <div class="profile-banner" [style.background]="'url(' + selectedBanner + ') center/cover no-repeat'">
        <div class="overlay"></div>
        <div>
          <div class="d-flex change-cover-button">
            <label for="fileInput">
              <img src="../../../../../assets/icons/camera.png" alt="camera">
              Change Cover Photo
            </label>
            <input type="file" #fileInput (change)="onBannerChange($event)" id="fileInput" style="display: none"
              accept="image/*">
          </div>
          <div class="delete-icon" (click)="removeBannerData()" *ngIf="removeBanner">
            <div class="d-flex justify-content-center"><img src="../../../../../assets/icons/remove-1.png"
                alt="Remove Profile Photo" title="Remove Profile Image"> <span>Remove Cover Photo</span></div>
          </div>
        </div>
      </div>
      <div class="profile-image mt-2">
        <div class="upload-image">
          <p *ngIf="selectedImage === null" class="initials">{{firstLastName}}</p>
          <img *ngIf="selectedImage !== null" [src]="selectedImage" alt="img" class="profile-banner" />
          <div class="edit-icon" (click)="removeProfileData()" *ngIf="removeProfile">
            <div class="d-flex justify-content-center"><img src="../../../../../assets/icons/remove-1.png"
                alt="Remove Profile Photo" title="Remove Profile Image"> <span>Remove</span></div>

          </div>
          <div class="upload-file">
            <input type="file" (change)="onFileChange($event)" accept="image/*" class="form-control me-2 input_file"
              name="file" id="file" />
            <label for="file">
              <img src="../../../../../assets/icons/camera-1.png" alt="camera">
              <br />Change Photo
            </label>
          </div>
        </div>
      </div>

      <div class="update-preview" *ngIf="isProfileChanged">
        <div class="edit-preview">
          <div class="image-preview px-2">
            <h5>Choose Profile Picture</h5>
            <div class="adjust-screen mt-2">
              <div class="screen-view">
                <img [src]="selectedImage" alt="img" class="preview-image" id="profileImage" #profileImageView
                  [style.left.px]="imageLeft" [style.top.px]="imageTop" />
                <div class="focus-outer">
                  <span class="focus"></span>
                </div>
              </div>


              <div class="zoomin-slider">
                <span class="zoom-out">-</span>
                <div class="zoom-slider">
                  <input type="range" min="1" max="10" [(ngModel)]="zoomLevel" (input)="adjustZoom($event)" #zoomer>
                </div>
                <span class="zoom-in">+</span>
              </div>
            </div>



          </div>
        </div>
        <div class="edit-tab">
          <button class="save-btn" (click)="updateProfileImage()">Save</button>
          <button class="cancel-btn" (click)="cancelUpdateImage()">Cancel</button>
        </div>
      </div>

    </div>
  </div>
  <div class="profile-details">
    <h3>{{ profileData?.userName }}</h3>
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column justify-content-start">
        <div class="details-section">
          <div class="icon-container">
            <img src="../../../../../assets/icons/profile-org.png" alt="" class="icon">
          </div>
          <div class="text-container">
            <h4 class="title">Organisation Name</h4>
            <p class="description">{{profileData?.tenantName}}</p>
          </div>
        </div>
        <div class="details-section">
          <div class="icon-container">
            <img src="../../../../../assets/icons/profile-mail.png" alt="" class="icon">
          </div>
          <div class="text-container">
            <h4 class="title">Email ID</h4>
            <p class="description">{{email}}</p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-start">
        <div class="details-section">
          <div class="icon-container">
            <img src="../../../../../assets/icons/profile-user-role.png" alt="" class="icon">
          </div>
          <div class="text-container">
            <h4 class="title">User Role</h4>
            <p class="description">{{profileData?.roleName}}</p>
          </div>
        </div>
        <div class="details-section">
          <div class="icon-container">
            <img src="../../../../../assets/icons/profile-contact.png" alt="" class="icon">
          </div>
          <div class="text-container">
            <h4 class="title">Phone Number</h4>
            <p class="description"><span *ngIf="profileData?.countryCode">{{"+" + profileData?.countryCode +"
                "}}</span>{{profileData?.mobileNumber}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-start">
      <div class="details-section" *ngIf="profileData?.roleName === 'store-user'">
        <div class="icon-container">
          <img src="../../../../../assets/icons/profile-store-assigned.png" alt="" class="icon">
        </div>
        <div class="text-container">
          <h4 class="title">Store Assigned</h4>
          <div class="store-name-container">
            <p *ngFor="let store of storesAssigned" class="store-name description">{{ store }}</p>
            <button class="toggle-show" (click)="toggleExpansion()">
              {{ isExpanded ? 'View Less' : 'View All' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="profile-divider"></div>
    <div class="profile-settings">
      <button (click)="openResetPasswordModal()" class="reset-password">Reset Password</button>
      <div class="spinner-outer1" *ngIf="processing">
        <div class="loading-spinner1"></div>
      </div>
    </div>
  </div>
</div>