<div class="modal-header">
  <h4 class="modal-title">{{ userData?.userId ? "Edit User" : "Add User" }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()" class="user-form">
    <div class="user-form-group mb-4" *ngIf="isCSAdmin">
      <div class="auto-dropdown">
        <label for="orgName">Organization Name: <sup>*</sup></label>
        <ng-autocomplete [data]="filteredOrgs | orderBy : 'tenantName'" [searchKeyword]="keyword"
          (selected)="changeOrganization($event)" [placeholder]="placeholderOrg" [itemTemplate]="itemTemplateStaticOrg"
          class="form-control form-autocomplete" [(ngModel)]="selectedTenantName"
          [notFoundTemplate]="notFoundTemplateOrg" #autoOrgDrop [ngModelOptions]="{ standalone: true }"
          [disabled]="isEditing" [ngClass]="{ 'is-invalid': isSubmitted && !selectedTenantName }" (inputCleared)="clearStores()">
        </ng-autocomplete>

        <input type="text" id="orgName" name="orgName" formControlName="tenantId" class="form-control"
          placeholder="Organization Name" required="true" [(ngModel)]="selectedTenantName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" />

        <span class="dropdown-down" (click)="toggleOrgPanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticOrg let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateOrg let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>

        <div *ngIf="addUserForm.get('tenantId')?.hasError('required') || selectedTenantName == ''" class="invalid-feedback">
          Organization Name is required
        </div>
      </div>
    </div>

    <div class="user-form-group mb-4">
      <div class="auto-dropdown">
        <label for="storeName">Select Stores: <sup>*</sup></label>
        <ng-select [multiple]="true" [(ngModel)]="selectedStoreNames" [ngModelOptions]="{ standalone: true }"
          (change)="changeStore($event)" [placeholder]="placeholderStore" class="form-control form-autocomplete"
          [ngClass]="{
            'is-invalid':
              (isSubmitted && !selectedStoreNames)
          }">
          <ng-option *ngFor="let store of filteredStores" [value]="store.storeId">{{ store.storeName }}</ng-option>
        </ng-select>
        <span class="dropdown-down" (click)="toggleStorePanel($event)"></span>
        <div *ngIf="addUserForm.get('storeIds')?.hasError('required') || selectedStoreNames == ''" class="invalid-feedback">
          Store Names required
        </div>
      </div>
    </div>

    <div class="user-form-group mb-4">
      <div class="auto-dropdown">
        <label for="roleName">Role Name: <sup>*</sup></label>

        <ng-autocomplete [data]="rolesData | orderBy : 'roleName'" [searchKeyword]="keyword"
          (selected)="changeRole($event)" [placeholder]="placeholderRole" [itemTemplate]="itemTemplateStaticRole"
          class="form-control form-autocomplete" [(ngModel)]="selectedRoleName"
          [ngClass]="{ 'is-invalid': isSubmitted && !selectedRoleName }" [notFoundTemplate]="notFoundTemplateRole"
          #autoRoleDrop [ngModelOptions]="{ standalone: true }" (inputCleared)="clearRoles()">
        </ng-autocomplete>

        <input type="text" id="roleName" name="roleName" formControlName="roleId" class="form-control"
          placeholder="Role Name" required="true" [(ngModel)]="selectedRoleName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" />

        <span class="dropdown-down" (click)="toggleRolePanel($event)"></span>
        <ng-template #itemTemplateStaticRole let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateRole let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>

        <div *ngIf="addUserForm.get('roleId')?.hasError('required')" class="invalid-feedback">
          Role required
        </div>
      </div>
    </div>

    <div class="user-form-group mb-4">
      <label for="firstName1">First Name:<sup>*</sup></label>
      <input type="text" id="firstName1" formControlName="firstName" class="form-control" placeholder="First Name"
        [ngClass]="{ 'is-invalid': isSubmitted && fc['firstName'].errors }" required />

      <div *ngIf="addUserForm.get('firstName')?.hasError('required')" class="invalid-feedback">
        First name is required
      </div>
    </div>

    <div class="user-form-group mb-4">
      <label for="lastName1">Last Name:<sup>*</sup></label>
      <input type="text" id="lastName1" formControlName="lastName" class="form-control" placeholder="Last Name"
        [ngClass]="{ 'is-invalid': isSubmitted && fc['lastName'].errors }" required />
      <div *ngIf="addUserForm.get('lastName')?.hasError('required')" class="invalid-feedback">
        Last name is required
      </div>
    </div>  

    <div class="user-form-group mb-4">
      <label for="email1">Business Email:<sup>*</sup></label>
      <input 
        type="email" 
        id="email1" 
        formControlName="email" 
        class="form-control" 
        placeholder="Business Email"
        [ngClass]="{
          'is-invalid': (isSubmitted && fc['email'].errors) || isEmailExist
        }" 
        required 
        [(ngModel)]="checkEmail" 
        (keyup)="emailCheckUnique()"
        [readonly]="userData != null && userData.isloggedin" 
        [class.disabled]="userData != null && userData.isloggedin"
        /> 
      <div *ngIf="addUserForm.get('email')?.hasError('required')" class="invalid-feedback">
        Email is required
      </div>
      <div *ngIf="addUserForm.get('email')?.hasError('pattern')" class="invalid-feedback">
        Please enter a valid email address
      </div>
    
      <p class="isExistvalid" *ngIf="isEmailExist">Email Already Exists</p>
    </div>
    


    <div class="user-form-group mb-4">
      <label for="contact1">Business Phone 1:<sup>*</sup></label>
      <div class="country-box">
        <select class="icon-arrow country-code" id="countryCodeId2" formControlName="countryCodeId"
          (change)="changeCountryCode($event)" [(ngModel)]="selectedCountryCode" [ngClass]="{
            'is-invalid': isSubmitted && fc['countryCodeId'].errors
          }">
          <option *ngIf="!isEditing" [disabled]="true" [selected]="true" value="">
            -Country-
          </option>
          <option *ngFor="let code of countryCodeList" [selected]="true" [value]="code.countryUID">
            +{{code.countryCode}} ({{code.name}})
          </option>
        </select>
        <input type="text" id="contact1" name="contact1" required="true" formControlName="phoneNo"
          class="contact-input form-control" placeholder="Primary contact number" [maxlength]="10" [minlength]="10"
          size="10" [ngClass]="{
            'is-invalid': (isSubmitted && fc['phoneNo'].errors) || isPhoneExist
          }" [appNumericOnly]="true" [(ngModel)]="checkContact" autocomplete="off" />
        <div
          *ngIf=" addUserForm.get('countryCodeId')?.hasError('required') || addUserForm.get('phoneNo')?.hasError('required')"
          class="invalid-feedback">
          Phone Number with Country code is required
        </div>
        <div *ngIf="addUserForm.get('phoneNo')?.hasError('minLength') && addUserForm.get('phoneNo')?.value?.length > 0"
          class="invalid-feedback">
          Phone Number should be valid
        </div>
        <p class="isExistvalid" *ngIf="isPhoneExist">Phone No Already Exists</p>

      </div>
    </div>
    <div class="custom-icon mb-3">
      Status:
      <label class="toggle-button">
        <div class="shadow-input" *ngIf="isEditing === false"></div>
        <input [disabled]="isEditing === false" class="form-check-input" type="checkbox" formControlName="status"
          [(ngModel)]="isChecked" />
        <span class="slider round"></span>
      </label>
    </div>

    <button type="submit" class="btn btn-add">Submit</button>
  </form>
  <div class="spinner-outer" *ngIf="processing">
    <div class="loading-spinner"></div>
  </div>
</div>