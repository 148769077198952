<div class="control-data">
  <ul class="controller-info">
    <li
      class="assignee control-tab d-flex justify-content-start align-items-center"
    >
      <label>People</label>
      <div class="d-flex justify-content-start align-items-center">
        <div
          class="assignee-profile"
          *ngFor="let people of involvementData?.people" >
          <span class="img-profile"><img src={{people}} alt=""/>  </span>
        </div>
        <div class="assignee-profile">
          <button class="add-profile">
            <img src="../../../../assets/icons/plus.png" alt="" />
            Add New
          </button>
        </div>
      </div>
    </li>

    <li
      class="assignee control-tab d-flex justify-content-start align-items-center"
    >
      <label>Vehicles</label>
      <div class="d-flex justify-content-start align-items-center">
        <div
          class="assignee-profile"
          *ngFor="let vehicle of involvementData?.vehicles"
        >
          <span class="img-profile"><img src={{vehicle}} alt=""/></span>
        </div>

        <div class="assignee-profile">
          <button class="add-profile">
            <img src="../../../../assets/icons/plus.png" alt="" />
            Add New
          </button>
        </div>
      </div>
    </li>
    <li
      class="assignee control-tab d-flex justify-content-start align-items-center"
    >
      <label>Assets</label>
      <div class="d-flex justify-content-start align-items-center">
        <div
          class="assignee-profile"
          *ngFor="let asset of involvementData?.assets"
        >
          <span class="img-profile"><img src={{asset}} alt=""/></span>
        </div>
        <div class="assignee-profile">
          <button class="add-profile">
            <img src="../../../../assets/icons/plus.png" alt="" />
            Add New
          </button>
        </div>
      </div>
    </li>
  </ul>
</div>
