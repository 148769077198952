<div class="card">
  <div class="container">
    <form [formGroup]="uploadImageForm" (ngSubmit)="uploadImage()" class="mt-3 mb-3">
      <div class="mb-4 mt-4">
        <label for="usecasename">Upload the Image<sup>*</sup></label>
        <div class="d-flex justify-content-between">
          <input type="file" (change)="onFileChange($event)" id="uploadedImage" class="form-control me-2"
            [ngClass]="{'is-invalid': isSubmitted && uploadImageForm.get('uploadImage')?.invalid || fileError}" required
            formControlName="uploadImage" name="uploadedImage" />

          <button type="submit" class="btn usecase-device-btn">Upload</button>
        </div>
        <div class="file-error">
          <p *ngIf="fileError">{{fileErrorMessage}}</p>
        </div>
      </div>

      <div class="usecase-form-group me-2 custom-icon mb-4" *ngIf="toggleROISelect">
        <label for="usecasename">Select ROI Type:</label>
        <select class="form-control" id="openTime" formControlName="uploadImage" (change)="changeRoiType($event)"
          [(ngModel)]="selectedRoiType" [ngClass]="{'is-invalid': isSubmitted && fc['uploadImage'].errors}">
          <option *ngFor="let type of roiTypes" [value]="type">{{ type }}</option>
        </select>
        <div *ngIf="uploadImageForm.get('uploadImage')?.hasError('required')" class="invalid-feedback">
          Please upload the Image to draw ROI
        </div>
      </div>

      <div class="canvas-container mb-4" [hidden]="!canvasVisible">
        <canvas #canvasElement class="draw-canvas" [width]="canvasWidth" [height]="canvasHeight"
          style="background: url({{selectedImage}}) no-repeat center center; background-size: cover;">{{selectedImage}}</canvas>
        <p *ngIf="!isImageLoaded" class="load-image">{{loadImageData}}</p>
      </div>
    </form>
  </div>
</div>