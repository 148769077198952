import { Component, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { StoreInsightService } from "src/app/services/store-insight.service";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import jwt_decode from "jwt-decode";
import { CommonService } from "src/app/services/common.service";
import { AuthorizationService } from "src/app/services/authorization.service";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
  isCSOrgAdmin: boolean = false;
  isStoreUser: boolean = false;

  userRole: any;
  userRoleId: any;
  tenantId: any;
  loginUid: any;
  email: any;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private authService: AuthService,
    public storeInsightService: StoreInsightService,
    public router: Router,
    private commonService: CommonService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    this.extractUserRoles();
  }

  extractUserRoles() {
    try {
      const accessToken = localStorage.getItem("auth_token") ?? "default";
      const decodedToken: any = jwt_decode(accessToken);
      const extraDataObject = decodedToken.extra_data;
      this.tenantId = extraDataObject.org;
      this.userRoleId = decodedToken.role_id;
      this.userRole = decodedToken.role_name;
      this.loginUid = decodedToken.login_uid;
      this.email=decodedToken.user_email;
      localStorage.setItem("userRole", this.userRole);
      localStorage.setItem("tenantId", this.tenantId);
      localStorage.setItem("loginUid", this.loginUid);
      localStorage.setItem("UserRoleID", this.userRoleId);
      localStorage.setItem("email", this.email);
      if ( this.authorizationService.isUserAuthorized(decodedToken?.policies?.orgs, ['list', '*']) 
        || this.authorizationService.isUserAuthorized(decodedToken?.policies?.stores, ['list', '*']) ) {
        this.isCSOrgAdmin = true;
        this.isStoreUser = false;
        if (this.router.url === "/") {
          this.router.navigate(["/configuration"]);
        }
      }
      if ( this.authorizationService.isUserAuthorized(decodedToken?.policies?.tasks, ['task', '*']) ) {
        this.isCSOrgAdmin = false;
        this.isStoreUser = true;
        if (this.router.url === "/") {
          this.router.navigate(["/my-tasks"]);
        }
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  logout() {
    this.authService.logout();
    // code to close the case management circular notification card and error card after user clicks logout
    this.commonService.setCheckForLogout(true);
    this.commonService.resetOrgStoreValues();
  }
}
