import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  private pageTitleSubject = new Subject<any>();
  pageTitle$ = this.pageTitleSubject.asObservable();

  private caseInformation = new BehaviorSubject<string>("Default Data");
  public caseTaskInfo$ = this.caseInformation.asObservable();

  private hideSearchSubject = new BehaviorSubject<boolean>(false);
  hideSearch$ = this.hideSearchSubject.asObservable();

  setCaseInfoData(newData: string): void {
    this.caseInformation.next(newData);
  }

  constructor() {}

  setPageTitle(pageTitle: any) {
    this.pageTitleSubject.next(pageTitle);
  }

  hideSearchBar(hideSearch: any) {
    this.hideSearchSubject.next(hideSearch);
  }

}
