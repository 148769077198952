import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-heat-map-container-popup',
  templateUrl: './heat-map-container-popup.component.html',
  styleUrls: ['./heat-map-container-popup.component.scss']
})
export class HeatMapContainerPopupComponent {

  @Input() public heatMapDataSet: any;
  @Input() public colorArrayLegends: any;
  @Input() public cameraData: any;
  @Input() public selectedCamera: any;
  @Input() public heatMapCameraImage: any;
  @Input() public storeName: any;
  @Input() public currentTime: any;
  @Output() selectedCameraValuePopup: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  selectedCameraValue(event: any) {
    this.selectedCameraValuePopup.emit(event);
  }

}
