<div class="modal-header">
  <h4 class="modal-title">{{ storeData?.storeId ? 'Edit Store' : 'Add Store' }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">

  <form [formGroup]="addStoreForm" autocomplete="off" (ngSubmit)="submitForm()" class="stores-form">

    <div class="stores-form-group mb-4" *ngIf="isCSAdmin">
      <div class="auto-dropdown">
        <label for="orgName">Organization Name: <sup>*</sup></label>
        <ng-autocomplete [data]="filteredOrgs | orderBy:'tenantName'" [searchKeyword]="keyword"
          (selected)="changeOrganization($event)" [placeholder]="placeholderOrg" [itemTemplate]="itemTemplateStaticOrg"
          class="form-control" [(ngModel)]="selectedTenantName"
          [ngClass]="{'is-invalid': isSubmitted && fc['tenantId'].errors && !selectedTenantName}"
          [notFoundTemplate]="notFoundTemplateOrg" #autoOrgDrop [ngModelOptions]="{standalone: true}"
          [disabled]="isEditing">
        </ng-autocomplete>

        <input type="text" id="orgName" name="orgName" formControlName="tenantId" class="form-control"
          placeholder="Organization Name" required="true" [(ngModel)]="selectedTenantName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [disabled]="isEditing" *ngIf="isVisible" autocomplete="off">
        <span class="dropdown-down" (click)="toggleOrgPanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticOrg let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateOrg let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addStoreForm.get('tenantId')?.hasError('required')" class="invalid-feedback">
          Organization Name is required
        </div>
      </div>
    </div>

    <div class="stores-form-group mb-4">
      <label for="storeName1">Store Name: <sup>*</sup></label>
      <input type="text" id="storeName1" name="storeName" formControlName="storeName" [value]="storeData?.storeId"
        class="form-control" placeholder="Store Name" required="true" (keyup)="storeNameCheckUnique()"
        [(ngModel)]="checkStore" [ngClass]="{'is-invalid': isSubmitted && fc['storeName'].errors}" autocomplete="off">
      <p class="isExistvalid" *ngIf="isStoreExist">Store Name Already Exist</p>
      <div *ngIf="addStoreForm.get('storeName')?.hasError('required')" class="invalid-feedback">
        Store Name is required
      </div>
    </div>

    <div class="stores-form-group mb-4">
      <label for="address1">Address 1<sup>*</sup></label>
      <input type="text" id="address1" name="address1" formControlName="siteAddressL1" class="form-control"
        placeholder="Address 1" [ngClass]="{'is-invalid': isSubmitted && fc['siteAddressL1'].errors}" autocomplete="on">
      <div *ngIf="addStoreForm.get('siteAddressL1')?.hasError('required')" class="invalid-feedback">
        Address is required
      </div>
    </div>

    <div class="stores-form-group mb-4">
      <label for="address2">Address 2</label>
      <input type="text" id="address2" name="address2" formControlName="siteAddressL2" class="form-control"
        placeholder="Address 2" autocomplete="off">
    </div>

    <div class="stores-form-group mb-4">
      <div class="auto-dropdown">
        <label for="countryId">Country: <sup>*</sup></label>

        <ng-autocomplete [data]="countriesList | orderBy:'countryName'" [searchKeyword]="keyword"
          (selected)="changeCountry($event)" [placeholder]="placeholderCountry"
          [itemTemplate]="itemTemplateStaticCountry" class="form-control" [(ngModel)]="selectedCountryName"
          [ngClass]="{'is-invalid': isSubmitted && fc['countryUid'].errors && !selectedCountryName}"
          [notFoundTemplate]="notFoundTemplateCountry" #autoCountryDrop (inputCleared)="clearCountry()"
          [ngModelOptions]="{standalone: true}">
        </ng-autocomplete>

        <input type="text" id="countryId" name="countryId" formControlName="countryUid" class="form-control"
          placeholder="Country Name" required="true" [(ngModel)]="selectedCountryName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" autocomplete="off">

        <span class="dropdown-down" (click)="toggleCountryPanel($event)"></span>
        <ng-template #itemTemplateStaticCountry let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateCountry let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>

        <div *ngIf="addStoreForm.get('countryUid')?.hasError('required')" class="invalid-feedback">
          Country is required
        </div>
      </div>
    </div>

    <div class="stores-form-group mb-4">
      <div class="auto-dropdown">
        <label for="stateId" value="state">State: <sup>*</sup></label>
        <ng-autocomplete [data]="statesList | orderBy:'stateName'" [searchKeyword]="keyword"
          (selected)="changeState($event)" [placeholder]="placeholderState" [itemTemplate]="itemTemplateStaticState"
          class="form-control" [(ngModel)]="selectedStateName"
          [ngClass]="{'is-invalid': isSubmitted && fc['stateUid'].errors && !selectedStateName}"
          [notFoundTemplate]="notFoundTemplateState" #autoStateDrop (inputCleared)="clearState()"
          [ngModelOptions]="{standalone: true}">
        </ng-autocomplete>
        <input type="text" id="stateId" name="stateId" formControlName="stateUid" class="form-control"
          placeholder="State Name" required="true" [(ngModel)]="selectedStateName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" autocomplete="off">
        <span class="dropdown-down" (click)="toggleStatePanel($event)"></span>
        <ng-template #itemTemplateStaticState let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateState let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addStoreForm.get('stateUid')?.hasError('required')" class="invalid-feedback">
          State is required
        </div>
      </div>
    </div>

    <div class="stores-form-group mb-4">
      <div class="auto-dropdown">
        <label for="cityId">City: <sup>*</sup></label>
        <ng-autocomplete [data]="cityList | orderBy:'cityName'" [searchKeyword]="keyword"
          (selected)="changeCity($event)" [placeholder]="placeholderCity" [itemTemplate]="itemTemplateStaticCity"
          class="form-control" [(ngModel)]="selectedCityName"
          [ngClass]="{'is-invalid': isSubmitted && fc['cityUid'].errors && !selectedCityName}"
          [notFoundTemplate]="notFoundTemplateCity" #autoCityDrop (inputCleared)="clearCity()"
          [ngModelOptions]="{standalone: true}">
        </ng-autocomplete>
        <input type="text" id="cityId" name="cityId" formControlName="cityUid" class="form-control"
          placeholder="City Name" required="true" [(ngModel)]="selectedCityName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" autocomplete="off">

        <span class="dropdown-down" (click)="toggleCityPanel($event)"></span>
        <ng-template #itemTemplateStaticCity let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>

        <ng-template #notFoundTemplateCity let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addStoreForm.get('cityUid')?.hasError('required')" class="invalid-feedback">
          City is required
        </div>
      </div>
    </div>


    <div class="stores-form-group mb-4">
      <div class="auto-dropdown">
        <label for="zipCode">Zip Code<sup>*</sup></label>
        <ng-autocomplete [data]="zipList | orderBy:'zipcode'" [searchKeyword]="keyword"
          (selected)="changeZipCode($event)" [placeholder]="placeholderZip" [itemTemplate]="itemTemplateStaticZip"
          class="form-control" [(ngModel)]="selectedZipCode"
          [ngClass]="{'is-invalid': isSubmitted && fc['zipCode'].errors && !selectedZipCode}"
          [notFoundTemplate]="notFoundTemplateZip" #autoZipDrop (inputCleared)="clearZipCode()"
          [ngModelOptions]="{standalone: true}">
        </ng-autocomplete>
        <input type="text" id="zipCode" name="zipCode" formControlName="zipCode" class="form-control"
          placeholder="ZipCode" required="true" [(ngModel)]="selectedZipCode" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" autocomplete="off">
        <span class="dropdown-down" (click)="toggleZipPanel($event)"></span>
        <ng-template #itemTemplateStaticZip let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>

        <ng-template #notFoundTemplateZip let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addStoreForm.get('zipCode')?.hasError('required')" class="invalid-feedback">
          ZipCode is required
        </div>
      </div>
    </div>

    <div class="stores-form-group mb-4">
      <div class="auto-dropdown">
        <label for="timeZoneId">Time Zone:<sup>*</sup></label>
        <ng-autocomplete [data]="timeZonesList | orderBy:'standardname'" [searchKeyword]="keyword"
          (selected)="changeTimeZoneCode($event)" [placeholder]="placeholderTimezone"
          [itemTemplate]="itemTemplateStaticZone" class="form-control" [(ngModel)]="selectedTimeZoneName"
          [ngClass]="{'is-invalid': isSubmitted && fc['timeZoneId'].errors && !selectedTimeZoneName}"
          [notFoundTemplate]="notFoundTemplateZone" #autoTimeZoneDrop (inputCleared)="clearTimeZoneCode()"
          [ngModelOptions]="{standalone: true}">
        </ng-autocomplete>

        <input type="text" id="timeZoneId" name="timeZoneId" formControlName="timeZoneId" class="form-control"
          placeholder="Time Zone" required="true" [(ngModel)]="selectedTimeZoneName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" autocomplete="off">
        <span class="dropdown-down" (click)="toggleTimeZonePanel($event)"></span>
        <ng-template #itemTemplateStaticZone let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>

        <ng-template #notFoundTemplateZone let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addStoreForm.get('timeZoneId')?.hasError('required')" class="invalid-feedback">
          Time Zone is required
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="stores-form-group select-hours me-2 custom-icon mb-4">
        <label for="openTime">Open at<sup>*</sup></label>
        <select class="form-control" id="openTime" formControlName="startTime" [(ngModel)]="selectedOpenHours"
          (change)="changeOpenHours($event)" [ngClass]="{'is-invalid': isSubmitted && fc['startTime'].errors}">
          <option *ngIf="!isEditing" [disabled]="true" value="">Select Open Time</option>
          <option *ngFor="let code of operatingHours" value="{{code}}">
            {{code}}
          </option>
        </select>
        <div *ngIf="addStoreForm.get('startTime')?.hasError('required')" class="invalid-feedback">
          Opening time is required
        </div>
      </div>

      <div class="stores-form-group select-hours mr-2 custom-icon mb-4">
        <label for="closeTime">Close at<sup>*</sup></label>
        <select class="form-control" id="closeTime" formControlName="endTime" [(ngModel)]="selectedClosedHours"
          (change)="changeClosedHours($event)" [ngClass]="{'is-invalid': isSubmitted && fc['endTime'].errors}">
          <option *ngIf="!isEditing" [disabled]="true" value="">Select Closing Time</option>
          <option *ngFor="let code of operatingHours" value="{{code}}">
            {{code}}
          </option>
        </select>
        <div *ngIf="addStoreForm.get('endTime')?.hasError('required')" class="invalid-feedback">
          Closing time is required
        </div>
      </div>
    </div>

    <div class="mb-4 alerts-form-group">
      <label for="alert">Alert Modes<sup>*</sup></label>
      <div class="alerts" class="contact-input d-flex justify-content-between">
        <div class="form-check" *ngFor="let alert of modeOfAlerts; let i = index">
          <input class="form-check-input" type="checkbox" [value]="alert.alertUID"
            (change)="changeModeOfAlert($event, alert)" [checked]="isAlertSelected(alert)"
            [ngClass]="{'is-invalid': isSubmitted && selectedModeOfAlert.length === 0}" autocomplete="off">
          <label class="form-check-label" for="value">
            {{alert.alertMode}}
          </label>
        </div>
      </div>
      <div *ngIf="isSubmitted && selectedModeOfAlert.length === 0" class="invalid-alert-feedback">
        Choose Any One Alert Mode.
      </div>
    </div>





    <div class="stores-form-group mb-4" *ngIf="isAuthTokenVisible">
      <label for="text">Auth Token:</label>
      <div class="btn-verify">
        <input type="{{ showAuthToken ? 'text' : 'password' }}" id="textBox" class="form-control"
          placeholder="Please Enter Auth Token" [(ngModel)]="authTokenEntry" [ngModelOptions]="{standalone: true}"
          [ngClass]="{'is-invalid': isSubmitted && authTokenEntry && errorMessage}" autocomplete="new-password"
          (input)="authCheckEmpty()" />
        <span (click)="togglePasswordVisibility()" class="visible-auth" *ngIf="authTokenEntry != ''"><img
            src="../../../../../../assets/icons/hide.png" alt="visible" /></span>
      </div>

      <div class="auth-error" *ngIf="errorMessage">
        <p>{{providedErrorMessage}}</p>
      </div>
      <div class="auth-msg" *ngIf="deassociatedToken">
        <p>{{tokenValidated}}</p>
      </div>

    </div>

    <div class="stores-form-group mb-4">
      <label for="contact1">Primary contact number <sup>*</sup></label>
      <div class="country-box">
        <select class="icon-arrow country-code" id="countryCodeId1" formControlName="countryCodeId"
          (change)="changeCountryCode($event)" [(ngModel)]="selectedCountryCodeId"
          [ngClass]="{'is-invalid': isSubmitted && fc['countryCodeId'].errors}">
          <option *ngIf="!isEditing" [disabled]="true" [selected]="true" value="">-Country-</option>
          <option *ngFor="let code of countryCodeList" [selected]="true" [value]="code.countryUID">
            +{{code.countryCode}} ({{code.name}})
          </option>
        </select>

        <input type="text" id="contact1" name="contact1" required="true" formControlName="sitePhoneNo1"
          class="contact-input form-control" placeholder="Primary contact number" [maxlength]="10" [minlength]="10"
          size="10" [ngClass]="{'is-invalid': isSubmitted && fc['sitePhoneNo1'].errors}" [appNumericOnly]="true"
          autocomplete="off">

        <div
          *ngIf="addStoreForm.get('countryCodeId')?.hasError('required') || addStoreForm.get('sitePhoneNo1')?.hasError('required')"
          class="invalid-feedback">
          Phone Number with Country code is required
        </div>
        <div
          *ngIf="addStoreForm.get('sitePhoneNo1')?.hasError('minLength') && addStoreForm.get('sitePhoneNo1')?.value?.length > 0"
          class="invalid-feedback">
          Phone Number should be valid
        </div>
      </div>
    </div>

    <div class="stores-form-group mb-3">
      <label for="contact2">Secondary contact number</label>
      <input type="text" id="contact2" name="contact2" formControlName="sitePhoneNo2" class="form-control"
        placeholder="Secondary contact number" [maxlength]="10" [minlength]="10" size="10" [appNumericOnly]="true"
        autocomplete="off">
    </div>

    <div class="user-form-group custom-icon mb-3">
      Status:
      <label class="toggle-button">
        <div class="shadow-input" *ngIf="isEditing === false"></div>
        <input [disabled]="isEditing === false" class="form-check-input" type="checkbox" formControlName="status"
          [(ngModel)]="isChecked">
        <span class="slider round"></span>
      </label>
    </div>

    <button type="submit" class="btn btn-add">Submit</button>
  </form>
  <div class="spinner-outer" *ngIf="processing">
    <div class="loading-spinner"></div>
  </div>
</div>