<div class="modal-header">
  <h4 class="modal-title">{{ cameraData?.camUID ? 'Edit Camera' : 'Add Camera' }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form [formGroup]="addCameraForm" (ngSubmit)="onSubmit()" class="camera-form">

    <div class="camera-form-group mb-4" *ngIf="isCSAdmin">
      <div class="auto-dropdown">
        <label for="orgName">Organization Name: <sup>*</sup></label>
        <ng-autocomplete [data]="filteredOrgs | orderBy:'tenantName'" [searchKeyword]="keyword"
          (selected)="changeOrganization($event)" [placeholder]="placeholderOrg" [itemTemplate]="itemTemplateStaticOrg"
          class="form-control form-autocomplete" [(ngModel)]="selectedTenantName"
          [notFoundTemplate]="notFoundTemplateOrg" #autoOrgDrop [ngModelOptions]="{standalone: true}"
          [disabled]="isEditing" (inputCleared)="clearStores()"
          [ngClass]="{'is-invalid': isSubmitted && !selectedTenantName}">
        </ng-autocomplete>

        <input type="text" id="orgName" name="orgName" formControlName="tenantId" class="form-control"
          placeholder="Organization Name" required="true" [(ngModel)]="selectedTenantName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible">

        <span class="dropdown-down" (click)="toggleOrgPanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticOrg let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateOrg let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addCameraForm.get('tenantId')?.hasError('required')" class="invalid-feedback">
          Organization is required
        </div>
      </div>
    </div>

    <div class="camera-form-group mb-4">
      <div class="auto-dropdown">
        <label for="selectStore">Select Store:<sup>*</sup></label>
        <ng-autocomplete [data]="filteredStores | orderBy:'storeName'" [searchKeyword]="keyword"
          (selected)="changeStore($event)" [placeholder]="placeholderStore" [itemTemplate]="itemTemplateStaticStore"
          class="form-control form-autocomplete" [(ngModel)]="selectedStoreName"
          [notFoundTemplate]="notFoundTemplateStore" #autoStoreDrop [ngModelOptions]="{standalone: true}"
          [disabled]="isEditing" [ngClass]="{'is-invalid': isSubmitted && !selectedStoreName}">
        </ng-autocomplete>

        <input type="text" id="selectStore" name="storeName" formControlName="storeId" class="form-control"
          placeholder="Select Store" required="true" [(ngModel)]="selectedStoreName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible">

        <span class="dropdown-down" (click)="toggleStorePanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticStore let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateStore let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addCameraForm.get('storeId')?.hasError('required')" class="invalid-feedback">
          Store is required
        </div>

      </div>
    </div>

    <div class="camera-form-group mb-4">
      <label for="cameraName">Enter Camera Name:<sup>*</sup></label>
      <input type="text" id="cameraName" formControlName="camName" class="form-control" placeholder="Camera Name"
        (input)="cameraNameCheckUnique()" [(ngModel)]="checkCamera" required="true"
        [ngClass]="{'is-invalid': isSubmitted && fc['camName'].errors}" [tabindex]="isEditing ? -1 : null"
        (keyup)="onInputChange()">
      <p class="isExistvalid" *ngIf="isExist">Camera Name Already Exist</p>
      <div *ngIf="isSubmitted && fc['camName'].errors" class="invalid-feedback">
        <div *ngIf="fc['camName'].errors['required']">Camera Name is required</div>
      </div>
    </div>

    <div class="camera-form-group mb-4">
      <label for="email1">Camera Description:</label>
      <textarea resize="false" rows="2" id="description" formControlName="description" class="form-control"
        placeholder="Camera Description"> </textarea>
    </div>

    <div class="camera-form-group mb-4">
      <label for="camLocation">Location:<sup>*</sup></label>
      <input type="text" id="camLocation" formControlName="camLocation" class="form-control" placeholder="Location"
        [ngClass]="{'is-invalid': isSubmitted && fc['camLocation'].errors}" required>

      <div *ngIf="addCameraForm.get('camLocation')?.hasError('required')" class="invalid-feedback">
        Please Provide CamLocation
      </div>
    </div>


    <div class="camera-form-group mb-4">
      <label for="rtspUrl">RTSP:<sup>*</sup></label>
      <input type="text" id="rtspUrl" formControlName="rtsp" class="form-control" placeholder="RTSP"
        [ngClass]="{'is-invalid': isSubmitted && fc['rtsp'].errors}" required>

      <div *ngIf="addCameraForm.get('rtsp')?.hasError('required')" class="invalid-feedback">
        RTSP must not be empty
      </div>
    </div>

    <div class="custom-icon mb-3">
      Status:
      <label class="toggle-button">
        <div class="shadow-input" *ngIf="isEditing === false"></div>
        <input [disabled]="isEditing === false" class="form-check-input" type="checkbox" formControlName="status"
          [(ngModel)]="isChecked">
        <span class="slider round"></span>
      </label>
    </div>

    <button type="submit" class="btn btn-add" [disabled]="isExist || isLoading">Submit</button>
  </form>
  <div class="spinner-outer" *ngIf="isLoading">
    <div class="loading-spinner"></div>
  </div>
</div>