import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment-timezone';

/**
* A moment timezone pipe to support parsing based on time zone abbreviations
* covers all cases of offset variation due to daylight saving.
*
* Same API as DatePipe with additional timezone abbreviation support
* Official date pipe dropped support for abbreviations names from
Angular V5
*/

@Pipe({
  name: 'timeZoneDateMoment'
})

export class TimeZoneDateMomentPipe implements PipeTransform {
  transform( value: string | Date, format: string, timezone: string ): string {
    const timezoneOffset: any = moment(value)?.tz(timezone)?.format('Z');
    const date: any = new DatePipe('en-US', timezone)?.transform(value, format, timezoneOffset);
    return date;
  }
}
