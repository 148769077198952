<figure class="aspectratio pitaya-player-container" #pitayaPlayerContainer>
  <video (loadedmetadata)="initializeVideo()" (timeupdate)="updateProgress()" [src]="media" #pitayaPlayer
    [autoplay]="autoplay" muted (canplaythrough)="onVideoCanPlay($event)" type="application/x-mpegURL"
    *ngIf="media"></video>

  <div class="custom-video-content d-flex justify-content-center align-items-center" id="no_video_content"
    *ngIf="!media">
    <div class="novideo-icon"><img src="../../../../assets/no-video.svg" alt="no video found"></div>
    <div class="novideo-content">
      <h4>Video unavailable</h4>
      <p>This video is not available</p>
    </div>
  </div>

  <figcaption class="camera-placement" *ngIf="displaymeta">
    {{ meta.CameraPlacement }}
  </figcaption>

  <figcaption class="camera-name" *ngIf="displaymeta">
    {{ meta.cameraName }}
  </figcaption>
  <div *ngIf="controls" class="controls-wrapper">
    <div class="timeline">
      <div class="bar">
        <progress #progressBar [value]="progressValue" [max]="maxProgressValue" min="0"></progress>
        <input class="seek" [min]="0" [max]="maxProgressValue" type="range" step="1" #seekControl [value]="seekValue"
          (change)="seekVideo($event)"
          style="background: linear-gradient(90deg,rgb(205,18,137) 0%,rgb(248,159,29) 100%);" />
      </div>
    </div>
    <div class="controls">
      <div class="left-wrapper">
        <button (click)="rewindTenSeconds()" class="prev" type="button">Rewind</button>
        <button (click)="playVideo()" [class.play]="!isPlaying || !media" [class.pause]="isPlaying  && media">
          Play/Pause
        </button>
        <button (click)="forwardTenSeconds()" class="next" type="button">Forward</button>

        <div class="speed-wrapper">
          <select [(ngModel)]="playbackRate" (change)="setPlaybackRate($event)">
            <option value="0.25">0.25x</option>
            <option value="0.5">0.5x</option>
            <option value="1">1x</option>
            <option value="2">2x</option>
            <option value="4">4x</option>
          </select>
        </div>
      </div>
      <div class="right-wrapper">
        <div class="duration">
          <span>{{ totalDuration }}</span>
        </div>
        <button (click)="toggleFullscreen()" class="fullscreen">Fullscreen</button>
      </div>
    </div>
  </div>
</figure>