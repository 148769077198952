<div class="investigation-tab d-flex justify-content-between" *ngIf="selectedTask">
  <input type="text" class="add-notes form-control" placeholder="Add investigation notes" [(ngModel)]="customEntry"
    (ngModelChange)="checkEntryValue()" [disabled]="selectedTask?.status?.allow_case_update==false" required >
  <button class="submit" type="button" (click)="addInvestigation()" [disabled]="!customEntry">
    Submit
  </button>
</div>

<div class="notes-container" *ngIf="investgationCustomData?.length">
  <div class="investigation-notes mt-2 d-flex justify-content-left" *ngFor="let investigation of investgationCustomData">
    <div class="notes-data">
      <div class="profile-icon">
        <img src="{{(investigation?.user?.profile_pic)? investigation?.user?.profile_pic : '../../../../assets/user.png'}}" alt="icon" />
      </div>
    </div>
    <div class="invest-section">
      <h5>
        {{ investigation?.user?.name }} <span class="invest-time" *ngIf="investigation?.created_at">
          {{ investigation?.created_at | timeZoneDateMoment : "MMMM dd, yyyy hh:mm a" : selectedTask?.source_data?.store_timezone }}</span>
      </h5>
      <P>{{ investigation?.note }}</P>
    </div>
  </div>
</div>

<div class="no-data d-flex align-items-center justify-content-center" *ngIf="!investgationCustomData?.length">
  <span>There is no investigation to display</span>
</div>