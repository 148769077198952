import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "../../services/auth.service";
import jwt_decode from "jwt-decode";
import { AuthorizationService } from "src/app/services/authorization.service";

@Injectable({
  providedIn: "root",
})
export class NoAuthGuard implements CanActivate {
  constructor(private authService: AuthService, 
    private router: Router,
    private authorizationService: AuthorizationService) {}
  userRole: any;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authService.isAuthenticate()) {
      // Allow access for unauthenticated users
      return true;
    }


    if ( this.authService.isAuthenticate() && (state.url == '/login' || state.url == '/reset-password' || state.url == '/forgot-password' ) ) {
      // restrict access to login, forgot password and reset password page if user is authenticated
      this.router.navigate(['/']);
      return false;
    }

    const getAccessTokenValue = this.authService.getAccessToken();
    if (getAccessTokenValue !== null) {
      let decodedToken: any = jwt_decode(getAccessTokenValue);
      let userRole = decodedToken.roleName;

      if ( this.authorizationService.isUserAuthorized(decodedToken?.policies?.orgs, ['list', '*']) 
        || this.authorizationService.isUserAuthorized(decodedToken?.policies?.stores, ['list', '*']) ) {
          this.handleUnauthorizedAccess("/configuration", state.url);
        return false;
      } else if ( this.authorizationService.isUserAuthorized(decodedToken?.policies?.tasks, ['task', '*']) ) {
        this.handleUnauthorizedAccess("/my-tasks", state.url);
        return false;
      }
    }

    return true;
  }

  private handleUnauthorizedAccess(
    defaultRoute: string,
    currentRoute: string
  ): void {
    const unauthorizedRoute = "/unauthorized";
    const redirectRoute = currentRoute.includes(defaultRoute)
      ? unauthorizedRoute
      : defaultRoute;
    this.router.navigate([redirectRoute]);
  }
}
