<div class="container-fluid">
  <div class="ms-auto text-end casebtn-box">
    <button type="button" class="case-btn" (click)="makeitaCase(selectedTask)"><img
        src="../../../../assets/icons/round-plus.png" alt="" />Make it a Case</button>
  </div>
  <div class="Tab-change">

    <div class="Tab-change1 col-lg-4 col-xxl-4 px-0">
      <div class="tab mt-4" role="tabpanel">
        <div class="px-3">
          <ul class="nav nav-tabs" id="status_tabs" role="tablist">
            <li class="nav-item justify-content-between flex-grow-1" *ngFor="let status of statusTabs; let i = index">
              <a class="nav-link d-flex justify-content-center align-items-center"
                [ngClass]="{ 'active': i === defaultStatusTab }" [id]="status.id" data-bs-toggle="tab"
                [href]="'#tab_' + status.name" role="tab" (click)="onTabChange(status.id)">
                {{ status.title }}
                <ng-container *ngFor="let counter of taskCounter">
                  <div class="badge bg-secondary text-center" *ngIf="status.label === counter.status">
                    <span class="badge-count">{{ counter.statusCount || baseIndex }}</span>
                  </div>
                </ng-container>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="my_tasks_content">
        <div *ngFor="let status of statusTabs; let i = index" class="tab-pane fade" [id]="'tab_' + status.name"
          [ngClass]="{ 'show active': i === defaultStatusTab }" role="tabpanel" [attr.aria-labelledby]="status.name">

          <div class="font-small px-2 bb-1" role="tabpanel">
            <ul class="list-filters d-flex justify-content-between align-items-top text-center" id="usecase_tabs"
              role="tablist">
              <li class="list-filter-item nav-item align-items-center"
                *ngFor="let useCaseTab of useCaseTabs; let j = index">
                <a class="nav-link" [ngClass]="{ 'active': this.selectedUseCase === useCaseTab.useCase}"
                  [id]="'items_' + useCaseTab.name + '_new'" data-bs-toggle="tab"
                  [href]="'#' + useCaseTab.name + '_items'" role="tab"
                  (click)="onSubTabChange(useCaseTab.useCase, status.id)">
                  <div class="icon-filter" [ngClass]="'filter-' + useCaseTab.icon"></div>
                  <div class="text-filter">{{ useCaseTab.label }}</div>
                </a>
              </li>
            </ul>
          </div>

          <div class="tab-content" id="sub_tasks_content">
            <div *ngFor="let useCaseTab of useCaseTabs; let k = index" class="tab-pane fade scrollable-list-container"
              [id]="useCaseTab.name + '_items'" role="tabpanel" [attr.aria-labelledby]="useCaseTab.name + '_tab'"
              (scroll)="onScroll($event, scrollContainer)" #scrollContainer
              [ngClass]="{ 'show active': k === baseIndex }">
              <ul class="list-group scrollable-list" id="tasks_data"
                *ngIf="taskList.length == baseIndex || taskList.length >= baseFirstIndex">
                <li class="list-group-item" *ngFor="let item of taskList; let i = index"
                  [ngClass]="{ 'active-row': i === clickedRowIndex}" (click)="fetchTaskDetails(item, i)">
                  <div [class.read]="item.read === true" [class.unread]="item.read === false"
                    class="d-flex align-items-center justify-content-center list-row">
                    <div class="task-item-img">
                      <span class="icon-task" [ngClass]="item.taskIcon"></span>
                    </div>
                    <div class="task-list">
                      <h6 class="mb-1">{{item.taskDisplayName}}</h6>
                      <p>{{item.camName}}</p>
                      <p>{{item.storeName}}</p>
                    </div>
                    <div class="ms-auto text-end timezone">
                      <p class="mb-1">{{item.createdDate}}</p>
                      <p class="mb-1">{{item.createdTime}}</p>
                      <p class="mb-0 m-0 task-score">
                        <span class="fw-bold">{{item.severity}}</span>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <ul class="list-group scrollable-list"
                *ngIf="taskList.length == baseIndex || taskList.length < baseFirstIndex">
                <li>
                  <p class="p-4 text-center">No Tasks Available</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="selected-task col-lg-8 col-xxl-8 px-3">
      <div class="task-details-header">
        <div class="selected-task1 d-flex align-items-top">
          <div class="task-detail" *ngIf="selectedTask?.storeName">
            <h6 class="mb-1 fw-bolder">{{selectedTask?.usecaseDescription}}
              <div *ngIf="selectedTask?.duplicates_count>0 || selectedTask?.is_duplicate == true">| Duplicate<button
                  [disabled]="selectedTask?.index == undefined"
                  (click)="duplicateTaskDetails(selectedTask?.index !== undefined ? selectedTask?.index - 1 : 1)"
                  style="background-color: white; border: 1px solid white;">
                  <img src="../../../../assets/icons/lessthan.png" alt="" style="cursor: pointer;" />
                </button> incident {{
                selectedTask?.index !== undefined ? selectedTask?.index + 1 : 1 }} / {{totalIncidentCount}}
                <button [disabled]="selectedTask?.index == totalIncidentCount - 1 "
                  (click)="duplicateTaskDetails(selectedTask?.index !== undefined ? selectedTask?.index + 1 : 1)"
                  style="background-color: white; border: 1px solid white;">
                  <img src="../../../../assets/icons/greaterthan.png" alt="" style="cursor: pointer;" />
                </button>
              </div>
            </h6>
            <p>{{ selectedTask?.camLocation }}</p>
            <p class="mb-0"><span>{{selectedTask?.storeName}},
                {{selectedTask?.nrfExtra1}}</span> |
              <span style="white-space: nowrap"> {{selectedTask?.createdDate}} {{selectedTask?.createdTime}}
                ({{selectedTask?.timezoneCode}})</span>
            </p>
          </div>
          <div class="incidence-status ms-auto text-end">
            <div class="d-flex justify-content-end float-xxl-end">
              <p class="me-2 status-title">Incidence Status:</p>
              <div class="d-flex justify-content-between">
                <div class="incident-status">
                  <select class="form-control search-input" [ngModel]="selectedStatus"
                    (ngModelChange)="selectedStatus = $event" (change)="changeTaskStatus($event, selectedTask?.taskId)"
                    [disabled]="selectedTask?.index > 0">
                    <option *ngFor="let tab of statusTabs" [value]="tab.name"
                      [selected]="selectedTask?.status == tab.name">
                      {{ tab.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end float-xxl-end" *ngIf="selectedTask">
              <div class="search">
                <select class="form-control search-input" [(ngModel)]="selectedSeverity"
                  [disabled]="selectedTask?.index > 0" (change)="changeSeverity(selectedTask,$event)">
                  <option *ngFor="let severity of severityList" [value]="severity.id"
                    [selected]="selectedTask?.severity == severity.type"> {{ severity.type }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="selected-taskname task-details-body">
        <div class="d-flex align-items-center">
          <div class="task-detail" *ngIf="selectedTask?.storeName">
            <h6 class="mb-1 fw-bold">{{selectedTask?.taskDescription}}</h6>
            <p class="mb-1">
              <span>CID - {{selectedTask?.createdDate| date:'ddMMyyyy'}}{{ selectedTask?.CID }}</span>
            </p>

            <div class="video-play-section img-opac py-3">
              <div class="image-player" *ngIf="selectedTask?.useCaseName == 'PeopleMovement'">
                <img
                  src="https://retailsaasuat.blob.core.windows.net/tasks-images-ui/{{ selectedTask?.nrfExtra2 }}?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-10-10T19:17:22Z&st=2023-08-01T11:17:22Z&spr=https&sig=Vdj972TSAzqD%2Fr%2BPvGIR4HFTLukVgh%2Baj5xd6AozKeo%3D"
                  alt="video" />
              </div>
              <div class="video-player" style="height: 600px;" *ngIf="selectedTask?.useCaseName !== 'PeopleMovement'">
                <app-video-player [displaymeta]="false" [meta]="selectedTask" [media]="taskVideoUrl"
                  [taskVideoStartTime]="taskCreatedTime" [taskVmsStartTime]="taskVmsPlayTime" [autoplay]="false"
                  [controls]="true"></app-video-player>
              </div>

              <div class="feedback" *ngIf="feedbackTags?.length">
                <p class="me-2 status-title mb-0 user">{{feedbackTags[0]?.feedbackTitle}}</p>
                <div class="feedback-shrinkage" *ngFor="let action of feedbackTags; let i = index">
                  <input type="radio" [checked]="selectedTag === action.actionId" id="{{action.actionId}}"
                    name={{feedbackTags[0]?.feedbackTitle}} />
                  <label for="size_3" class="tags-feedBack me-2"
                    [ngClass]="selectedTag === action.actionId ? 'active-status' : ''"
                    (click)="taskTagActions(selectedTask, action, i)">
                    <span class="icon" [class]="action.icon" *ngIf="action.icon"></span>
                    {{action.tag}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #feedbackComments let-modal>
  <div class="feedback-modal">
    <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal(modal)"></button>
    <div class="modal-body">
      <p class="feedback-title mb-3">Selected Task Id: {{selectedTask?.taskId}}</p>
      <div class="user-form-group mb-4 custom-icon">
        <label for="feedback">Reason:<sup>*</sup></label>
        <select class="feedback-type form-control" [(ngModel)]="selectedReason">
          <option selected disabled value="">
            Select the reason
          </option>
          <option *ngFor="let reason of feedbackActionTags ; let i = index" value="{{reason}}">
            {{reason}}
          </option>
        </select>
      </div>
      <div class="user-form-group mb-2">
        <label for="feedback">Comments:<sup>*</sup></label>
        <input type="text" id="feedback" class="form-control" placeholder="Comments" required
          (blur)="inputFeedbackReason()" [(ngModel)]="taskActionFeedback">
      </div>
      <p *ngIf="provideReason" class="error-reason">Please provide the Reason..</p>
      <button type="button" class="btn btn-feed-submit"
        (click)="submitFeedback(selectedTask?.taskId, modal)">Submit</button>
    </div>
  </div>
</ng-template>