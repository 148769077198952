import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { HttpClient } from "@angular/common/http";

export interface ROI {
  type: string;
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;
}

@Component({
  selector: "app-usecase-config",
  templateUrl: "./usecase-config.component.html",
  styleUrls: ["./usecase-config.component.scss"],
})
export class UsecaseConfigComponent implements OnInit, AfterViewInit {
  @ViewChild("canvasElement", { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private ctx!: CanvasRenderingContext2D;
  @Input() configData: any;
  uploadImageForm: FormGroup;
  selectedRoiType: string = "ProductROI"; // Default selected ROI type
  roiTypes: string[] = ["ProductROI", "PersonROI"];
  rois: ROI[] = [];

  personRoiPaths: Path2D[] = [];
  productRoiPaths: Path2D[] = [];

  // Constants for canvas dimensions
  canvasWidth: any = 450;
  canvasHeight: any = 300;

  imageUrl: string = "";
  loadImageData: string;
  selectedFile: File | undefined;
  selectedImage: any;

  useCaseIdentifier: string;
  selectedUseCaseId: number;

  toggleROISelect: boolean;
  canvasVisible: boolean = false;
  isImageLoaded: boolean = false;
  isSubmitted: boolean = false;
  isVisibleImage: boolean = false;
  isImageUpload: boolean = false;

  imageWidth: number | undefined;
  imageHeight: number | undefined;
  drawROIFile: any;
  uploadedImage: any;

  fileErrorMessage: string = "Please choose file and upload to draw ROI";
  fileError: boolean = false;
  fileUploadError: boolean = false;

  constructor(
    private configService: ConfigurationService,
    private http: HttpClient,
    private fb: FormBuilder
  ) {
    this.uploadImageForm = this.fb.group({
      uploadImage: ["ProductROI", [Validators.required]],
    });
  }
  get fc() {
    return this.uploadImageForm.controls;
  }

  ngOnInit(): void {   
    if (this.configData?.useCase?.usecaseIdentifier == "Dwell" || this.configData?.useCaseIdentifier == "Dwell") {
      this.roiTypes.splice(0, 1)
      this.selectedRoiType = "PersonROI"
    }
    if (this.configData?.useCase?.usecaseIdentifier == "PeopleCounter" || this.configData?.useCaseIdentifier == "PeopleCounter") {
      this.roiTypes.splice(0, 1)
      this.selectedRoiType = "PersonROI"
    }

    this.configService.fileUploadStatus.subscribe((status) => {
      this.fileError = this.uploadedImage ? false : status;
    });
  }

  ngAfterViewInit(): void {
    this.initializeCanvas();
    this.registerCanvasEvents();
  }
  onFileChange(event: any) {
    this.isSubmitted = true;
    const profileImage = event.target.files[0];
    this.drawROIFile = profileImage;

    if (profileImage) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target?.result as string;
        this.uploadedImage = base64String;
      };
      this.fileError = false;
      const image = new Image();
      image.src = URL.createObjectURL(this.drawROIFile);

      image.onload = () => {
        this.imageWidth = image.naturalWidth;
        this.imageHeight = image.naturalHeight;
      };

      reader.readAsDataURL(profileImage);
    }
    this.clearROIs();
  }

  uploadImage() {
    this.isSubmitted = true;
    this.selectedImage = this.uploadedImage;
    this.fileError = this.uploadedImage ? false : true;
    localStorage.setItem("uploadedImage", this.selectedImage);
    this.clearROIs();
    this.clearCanvas();
    if (this.uploadImageForm.invalid) {
      return;
    }
    this.canvasVisible = true;

    if (this.drawROIFile === undefined) {
      this.isVisibleImage = true;
      this.isImageLoaded = false;
      this.canvasVisible = false;
    } else {
      this.isVisibleImage = false;
      const formData = new FormData();
      if (this.drawROIFile) {
        formData.append("image", this.drawROIFile);
      }

      this.fetchUseCaseInfo();
      this.getSavedImage();
      this.isImageLoaded = true;

      const dimensions = {
        dimensionWidth: this.imageWidth,
        dimensionHeight: this.imageHeight,
      };
      this.configService.setImageDimensions(dimensions);
    }
  }

  bytesToSize(bytes: number): string {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
  }

  changeRoiType(event: any) {
    this.selectedRoiType = event.target.value;
    this.uploadImageForm.get("uploadImage")?.setValue(this.selectedRoiType);
  }

  fetchUseCaseInfo() {
    if (this.configData?.useCase) {
      this.useCaseIdentifier = this.configData?.useCase?.useCaseIdentifier;
      this.selectedUseCaseId = this.configData?.useCase?.usecaseId;
    } else {
      this.useCaseIdentifier = this.configData?.useCaseIdentifier;
      this.selectedUseCaseId = this.configData?.useCaseUId;
    }
    if (this.selectedUseCaseId === 2 || this.selectedUseCaseId === 4) {
      this.toggleROISelect = false;
    } else {
      this.toggleROISelect = true;
    }
  }

  getSavedImage() {
    this.selectedImage = localStorage.getItem("uploadedImage");
    return this.selectedImage;
  }

  private initializeCanvas(): void {
    this.ctx = this.canvas.nativeElement.getContext("2d")!;
    this.canvas.nativeElement.width = this.canvasWidth;
    this.canvas.nativeElement.height = this.canvasHeight;
    this.ctx.strokeStyle = "#a81d9d";
    this.ctx.lineWidth = 2;
  }

  private registerCanvasEvents(): void {
    this.canvas.nativeElement.addEventListener(
      "mousedown",
      this.onMouseDown.bind(this)
    );
    this.canvas.nativeElement.addEventListener(
      "mousemove",
      this.onMouseMove.bind(this)
    );
    this.canvas.nativeElement.addEventListener(
      "mouseup",
      this.onMouseUp.bind(this)
    );
  }

  private isDrawing: boolean = false;
  private xMin: number = 0;
  private yMin: number = 0;
  private xMax: number = 0;
  private yMax: number = 0;
  private scaleX: number = 1;
  private scaleY: number = 1;
  private scalerValue: number = 2;

  onMouseDown(event: MouseEvent): void {
    if (this.isImageLoaded) {
      this.isDrawing = true;
      this.xMin = event.offsetX;
      this.yMin = event.offsetY;
    } else {
      this.loadImageData = "Please load an image before drawing an ROI.";
    }
  }

  onMouseMove(event: MouseEvent): void {
    if (!this.isDrawing) return;
    this.xMax = event.offsetX;
    this.yMax = event.offsetY;
    this.drawROI();
  }

  onMouseUp(): void {
    this.isDrawing = false;

    if (this.imageWidth !== undefined && this.imageHeight !== undefined) {
      let startX = Math.max(
        0,
        Math.min(
          Math.round(
            ((this.xMin / this.canvasWidth) * this.imageWidth) /
              this.scalerValue
          ),
          this.imageWidth
        )
      );
      let startY = Math.max(
        0,
        Math.min(
          Math.round(
            ((this.yMin / this.canvasHeight) * this.imageHeight) /
              this.scalerValue
          ),
          this.imageHeight
        )
      );
      let endX = Math.min(
        this.imageWidth,
        Math.max(
          Math.round(
            ((this.xMax / this.canvasWidth) * this.imageWidth) /
              this.scalerValue
          ),
          0
        )
      );
      let endY = Math.min(
        this.imageHeight,
        Math.max(
          Math.round(
            ((this.yMax / this.canvasHeight) * this.imageHeight) /
              this.scalerValue
          ),
          0
        )
      );
      // Ensure drawing from left to right
      if (endX < startX) {
        const tempX = endX;
        endX = startX;
        startX = tempX;
      }

      // Ensure drawing from top to bottom
      if (endY < startY) {
        const tempY = endY;
        endY = startY;
        startY = tempY;
      }

      const roi: ROI = {
        type: this.selectedRoiType,
        xMin: startX,
        yMin: startY,
        xMax: endX,
        yMax: endY,
      };

      this.rois.push(roi);
      this.configService.addOrUpdateROIConfiguration(roi);
      this.drawROI();
    }
  }

  private drawROI(): void {
    if (!this.ctx) return;
    this.clearCanvas();
    this.ctx.beginPath();
    const color = this.selectedRoiType === "ProductROI" ? "#c818eb" : "#bd1793";
    this.ctx.strokeStyle = color;

    if (this.selectedRoiType === "PersonROI") {
      this.personRoiPaths.forEach((path) => {
        this.ctx.stroke(path); // Draw PersonROI paths
      });
    }

    if (this.selectedRoiType === "ProductROI") {
      this.productRoiPaths.forEach((path) => {
        this.ctx.stroke(path); // Draw ProductROI paths
      });
    }
    this.ctx.rect(
      this.xMin * this.scaleX,
      this.yMin * this.scaleY,
      (this.xMax - this.xMin) * this.scaleX,
      (this.yMax - this.yMin) * this.scaleY
    );
    this.ctx.stroke();
  }

  private clearCanvas(): void {
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
  }

  clearROIs(): void {
    this.rois = [];
  }
}
