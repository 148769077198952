import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'src/app/services/configuration.service';
import Swal from 'sweetalert2';
import { AnimationKeyframesSequenceMetadata } from '@angular/animations';
import { AlertMessagesService } from "src/app/services/alert-messages.service";

@Component({
  selector: 'app-profile-reset-password',
  templateUrl: './profile-reset-password.component.html',
  styleUrls: ['./profile-reset-password.component.scss']
})
export class ProfileResetPasswordComponent implements OnInit {
  
  @ViewChild("otpVerification", { static: true }) otpVerification: ElementRef;

  isSubmitting: Boolean = false;
  error: Boolean = false;
  privateIPAddress: string = "000.000.000.000";
  currentDate = new Date();
  newPassword: string;
  currentPassword: string;
  confirmPassword: string;
  message: string = "Please Enter the Password";
  showAuthToken: boolean = false;
  showAuthToken1: boolean = false;
  showAuthToken2: boolean = false;
  resetTokenId: string;
  valid : Boolean;
  resetForm: FormGroup;
  isSubmitted: boolean;
  currentPasswordError: boolean = true;
  newPasswordError: boolean;
  confirmPasswordError: boolean;
  input1Value: string = '';
  input2Value: string = '';
  input3Value: string = '';
  input4Value: string = '';
  email: string | null;
  initialTime: number = 600; //seconds
  remainingTime: number = this.initialTime;
  countdownTimer: any;
  otpVerificationMessage: string;
  otpUid: string;
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';

  constructor(
    private router: Router,
    private configService: ConfigurationService,
    private http: HttpClient,
    private route: ActivatedRoute,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    public modalService: NgbModal,
    private alertMessagesService: AlertMessagesService
  ){ this.resetForm = this.fb.group({
    newPassword: ["", [Validators.required]],
    confirmPassword: ["", [Validators.required]],
    currentPassword: ["", [Validators.required]],
  });
  
 
  }
  get fc() {
    return this.resetForm.controls;
  }
 
  ngOnInit(): void {
    this.email = localStorage.getItem("email")
    this.route.queryParams.subscribe((params) => {
      this.resetTokenId = params["id"];
    });
  }
  togglePasswordVisibility() {
    this.showAuthToken = !this.showAuthToken;
 
  }
  togglePasswordVisibility1() {
    this.showAuthToken1 = !this.showAuthToken1;
  }
  togglePasswordVisibility2() {
    this.showAuthToken2 = !this.showAuthToken2;
  }

  checkPasswordMatch() {
    if(this.newPassword === this.confirmPassword && this.newPassword !== "") {
      this.valid = true;
      this.confirmPasswordError = false;
      this.message = "";
    }
    else {
      if(this.newPassword !== "" && !this.newPasswordError){
        this.valid = false;
        this.confirmPasswordError = true;
        this.message = "Both Password Didn't Match";
      }
      else{
      this.confirmPasswordError = false;
      }
    }
  }
  comparePasswords() {
    if (!this.newPassword || this.newPassword === '') {
      this.newPasswordError = true;
      this.valid = false;
      this.message = "Please enter the password";
    }
    if (this.newPassword.length < 10) {
      this.newPasswordError = true;
      this.message = "Your password must contain more than 8 characters.";
      this.valid = false;
    } else if (!/[A-Z]/.test(this.newPassword)) {
      this.newPasswordError = true;
      this.message = "Your password must contain at least one uppercase letter (A to Z).";
      this.valid = false;
    } else if (!/\d/.test(this.newPassword)) {
      this.newPasswordError = true;
      this.message = "Your password must contain at least one number.";
      this.valid = false;
    } else if (!/[!@#$]/.test(this.newPassword)) {
      this.newPasswordError = true;
      this.message = "Your password must contain at least one special character.";
      this.valid = false;
    }
    else{
      this.message = "";
      this.newPasswordError = false;
      this.valid = true;
    }
    this.checkPasswordMatch();
  }
  
 
  removeMessage(){
    this.message=""
  }
 
 
  submitForm(): void {
    this.isSubmitting = true;
    const data = {
      requestTokenId: this.resetTokenId,
      new_password: this.newPassword,
      old_password: this.currentPassword,
      confirm_password: this.confirmPassword,
      usedIP: this.privateIPAddress,
      date: this.currentDate,
    };
        this.comparePasswords();
    if (this.valid) {
      this.configService.profileResetPassword(data).subscribe(
        (resp: any) => {
          if (resp.status === true) {
            this.activeModal.dismiss();
            const options: NgbModalOptions = {
              backdrop: 'static',
              keyboard: false
            };
            const modalRef: NgbModalRef = this.modalService.open(this.otpVerification, options);
            this.otpVerificationMessage = "OTP has been sent to "+ this.email;
            this.currentPasswordError = false;
            this.message = '';
            this.startTimer();
            this.otpUid = resp.detail;
          } else {
            this.currentPasswordError = true;
            this.message = "Please Recheck Current Password";
          }
        },
        (error: any) => {
          this.alertMessagesService.alertWithError(error.error.detail);
          this.currentPasswordError = true;
          this.message = "Please Recheck Current Password";
        }
      );
      }
  }

  closePage(){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      text: "Are you sure to cancel?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {   
      if (result.isConfirmed) {
        this.activeModal.dismiss();
      } 
      else {
        Swal.close();
      } 
    })
  }

  startTimer(): void {
    this.countdownTimer = setInterval(() => {
      if (this.remainingTime > 0) {
        this.remainingTime--;
      } else {
        clearInterval(this.countdownTimer);
      }
    }, 1000);
  }

  formatTime(time: number): string {
    const minutes: string = Math.floor(time / 60).toString().padStart(2, '0');
    const seconds: string = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  }

  ResendOTP(){
    this.configService.profileResendOtp().subscribe(
      (resp: any) => {
        if (resp.status === true) {
           this.remainingTime = this.initialTime
           this.otpVerificationMessage = "OTP has been resent to "+ this.email;
           this.startTimer();
           this.otpUid = resp.data;
        } 
      },
      (error: any) => {
        // This block handles API call errors
        this.alertMessagesService.alertWithError(error.error.detail);
      }
      
    );
  }

  dismissModal(modal: any){
    modal.close();
  }

  submitOTP(modal: any) {
    const enteredOtp = this.input1Value + this.input2Value + this.input3Value + this.input4Value;
    // const otpNumber = parseInt(otp, 10);
    const data = {
      otp_uid: this.otpUid,
      entered_otp: enteredOtp,
      new_password: this.newPassword,
    } 
    this.configService.profileValidateOtp(data).subscribe(
      (resp: any) => {
        if (resp.status === true) {
          this.alertMessagesService.alertWithSuccess("Password Updated Successfully");
          this.dismissModal(modal)
        }
      else{
        this.alertMessagesService.alertWithError("Wrong OTP");
      }
      },
      (error: any) => {        
        this.alertMessagesService.alertWithError(error.error.detail);
      }
    );
  }
}
