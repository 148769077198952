import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppStateService {
  private jsonDataSubject = new BehaviorSubject<any>(null);
  jsonData$: Observable<any> = this.jsonDataSubject.asObservable();
  private localJsonDataSubject = new BehaviorSubject<any>(null);
  private widgetDataSubject = new BehaviorSubject<any>(null);
  jsonWidgetData$: Observable<any> = this.widgetDataSubject.asObservable();

  constructor() {}

  setJsonData(data: any): void {
    this.jsonDataSubject.next(data);
  }
  setWidgetJsonData(data: any): void {
    this.widgetDataSubject.next(data);
  }
  getWidgetJsonData(): any {
    let jsonWidgetDataValue: any;
    this.jsonWidgetData$.subscribe((data) => {
      jsonWidgetDataValue = data;
    });
    return jsonWidgetDataValue;
  }

  getJsonData(): any {
    // return this.jsonDataSubject.value;
    let jsonDataValue: any;
    this.jsonData$.subscribe((data) => {
      jsonDataValue = data;
    });
    return jsonDataValue;
  }

  setLocalJsonData(data: any): void {
    // this.localJsonDataSubject.next(data);
    this.jsonDataSubject.next(data);
    localStorage.setItem("globalJson", data);
  }

  getLocalJsonData(): any {
    return localStorage.getItem("globalJson");
  }
  getTabsJsonData(): any {
    return localStorage.getItem("selectedScenario1");
  }
}
