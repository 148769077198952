<div class="row strategy-insights m-0 py-3 px-2">
  <div class="store-search-box">
    <div class="card search-box card-box">
      <div class="d-flex br-3 justify-content-between px-3">
        <div class="search-item d-flex me-4 py-3">
          <label>Store</label>
          <div class="search-selector">
            <select class="store-search">
              <option selected>All</option>
              <option>NRF store</option>
            </select>
          </div>
        </div>
        <div class="search-item d-flex me-4 py-3">
          <label>Zip Code</label>
          <div class="search-selector">
            <select class="store-search">
              <option selected>All</option>
              <option>76051</option>
            </select>
          </div>
        </div>
        <div class="search-item d-flex me-4 py-3">
          <label>State</label>
          <div class="search-selector">
            <select class="store-search">
              <option selected>All</option>
              <option>TX</option>
            </select>
          </div>
        </div>
        <div class="search-item d-flex me-4 py-3">
          <label>Country</label>
          <div class="search-selector">
            <select class="store-search">
              <option selected>All</option>
              <option>USA</option>
            </select>
          </div>
        </div>
        <div class="widget-customize ms-auto py-3">
          <div class="custom-widget">
            <span><img src="../../../../assets/icons/widgets.svg" alt=""></span>
            Customize Widgets
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="insight-charts row mx-0 mt-2">
    <div class="insight-chart-box col-12 px-0">
      <div class="px-2">
        <div class="chart-card p-3">
          <div class="alert-distributed chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Interactions to Footfall</h5>
            </div>
            <div class="data-chart">
              <div echarts [options]="strategyInteraction" class="demo-chart"></div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

  <div class="insight-charts row mx-0 mt-2">
    <div class="insight-questions col-6 px-0">
      <div class="px-2">

        <div class="chart-card p-3">
          <div class="strategy-form-group custom-icon mb-4">
            <select class="form-control question-select" (change)="selectQuestion($event)">
              <option *ngFor="let question of strategyQuestions" [value]="question.id">
                {{question.question}}
              </option>

            </select>
          </div>
          <div class="strategy-question">
            <p>Orders can be affected by various factors such as Footfalls, Interactions, Time spent on store and other
              external factors:</p>
            <p>For your store:</p>
            <ul>
              <li>Overall footfall has decreased by 6%</li>
              <li>Interaction has decreased by 8%</li>
              <li>Time spent in store has gone down by 12%</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="insight-chart-box col-6 px-0">
      <div class="px-2">
        <div class="chart-card p-3">
          <div class="alert-distributed chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Interactions to Footfall</h5>
            </div>
            <div class="data-chart">
              <div echarts [options]="strategyInteraction" class="demo-chart"></div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>