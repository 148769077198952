<div class="popup-container" id="popupContainer_1" *ngIf="caseCreation">
  <div class="case-popup">
    <h4 class="modal-title case-creation">Case Creation</h4>
    <p class="investigation">You are making this task into a case. Making it a case will allow you to launch an
      investigation.</p>

    <div class="container-confirm" *ngIf="confirmPopup">
      <label>Do you wish to proceed?</label>
      <button type="button" class="btn-accept" (click)="createCase()">Yes</button>
      <button type="button" class="btn-reject" (click)="activeModal.dismiss()">No</button>
    </div>

    <div class="container-confirm" *ngIf="loaderPopup">
      <div class="progress">
        <div class="progress-bar" [style.width]="uploadProgress + '%'"></div>
        <p>{{ uploadProgress }}% Complete</p>
      </div>
      <p>{{errorMessage}}</p>
    </div>
  </div>
</div>

<div class="popup-container" id="popupContainer_2" *ngIf="caseCreated">
  <div class="case-popup">
    <div class="congrats-msg"><img src="../../../../../assets/icons/congrats.png" alt=""> </div>
    <h4 class="modal-title case-creation">Congratulations, <br /> Case {{this.createdCaseInfo.taskId}} has been created!
    </h4>
    <p class="investigation mb-3">Your task is now a case!</p>
    <button type="button" class="btn-case-accept mt-5" (click)="goToCase()">Go to this
      Case</button>

  </div>
  <div class="form-control-container text-center " *ngIf="errorMessage">
    <p>{{errorMessage}}</p>
  </div>
</div>