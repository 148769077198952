<div class="row">
  <section class="login">
    <div class="wrapper">
      <div class="login-form form">
        <div class="form-logo mb-3">
          <img src="../../../../assets/logo.png" alt="">
        </div>
        <h3 class="text-center mb-4">Forgot Password</h3>
        <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">

          <div class="form-control-container">
            <div class="form-group form-relative">
              <label for="username" class="form-label">
                Email
              </label>
              <div class="login-icon">
                <input type="email" class="form-control email"
                  [ngClass]="{ 'is-invalid': isSubmitted && fc['email'].errors }" id="email" placeholder="Email ID"
                  formControlName="email" [ngClass]="{'is-invalid': isSubmitted && fc['email'].errors}" />

                <div *ngIf="forgotForm.get('email')?.hasError('required')" class="invalid-feedback">Please Enter Your
                  Email
                </div>
              </div>
            </div>
          </div>

          <div class="forgot">
            <a class="forgot-link" routerLink="/login"><span><img src="../../../../assets/icons/left-arrow.png"
                  alt="arrow" /></span> Get back to Login</a>
          </div>

          <button type="button" type="submit">Submit</button>
        </form>
        <div *ngIf="errorMessage" class="error-message">
          <p>{{ providedErrorMessage }}</p>
        </div>
      </div>
    </div>
  </section>
  <div class="spinner-outer" *ngIf="processing">
    <div class="loading-spinner"></div>
  </div>
</div>