import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (! this.authService.isAuthenticate()) {
      this.router.navigate(['/login']);
      return false; // Block navigation to the login page
    }

    if ( this.authService.isAuthenticate() && state.url == '/unauthorized' ) {
      // restrict access to login, forgot password and reset password page if user is authenticated
      this.router.navigate(['/']);
      return false;
    }
    // this.router.navigate(['/']);
    return true;
  }
}
