import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
} from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import { AddRolesComponent } from "./add-roles/add-roles.component";
import { CommonService } from "src/app/services/common.service";
import {
  ModalDismissReasons,
  NgbModal,
  NgbActiveModal,
  NgbModalConfig
} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import { Subject, takeUntil } from "rxjs";
// import { ColDef } from 'ag-grid-community';
@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"],
})
export class RolesComponent implements OnInit {
  @Input() filteredOrgs: any[] = [];

  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  noDataFound: boolean = false;
  closeResult = "";
  storesData: any;
  totalItems: number = 0;
  pageSize: number = this.configurationService.pageSize;
  itemsPerPage: number = this.configurationService.itemsPerPage;
  currentPage: number = 1;
  paginationId = "store_items";
  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: any = "";
  private ngUnsubscribe = new Subject<void>();
  
  //storeData: any;
  loggedUser: any;
  visibleOrg: boolean = false;
  visibleStore: boolean = false;
  selectedTenantIdDropdown: boolean = false;
  selectedTenantName: string = "";
  isSubmitted: boolean;
  message: string = "Initial message";
  deleteRoleObj: {
    org_id: string;
    role_id: string;
  };
  tenantId: any;
  rolesData: any = [];
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';
  constructor(
    private configurationService: ConfigurationService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private commonService: CommonService,
    private config: NgbModalConfig,
    private alertMessagesService: AlertMessagesService,

  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.commonService.selectedOrganization$.subscribe(org => {
      if (org) {
        if(org.tenantId === ""){
          this.selectedTenantIdDropdown = true;
          this.selectedTenantId = org.tenantId;
          this.rolesData = [];
        }
        this.selectedTenantIdDropdown = true;
        this.selectedTenantId = org.tenantId;
        this.getRolesData(this.selectedTenantId);

      }
    });
    this.deleteRoleObj = {
      org_id: "",
      role_id: "",
    };
    this.commonService.rolesTabClicked.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isClicked) => {
      if (isClicked){
        this.getTenantName(this.selectedTenantId);
        this.getRolesData(this.selectedTenantId);
      }
    });
  }

  ngOnInit(): void {
    this.tenantId = this.selectedTenantId;
    this.configByRole();
    this.commonService.setSelectedOrganization({
      tenantId: this.tenantId,
      storeId: ''
    });
    this.getRolesData(this.tenantId);
  }
  getRolesData(tenantId: any) {
    if( !tenantId ) {
      return;
    }
    this.configurationService
      .getAllRolesDataByOrgId(tenantId)
      .subscribe({
        next: (resp: any) => {
          if (resp.message === "success") {
            this.rolesData = resp.data;
            this.rolesData = this.rolesData.forEach((ele: any) => {
              ele.tenantId = tenantId;
              ele.tenantName = this.selectedTenantName;
            });
            this.rolesData = resp.data.sort((a: any, b: any) => a.role_name.localeCompare(b.role_name));
            this.totalItems = resp.data.length;
          }
          if (this.totalItems === 0) {
            this.rolesData = [];
            this.noDataFound = true;
          }
        }, error: (error: any) => {
          this.rolesData = [];
          this.totalItems = 0;
        }
      });
  }
  configByRole() {
    if (this.loggedUser === "csm") {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      this.visibleOrg = true;
    }
    if (this.loggedUser === "org-admin") {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
      this.visibleOrg = false;
      this.selectedTenantId = this.defaultTenantId;
    }
  }

  openModal(content: any) {
    // Open the modal with the specified content
    this.modalService.open(content);
  }

  changeOrg(event: any) {
    this.visibleOrg = false;
    this.visibleStore = true;
    this.currentPage = 1;
    this.selectedTenantId = event.target.value;
    this.getTenantName(this.selectedTenantId)
    this.commonService.setSelectedOrganization({
      tenantId: this.selectedTenantId,
      storeId: '',
      tenantName: this.selectedTenantName
    });
    this.getRolesData(this.selectedTenantId);
  }
  getTenantName(tenantId: any) {
    this.filteredOrgs.forEach((ele: any) => {
      if (ele.tenantId == tenantId) {
        this.selectedTenantName = ele.tenantName;
      }
    });
  }
  addRolesData() {
    const modelRef = this.modalService.open(AddRolesComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
      size: 'xl'
    });
    modelRef.componentInstance.filteredOrgs = this.filteredOrgs;
    modelRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getRolesData(this.selectedTenantId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  handleActionEvent(actionDetails: any) {
    if (actionDetails.action === 'edit') {
      this.updateRoleData(actionDetails.data);
    } else if (actionDetails.action === 'delete') {
      this.deleteRole(actionDetails.data.role_id);
    }
  }

  updateRoleData(roles: any) {
    const modalRef = this.modalService.open(AddRolesComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
      size: 'xl'
    });
    modalRef.componentInstance.rolesData = roles;
    modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getRolesData(this.selectedTenantId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  deleteRole(role_id: any) {
    this.deleteRoleObj.org_id = this.selectedTenantId;
    this.deleteRoleObj.role_id = role_id;
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      title: "Are you sure?",
      text: "You really want to delete the Role.",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.configurationService.deleteRole(this.deleteRoleObj.role_id).subscribe(
          (resp: any) => {
            if (resp.message === 'Success') {
              this.configurationService.deleteRoleInUserManagement(this.deleteRoleObj.role_id).subscribe()
              this.alertMessagesService.alertWithSuccess("Role deleted successfully.");

              this.getRolesData(this.selectedTenantId);
            } else {
              this.alertMessagesService.alertWithError("Failed to delete Role");
            }
          },
          (error) => {
            this.alertMessagesService.alertWithError("Failed to delete Role");
          }
        );
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
