<div class="d-flex justify-content-end">
  <select class="selected-item" [(ngModel)]="selectedStatus" (change)="onStatusChange()">
    <option value='Active' selected>Active</option>
    <option value='Inactive'>Inactive</option>
  </select>
  <select class="selected-item" (change)="changeOrg($event)" *ngIf="isCSAdmin">
    <option [selected]="!selectedTenantIdDropdown" value="">All Organizations</option>
    <option *ngFor="let org of filteredOrgs" value={{org.tenantId}} [selected]="org.tenantId === selectedTenantId">{{org.tenantName}}</option>
  </select>
  <select class="selected-item" (change)="changeStore($event)" *ngIf="isOrgAdmin">
    <option [selected]="!selectedStoreIdDropdown" value="">All Stores</option>
    <option *ngFor="let store of filteredStores" value={{store.storeId}} [selected]="store.storeId === selectedStoreId">{{store.storeName}}</option>
  </select>

    <button class="btn btn-add add-camera-btn" (click)="addCameraData()">+ Add camera</button>
</div>

<div class="ag-grid">
  <app-camera-grid [rowData]="cameraData" (gridActionEvent)="handleActionEvent($event)"></app-camera-grid>
</div> 