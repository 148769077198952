import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./components/shared/login/login.component";

import { LayoutComponent } from "./components/layout/layout/layout.component";
import { ProfilePageComponent } from "./components/pages/profile-page/profile-page.component";
import { StoreInsightComponent } from "./components/pages/store-insight/store-insight.component";
import { MyTasksComponent } from "./components/pages/my-tasks/my-tasks.component";
import { StrategyInsightComponent } from "./components/pages/strategy-insight/strategy-insight.component";
import { ConfigurationComponent } from "./components/pages/configuration/configuration.component";
// import { AuthGuard } from "@auth0/auth0-angular";
import { AuthGuard } from "./_auth/guards/auth.guard";
import { NoAuthGuard } from "./_auth/guards/noAuth.guard";

import { ResetPasswordComponent } from "./components/shared/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./components/shared/forgot-password/forgot-password.component";
import { CaseManagementComponent } from "./components/pages/case-management/case-management.component";
import { CaseInfoComponent } from "./components/pages/case-management/case-info/case-info.component";
import { UnsavedChangesGuard } from "./components/pages/case-management/case-info/unsaved-changes.guard";
import { UnauthorizedComponent } from "./components/shared/unauthorized/unauthorized.component";
import { ProfileResetPasswordComponent } from "./components/pages/profile-page/profile-reset-password/profile-reset-password.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    canActivate: [NoAuthGuard],    
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  // {
  //   path: "profile-reset-password",
  //   component: ProfileResetPasswordComponent,
  //   // canActivate: [NoAuthGuard],
  // },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "my-tasks",
        component: MyTasksComponent,
      },
      {
        path: "store-insights",
        component: StoreInsightComponent,
      },
      {
        path: "case-management",
        component: CaseManagementComponent,
      },
      {
        path: "case-management/:taskid",
        component: CaseInfoComponent,
        canDeactivate: [UnsavedChangesGuard],
      },
      // {
      //   path: "strategic-insights",
      //   component: StrategyInsightComponent,
      // },
      {
        path: "profile-page",
        component: ProfilePageComponent,
      },
      {
        path: "configuration",
        component: ConfigurationComponent,
      },
      {
        path: "unauthorized",
        component: UnauthorizedComponent,
        //canActivate: [NoAuthGuard],
      },
      {
        path: "**",
        redirectTo: "/",
        pathMatch: "full",
      },
      // { path: "**", redirectTo: "/" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
