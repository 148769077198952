import { Injectable, Inject } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    @Inject("ALLOWED_URLS") private allowedUrls: string[]
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.headers.has('X-Skip-Interceptor')) {
      // Remove the custom header before sending the request
      const headers = request.headers.delete('X-Skip-Interceptor');
      const modifiedRequest = request.clone({ headers });
      return next.handle(modifiedRequest);
    }

    if (this.isAllowedUrl(request.url)) {
      const token = this.authService.getAccessToken();
     // const token = localStorage.getItem("auth_token");

      if (token !== null) {
        const headers = request.headers.set("Authorization", `Bearer ${token}`);
        const modified = request.clone({ headers });
        return next.handle(modified);
      }
    }
    return next.handle(request);
  }

  private isAllowedUrl(url: string): boolean {
    // return this.allowedUrls.some((pattern) => new RegExp(pattern).test(url));
    return true;
  }
}
