import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertMessagesService {

  constructor() { }

  alertWithSuccess(message: string) {
    
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alerts-container',
        htmlContainer: 'add-content',
        confirmButton: "btn btn-submit",  
        icon: "alert-icon",
        title: "alert-title",                    
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({                  
      iconHtml: '<img src="../../../../assets/icons/check5.svg"  width="58px" height="58px" alt="" " />',
      title: "Success!",
      text: message,                  
      confirmButtonText: "OK",  
      allowOutsideClick: false,
      allowEscapeKey: false,                  
    });
  }

  alertWithError(message: string) {    
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alerts-container',
        htmlContainer: 'add-content',
        confirmButton: "btn btn-submit",  
        icon: "alert-icon",
        title: "alert-title",                    
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({                  
      iconHtml: '<img src="../../../../assets/icons/confirmationnew.svg"  width="58px" height="58px" alt="" " />',
      title: "Error",
      text: message,                  
      confirmButtonText: "OK",  
      allowOutsideClick: false,
      allowEscapeKey: false,                  
    });
  }

  alertWithSuccessConfirmAction(message: string) {    
    return new Promise((resolve, reject) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          popup: 'alerts-container',
          htmlContainer: 'add-content',
          confirmButton: "btn btn-submit", 
          icon: "alert-icon",
          title: "alert-title",                
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({                  
        iconHtml: '<img src="../../../../assets/icons/check5.svg"  width="58px" height="58px" alt="" " />',
        title: "Success!",
        text: message,                  
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,                 
      }).then((successRes: any) => {
        if (successRes.isConfirmed) {
          resolve(true);
        }
      });
    });
  }

  showToastMessage(message: any, messageTypeIconHtml: any, conditionalClass?: string) {
    const Toast = Swal.mixin({
      customClass: {
        popup: 'toast-container',
        icon: 'toast-icon-container',
        title: 'toast-title',
        container: `toast-main-container ${conditionalClass || ''}`,
      },
      buttonsStyling: false,
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      iconHtml: messageTypeIconHtml,
      title: message
    });
  }

}
