import { Directive, Input, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appDigitCount]",
})
export class DigitCountDirective {
  @Input("appDigitCount") maxCount: number;

  constructor(private el: ElementRef) {}

  @HostListener("input", ["$event.target.value"])
  onInput(value: string): void {
    if (typeof value === "string") {
      const digits = value.replace(/\D/g, ""); // Remove non-digit characters
      if (digits.length > this.maxCount) {
        this.el.nativeElement.value = digits.substring(0, this.maxCount);
      }
    }
  }
}
