import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataService {
  searchQuerySubject = new BehaviorSubject<string>("");
  clearSearchField: Subject<void> = new Subject<void>();

  setSearchValue(query: string) {
    this.searchQuerySubject.next(query);
  }

  getSearchValue() {
    return this.searchQuerySubject.asObservable();
  }
}
