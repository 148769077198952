import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from "sweetalert2";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class SessionTimeoutService {
  private timeoutHandle: number;
  private readonly inactivityDuration = 20 * 60 * 1000; // 20 Minues of inactivity
  private readonly timeoutDuration = 20 * 60 * 1000; // 20 Minutes total session timeout
  public allowCanDeactivateModalClose: boolean = false;

  constructor(private router: Router, private authService: AuthService, public modalService: NgbModal, private commonService: CommonService) {}

  private resetTimer(): void {
    window.clearTimeout(this.timeoutHandle);
  }

  startTimer(): void {
    this.resetTimer();
    this.timeoutHandle = window.setTimeout(() => {
      this.closeActiveModalAfterLogout();
      this.authService.logout();
    }, this.timeoutDuration);
  }

  resetTimeout(): void {
    this.resetTimer();
    this.startTimer();
  }

  initInactivityTimer(): void {
    let inactivityTimeout: number;
    const resetInactivityTimeout = (): void => {
      window.clearTimeout(inactivityTimeout);
      inactivityTimeout = window.setTimeout(() => {
        this.closeActiveModalAfterLogout();
        this.authService.logout();
      }, this.inactivityDuration);
    };
    const resetBothTimeouts = (): void => {
      this.resetTimeout();
      resetInactivityTimeout();
    };
    document.addEventListener("mousemove", resetBothTimeouts);
    document.addEventListener("keypress", resetBothTimeouts);
    resetBothTimeouts();
  }

  // method to close the active modal after session logout or inactivity logout like heat map modal
  closeActiveModalAfterLogout() {
    this.allowCanDeactivateModalClose = true;
    if( this.modalService ) {
      this.modalService.dismissAll();
    }
    if( Swal.isVisible() ) {
      Swal.close();
    }
    this.commonService.setCheckForLogout(true);
  }
}
