import { Component, Input, OnInit } from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-auth-token-validation",
  templateUrl: "./auth-token-validation.component.html",
  styleUrls: ["./auth-token-validation.component.scss"],
})
export class AuthTokenValidationComponent implements OnInit {
  @Input() storeData: any;
  authError: any;
  tokenVerification: any;
  visibleAuth: boolean = false;
  tokenValidated: any;
  visibleAuthSuccess: boolean = false;
  showAuthToken: boolean = false;
  tokenVisibility: boolean = false;
  isSubmitted: boolean = false;
  isTokenValidated: boolean = false;
  selectedStore: any;

  constructor(
    private configService: ConfigurationService,
    public activeModal: NgbActiveModal
  ) {}
  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    if (this.storeData) {
      this.tokenVerification = this.storeData?.authToken;
    }
    if (this.tokenVerification) {
      this.tokenVisibility = true;
      this.isSubmitted = true;
    } else {
      this.tokenVisibility = false;
    }
  }

  tokenValidation() {
    this.isSubmitted = false;
  }

  togglePasswordVisibility() {
    this.showAuthToken = !this.showAuthToken;
  }

  submitFeedback() {
    this.isTokenValidated = true;
    this.selectedStore = this.storeData?.storeId;
    this.configService
      .validateAuthToken(this.selectedStore, this.tokenVerification)
      .subscribe(
        (resp: any) => {
          if (resp.status === true) {
            this.tokenValidated =
              this.tokenVerification == ""
                ? "We deassociated Auth token, you will not receive hyperion alerts"
                : "Token Validated Successfully";
            this.visibleAuth = false;
            this.visibleAuthSuccess = true;
            if (
              this.tokenVerification === null ||
              this.tokenVerification === ""
            ) {
              this.tokenVisibility = false;
              this.isSubmitted = false;
            } else {
              this.tokenVisibility = true;
              this.isSubmitted = true;
            }
            setTimeout(() => {
              this.activeModal.close();
            }, 2000);
          }
        },
        (error) => {
          if (error) {
            this.isSubmitted = false;
            this.visibleAuth = true;
            this.visibleAuthSuccess = false;
            this.authError = error.error.message;
          }
        }
      );
  }
  onBlurMethod(){
    this.visibleAuth = false;
  }
}