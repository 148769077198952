// Core Modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthGuard } from "./_auth/guards/auth.guard";
import { AgGridAngular } from "ag-grid-angular";

// Custom Plugins
import { AuthHttpInterceptor } from "./services/tokenintercept";
import { environment as env } from "../environments/environment.dev";
// Main Modules
import { AppRoutingModule } from "./app-routing.module";
import { ConfigurationModule } from "./components/pages/configuration/configuration.module";
// Main Components
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import { LoginComponent } from "./components/shared/login/login.component";
import { LayoutComponent } from "./components/layout/layout/layout.component";
import { SidenavComponent } from "./components/layout/sidenav/sidenav.component";
import { StoreInsightComponent } from "./components/pages/store-insight/store-insight.component";
import { MyTasksComponent } from "./components/pages/my-tasks/my-tasks.component";
import { StrategyInsightComponent } from "./components/pages/strategy-insight/strategy-insight.component";
import { CaseManagementComponent } from "./components/pages/case-management/case-management.component";
import { OrganisationInfoComponent } from "./components/pages/configuration/organisation/organisation-info.component";
import { AddOrganisationComponent } from "./components/pages/configuration/organisation/add-organisation/add-organisation.component";
import { OrgGridComponent } from "./components/pages/configuration/organisation/org-grid/org-grid.component";
import { ConfigurationComponent } from "./components/pages/configuration/configuration.component";
import { StoresComponent } from "./components/pages/configuration/stores/stores.component";
import { AddStoresComponent } from "./components/pages/configuration/stores/add-stores/add-stores.component";
import { StoreGridComponent } from "./components/pages/configuration/stores/store-grid/store-grid.component";

import { RolesComponent } from "./components/pages/configuration/roles/roles.component";
import { AddRolesComponent } from "./components/pages/configuration/roles/add-roles/add-roles.component";
import { RoleGridComponent } from "./components/pages/configuration/roles/role-grid/role-grid.component";

import { AuthTokenValidationComponent } from "./components/pages/configuration/stores/auth-token-validation/auth-token-validation.component";
import { UsersComponent } from "./components/pages/configuration/users/users.component";
import { AddUserComponent } from "./components/pages/configuration/users/add-user/add-user.component";
import { UserGridComponent } from "./components/pages/configuration/users/user-grid/user-grid.component";
import { StoreSetupComponent } from "./components/pages/configuration/store-setup/store-setup.component";
import { DeviceComponent } from "./components/pages/configuration/store-setup/device/device.component";
import { DeviceGridComponent } from "./components/pages/configuration/store-setup/device/device-grid/device-grid.component";
import { CameraComponent } from "./components/pages/configuration/store-setup/camera/camera.component";
import { AddCameraComponent } from "./components/pages/configuration/store-setup/camera/add-camera/add-camera.component";
import { CameraGridComponent } from "./components/pages/configuration/store-setup/camera/camera-grid/camera-grid.component";

import { UsecaseComponent } from "./components/pages/configuration/store-setup/usecase/usecase.component";
import { AddUsecaseComponent } from "./components/pages/configuration/store-setup/usecase/add-usecase/add-usecase.component";
import { UsecaseGridComponent } from "./components/pages/configuration/store-setup/usecase/usecase-grid/usecase-grid.component";
import { UseralertMappingComponent } from "./components/pages/configuration/store-setup/useralert-mapping/useralert-mapping.component";
import { AddUseralertComponent } from "./components/pages/configuration/store-setup/useralert-mapping/add-useralert/add-useralert.component";
import { UseralertGridComponent } from "./components/pages/configuration/store-setup/useralert-mapping/useralert-grid/useralert-grid.component";

import { AddDeviceComponent } from "./components/pages/configuration/store-setup/device/add-device/add-device.component";
import { UsecaseConfigComponent } from "./components/pages/configuration/store-setup/usecase/usecase-config/usecase-config.component";
import { PeopleCounterComponent } from "./components/pages/configuration/store-setup/usecase/people-counter/people-counter.component";
import { OutOfStockComponent } from "./components/pages/configuration/store-setup/usecase/out-of-stock/out-of-stock.component";
import { PeopleStoreMovementComponent } from "./components/pages/configuration/store-setup/usecase/people-store-movement/people-store-movement.component";
import { ViolenceComponent } from "./components/pages/configuration/store-setup/usecase/violence/violence.component";
import { DwellComponent } from "./components/pages/configuration/store-setup/usecase/dwell/dwell.component";

// Third Party Plugins
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import {
  NgbModule,
  NgbActiveModal,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxEchartsModule } from "ngx-echarts";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgSelectModule } from "@ng-select/ng-select";

// Services
import { LayoutService } from "./services/layout.service";

// Directives
import { NoUppercaseDirective } from "./components/shared/directives/noUppercaseDirective";
import { NumericOnlyDirective } from "./components/shared/directives/numeric-only.directive";
import { DigitCountDirective } from "./components/shared/directives/digitCountDirective";

// Pipes
import { OrderByPipe } from "./components/shared/pipes/order-by.pipe";
import { SecondsToMinutesPipe } from "./components/shared/pipes/seconds-to-minutes.pipe";
import { SecondsToHoursPipe } from "./components/shared/pipes/seconds-to-minutes.pipe";
import { ResolutionTimePipe } from "./components/shared/pipes/seconds-to-minutes.pipe";

// Registers
// import { ECharts } from "echarts/core";
import { registerTheme } from "echarts/core";
import myTheme from "../themes/pitaya.json";

import { CookieService } from "ngx-cookie-service";
import { ProfilePageComponent } from "./components/pages/profile-page/profile-page.component";
import { ForgotPasswordComponent } from "./components/shared/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/shared/reset-password/reset-password.component";

import { TranscriptTabComponent } from "./components/pages/case-management/transcript-tab/transcript-tab.component";
import { InvolvementTabComponent } from "./components/pages/case-management/involvement-tab/involvement-tab.component";
import { AttachmentTabComponent } from "./components/pages/case-management/attachment-tab/attachment-tab.component";
import { InvestigationTabComponent } from "./components/pages/case-management/investigation-tab/investigation-tab.component";
import { LogTabComponent } from "./components/pages/case-management/log-tab/log-tab.component";
import { CaseInfoComponent } from "./components/pages/case-management/case-info/case-info.component";
import { UnsavedChangesGuard } from "./components/pages/case-management/case-info/unsaved-changes.guard";
import { VideoPlayerComponent } from "./components/shared/video-player/video-player.component";
// import { DwellComponent } from "./components/pages/configuration/store-setup/usecase/dwell/dwell.component";
import { HeatMapComponent } from "./components/pages/heat-map/heat-map.component";
import { HeatMapContainerPopupComponent } from "./components/pages/heat-map/heat-map-container-popup/heat-map-container-popup.component";
import { UnauthorizedComponent } from "./components/shared/unauthorized/unauthorized.component";
import { FileSizePipe } from "./components/shared/pipes/file-size.pipe";
import { ProfileResetPasswordComponent } from "./components/pages/profile-page/profile-reset-password/profile-reset-password.component";
import { CreateCaseComponent } from "./components/pages/my-tasks/create-case/create-case.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { CircularNotificationComponent } from "./components/shared/circular-notification/circular-notification.component";
import { TimeZoneDateMomentPipe } from "./components/shared/pipes/time-zone-date-moment.pipe";
import { EmailTemplatePreviewComponent } from "./components/pages/configuration/store-setup/useralert-mapping/email-template-preview/email-template-preview.component";
import { SafePipe } from "./components/shared/pipes/safe.pipe";
import { QuillModule } from "ngx-quill";

registerTheme("my-theme", myTheme);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    SidenavComponent,
    StoreInsightComponent,
    MyTasksComponent,
    StrategyInsightComponent,
    HeaderComponent,
    OrganisationInfoComponent,
    AddOrganisationComponent,
    OrgGridComponent,
    ConfigurationComponent,
    StoresComponent,
    AddStoresComponent,
    StoreGridComponent,
    AuthTokenValidationComponent,
    RolesComponent,
    AddRolesComponent,
    RoleGridComponent,
    UsersComponent,
    AddUserComponent,
    UserGridComponent,
    StoreSetupComponent,
    UseralertMappingComponent,
    AddUseralertComponent,
    EmailTemplatePreviewComponent,
    UseralertGridComponent,
    DeviceComponent,
    AddDeviceComponent,
    DeviceGridComponent,
    CameraComponent,
    AddCameraComponent,
    CameraGridComponent,
    UsecaseComponent,
    AddUsecaseComponent,
    UsecaseGridComponent,
    SecondsToMinutesPipe,
    SecondsToHoursPipe,
    ResolutionTimePipe,
    OrderByPipe,
    FileSizePipe,
    TimeZoneDateMomentPipe,
    SafePipe,
    NoUppercaseDirective,
    NumericOnlyDirective,
    DigitCountDirective,
    UsecaseConfigComponent,
    PeopleCounterComponent,
    OutOfStockComponent,
    DwellComponent,
    PeopleStoreMovementComponent,
    ViolenceComponent,
    ProfilePageComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CaseManagementComponent,
    TranscriptTabComponent,
    InvolvementTabComponent,
    AttachmentTabComponent,
    InvestigationTabComponent,
    LogTabComponent,
    CaseInfoComponent,
    VideoPlayerComponent,
    HeatMapComponent,
    HeatMapContainerPopupComponent,
    UnauthorizedComponent,
    ProfileResetPasswordComponent,
    CreateCaseComponent,
    CircularNotificationComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    AutocompleteLibModule,
    // ConfigurationModule,
    NgSelectModule,
    CarouselModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    QuillModule.forRoot({
      suppressGlobalRegisterWarning: true,
    }),
    AgGridAngular,
    // AuthModule.forRoot({
    //   ...env.authConfig,
    //   httpInterceptor: {
    //     allowedList: [
    //       `${env.apiStoreInsightBaseUrl}store-insight/*`,
    //       `${env.apiStoreInsightBaseUrl}get-solutions/`,
    //     ],
    //   },
    // }),
  ],
  providers: [
    {
      provide: "ALLOWED_URLS", // Use a custom token
      useValue: [
        `${env.apiBaseUrl}store-insight/*`,
        `${env.apiBaseUrl}get-solutions/*`,
      ],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    LayoutService,
    AuthGuard,
    UnsavedChangesGuard,
    NgMultiSelectDropDownModule,
    NgbActiveModal,
    NgbTooltipModule,
    CookieService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
