import { ScenarioService } from 'src/app/services/scenario.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-transcript-tab',
  templateUrl: './transcript-tab.component.html',
  styleUrls: ['./transcript-tab.component.scss']
})
export class TranscriptTabComponent {
  transcriptData: any;
  @Input() selectedTask: any;

  constructor(    private scenarioService: ScenarioService, private appStateService: AppStateService
    ) {}
  ngOnInit(): void {
    this.getTranscriptData();
  }
  // getTranscriptData() {
  //   this.scenarioService
  //     .getTranscriptionData()
  //     .subscribe((data: any) => {
  //       this.transcriptData = data.taskDetails[0].tabs.transcript;
  //     });
  // }
  getTranscriptData() {
    // this.scenarioService.getTranscriptionData()
      // .subscribe((data: any) => {
        this.transcriptData = this.selectedTask.tabs.transcript;
        // let data = this.appStateService.getJsonData();
        // const taskIndex = data.taskDetails.findIndex(
        //   (task: any) => task.taskId === this.selectedTask.taskId
        // );
        // data.taskDetails[taskIndex] = this.selectedTask;
        // this.appStateService.setJsonData(data);
        // this.scenarioService.getTranscriptionData().subscribe((data: any) => {
        // });
      }
      // });

}