import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { VideoService } from "../../../services/video.service";

@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
})
export class VideoPlayerComponent implements OnInit {
  @Input() media: string;
  @Input() taskVideoStartTime: any;
  @Input() taskVmsStartTime: any;
  @Input() autoplay: boolean;
  @Input() controls: boolean;
  @Input() meta: any;
  @Input() displaymeta: boolean;

  @ViewChild("pitayaPlayer") pitayaPlayer: ElementRef<HTMLVideoElement>;
  @ViewChild("pitayaPlayerContainer") pitayaPlayerContainer: ElementRef;
  @ViewChild("progressBar") progressBar: ElementRef;
  @ViewChild("seekControl") seekControl: ElementRef;

  playbackTime = 0;
  progressValue = 1;
  maxProgressValue = 0;
  totalDuration: string = "00:00:00";
  timeElapsed: string = "";
  seekValue = 1;
  playbackRate = 1;
  isPlaying = false;

  taskStartTime: any;
  taskPlayBackTime: any;
  taskTotalDuration: any;
  videoTimestamp = 0;

  constructor(private videoService: VideoService) {}

  ngOnInit(): void {
    // this.playBackTime();
  }

  ngAfterViewInit(): void {
    const video = this.pitayaPlayer.nativeElement;
    video.currentTime = this.playbackTime || 0;
    this.initializeVideo();
    if (this.autoplay) {
      this.playVideo();
    }
  }

  initializeVideo(): void {
    const video = this.pitayaPlayer.nativeElement;
    video.addEventListener("loadedmetadata", () => {
      this.totalDuration =
        this.formatDuration(Math.round(video.duration)) || "00:00:00";
    });
    this.maxProgressValue = Math.round(video.duration) || 0;
    this.getTaskVideoStartTime();
  }

  getTaskVideoStartTime() {
    const taskStartTime = new Date(this.taskVmsStartTime);
    const vmsStartTime = new Date(this.taskVideoStartTime);

    const taskHours = taskStartTime.getHours();
    const taskMinutes = taskStartTime.getMinutes();
    const taskSeconds = taskStartTime.getSeconds();

    const vmsHours = vmsStartTime.getHours();
    const vmsMinutes = vmsStartTime.getMinutes();
    const vmsSeconds = vmsStartTime.getSeconds();

    const taskPlayTime = `${this.padNumber(taskHours)}:${this.padNumber(
      taskMinutes
    )}:${this.padNumber(taskSeconds)}`;
    this.taskPlayBackTime = taskPlayTime.slice(0, -2) + "00";

    this.taskStartTime = `${this.padNumber(vmsHours)}:${this.padNumber(
      vmsMinutes
    )}:${this.padNumber(vmsSeconds)}`;

    const videoDurationLength = this.calculateTotalDuration(
      this.taskPlayBackTime,
      this.taskStartTime
    );
    const videoLessTimeStamp =
      this.getTimestampInSeconds(videoDurationLength) || 0;

    this.videoTimestamp = videoLessTimeStamp - 5;
    // this.playBackTime();
  }

  // playBackTime() {
  //   this.playbackTime = this.videoTimestamp;
  // }

  getTimestampInSeconds(timestamp: string): number {
    const [hours, minutes, seconds] = timestamp?.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  calculateTotalDuration(startTime: string, endTime: string): string {
    const startSeconds = this.getTimestampInSeconds(startTime);
    const endSeconds = this.getTimestampInSeconds(endTime);
    const totalSeconds = endSeconds - startSeconds;
    this.taskTotalDuration = totalSeconds;
    return this.formatDuration(totalSeconds);
  }

  formatDuration(seconds: number): string {
    const date = new Date(seconds * 1000);
    const hours = this.padNumber(date.getUTCHours());
    const minutes = this.padNumber(date.getUTCMinutes());
    const secondsFormatted = this.padNumber(date.getUTCSeconds());

    return `${hours}:${minutes}:${secondsFormatted}`;
  }

  padNumber(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  onVideoCanPlay(event: Event): void {
    const videoElement = this.pitayaPlayer.nativeElement;
    videoElement.muted = true;

    if (this.autoplay) {
      videoElement
        .play()
        .then(() => {
          this.isPlaying = true;
        })
        .catch((error: any) => {
          this.isPlaying = false;
          console.error("Error playing the video:", error);
        });
    }
  }

  playVideo(): void {
    const video = this.pitayaPlayer.nativeElement;
    this.getCurrentTime();
    video.muted = true;

    if (this.isPlaying) {
      video.pause();
    } else {
      video
        .play()
        .then(() => (this.isPlaying = true))
        .catch((error: any) =>
          console.error("Error playing the video:", error)
        );
    }
    this.isPlaying = !this.isPlaying;
  }

  updateProgress(): void {
    try {
      const video = this.pitayaPlayer.nativeElement;
      const currentTime = Math.round(video.currentTime);
      const remainingTime = Math.round(video.duration - video.currentTime) || 0;
      this.timeElapsed = this.formatDuration(currentTime);
      this.totalDuration = this.formatDuration(remainingTime);
      if (!this.controls) {
        this.videoService.updateTimestamp(currentTime);
      }

      if (remainingTime <= 0) {
        this.progressValue = this.maxProgressValue;
        this.seekValue = this.maxProgressValue;
        this.isPlaying = false;
      } else {
        this.progressValue = currentTime;
        this.seekValue = currentTime;
      }
    } catch (error) {
      console.error("Error updating progress:", error);
    }
  }

  seekVideo(event: any): void {
    try {
      const time = event.target.value;
      const video = this.pitayaPlayer.nativeElement;
      video.currentTime = time;

      this.progressValue = time;
      this.seekValue = time;
      if (this.maxProgressValue !== Math.round(video.duration)) {
        this.maxProgressValue = Math.round(video.duration);
      }
    } catch (error) {
      console.error("Error seeking video:", error);
    }
  }

  setPlaybackRate(event: any): void {
    try {
      this.playbackRate = event.target.value;
      const video = this.pitayaPlayer.nativeElement;
      video.playbackRate = this.playbackRate;
    } catch (error) {
      console.error("Error setting playback rate:", error);
    }
  }

  toggleFullscreen(): void {
    const container = this.pitayaPlayerContainer.nativeElement;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      container.requestFullscreen();
    }
  }

  getCurrentTime(): number {
    const video = this.pitayaPlayer.nativeElement;
    video.currentTime = this.videoTimestamp;
    const currentTime = video.currentTime;
    return currentTime;
  }

  rewindTenSeconds(): void {
    try {
      const video = this.pitayaPlayer.nativeElement;
      video.currentTime -= 10;
    } catch (error) {
      console.error("Error rewinding video:", error);
    }
  }

  forwardTenSeconds(): void {
    try {
      const video = this.pitayaPlayer.nativeElement;
      video.currentTime += 10;
    } catch (error) {
      console.error("Error forwarding video:", error);
    }
  }
}
