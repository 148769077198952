import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { CommonService } from 'src/app/services/common.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AgCellActionsComponent } from '../../organisation/org-grid/ag-cell-actions/ag-cell-actions.component';

@Component({
  selector: 'app-role-grid',
  templateUrl: './role-grid.component.html',
  styleUrls: ['./role-grid.component.scss']
})
export class RoleGridComponent {
  @Input('rowData') rolesData: any[];
  @Input('selectedOrgName') selectedOrgName: string;
  @Output() gridActionEvent: EventEmitter<any> = new EventEmitter<any>();
  private gridApi : any;
  constructor(private commonService: CommonService, private configuartionService: ConfigurationService) 
  { 
    this.commonService.roleGridActionEvent.subscribe((data) => {
      this.gridActionEvent.emit(data);
    });    
  }

  pagination = true;
  paginationPageSize = 10;
  suppressPaginationPanel = true;
  paginationPageSizeSelector: boolean = false;
  pageSize: number = this.configuartionService.itemsPerPage;
  rowHeight: number = 50;
  public defaultColDef: ColDef = {
    sortable: true,
    flex: 1,
    // resizable: true,
  };    
  colDefs: ColDef[] = [   
    {
      headerName: 'Org Name',
      field: 'tenantName',
      minWidth: 140,
    },   
    {
      headerName: 'Role Name',
      field: 'role_name',
      minWidth: 140,
    },    
    {
      headerName: 'Actions',
      cellClass: 'ag-cell-actions',
      cellRenderer: AgCellActionsComponent,
      cellRendererParams: {
        gridIdentifier: 'role',
        isDelete: true,
        roleDelete: true,
      },
      minWidth: 110,
      sortable: false,
      resizable: false,
    },
  ];

  ngOnInit(): void {   
  }

  onGridReady(params: { api: any }) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedOrgName'] && !changes['selectedOrgName'].firstChange) {
      if (this.gridApi) {
        this.gridApi.refreshCells();
      }
    }
    if (changes['rolesData'] && !changes['rolesData'].firstChange) {
      if (this.gridApi) {
        this.gridApi.paginationGoToFirstPage();
       
      }
    }
  }
}
