import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { StoreInsightService } from "src/app/services/store-insight.service";
import { LayoutService } from "src/app/services/layout.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { StoresComponent } from "./stores/stores.component";
import { CommonService } from "src/app/services/common.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import jwt_decode from "jwt-decode";
import { AuthorizationService } from "src/app/services/authorization.service";

@Component({
  selector: "app-configuration",
  templateUrl: "./configuration.component.html",
  styleUrls: ["./configuration.component.scss"],
})
export class ConfigurationComponent implements OnInit {
  @ViewChild(StoresComponent) storesComponent: StoresComponent;
  selectedTab: string;
  loggedUser: any;
  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  pageSize: number = this.configurationService.pageSize;
  itemsPerPage: number = this.configurationService.itemsPerPage;
  currentPage: number = 1;
  orgData: any[];
  filteredOrgs: any[] = [];
  storeData: any[] = [];
  filteredStores: any[] = [];
  storeSetupTab: boolean = false;
  storeTab: boolean = false;
  rolesTab: boolean = false;
  userTab: boolean = false;
  defaultSelectedTab: boolean = true;
  storeSetupTabName: string;
  isDataRefreshed: boolean = true;
  private ngUnsubscribe = new Subject<void>();
  decodedToken: any;

  constructor(
    private layoutService: LayoutService,
    private configurationService: ConfigurationService,
    @Inject(DOCUMENT) public document: Document,
    public storeInsightService: StoreInsightService,
    private commonService: CommonService,
    private router: Router,
    private authorizationService: AuthorizationService
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.commonService.selectedOrganization$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(org => {
      if (org) {
        this.getStoreData(org.tenantId)
        this.isDataRefreshed = false;
      }
    });
  }

  ngOnInit(): void {
    this.configByRole();
    this.getOrgData('Active');
    if(this.isDataRefreshed){
      this.getStoreData('');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  configByRole() {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    if (this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.orgs, ['list', '*'])) {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      const pageTitle = "Organizations";
      this.layoutService.setPageTitle(pageTitle);
    } else if (this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['list', '*'])) {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
      const pageTitle = "Stores";
      this.layoutService.setPageTitle(pageTitle);
    } else {
      this.router.navigateByUrl('/unauthorized');
    }
  }

  getOrgData(selectedStatus: any) {
    this.configurationService
      .getOrgData(this.pageSize, this.currentPage, selectedStatus)
      .subscribe((resp: any) => {
        this.orgData = (resp?.data?.length > 0) ? resp.data : [];
        this.orgData.sort((a, b) => {
          const tenantNameA = a.tenantName.toLowerCase();
          const tenantNameB = b.tenantName.toLowerCase();
          if (tenantNameA < tenantNameB) {
            return -1;
          } else if (tenantNameA > tenantNameB) {
            return 1;
          } else {
            return 0;
          }
        });
        this.storesComponent.refreshStoresData();
      });
  }

  getStoreData(tenantId: any) {
    let defaultStatus: string = 'Active';  
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((data: any) => {
        this.storeData = data.stores;
        this.storeData = data.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.filteredStores = this.storeData.filter(
          (storeData: any) => storeData.status === true
        );
      });
  }

  getSelectedTab(tabName: string) {
    this.storeSetupTabName = tabName
  }

  selectedTabClick(tabName: any) {
    this.layoutService.setPageTitle(tabName);
    this.filteredOrgs = this.orgData;
    this.filteredOrgs = this.filteredOrgs.filter(
      (orgData: any) => orgData?.status === true
    );
    if (tabName === 'Stores') {
      this.storeTab = true;
      this.commonService.emitStoreTabClickedEvent(this.storeTab);
    } else if (tabName === 'Roles') {
      this.rolesTab = true;
      this.commonService.emitRolesTabClickedEvent(this.rolesTab);
    } else if (tabName === 'Users') {
      this.userTab = true;
      this.commonService.emitUserTabClickedEvent(this.userTab);
    } else if (tabName === 'Store Setup') {
      this.storeSetupTab = true;
      if (this.defaultSelectedTab) {
        this.defaultSelectedTab = false;
        this.commonService.emitDeviceTabClickedEvent(this.storeSetupTab);
      }
      else {
        if (this.storeSetupTabName === 'Device') {
          this.commonService.emitDeviceTabClickedEvent(this.storeSetupTab);
        } else if (this.storeSetupTabName === 'Usecase') {
          this.commonService.emitUsecaseTabClickedEvent(this.storeSetupTab);
        } else if (this.storeSetupTabName === 'Camera') {
          this.commonService.emitCameraTabClickedEvent(this.storeSetupTab);
        } else if (this.storeSetupTabName === 'Alerts') {
          this.commonService.emitAlertTabClickedEvent(this.storeSetupTab);
        }
      }
    }
  }
}
