import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-add-camera",
  templateUrl: "./add-camera.component.html",
  styleUrls: ["./add-camera.component.scss"],
})
export class AddCameraComponent implements OnInit {
  @Input() filteredOrgs: any[] = [];
  @Input() cameraData: any;
  @ViewChild("autoStoreDrop") autoStoreDrop: any;
  @ViewChild("autoOrgDrop") autoOrgDrop: any;

  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  isLoading: boolean = false;
  isEditing: boolean = false;
  processing: boolean = false;
  addCameraForm: FormGroup;
  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: any;
  selectedTenantName: string;
  selectedStoreId: any;
  selectedStoreName: string;
  selectedCamUid: any;
  // selectedcamMacAdd: string;

  isSubmitted: boolean = false;
  isVisible: boolean = false;
  isExist: boolean = false;
  checkCamera: any;
  pageSize: number = this.configurationService.pageSize;
  currentPage: number = 1;
  keyword = "name";
  placeholderOrg = "Select the Organization";
  placeholderStore = "Select the Store";

  loggedUser: any;
  orgData: any[] = [];
  storeData: any[] = [];
  filteredStores: any[] = [];
  errorMessage: any[] = [];
  isChecked: boolean = true;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private configurationService: ConfigurationService,
    private alertMessagesService: AlertMessagesService,
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.addCameraForm = this.fb.group({
      tenantId: ["", [Validators.required]],
      storeId: ["", [Validators.required]],
      camName: ["", [Validators.required]],
      camLocation: ["", [Validators.required]],
      rtsp: ["", [Validators.required]],
      description: ["", [Validators.required]],
      // camMacAdd: ["", [Validators.required]],
      status: [false],
      isUpdate: [false],
    });
  }
  get fc() {
    return this.addCameraForm.controls;
  }

  ngOnInit() {
    this.assignDefaultValues();
    this.configByRole();
  }

  configByRole() {
    if (this.loggedUser == "csm") {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      this.filterOrgData();
    }
    if (this.loggedUser == "org-admin") {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
      this.selectedTenantId = this.defaultTenantId;
      this.getStoreData(this.selectedTenantId);
    }
  }

  toggleOrgPanel(e: any): void {
    e.stopPropagation();
    this.autoOrgDrop.open();
  }
  toggleStorePanel(e: any): void {
    e.stopPropagation();
    this.autoStoreDrop.open();
  }

  assignDefaultValues() {
    if (this.cameraData) {
      this.isEditing = !this.isEditing;
      this.addCameraForm.patchValue({
        camName: this.cameraData?.camName,
        email: this.cameraData?.email,
        camLocation: this.cameraData?.camLocation,
        rtsp: this.cameraData?.rtsp,      
        isUpdate: true,
      });
      this.selectedTenantId = this.cameraData?.tenantID;
      this.selectedTenantName = this.cameraData?.tenantName;
      this.selectedStoreId = this.cameraData?.storeID;
      this.selectedStoreName = this.cameraData?.storeName;
      this.selectedCamUid = this.cameraData?.camUID;
      this.isChecked = this.cameraData?.status;
      this.checkCamera = this.cameraData?.camName;
    }
  }

  filterOrgData() {
    this.filteredOrgs.map((org: any) => (org.name = `${org.tenantName}`));
    this.filteredOrgs = this.filteredOrgs.filter(
      (orgData: any) => orgData.status === true
    );
  }

  changeOrganization(event: any) {
    this.selectedTenantId = event.tenantId;
    this.checkCamera = "";
    this.onInputChange();
    if (this.selectedTenantId) {
      this.getStoreData(this.selectedTenantId);
    } else {
      this.getStoreData(this.defaultTenantId);
    }
  }

  getStoreData(tenantId: any) {
    let defaultStatus : string = 'Active';
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((data: any) => {
        this.storeData = data.stores;
        this.storeData = data.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.storeData.map((store: any) => (store.name = `${store.storeName}`));
        this.filteredStores = this.storeData.filter(
          (storeData: any) => storeData.status === true
        );
      });
  }
  changeStore(event: any) {
    this.selectedStoreId = event.storeId;
    this.checkCamera = "";
    this.onInputChange();
  }
  clearStores() {
    this.storeData = [];
    this.selectedStoreName = "";
  }

  onInputChange() {
    this.isExist = false;
  }

  cameraNameCheckUnique() {
    this.configurationService
      .getCameraNameUnigue(this.checkCamera,this.selectedTenantId,this.selectedStoreId)
      .subscribe((resp: any) => {
        if (resp === true) {
          this.isExist = true;
        } else {
          this.isExist = false;
        }
      });
  }

  onSubmit() {
    this.isSubmitted = true;
    this.isLoading = true;
    this.addCameraForm.patchValue({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId,
    });

    if (this.cameraData) {
      this.addCameraForm.patchValue({
        tenantId: this.cameraData?.tenantID,
        storeId: this.cameraData?.storeID,
      });
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 5000);

    if (this.cameraData?.camUID) {
      this.processing = true;
      this.configurationService
        .updateCamera(this.selectedCamUid, this.addCameraForm.value)
        .subscribe(
          (resp: any) => {
            this.processing = false;
            if (resp.status === true) {
              this.activeModal.close();
              this.alertMessagesService.alertWithSuccess(resp.message);
              this.isLoading = false;
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else {
      if (!this.isExist) {
        this.configurationService
          .createCamera(this.addCameraForm.value)
          .subscribe(
            (resp: any) => {
              this.processing = false;
              if (resp.status === true) {
                this.activeModal.close();
                this.alertMessagesService.alertWithSuccess(resp.message);
                this.isLoading = false;
              }
            },
            (error) => {
              this.isLoading = false;
            }
          );
      }
    }
  }

}
