<div class="p-3">
  <div class="card">
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col-12 org-data">
          <div class="tab org-tabs d-flex justify-content-between" role="tabpanel">
            <div class="col-12">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item justify-content-between flex-grow-1" *ngIf="isCSAdmin">
                  <a class="nav-link" id="new-tab" data-bs-toggle="tab" href="#organizations" role="tab"
                    aria-controls="organizations" aria-selected="true" (click)="selectedTabClick('Organizations')"
                    [ngClass]="{'active': isCSAdmin}">
                    Organizations
                  </a>
                </li>
                <li class="nav-item justify-content-between flex-grow-1">
                  <a class="nav-link" id="blocked-tab" data-bs-toggle="tab" href="#stores" role="tab"
                    aria-controls="blocked" aria-selected="false" (click)="selectedTabClick('Stores')"
                    [ngClass]="{'active': !isCSAdmin && isOrgAdmin}">
                    Stores
                  </a>
                </li>
                <li class="nav-item justify-content-between flex-grow-1">
                  <a class="nav-link" id="blocked-tab" data-bs-toggle="tab" href="#roles" role="tab"
                    aria-controls="blocked" aria-selected="false" (click)="selectedTabClick('Roles')">
                    Roles
                  </a>
                </li>
                <li class="nav-item justify-content-between flex-grow-1">
                  <a class="nav-link" id="completed-tab" data-bs-toggle="tab" href="#users" role="tab"
                    aria-controls="completed" aria-selected="false" (click)="selectedTabClick('Users')">
                    Users
                  </a>
                </li>
                
                <li class="nav-item justify-content-between flex-grow-1">
                  <a class="nav-link" id="store-tab" data-bs-toggle="tab" href="#storesetup" role="tab"
                    aria-controls="completed" aria-selected="false" (click)="selectedTabClick('Store Setup')">
                    Store Setup
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" [ngClass]="{'show active scrollable-list-container org-table-data': isCSAdmin}"
              id="organizations" role="tabpanel" aria-labelledby="tab1-tab" *ngIf="isCSAdmin">
              <app-organisation-info [filteredOrgs]="orgData" (updatedOrgs)="getOrgData($event)"></app-organisation-info>
            </div>
            <div class="tab-pane fade" id="stores" role="tabpanel" aria-labelledby="stores"
              [ngClass]="{'show active scrollable-list-container org-table-data': !isCSAdmin && isOrgAdmin}">
              <app-stores [filteredOrgs]="filteredOrgs"></app-stores>
            </div>
            <div class="tab-pane fade" id="roles" role="tabpanel" aria-labelledby="roles">
              <app-roles [filteredOrgs]="filteredOrgs"></app-roles>
            </div>
            <div class="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users">
              <app-users [filteredOrgs]="filteredOrgs" [filteredStores]="filteredStores"></app-users>
            </div>

            <div class="tab-pane fade" id="storesetup" role="tabpanel" aria-labelledby="store-tab">
              <app-store-setup (selectedTabEvent)="getSelectedTab($event)" [filteredOrgs]="filteredOrgs" [filteredStores]="filteredStores"></app-store-setup>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>