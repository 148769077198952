<div class="modal-header">
  <h4 class="modal-title">
    {{ alertData?.alertUcUID ? "Edit Alert" : "Add Alert" }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form [formGroup]="addAlertForm" (ngSubmit)="onSubmit()" class="alert-form">
    <div class="alert-form-group mb-4" *ngIf="isCSAdmin">
      <div class="auto-dropdown">
        <label for="orgName">Organization Name: <sup>*</sup></label>
        <ng-autocomplete [data]="filteredOrgs" [searchKeyword]="keyword" (selected)="changeOrganization($event)"
          [placeholder]="placeholderOrg" [itemTemplate]="itemTemplateStaticOrg" class="form-control form-autocomplete"
          [(ngModel)]="selectedTenantName" [ngClass]="{ 'is-invalid': isSubmitted && !selectedTenantName}"
          [notFoundTemplate]="notFoundTemplateOrg" #autoOrgDrop [ngModelOptions]="{ standalone: true }"
          [disabled]="isEditing" (inputCleared)="clearStores()">
        </ng-autocomplete>

        <input type="text" id="orgName" name="orgName" formControlName="tenantId" class="form-control"
          placeholder="Organization Name" required="true" [(ngModel)]="selectedTenantName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" />

        <span class="dropdown-down" (click)="toggleOrgPanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticOrg let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateOrg let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>

        <div *ngIf="addAlertForm.get('tenantId')?.hasError('required')" class="invalid-feedback">
          Organization is required
        </div>

      </div>
    </div>

    <div class="alert-form-group mb-4">
      <div class="auto-dropdown">
        <label for="storeName">Store Name: <sup>*</sup></label>
        <ng-autocomplete [data]="filteredStores | orderBy : 'storeName'" [searchKeyword]="keyword"
          (selected)="changeStore($event)" [placeholder]="placeholderstore" [itemTemplate]="itemTemplateStaticStore"
          class="form-control form-autocomplete" [(ngModel)]="selectedStoreName"
          [notFoundTemplate]="notFoundTemplateStore" #autoStoreDrop [ngModelOptions]="{ standalone: true }"
          [disabled]="isEditing" [ngClass]="{ 'is-invalid': isSubmitted && !selectedStoreName }" (inputCleared)="clearStores()">
        </ng-autocomplete>

        <input type="text" id="storeId" name="storeId" formControlName="storeId" class="form-control"
          placeholder="Store Name" required="true" [(ngModel)]="selectedStoreName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" />

        <span class="dropdown-down" (click)="toggleStorePanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticStore let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateStore let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addAlertForm.get('storeId')?.hasError('required')" class="invalid-feedback">
          Store is required
        </div>

      </div>
    </div>

    <div class="alert-form-group mb-4">
      <div class="auto-dropdown">
        <label for="usecasename">Usecase Name: <sup>*</sup></label>
        <ng-autocomplete [data]="useCaseList | orderBy : 'useCaseName'" [searchKeyword]="keyword"
          (selected)="changeUseCase($event)" [placeholder]="placeholderUseCase"
          [itemTemplate]="itemTemplateStaticUseCase" class="form-control form-autocomplete"
          [(ngModel)]="selectedUseCaseName" [notFoundTemplate]="notFoundTemplateUseCase"
          [ngClass]="{ 'is-invalid': isSubmitted && !selectedUseCaseName }" [ngModelOptions]="{ standalone: true }" (inputCleared)="clearUsecases()"
          #autoUseCase>
        </ng-autocomplete>

        <input type="text" id="useCaseName" name="useCaseName" formControlName="useCaseId" class="form-control"
          placeholder="Usecase Name" required="true" [(ngModel)]="selectedUseCaseName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" />

        <span class="dropdown-down" (click)="toggleUseCasePanel($event)"></span>
        <ng-template #itemTemplateStaticUseCase let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateUseCase let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>

        <div *ngIf="addAlertForm.get('useCaseId')?.hasError('required')" class="invalid-feedback">
          Usecase Name is required
        </div>
      </div>
    </div>

    <div class="alert-form-group custom-icon mb-4">
      <label for="priority_level">Priority Level: <sup>*</sup></label>
      <select class="form-control" id="priority_level" [(ngModel)]="selectedPriorityLevel" (change)="changePriorityLevel()" formControlName="order"
        [ngClass]="{ 'is-invalid': isSubmitted && fc['order'].errors }">
        <option disabled [attr.selected]="isPriorityCleared ? true : null" value="">Select Priority Level</option>
        <option *ngFor="let code of priorityLevels" [value]=" code.id">
          {{ code.value }}
        </option>
      </select>
      <div *ngIf="addAlertForm.get('order')?.hasError('required')" class="invalid-feedback">
        Priority Level is required
      </div>

    </div>

    <div class="alert-form-group mb-4">
      <div class="auto-dropdown">
        <label for="modeofalert">Mode Of Alert: <sup>*</sup></label>
        <ng-autocomplete [data]="modeOfAlerts | orderBy : 'alertMode'" [searchKeyword]="keyword"
          (selected)="changeModeOfAlert($event)" [placeholder]="placeholderModeOfAlert"
          [itemTemplate]="itemTemplateStaticModeofalert" class="form-control form-autocomplete"
          [(ngModel)]="selectedModeOfAlert" [notFoundTemplate]="notFoundTemplateModeOfAlert"
          [ngClass]="{ 'is-invalid': isSubmitted && !selectedModeOfAlert }" [ngModelOptions]="{ standalone: true }"
          #autoModeOfAlert (inputCleared)="clearAlertModes()">
        </ng-autocomplete>

        <span class="dropdown-down" (click)="toggleModeOfAlertPanel($event)"></span>
        <ng-template #itemTemplateStaticModeofalert let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateModeOfAlert let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      <p class="isExistvalid"  [hidden]="!isExist">Alert Already Exist</p>
        <div *ngIf="addAlertForm.get('modeOfAlert')?.hasError('required') || selectedModeOfAlert == ''" class="invalid-feedback">
          ModeOfAlert is required
        </div>
      </div>
    </div>

    <div class="alert-form-group custom-icon mb-4" *ngIf="visibleRecipient">
      <div class="auto-dropdown">
        <label for="recipient">Recipient: <sup>*</sup></label>
        <ng-select [multiple]="true" class="form-control" [(ngModel)]="selectedRecipientNames"
          [ngModelOptions]="{ standalone: true }" formControlName="receiver" (change)="changeRecipient($event)"
          [ngClass]="{ 'is-invalid': isSubmitted && fc['receiver'].errors }" [placeholder]="placeholderRecipient" #recipientDrop>
          <ng-option *ngFor="let user of usersData" [selected]="true" [value]="user.userId">
            {{ user.userName }}
          </ng-option>
        </ng-select>
        <span class="dropdown-down" (click)="toggleRecipientPanel($event)" *ngIf="!isEditing"></span>
        <div *ngIf="addAlertForm.get('receiver')?.hasError('required')" class="invalid-feedback">
          Recipient is required
        </div>
        <p class="invalid-feedback" *ngIf="usersData.length === 0">No Recipients Found</p>
      </div>
    </div>

    <div class="alert-form-group  mb-4">
      <label for="Threshold">Threshold:</label>
      <input type="text" id="Threshold" formControlName="threshold" [(ngModel)]="selectedThreshold" class="form-control"
        placeholder="Threshold"
        [ngClass]="{'is-invalid': isSubmitted && fc['threshold'].errors}">
      <div *ngIf="isSubmitted && fc['threshold'].errors" class="invalid-feedback">
      </div>
    </div>
 
    <div class="alert-form-group  mb-4">
      <div *ngIf="isModeOfAlertEmail" [ngClass]="{ 'is-invalid': showMessageError }">
        <div class="msg-nonedit" *ngIf="!isMessageEditable" [ngClass]="{'read-only': !isMessageEditable}">
          <div class="alert-form-group  mb-4">
            <label for="Subject">Subject: <sup>*</sup></label>
            <input type="text" id="Subject" formControlName="emailSubject" [(ngModel)]="selectedEmailSubject" class="form-control"
              placeholder="Subject" [ngClass]="{ 'is-invalid': isSubmitted && ( isModeOfAlertEmail == true && !selectedEmailSubject ) }">
          </div>

          <div class="alert-form-group">
            <label>Message: <sup>*</sup></label>
            <quill-editor class="content-editor" formControlName="emailMessage" [placeholder]="'Type a message...'" 
              [disabled]="!isMessageEditable" [(ngModel)]="selectedEmailMessage"
              [ngClass]="{ 'is-invalid': isSubmitted && ( isModeOfAlertEmail == true && !selectedEmailMessage ) }"></quill-editor>
            <span class="edit-icon" title="Edit" (click)="messageFieldEdit()"></span>
          </div>
        </div>

        <div class="msg-edit-container d-flex justify-content-between" *ngIf="isMessageEditable">
          <div class="msg-edit">
            <div class="alert-form-group  mb-4">
              <label for="Subject">Subject: <sup>*</sup></label>
              <input type="text" id="Subject" formControlName="emailSubject" [(ngModel)]="selectedEmailSubject"  
                #subjectEditor (click)="editorSubjectClicked()" placeholder="Subject" class="form-control"
                [ngClass]="{ 'is-invalid': isSubmitted && ( isModeOfAlertEmail == true && !selectedEmailSubject ) }">
            </div>

            <div class="alert-form-group">
              <label>Message: <sup>*</sup></label>
              <quill-editor class="content-editor" formControlName="emailMessage" [placeholder]="'Type a message...'" 
                (click)="quilleditorClicked()" [(ngModel)]="selectedEmailMessage"
                [ngClass]="{ 'is-invalid': isSubmitted && ( isModeOfAlertEmail == true && !selectedEmailMessage ) }"></quill-editor>
              <span class="undo-icon" title="Revert" (click)="replacePreviousMsg()"></span>
              <span class="save-icon" title="Save" (click)="messageFieldEdit()"></span>
            </div>
          </div>
          <div class="params-list">
            <ul class="list-group">
              <li class="list-group-item d-flex" 
                *ngFor="let item of dynamicParamsList" (click)="onParamSelect($event, item)">
                <div class="param-label">
                  {{item.key}}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div *ngIf="isModeOfAlertSms" [ngClass]="{ 'is-invalid': showMessageError }">
        <label for="Message">Message: <sup>*</sup></label>
        <div class="msg-nonedit msg-sms" *ngIf="!isMessageEditable" [ngClass]="{'read-only': !isMessageEditable}">
          <textarea class="form-control" rows="8" maxlength="160" id="Message" [placeholder]="'Type a message...'" formControlName="smsMessage" 
            [(ngModel)]="selectedSmsMessage" [disabled]="!isMessageEditable" 
            [ngClass]="{ 'is-invalid': isSubmitted && ( isModeOfAlertSms == true && !selectedSmsMessage ) }"></textarea>
          <span class="edit-icon" title="Edit" (click)="messageFieldEdit()"></span>
        </div>

        <div class="msg-edit-container msg-sms-container d-flex justify-content-between" *ngIf="isMessageEditable">
          <div class="msg-edit msg-sms">
            <textarea class="form-control" rows="8" maxlength="160" id="Message" [placeholder]="'Type a message...'" #textareaEditor
              formControlName="smsMessage" [(ngModel)]="selectedSmsMessage" (click)="editorTextAreaClicked()"
              [ngClass]="{ 'is-invalid': isSubmitted && ( isModeOfAlertSms == true && !selectedSmsMessage ) }"></textarea>
            <span class="undo-icon" title="Revert" (click)="replacePreviousMsg()"></span>
            <span class="save-icon" title="Save" (click)="messageFieldEdit()"></span>
          </div>
          <div class="params-list">
            <ul class="list-group">
              <ng-container *ngFor="let item of dynamicParamsList">
                <li class="list-group-item d-flex" *ngIf="item.key != 'Store Location'"
                  (click)="onParamSelect($event, item)">
                  <div class="param-label">
                    {{item.key}}
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-item-centers mt-2">
        <div class="error-msg" [ngClass]="{'show-error': isSubmitted && (showMessageError 
          || (isModeOfAlertEmail == true && (!selectedEmailSubject || !selectedEmailMessage)) 
          || ( isModeOfAlertSms == true && !selectedSmsMessage ) ) }">
          <p *ngIf="showMessageError">{{paramsError}}</p>
          <p *ngIf="(isModeOfAlertEmail == true && (!selectedEmailSubject || !selectedEmailMessage))">Subject and Message is required</p>
          <p *ngIf="( isModeOfAlertSms == true && !selectedSmsMessage )">Message is required</p>
        </div>
        <p class="preview-email" (click)="openEmailPreviewPopup()" *ngIf="isModeOfAlertEmail && !isLoading">Email Preview</p>
      </div>
    </div>

    <div class="custom-icon mb-3">
      Status:
      <label class="toggle-button">
        <div class="shadow-input" *ngIf="isEditing === false"></div>
        <input [disabled]="isEditing === false" class="form-check-input" type="checkbox" formControlName="status"
          [(ngModel)]="isChecked">
        <span class="slider round"></span>
      </label>
    </div>

    <button type="submit" class="btn btn-add" [disabled]="isLoading || isExist">Submit</button>
  </form>
  <div class="spinner-outer" *ngIf="processing">
    <div class="loading-spinner"></div>
  </div>
</div>