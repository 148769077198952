<form [formGroup]="peopleUseCaseForm" (ngSubmit)="onSubmit()" class="usecase-config-form">
  <div class="mb-4">
    <h6 class="mb-3">ROI Dimensions</h6>
    <div class="d-flex justify-content-between mb-4">
      <div class="dimension-input usecase-form-group">
        <label for="xMin">Delta Min X: <sup>*</sup></label>
        <input type="text" id="xMin" name="xMin" required="true" [(ngModel)]="selectedProductROI.xMin"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Delta Min X" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.xMin}">
        <div *ngIf="isSubmitted && !selectedProductROI.xMin" class="invalid-feedback">
          Delta Min X value Required
        </div>
      </div>
      <div class="dimension-input usecase-form-group">
        <label for="xMax">Delta Max X: <sup>*</sup></label>
        <input type="text" id="xMax" name="xMax" required="true" [(ngModel)]="selectedProductROI.xMax"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Delta Max X" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.xMax}">
        <div *ngIf="isSubmitted && !selectedProductROI.xMax" class="invalid-feedback">
          Delta Max X value Required
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-4">
      <div class="dimension-input usecase-form-group">
        <label for="yMin">Delta Min Y: <sup>*</sup></label>
        <input type="text" id="yMin" name="yMin" required="true" [(ngModel)]="selectedProductROI.yMin"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Delta Min Y" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.yMin}">
        <div *ngIf="isSubmitted && !selectedProductROI.yMin" class="invalid-feedback">
          Delta Min Y value Required
        </div>
      </div>
      <div class="dimension-input usecase-form-group">
        <label for="yMax">Delta Max Y: <sup>*</sup></label>
        <input type="text" id="yMax" name="yMax" required="true" [(ngModel)]="selectedProductROI.yMax"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Delta Max Y" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.yMax}">
        <div *ngIf="isSubmitted && !selectedProductROI.yMax" class="invalid-feedback">
          Delta Max Y value Required
        </div>
      </div>
    </div>
  </div>
  <div class="usecase-form-group me-2 custom-icon mb-4">
    <div class="auto-dropdown">
      <label for="entryAxisId"> Entry Axis: </label>
      <span class="dropdown-down" (click)="changeAxis($event)"></span>
      <select class="form-control" id="AxisValue" formControlName="AxisValue" (change)="changeAxis($event)"
        [(ngModel)]="selectedChangeAxisValue" [ngClass]="{'is-invalid': isSubmitted && fc['AxisValue'].errors}">
        <option *ngFor="let type of AxisValue" [value]="type">{{ type }}</option>
      </select>
    </div>
  </div>
  <div class="mb-4" *ngIf="isAxisX==true">
    <div class="people-dimension-input usecase-form-group">
      <label for="threshouldXId">Threshold X: <sup>*</sup></label>
      <input type="number" id="threshouldXId" name="threshouldXId" formControlName="thresholdX" class="form-control"
        placeholder="Threshold X Value" [ngClass]="{'is-invalid': isSubmitted && fc['thresholdX'].errors}">
      <div *ngIf="peopleUseCaseForm.get('thresholdX')?.hasError('required')" class="invalid-feedback">
        Threshold X is Required
      </div>
    </div>
  </div>
  <div class="mb-4" *ngIf="isAxisX==false">
    <div class="d-flex justify-content-between mb-4">
      <div class="people-dimension-input usecase-form-group">
        <label for="threshouldYId">Threshold Y: <sup>*</sup></label>
        <input type="number" id="threshouldYId" name="threshouldYId" formControlName="thresholdY" class="form-control"
          placeholder="Threshold Y Value" [ngClass]="{'is-invalid': isSubmitted && fc['thresholdY'].errors}">
        <div *ngIf="peopleUseCaseForm.get('thresholdY')?.hasError('required')" class="invalid-feedback">
          Threshold Y is Required
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between mb-4">
    <div class="people-dimension-input usecase-form-group">
      <label for="counterAlert">Counter Alert Threshold: <sup>*</sup></label>
      <input type="number" id="counterAlert" name="counterAlert" formControlName="counterAlertThreshold"
        class="form-control" placeholder="Enter Counter Alert Threshold Value"
        [ngClass]="{'is-invalid': isSubmitted && fc['counterAlertThreshold'].errors}">
      <div *ngIf="peopleUseCaseForm.get('counterAlertThreshold')?.hasError('required')" class="invalid-feedback">
        Counter Alert Threshold is Required
      </div>
    </div>
  </div>
  <div class="user-form-group custom-icon mb-3">
    Status:
    <label class="toggle-button">
      <div class="shadow-input"></div>
      <input class="form-check-input" type="checkbox" formControlName="status" [(ngModel)]="isChecked">
      <span class="slider round"></span>
    </label>
  </div>
  <button type="submit" class="btn btn-add" [disabled]="isLoading">Submit</button>
</form>