import { Component, OnInit } from "@angular/core";
import { LayoutService } from "src/app/services/layout.service";
import { DataService } from "../../../services/search.service";
import { Router, NavigationEnd } from "@angular/router";
import { SessionTimeoutService } from "src/app/services/session-timeout.service";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  pageTitle: any;
  searchTerm: string = "";
  searchBarHide: boolean = false;
  searchVisible: boolean = true;

  filteredData: any[] = [];
  constructor(
    private layoutService: LayoutService,
    private searchService: DataService,
    private router: Router,
    private sessionTimeoutService: SessionTimeoutService
  ) {}

  ngOnInit(): void {
    this.getPageTitle();
    this.getSearchBarHide();
    this.clearSearchField();
    this.sessionTimeoutService.initInactivityTimer();
  }

  getPageTitle() {
    this.layoutService.pageTitle$.subscribe((pageTitle) => {
      this.pageTitle = pageTitle;
      if(pageTitle === "Store Insights" || pageTitle === "My Profile"){
        this.searchVisible = false;
      }
      else{
        this.searchVisible = true; 
      }
    });
  }

  getSearchBarHide() {
    this.layoutService.hideSearch$.subscribe((hideSearch) => {
      this.searchBarHide = hideSearch;
    });
  }

  handleInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    if (inputValue === "") {
      this.globalSearch();
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.globalSearch();
    }
  }

  globalSearch() {
    this.searchService.setSearchValue(this.searchTerm);
    var pageTasks = "/my-tasks";
    var pageStore = "/store-insights";
    var pageStrategic = "/strategy-insights";
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url !== pageTasks ||
          event.url !== pageStore ||
          event.url !== pageStrategic
        ) {
          this.searchTerm = "";
          this.searchService.setSearchValue(this.searchTerm);
        } else {
          this.searchService.setSearchValue(this.searchTerm);
        }
      }
    });
  }

  clearSearchField() {
    this.searchService.clearSearchField.subscribe(() => {
      this.searchTerm = "";
      this.globalSearch();
    });
  }
}
