import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Scenario } from "../models/scenario.model";
import { HttpClient } from "@angular/common/http";
import { AppStateService } from "./app-state.service";

@Injectable({
  providedIn: "root",
})
export class ScenarioService {
  private selectedScenarioSubject = new BehaviorSubject<string | null>(null);
  selectedScenario$: Observable<string | null> =
    this.selectedScenarioSubject.asObservable();

  jsonData: any; // Store the loaded JSON data here
  defaultJsonFilePath: any = `../../assets/scenarios/default.json`;

  constructor(
    private http: HttpClient,
    private appStateService: AppStateService
  ) {}

  getScenarios(): Observable<any> {
    return this.http.get("../../assets/scenarios/scenario.json");
  }

  setSelectedScenario(scenarioName: string): void {
    // Load JSON data based on the selected scenarioName
    this.appStateService.jsonData$.subscribe((jsonData: any) => {
      if (jsonData && jsonData?.taskDetails) {
        this.jsonData = jsonData;
      } else {
        this.loadDefaultJsonData(this.defaultJsonFilePath);
        // this.loadDefaultJsonData(this.defaultJsonFilePath);
      }
    });
    const jsonFilePath = `../../assets/scenarios/${scenarioName.toLowerCase()}.json`;
    this.loadJsonData(jsonFilePath);
    this.selectedScenarioSubject.next(scenarioName);
  }

  private loadDefaultJsonData(jsonFilePath: string): void {
    this.http.get(jsonFilePath).subscribe((jsonData: any) => {

      this.jsonData = jsonData;
      // this.appStateService.setJsonData(this.jsonData);
      this.appStateService.setJsonData(this.jsonData);

    });
  }

  private loadJsonData(jsonFilePath: string): void {
    // Load JSON data using HttpClient or any method you prefer
    this.http.get(jsonFilePath).subscribe((jsonData: any) => {

      // this.jsonData = { ...this.jsonData, ...jsonData };
      const previousJson = this.jsonData;
      this.jsonData = jsonData;
      this.jsonData.taskDetails = [
        ...previousJson.taskDetails,
        ...jsonData.taskDetails,
      ];
      // Create a Set to keep track of unique taskids
      const uniqueTaskIds = new Set();
      this.jsonData.taskDetails = this.jsonData.taskDetails.filter(
        (task: any) => {
          if (!uniqueTaskIds.has(task.taskId)) {
            uniqueTaskIds.add(task.taskId);
            return true;
          }
          return false;
        }
      );
      // const mergedTaskDetails = jsonData.taskDetails.map((task1:any) => {
      //   const matchingTask = previousJson.taskDetails.find((task2:any) => task2.taskId === task1.taskId);
      //   if (matchingTask) {
      //     return matchingTask;
      //   }
      //   return task1;
      // });

      // for (const task of jsonData.taskDetails) {
      //   if (!taskIds.has(task.taskid)) {
      //     mergedTaskDetails.push(task);
      //     taskIds.add(task.taskid);
      //   }
      // }
      // this.jsonData.taskDetails = mergedTaskDetails;
      // this.appStateService.setJsonData(this.jsonData);
      this.appStateService.setJsonData(this.jsonData);
    });
  }

  getSelectedScenario(): string | null {
    return this.selectedScenarioSubject.value;
  }
  // getTranscriptionData():any {
  //   return this.http.get('../../assets/scenarios/scenario1.json');
  //  }
}
