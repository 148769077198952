<div class="">
  <div class="sidebar"><app-sidenav></app-sidenav></div>
  <div class="header"><app-header></app-header></div>
  <div class="main-content">
    <router-outlet></router-outlet>
    <!-- <footer class="footer mt-5">
      <div class="container-layout">
        <div class="copyright">
          <div class="copy float-start">
            <p>©2023 Centific. All Rights Reserved.</p>
          </div>
          <div class="float-end">
            <ul class="privacy">
              <li>
                <span>Privacy Policy</span>
              </li>
              <li>
                <span>Terms &amp; Conditions</span>
              </li>
              <li>
                <span>Cookies Policy</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer> -->
  </div>
</div>