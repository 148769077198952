import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddUseralertComponent } from "./add-useralert/add-useralert.component";
import { CommonService } from "src/app/services/common.service";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import Swal from "sweetalert2";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: "app-useralert-mapping",
  templateUrl: "./useralert-mapping.component.html",
  styleUrls: ["./useralert-mapping.component.scss"],
})
export class UseralertMappingComponent implements OnInit {
  @Input() filteredOrgs: any[] = [];
  @Input() filteredStores: any[] = [];

  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  closeResult = "";
  alertData: any;
  totalItems: number = 0;
  pageSize: number = this.configurationService.pageSize;
  itemsPerPage: number = this.configurationService.itemsPerPage;
  currentPage: number = 1;
  paginationId = "alert_items";
  useCaseAlert: any;

  selectedTenantName: string;
  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: string = "";
  selectedStoreId: string = "";
  selectedAlertId: any;
  selectedalertTypeId: any;
  orgData: any[] = [];
  storeData: any[] = [];
  visibleOrg: boolean = true;
  visibleStore: boolean = false;
  noDataFound: boolean = false;
  selectedTenantIdDropdown: boolean = false;
  selectedStoreIdDropdown: boolean = false;
  loggedUser: any;
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';
  selectedStatus: string = "Active";
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private configurationService: ConfigurationService,
    private modalService: NgbModal,
    private alertMessagesService: AlertMessagesService,
    private commonService: CommonService
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.commonService.selectedOrganization$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(org => {
      if (org) {
        this.selectedTenantIdDropdown = true;
        this.visibleOrg = false;
        this.selectedTenantId = org.tenantId;
        this.selectedStoreId = org.storeId;
      }
    });
    this.commonService.selectedStore$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(store => {
      if (store) {
        this.selectedStoreIdDropdown = true;
        this.selectedStoreId = store.storeId;        
      }
    });

    this.commonService.alertTabClicked.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isClicked) => {
      if(isClicked){
        this.getUsecaseAlertData(this.selectedStoreId, this.selectedTenantId);
      }
    });
  }

  ngOnInit(): void {
    this.configByRole();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  configByRole() {
    if (this.loggedUser === "csm") {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      this.visibleOrg = true;
    }
    if (this.loggedUser === "org-admin") {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
      this.visibleOrg = false;
      this.selectedTenantId = this.defaultTenantId;
      this.getStoreData(this.selectedTenantId);
    }
  }

  changeOrg(event: any) {
    this.visibleOrg = false;
    this.visibleStore = true;
    this.noDataFound = false;
    this.currentPage = 1;
    this.selectedTenantId = event.target.value;
    this.selectedStoreId = "";
    this.getUsecaseAlertData(this.selectedStoreId, this.selectedTenantId);
    this.commonService.setSelectedOrganization({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId
    });
  }

  getStoreData(tenantId: any) {
    let defaultStatus : string = 'Active';
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((data: any) => {
        this.storeData = data.stores;
        this.storeData = data.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.filteredStores = this.storeData.filter(
          (storeData: any) => storeData.status === true
        );
      });
  }

  changeStore(event: any) {
    this.visibleOrg = false;
    this.visibleStore = false;
    this.currentPage = 1;
    this.selectedStoreId = event.target.value;
    this.commonService.setSelectedStore({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId
    });
    this.getUsecaseAlertData(this.selectedStoreId, this.selectedTenantId);
  }

  storePageChanged(event: any) {
    this.currentPage = event;
    window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
  }

  onStatusChange(){
  this.configurationService
      .getUsecaseAlertData(this.selectedStoreId, this.selectedTenantId, this.selectedStatus)
      .subscribe((data: any) => {
        this.useCaseAlert = data.data;
        this.totalItems = this.useCaseAlert.length;
        if (this.totalItems === 0) {
          this.noDataFound = true;
        }
      });
  }

  getUsecaseAlertData(storeId: any, tenantId: any) {
    this.configurationService
      .getUsecaseAlertData(storeId, tenantId, this.selectedStatus)
      .subscribe((data: any) => {
        this.useCaseAlert = data.data;
        this.totalItems = this.useCaseAlert.length;
        if (this.totalItems === 0) {
          this.noDataFound = true;
        }
      });
  }
  pageChanged(event: any) {
    this.currentPage = event;
    window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
  }

  handleActionEvent(actionDetails: any) {
    if (actionDetails.action === 'edit') {
      this.updateAlertData(actionDetails.data);
    } else if (actionDetails.action === 'delete') {
      this.deleteAlert(actionDetails.data.alertUcUID, actionDetails.data.uniqueId)
    }
  }

  addAlertData() {
    const modelRef = this.modalService.open(AddUseralertComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
      size: 'lg'
    });
    modelRef.componentInstance.filteredOrgs = this.filteredOrgs;
    modelRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getUsecaseAlertData(this.selectedStoreId, this.selectedTenantId);

      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  deleteAlert(alertUcUID: any, uniqueId: number) {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",                  
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({        
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      title: "Are you sure?",
      text: "You are really want to delete the Alert.",
      showCancelButton: true,                
      confirmButtonText: "Yes", 
      cancelButtonText: "No",  
    }).then((result) => {
      if (result.isConfirmed) {
        this.configurationService
          .deleteUserAlert(alertUcUID, uniqueId)
          .subscribe(
            (resp: any) => {
              if (resp.status === true) {
                this.alertMessagesService.alertWithSuccess(resp.message);
                this.getUsecaseAlertData(this.selectedStoreId, this.selectedTenantId);
              } else {
                this.alertMessagesService.alertWithError("Failed to delete Alert");
              }
            },
            (error) => {
              this.alertMessagesService.alertWithError("Failed to delete Alert");
            }
          );
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  updateAlertData(alert: any) {
    const modalRef = this.modalService.open(AddUseralertComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
      size: 'lg'
    });
    modalRef.componentInstance.alertData = alert;
    modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getUsecaseAlertData(this.selectedStoreId, this.selectedTenantId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
}
