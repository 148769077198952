import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LayoutService } from "src/app/services/layout.service";
import { CaseManagementService } from "src/app/services/case-management.service";
import { caseInfoFormData } from "./case-info-interface";
import { CanComponentDeactivate } from "./unsaved-changes.guard";
import Swal from "sweetalert2";
import { Observable } from "rxjs";
import { VideoService } from "src/app/services/video.service";
import { saveAs } from "file-saver";
import { SessionTimeoutService } from "src/app/services/session-timeout.service";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import jwt_decode from "jwt-decode";
import { AuthorizationService } from "src/app/services/authorization.service";

@Component({
  selector: "app-case-info",
  templateUrl: "./case-info.component.html",
  styleUrls: ["./case-info.component.scss"],
})
export class CaseInfoComponent
  implements CanComponentDeactivate, OnInit, OnDestroy {
  taskInfo: any = {};
  initialTaskInfo: any = {};
  selectedCaseId: any;

  impactList: any = ["LOW", "MEDIUM", "HIGH"];
  impact: {};
  statusList: any[] = [];
  severityList: any[] = ["Severity1", "Severity2", "Severity3"];
  caseInfoPayload: caseInfoFormData = {
    status: "",
    severity: "",
    rank: "",
    amount: null,
    note: "",
    newIncidentList: [],
  };
  disableSaveBtn: boolean = true;
  downloading: boolean = false;
  impactAmount: any = null;
  impactNote: string = "";
  impactRank: string = "";
  caseStatus: string = "";
  caseSeverity: string = "";
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';
  isdays: number;
  today: Date;
  closedate: Date;
  private autoRefereshPrepareReport: any;
  loggedUser: any;
  decodedToken: any;
  duplicates_count: number ;
  incidentNumber: number = 1;
  index: number = 0;
  // Video player related code
  taskVideoUrl: any;
  taskCreatedTime: any;
  taskVmsPlayTime: any;
  selectedMonitorId: string;
  // Video player related code ends

  constructor(
    public activeModal: NgbActiveModal,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private caseManagementService: CaseManagementService,
    private videoService: VideoService,
    private sessionTimeoutService: SessionTimeoutService,
    private alertMessagesService: AlertMessagesService,
    private router: Router,
    private authorizationService: AuthorizationService
  ) {
    this.loggedUser = localStorage.getItem("userRole");
  }

  ngOnInit() {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.cases, ['detail', '*']) ) {
      const pageTitle = " ";
      this.layoutService.setPageTitle(pageTitle);
      this.sessionTimeoutService.allowCanDeactivateModalClose = false;
      this.getCaseStatusList();
      this.getTaskInfo();
    } else {
      this.router.navigateByUrl('/unauthorized');
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // Check if there are unsaved changes
    return new Promise((resolve, reject) => {
      if (
        !this.disableSaveBtn &&
        !this.sessionTimeoutService.allowCanDeactivateModalClose
      ) {
        let confirmMessage =
          "You have made some changes to the case details. Do you want to save the changes?";
        let successMessage = "Your changes are saved successfully.";
        if (this.caseInfoPayload.status != "") {
          if (this.caseInfoPayload.status == "closed") {
            confirmMessage =
              'Are you sure you want to update the incident status to "Closed"? A closed case cannot be reopened and will be available as view only.';
            successMessage = "Case is closed successfully.";
          } else if (this.caseInfoPayload.status == "invalidate") {
            confirmMessage =
              "Please confirm this as an Invalid Incident. Once confirmed this incident will no more be a case.";
          } else if (this.caseInfoPayload.status == "not-a-case") {
            confirmMessage = "Please confirm that the incident is not a case.";
          } else {
            confirmMessage =
              "You have made some changes to the case details. Do you want to save the changes?";
          }
        }

        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            popup: 'alert-warning-container',
            htmlContainer: 'alert-warning-content',
            confirmButton: "btn btn-submit",
            cancelButton: "btn btn-cancel",
            icon: "alert-warning-icon",
            title: "alert-warning-title",
            closeButton: "alert-warning-close",
          },
          buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
          iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
          text: confirmMessage,
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(async (result: any) => {
          if (result.isConfirmed) {
            // here need to update the case details and then need to leave the page
            if (!this.disableSaveBtn) {
              await this.updateCaseInfoService();
            }
            const response = await this.alertMessagesService.alertWithSuccessConfirmAction(successMessage);
            if (response == true) {
              resolve(true);
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // here no need to update the case details and need to leave the page
          resolve(true);
          } else {
            // here no need to leave the page
            reject(false);
          }
        });
      } else {
        // here if app gets auto logout after 20 mins of idle time then we need to save the form details if there are any unsaved changes
        if (!this.disableSaveBtn && this.sessionTimeoutService.allowCanDeactivateModalClose) {
          this.updateCaseInfoService();
        }
        // if there are no changes done in form we need to leave the page if user clicks on other tab
        resolve(true);
      }
    });
  }

  getCaseStatusList() {
    this.caseManagementService.getCaseStatusList().subscribe((resp: any) => {
      this.statusList = resp?.data?.length > 0 ? resp.data : [];
      this.statusList.sort((a, b) => (a["position"] > b["position"] ? 1 : -1));
    });
  }

  ngOnDestroy() {
    clearInterval(this.autoRefereshPrepareReport);
    this.autoRefereshPrepareReport = null;
  }

  days_between(date1: any, date2: any) {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const differenceMs = Math.abs(date1 - date2);
    return Math.round(differenceMs / ONE_DAY);
  }
  duplicateCaseData(incidentNumber: number, inc: number) {
    this.incidentNumber = incidentNumber + inc;
    this.index = this.incidentNumber - 2;    
    if (this.incidentNumber == 1 ) {
      this.taskInfo = structuredClone(this.initialTaskInfo);
    } else {
      this.taskInfo.source_data = structuredClone(this.taskInfo.duplicate_cases[this.index].source_data);      
    }     
  }

  checkReportStatus() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      text: "Are you sure you want to download the report?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        if (this.taskInfo.report_uptodate == true) {
          this.downloading = true;
          this.downloadReportZip();
        } else {
          this.downloading = true;
          this.prepareReportZip();
        }
      }
    });
  }

  prepareReportZip() {
    this.caseManagementService.downloadCaseInfo(this.selectedCaseId).subscribe({
      next: (resp: any) => {
        if (resp?.data?.status == "SUCCESS") {
          this.taskInfo.report_uptodate = true;
          this.taskInfo.report = resp?.data;
          clearInterval(this.autoRefereshPrepareReport);
          this.autoRefereshPrepareReport = null;
          //here need to call download report method as report preparation is completed
          this.downloadReportZip();
        } else if (resp?.data?.status == "FAILED") {
          this.downloading = false;
          this.alertMessagesService.showToastMessage(
            `Some error occured while downloading report. Please retry downloading report.`,
            '<img src="../../../../assets/icons/confirmationnew.svg" width="34" height="34" alt="" />'
          );
        } else {
          if (!this.autoRefereshPrepareReport) {
            this.autoRefereshPrepareReport = setInterval(() => {
              this.prepareReportZip();
            }, 2000);
          }
        }
      },
      error: (error) => {
        this.downloading = false;
        this.alertMessagesService.showToastMessage(
          `Some error occured while downloading report. Please retry downloading report.`,
          '<img src="../../../../assets/icons/confirmationnew.svg" width="34" height="34" alt="" />'
        );
      },
    });
  }

  downloadReportZip() {
    if (
      this.taskInfo?.report?.status == "SUCCESS" &&
      this.taskInfo?.report?.url
    ) {
      setTimeout(async () => {
        /// Find the last index of '/' to get the file name
        // Find the index of the '?' character
        const index = this.taskInfo?.report?.url?.indexOf('?');
        // Extract the substring up to the '?' character
        let fileName = this.taskInfo?.report?.url?.substring(0, index);
        const parts = fileName?.split('/');
        /// Extract the text after the last '/'
        fileName = (parts?.length) ? parts[parts.length - 1] : 'download.zip';
        let response = await fetch(this.taskInfo?.report?.url);
        if (response?.status == 200) {
          let blob = await response.blob();
          await saveAs(blob, fileName);
          this.downloading = false;
          this.alertMessagesService.showToastMessage(
            `Report has been downloaded successfully.`,
            '<img src="../../../../assets/icons/success-icon.svg" width="34" height="34" alt="" />'
          );
        } else {
          this.downloading = false;
          this.taskInfo.report_uptodate = false;
          this.alertMessagesService.showToastMessage(
            `Some error occured while downloading report. Please retry downloading report.`,
            '<img src="../../../../assets/icons/confirmationnew.svg" width="34" height="34" alt="" />'
          );
        }
      }, 500);
    } else {
      setTimeout(() => {
        this.downloading = false;
        this.taskInfo.report_uptodate = false;
        this.alertMessagesService.showToastMessage(
          `Some error occured while downloading report. Please retry downloading report.`,
          '<img src="../../../../assets/icons/confirmationnew.svg" width="34" height="34" alt="" />'
        );
      }, 500);
    }
  }

  getCaseDetailsById() {
    this.taskInfo = {};
    this.caseManagementService
      .getCaseDetailsById(this.selectedCaseId)
      .subscribe((resp: any) => {
        this.taskInfo = (resp?.data?.id)?resp.data:{};
        this.initialTaskInfo = structuredClone(this.taskInfo) ;
        this.impactAmount =
          this.taskInfo?.impact?.amount || this.taskInfo?.impact?.amount == 0
            ? this.taskInfo?.impact?.amount
            : null;
        this.impactNote = this.taskInfo?.impact?.note ?? "";
        this.impactRank = this.taskInfo?.impact?.rank ?? "";
        this.caseStatus = this.taskInfo?.status?.slug_name ?? "";
        this.caseSeverity = this.taskInfo?.severity ?? "";
        this.taskInfo.primaryIncident = [];
        if (this.taskInfo?.source_data?.primary_incident) {
          this.taskInfo.primaryIncident.push(
            this.taskInfo?.source_data?.primary_incident
          );
        }

        this.closedate = new Date("2024-03-13T01:49:49-05:00");
        // this.closedated = new Date(this.taskInfo.purge_date)
        this.today = new Date();
        this.isdays = this.days_between(this.closedate, this.today);

        // Video player related code
        // Here need to change some code after we get actual details from api
        this.taskInfo.useCaseName = this.taskInfo?.source_data?.usecase_name;
        //this.taskInfo.createdOn = "2024-01-02T18:07:43Z";
        this.taskInfo.createdOn = new Date(
          (this.taskInfo?.source_data?.case_detected_at).replace(/-/g, "/")
        )
          .toISOString()
          .replace(/\.\d{3}Z$/, "Z");
        const selectedUseCaseName = this.taskInfo?.useCaseName;

        this.taskVideoUrl = "";
        if (selectedUseCaseName && selectedUseCaseName !== "PeopleMovement") {
          this.videoTimeFormatter(selectedUseCaseName, this.taskInfo);
        }
        // Video player related code ends
      });
  }

  getTaskInfo() {
    this.route.paramMap.subscribe((params) => {
      this.selectedCaseId = params.get("taskid");
      this.getCaseDetailsById();
    });
  }

  selectedTabClick(tabName: any) { }

  // This checkForCaseStatusCriteria() will check if any form value is changed
  // then status should not be "new" it should be "investigation" so it will auto update.
  checkForCaseStatusCriteria() {
    if (
      this.caseInfoPayload.severity != "" ||
      this.caseInfoPayload.rank != "" ||
      this.caseInfoPayload.amount != null ||
      this.caseInfoPayload.note != ""
    ) {
      const currentStatusObj = this.statusList.find(
        (obj) => obj["slug_name"] === this.caseStatus
      );
      if (currentStatusObj?.position == 1) {
        // position 1 is for "new" status
        // here find "investigation" status from status list and update ngmodel and payload object with status as "investigation"
        const statusObj = this.statusList.find((obj) => obj["position"] == 2);
        setTimeout(() => {
          this.caseStatus = statusObj?.slug_name;
          this.caseInfoPayload.status = statusObj?.slug_name;
          this.disableSaveBtn = false;
        });
      } else {
        const impactAmount =
          this.taskInfo?.impact?.amount || this.taskInfo?.impact?.amount == 0
            ? this.taskInfo?.impact?.amount
            : null;
        const impactNote = this.taskInfo?.impact?.note
          ? this.taskInfo?.impact?.note
          : "";
        const impactRank = this.taskInfo?.impact?.rank
          ? this.taskInfo?.impact?.rank
          : "";
        const caseSeverity = this.taskInfo?.severity ?? "";

        if (
          this.caseSeverity == caseSeverity &&
          this.impactRank == impactRank &&
          this.impactAmount == impactAmount &&
          this.impactNote == impactNote
        ) {
          this.caseStatus = this.taskInfo?.status?.slug_name ?? "";
          this.disableSaveBtn = true;
          this.caseInfoPayload = {
            status: "",
            severity: "",
            rank: "",
            amount: null,
            note: "",
            newIncidentList: [],
          };
        }
      }
    }
  }

  selectStatus(event: any) {
    this.caseInfoPayload.status = event.target.value;
    if (this.caseStatus == this.taskInfo?.status?.slug_name) {
      this.disableSaveBtn = true;
    } else {
      this.disableSaveBtn = false;
    }
    this.checkForCaseStatusCriteria();
  }

  selectSeverity(event: any) {
    this.caseInfoPayload.severity = event.target.value;
    if (this.caseSeverity == this.taskInfo?.severity) {
      this.disableSaveBtn = true;
    } else {
      this.disableSaveBtn = false;
    }
    this.checkForCaseStatusCriteria();
  }

  selectRank(event: any) {
    this.caseInfoPayload.rank = event.target.value;
    this.caseInfoPayload.amount = this.impactAmount;
    this.caseInfoPayload.note = this.impactNote;
    const impactRank = this.taskInfo?.impact?.rank
      ? this.taskInfo?.impact?.rank
      : "";
    if (this.impactRank == impactRank) {
      this.disableSaveBtn = true;
    } else {
      this.disableSaveBtn = false;
    }
    this.checkForCaseStatusCriteria();
  }

  selectAmount(event: any) {
    this.caseInfoPayload.amount = event.target.value;
    this.caseInfoPayload.rank = this.impactRank;
    this.caseInfoPayload.note = this.impactNote;
    const impactAmount =
      this.taskInfo?.impact?.amount || this.taskInfo?.impact?.amount == 0
        ? this.taskInfo?.impact?.amount
        : null;
    if (this.impactAmount == impactAmount) {
      this.disableSaveBtn = true;
    } else {
      this.disableSaveBtn = false;
    }
    this.checkForCaseStatusCriteria();
  }

  selectNotes(event: any) {
    this.caseInfoPayload.note = event.target.value;
    this.caseInfoPayload.amount = this.impactAmount ?? "";
    this.caseInfoPayload.rank = this.impactRank;
    const impactNote = this.taskInfo?.impact?.note
      ? this.taskInfo?.impact?.note
      : "";
    if (this.impactNote == impactNote) {
      this.disableSaveBtn = true;
    } else {
      this.disableSaveBtn = false;
    }
    this.checkForCaseStatusCriteria();
  }

  updateCaseInfoData() {
    let confirmMessage =
      "You have made some changes to the case details. Do you want to save the changes?";
    let successMessage = "Your changes are saved successfully.";
    if (this.caseInfoPayload.status != "") {
      if (this.caseInfoPayload.status == "closed") {
        confirmMessage =
          'Are you sure you want to update the incident status to "Closed"? A closed case cannot be reopened and will be available as view only.';
        successMessage = "Case is closed successfully.";
      } else if (this.caseInfoPayload.status == "invalidate") {
        confirmMessage =
          "Please confirm this as an Invalid Incident. Once confirmed this incident will no more be a case.";
      } else if (this.caseInfoPayload.status == "not-a-case") {
        confirmMessage = "Please confirm that the incident is not a case.";
      } else {
        confirmMessage =
          "You have made some changes to the case details. Do you want to save the changes?";
      }
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",
        closeButton: "alert-warning-close",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      text: confirmMessage,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.updateCaseInfoService(successMessage);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.caseStatus = this.taskInfo?.status?.slug_name ?? "";
        this.caseSeverity = this.taskInfo?.severity ?? "";
        this.impactAmount =
          this.taskInfo?.impact?.amount || this.taskInfo?.impact?.amount == 0
            ? this.taskInfo?.impact?.amount
            : null;
        this.impactNote = this.taskInfo?.impact?.note ?? "";
        this.impactRank = this.taskInfo?.impact?.rank ?? "";
        this.disableSaveBtn = true;
        this.caseInfoPayload = {
          status: "",
          severity: "",
          rank: "",
          amount: null,
          note: "",
          newIncidentList: [],
        };
      }
    });
  }

  updateCaseInfoService(successMessage?: string) {
    return new Promise((resolve, reject) => {
      var payload: any = {};
      if (this.caseInfoPayload.status != "") {
        payload.status = this.caseInfoPayload.status;
      }
      if (this.caseInfoPayload.severity != "") {
        payload.severity = this.caseInfoPayload.severity;
      }
      if (
        this.caseInfoPayload.rank != "" ||
        this.caseInfoPayload.amount != null ||
        this.caseInfoPayload.note != ""
      ) {
        payload.impact = {};
        payload.impact.rank = this.caseInfoPayload?.rank
          ? this.caseInfoPayload?.rank
          : null;
        payload.impact.amount =
          this.caseInfoPayload?.amount || this.caseInfoPayload?.amount === 0
            ? this.caseInfoPayload?.amount
            : null;
        payload.impact.note = this.caseInfoPayload?.note ?? "";
      }
      let incidentArr = this.caseInfoPayload.newIncidentList.filter(
        (item: any) => item.name
      );
      if (incidentArr.length > 0) {
        payload.incident = incidentArr.map((item: any) => {
          return item.name;
        });
      }
      if (Object.keys(payload).length === 0) {
        return;
      }
      this.caseManagementService
        .updateCaseInfoDetails(payload, this.selectedCaseId)
        .subscribe({
          next: (resp: any) => {
            this.taskInfo.status = resp?.data?.status;
            this.taskInfo.severity = resp?.data?.severity;
            this.taskInfo.impact = resp?.data?.impact;
            this.taskInfo.incident = resp?.data?.incident;

            this.caseStatus = this.taskInfo?.status?.slug_name ?? "";
            this.caseSeverity = this.taskInfo?.severity ?? "";
            this.impactAmount =
              this.taskInfo?.impact?.amount ||
                this.taskInfo?.impact?.amount == 0
                ? this.taskInfo?.impact?.amount
                : null;
            this.impactNote = this.taskInfo?.impact?.note ?? "";
            this.impactRank = this.taskInfo?.impact?.rank ?? "";

            this.disableSaveBtn = true;
            this.caseInfoPayload = {
              status: "",
              severity: "",
              rank: "",
              amount: null,
              note: "",
              newIncidentList: [],
            };
            if (successMessage) {
              this.alertMessagesService.alertWithSuccess(successMessage);
            }
            this.taskInfo.report_uptodate = false;
            resolve(true);
          },
          error: (error: any) => {
            resolve(true);
          },
        });
    });
  }

  addNewIncident() {
    this.caseInfoPayload.newIncidentList.push({
      name: "",
    });
  }

  investigationSubmitEvent(investigationSubmitResp: any) {
    this.taskInfo.status = investigationSubmitResp.data.status;
    this.caseStatus = this.taskInfo?.status?.slug_name ?? "";
    this.taskInfo.report_uptodate = false;
  }

  fileUploadEvent(fileUploadResp: any) {
    this.taskInfo.status = fileUploadResp.data.status;
    this.caseStatus = this.taskInfo?.status?.slug_name ?? "";
    this.taskInfo.report_uptodate = false;
  }

  fileDeleteEvent(fileDeleteResp: any) {
    this.taskInfo.report_uptodate = false;
  }

  // Video player related code
  videoTimeFormatter(selectedUseCaseName: any, item: any) {
    // Format start time
    const dateTimeFrame = item?.createdOn;
    const vmsStartDateObject = new Date(dateTimeFrame);
    if (vmsStartDateObject.getMinutes() % 2 !== 0) {
      vmsStartDateObject.setSeconds(vmsStartDateObject.getSeconds() - 60);
    }
    const vmsStartTimeMinute = vmsStartDateObject
      .toISOString()
      .replace(".000Z", "");
    const vmsStartTime = vmsStartTimeMinute.slice(0, -2) + "00";

    // Format end time
    const vmsEndDateObject = new Date(dateTimeFrame);
    if (vmsEndDateObject.getMinutes() % 2 !== 0) {
      vmsEndDateObject.setSeconds(vmsEndDateObject.getSeconds() - 60);
    }
    const vmsEndtime = new Date(vmsEndDateObject.getTime() + 120 * 1000);
    const vmsEndTimeMinute = vmsEndtime.toISOString().replace(".000Z", "");
    const vmsEndTime = vmsEndTimeMinute.slice(0, -2) + "00";

    this.taskVmsPlayTime = vmsStartTimeMinute;
    this.taskCreatedTime = item?.createdOn.slice(0, -1);

    this.getVideoInfo(selectedUseCaseName, vmsStartTime, vmsEndTime);
  }

  getVideoInfo(
    selectedUseCaseName: string,
    vmsStartTime: any,
    vmsEndDate: any
  ) {
    if (selectedUseCaseName == "Dwell" || selectedUseCaseName == "Violence") {
      this.selectedMonitorId = "6aiXN3oEcL";
    } else if (
      selectedUseCaseName == "OOS" ||
      selectedUseCaseName == "Shrinkage"
    ) {
      this.selectedMonitorId = "avtkFUsstP";
    } else if (selectedUseCaseName == "checkout_shrink") {
      this.selectedMonitorId = "Cnl40hoiDr";
    }

    if (this.selectedMonitorId !== undefined) {
      this.videoService
        .getTaskVideoData(this.selectedMonitorId, vmsStartTime)
        .subscribe((data: any) => {
          const videoItems = data.videos;
          const videoItem = videoItems[videoItems.length - 1];
          if (videoItems.length !== 0) {
            const baseVideoUrl = this.videoService.videoApiUrl;
            const videoUrl = videoItem?.actionUrl;
            this.taskVideoUrl = baseVideoUrl + videoUrl;
          }
        });
    }
  }
  // Video player related code ends
}
