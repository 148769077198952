import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { Subscription } from "rxjs";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: "app-people-store-movement",
  templateUrl: "./people-store-movement.component.html",
  styleUrls: ["./people-store-movement.component.scss"],
})
export class PeopleStoreMovementComponent implements OnInit {
  @Input() configData: any;
  @Output() updatedUseCase: EventEmitter<void> = new EventEmitter<void>();
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  peoplestoremovementUseCaseForm: FormGroup;

  selectedUseCaseInfo: any;
  selectedUCConfig: any;
  useCaseConfigId: any;
  useCaseIdentifier: any;
  isChecked: boolean = true;

  constructor(
    private fb: FormBuilder,
    private configService: ConfigurationService,
    private alertMessagesService: AlertMessagesService,
    public activeModal: NgbActiveModal
  ) {
    this.peoplestoremovementUseCaseForm = this.fb.group({
      tenant: ["", Validators.required],
      store: ["", Validators.required],
      camera: ["", Validators.required],
      payLoadDeliveryInterval: [null, [Validators.required]],
      fps: [null, [Validators.required]],
      cameraId: ["", Validators.required],
      deviceId: ["", Validators.required],
      usecaseId: ["", Validators.required],
      status: [true],
      inputSource: [""],
    });
    // this.fetchConfigData();
  }
  get fc() {
    return this.peoplestoremovementUseCaseForm.controls;
  }

  ngOnInit(): void {
    this.selectedUseCaseInfo = this.configData.useCase;
    if (this.selectedUseCaseInfo) {
      this.getUseCaseConfigData();
    }
  }
  getUseCaseConfigData() {
    this.useCaseConfigId = this.selectedUseCaseInfo.ucConfigUID;
    this.useCaseIdentifier = this.selectedUseCaseInfo.usecaseIdentifier;
    this.configService
      .getUseCaseConfigData(this.useCaseConfigId, this.useCaseIdentifier)
      .subscribe((resp: any) => {
        this.selectedUCConfig = resp.data;
        this.fetchUseCaseConfigData();
      });
  }

  fetchUseCaseConfigData() {
    
    if (this.selectedUCConfig) {
      this.peoplestoremovementUseCaseForm.patchValue({
        tenant: this.selectedUCConfig.tenant,
        store: this.selectedUCConfig.store,
        usecaseId: this.selectedUCConfig.usecaseId,
        cameraId: this.selectedUCConfig.cameraId,
        deviceId: this.selectedUCConfig.deviceId,
        inputSource: this.selectedUCConfig.inputSource,
        camera: this.selectedUCConfig.camera,
        payLoadDeliveryInterval: this.selectedUCConfig.settings.PAY_LOAD_DELIVERY_INTERVAL.value,
        fps: this.selectedUCConfig.settings.FPS.value,
      });
      this.isChecked = this.configData?.useCase?.status;
    } else {
      this.peoplestoremovementUseCaseForm.patchValue({
        tenant: this.configData.tenantId,
        store: this.configData.storeId,
        usecaseId: this.configData.useCaseUId,
        cameraId: this.configData.camUId,
        deviceId: this.configData.deviceId,
        inputSource: this.configData.rtsp,
        camera: this.configData.camMacAdd,
        status: this.isChecked,
      });
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (
      this.peoplestoremovementUseCaseForm.get("payLoadDeliveryInterval")
        ?.value == undefined ||
      this.peoplestoremovementUseCaseForm.get("payLoadDeliveryInterval")
        ?.value <= 0 ||
      this.peoplestoremovementUseCaseForm.get("fps")?.value == undefined ||
      this.peoplestoremovementUseCaseForm.get("fps")?.value <= 0
    )
      return;

    if (this.selectedUCConfig) {
      this.peoplestoremovementUseCaseForm.patchValue({
        tenant: this.configData?.useCase?.tenantId,
        store: this.configData?.useCase?.storeId,
        usecaseId: this.configData?.useCase?.usecaseId,
        cameraId: this.selectedUCConfig.cameraId,
        deviceId: this.selectedUCConfig.deviceId,
        inputSource: this.selectedUCConfig.inputSource,
        camera: this.selectedUCConfig.camera,
        status: this.isChecked,
      });
    } else {
      this.peoplestoremovementUseCaseForm.patchValue({
        tenant: this.configData?.tenantId,
        store: this.configData?.storeId,
        usecaseId: this.configData?.useCaseUId,
        cameraId: this.configData?.camUId,
        deviceId: this.configData?.deviceId,
        inputSource: this.configData.rtsp,
        camera: this.configData.camMacAdd,
        status: this.isChecked,
      });
      this.useCaseIdentifier = this.configData?.useCaseIdentifier;
    }

    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 5000);
    const ucConfigUID = this.configData?.useCase?.ucConfigUID;
    const useCaseType = this.configData?.useCase?.usecaseIdentifier;
    this.configService.getFileUploadStatus(true);
    if (ucConfigUID) {
      this.configService
        .updateUseCaseMapping(
          ucConfigUID,
          useCaseType,
          this.peoplestoremovementUseCaseForm.value
        )
        .subscribe(
          (resp: any) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess("UseCase Updated successfully.");
              this.isLoading = false;
              this.configService.setActiveItem(false);
              this.updatedUseCase.emit();
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else {
      this.configService
        .createUseCaseMapping(
          this.useCaseIdentifier,
          this.peoplestoremovementUseCaseForm.value
        )
        .subscribe(
          (resp) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess("UseCase Added successfully.");
              this.isLoading = false;
              this.configService.setActiveItem(false);
              this.updatedUseCase.emit();
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    }
    
  }
}
