<div class="attachment-container">
  <div class="attachment-heading col-12">
    <div class="d-flex align-items-center margin-tb info-gap">
      <h5>Add Audio/Video Footage, Transaction Records, Documents, Images</h5>
    </div>
  </div>

  <div class="attachment-tab col-12">
    <div class="add-profile d-flex align-items-center justify-content-center"
      [ngClass]="{'drag-active': (showDragEnter && !selectedTask?.status?.allow_case_update==false), 'file-upload-disabled': selectedTask?.status?.allow_case_update==false}"
      *ngIf="selectedTask" (click)="openFileBrowser()" id="dropZone" (dragover)="onDragOver($event)"
      (drop)="onDrop($event)" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)">
      <div class="upload-section d-flex align-items-center justify-content-center flex-dir-column">
        <label class="icon label-file"
          [ngClass]="{'label-file-disabled': selectedTask?.status?.allow_case_update==false}">
          <img src="../../../../assets/icons/upload-icon.svg" alt="upload" />
        </label>
        <div class="drag-drop-text d-flex align-items-center justify-content-center">
          <span class="attachment-files">Drag and drop</span>
          <span class="files-text">or</span>
          <div class="browse-btn d-flex">
            <button class="btn btn-submit" [disabled]="selectedTask?.status?.allow_case_update==false">Browse</button>
            <input type="file" (click)="clearValueToAllowSameFile()" (change)="onFileChangeFootage($event)"
              accept="{{fileAllowedExtensionsString}}" class="form-control me-2 input_file" name="footegeFile"
              id="footegeFile" multiple style="display: none;" #fileInput
              [disabled]="selectedTask?.status?.allow_case_update==false" />
          </div>
        </div>
      </div>
    </div>
    <div class="remaining-sec d-flex align-items-center justify-content-end">
      <h5 *ngIf="attachmentData?.length">Remaining upload Size <span class="size">{{ remainingFileSize }}</span> MB</h5>
      <h5 *ngIf="!attachmentData?.length">Max : <span class="size">{{ totalFileSizeLimit }}</span> MB</h5>
    </div>
    <div class="added-attachments pt-2">
      <ul class="attachments-files" *ngIf="attachmentData?.length">
        <ng-container *ngFor="let attachment of attachmentData; let i = index">
          <li *ngIf="!attachment?.hideFileFromUI" 
            class="attachment-list d-flex align-items-center justify-content-between">
            <div class="file-details d-flex align-items-center">
              <div class="file-icon">
                <img src="../../../../assets/icons/attachments-icon.svg" alt="link"
                  *ngIf="!attachment?.uploadFileComplete && !attachment?.uploadFileInprogress && !attachment?.fileUploadFailed" />
                <img src="../../../../assets/icons/upload-progress.svg" alt="link"
                  *ngIf="attachment?.uploadFileInprogress || attachment?.showTemporarilyComplete && !attachment?.fileUploadFailed" />
                <img src="../../../../assets/icons/warning.svg" alt="link" *ngIf="attachment?.fileUploadFailed && !attachment?.uploadFileInprogress" />
              </div>
              <div class="file-name d-flex">
                <div class="name">
                  {{ attachment?.file_name }}
                </div>
                <div class="size d-flex align-items-center">
                  <div>{{ attachment?.file_size_bytes ?? 0 | fileSize }}</div>
                  <div class="failed-attachment" *ngIf="!attachment?.uploadFileInprogress && attachment?.fileUploadFailed">Failed</div>
                  <div class="progress-bar-container" *ngIf="attachment?.uploadFileInprogress">
                    <div class="progress-bar">
                      <div class="progress-bar-value"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="file-remove d-flex">
              <button type="button" class="remove-btn" autofocus="false" (click)="removeFootageFile(i, attachment)"
                *ngIf="!attachment?.uploadFileInprogress && !attachment?.showTemporarilyComplete && !attachment?.deleteFileInprogress && selectedTask?.status?.allow_case_update==true && !attachment?.fileUploadFailed">
                <img src="../../../../../assets/icons/remove-icon.png" title="Remove file" alt="" />
              </button>
              <button class="reload-btn" type="button" *ngIf="attachment?.fileUploadFailed && !attachment?.uploadFileInprogress" (click)="reUploadFailedFile(attachment)">
                <img src="../../../../../assets/icons/reload.svg" title="Remove file" alt="" /> Reload
              </button>
              <button type="button" class="remove-btn" autofocus="false" (click)="removeFailedFile(i, attachment)"
                *ngIf="attachment?.fileUploadFailed && !attachment?.uploadFileInprogress">
                <img src="../../../../../assets/icons/remove-icon.png" title="Remove file" alt="" />
              </button>
              <img src="../../../../../assets/icons/upload-complete.svg" title="File is uploaded" alt=""
                *ngIf="attachment?.showTemporarilyComplete && !attachment?.fileUploadFailed" />
            </div>
          </li>
        </ng-container>
      </ul>
      <div class="no-data d-flex align-items-center justify-content-center" *ngIf="!attachmentData?.length">
        <span>There is no attachment to display</span>
      </div>
    </div>
  </div>
</div>