import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ROI } from "../usecase-config/usecase-config.component";
import { Subscription } from "rxjs";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: "app-out-of-stock",
  templateUrl: "./out-of-stock.component.html",
  styleUrls: ["./out-of-stock.component.scss"],
})
export class OutOfStockComponent implements OnInit, OnDestroy {
  @Input() public configData: any;
  @Output() updatedUseCase: EventEmitter<void> = new EventEmitter<void>();
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  oosUseCaseForm: FormGroup;
  selectedProductROI: ROI = {
    type: "ProductROI",
    xMin: 0,
    yMin: 0,
    xMax: 0,
    yMax: 0,
  };

  selectedPersonROI: ROI = {
    type: "PersonROI",
    xMin: 0,
    yMin: 0,
    xMax: 0,
    yMax: 0,
  };
  roiConfigurations: ROI[] = [];
  filteredRoiConfigurations: ROI[] = [];
  public roiConfigurationsSubscription: Subscription;
  public roiDimensionsSubscription: Subscription;

  productROIConfig: any;
  personROIConfig: any;
  dimensions: any;

  selectedUseCaseInfo: any;
  selectedUCConfig: any;
  baseValue: 0;
  useCaseConfigId: any;
  useCaseIdentifier: any;
  isChecked: boolean = true;
  uploadedImage: any;
  selectedDefaultResolutionOneIndex : any;
  selectedDefaultResolutionZeroIndex : any;

  constructor(
    private fb: FormBuilder,
    private configService: ConfigurationService,
    private alertMessagesService: AlertMessagesService,
    public activeModal: NgbActiveModal
  ) {
    this.oosUseCaseForm = this.fb.group({
      tenant: ["", Validators.required],
      store: ["", Validators.required],
      camera: [],
      productRoiXMin: [null, [Validators.required]],
      productRoiXMax: [null, [Validators.required]],
      productRoiYMin: [null, [Validators.required]],
      productRoiYMax: [null, [Validators.required]],
      personRoiXMin: [null, [Validators.required]],
      personRoiXMax: [null, [Validators.required]],
      personRoiYMin: [null, [Validators.required]],
      personRoiYMax: [null, [Validators.required]],
      cameraId: ["", Validators.required],
      deviceId: ["", Validators.required],
      usecaseId: ["", Validators.required],
      capacity: ["", [Validators.required]],
      upperLimitPerc: [
        "",
        [Validators.required, this.digitCountValidator(100)],
      ],
      inputSource: [""],
      status: [true],
      defaultResolution: [""],
    });
    this.fetchConfigData();
  }
  get fc() {
    return this.oosUseCaseForm.controls;
  }

  ngOnInit(): void {
    this.selectedUseCaseInfo = this.configData.useCase;
    if (this.selectedUseCaseInfo) {
      this.getUseCaseConfigData();
    }
    this.refreshROIData();
  }

  refreshROIData() {
    this.selectedProductROI.xMin = this.baseValue;
    this.selectedProductROI.yMin = this.baseValue;
    this.selectedProductROI.xMax = this.baseValue;
    this.selectedProductROI.yMax = this.baseValue;
    this.selectedPersonROI.xMin = this.baseValue;
    this.selectedPersonROI.yMin = this.baseValue;
    this.selectedPersonROI.xMax = this.baseValue;
    this.selectedPersonROI.yMax = this.baseValue;
  }

  digitCountValidator(maxValue: number) {
    return (control: any) => {
      const value = parseFloat(control.value);
      return isNaN(value) || value <= maxValue
        ? null
        : { digitCountExceeded: true };
    };
  }

  getUseCaseConfigData() {
    this.useCaseConfigId = this.selectedUseCaseInfo.ucConfigUID;
    this.useCaseIdentifier = this.selectedUseCaseInfo.usecaseIdentifier;
    this.configService
      .getUseCaseConfigData(this.useCaseConfigId, this.useCaseIdentifier)
      .subscribe((resp: any) => {
        this.selectedUCConfig = resp.data;
        this.fetchUseCaseConfigData();
      });
  }

  fetchConfigData() {
    this.roiConfigurationsSubscription = this.configService
      .getROIConfigurations()
      .subscribe((configurations: ROI[]) => {
        this.roiConfigurations = configurations;
        this.filterRoiConfigurations();
      });

    this.roiDimensionsSubscription = this.configService
      .getImageDimensions()
      .subscribe((dimensions: any) => {
        this.dimensions = dimensions;
      });
  }

  filterRoiConfigurations() {
    const modifiedRois = [];
    for (const roi of this.roiConfigurations) {
      if (roi.type === "ProductROI") {
        modifiedRois.push({
          ...roi,
          xMin: roi.xMin * 2,
          yMin: roi.yMin * 2,
          xMax: roi.xMax * 2,
          yMax: roi.yMax * 2,
        });
      } else {
        modifiedRois.push(roi);
      }
    }

    this.filteredRoiConfigurations = modifiedRois;
    this.productROIConfig = this.filteredRoiConfigurations[0];
    this.personROIConfig = this.filteredRoiConfigurations[1];

    if (this.productROIConfig?.type === this.selectedProductROI.type) {
      this.selectedProductROI.xMin = this.productROIConfig?.xMin;
      this.selectedProductROI.yMin = this.productROIConfig?.yMin;
      this.selectedProductROI.xMax = this.productROIConfig?.xMax;
      this.selectedProductROI.yMax = this.productROIConfig?.yMax;
    }

    if (this.personROIConfig?.type === this.selectedPersonROI.type) {
      this.selectedPersonROI.xMin = this.personROIConfig?.xMin;
      this.selectedPersonROI.yMin = this.personROIConfig?.yMin;
      this.selectedPersonROI.xMax = this.personROIConfig?.xMax;
      this.selectedPersonROI.yMax = this.personROIConfig?.yMax;
    }
  }

  fetchUseCaseConfigData() {
    if (this.selectedUCConfig) {
      this.oosUseCaseForm.patchValue({
        tenant: this.selectedUCConfig.tenant,
        store: this.selectedUCConfig.store,
        usecaseId: this.selectedUCConfig.usecaseId,
        cameraId: this.selectedUCConfig.cameraId,
        deviceId: this.selectedUCConfig.deviceId,
        inputSource: this.selectedUCConfig.inputSource,
        camera: this.selectedUCConfig.camera,
        defaultResolution: this.selectedUCConfig.defaultResolution,
        capacity: this.selectedUCConfig.settings.BUSINESS_CONFIG_STRING.value.business_configs.full_count,
        upperLimitPerc: this.selectedUCConfig.settings.BUSINESS_CONFIG_STRING.value.business_configs.reduction_in_full_count_upper_limit_percent,
      });
      this.selectedProductROI.xMin = this.selectedUCConfig?.settings.ROI_CONFIG_STRING.value.roi_configs.product_roi.xmin;
      this.selectedProductROI.yMin = this.selectedUCConfig?.settings.ROI_CONFIG_STRING.value.roi_configs.product_roi.ymin;
      this.selectedProductROI.xMax = this.selectedUCConfig?.settings.ROI_CONFIG_STRING.value.roi_configs.product_roi.xmax;
      this.selectedProductROI.yMax = this.selectedUCConfig?.settings.ROI_CONFIG_STRING.value.roi_configs.product_roi.ymax;
      this.selectedPersonROI.xMin = this.selectedUCConfig?.settings.ROI_CONFIG_STRING.value.roi_configs.person_roi.xmin;
      this.selectedPersonROI.yMin = this.selectedUCConfig?.settings.ROI_CONFIG_STRING.value.roi_configs.person_roi.ymin;
      this.selectedPersonROI.xMax = this.selectedUCConfig?.settings.ROI_CONFIG_STRING.value.roi_configs.person_roi.xmax;
      this.selectedPersonROI.yMax = this.selectedUCConfig?.settings.ROI_CONFIG_STRING.value.roi_configs.person_roi.ymax;
      this.selectedDefaultResolutionZeroIndex = this.selectedUCConfig?.settings.ROI_CONFIG_STRING.value.roi_configs.default_resolution[0];
      this.selectedDefaultResolutionOneIndex = this.selectedUCConfig?.settings.ROI_CONFIG_STRING.value.roi_configs.default_resolution[1];
      this.isChecked = this.configData?.useCase?.status;
    } else {
      this.oosUseCaseForm.patchValue({
        tenant: this.configData?.tenantId,
        store: this.configData?.storeId,
        usecaseId: this.configData?.useCaseUId,
        cameraId: this.configData?.camUId,
        deviceId: this.configData?.deviceId,
        productRoiXMin: this.selectedProductROI.xMin,
        productRoiXMax: this.selectedProductROI.xMax,
        productRoiYMin: this.selectedProductROI.yMin,
        productRoiYMax: this.selectedProductROI.yMax,
        personRoiXMin: this.selectedPersonROI.xMin,
        personRoiXMax: this.selectedPersonROI.xMax,
        personRoiYMin: this.selectedPersonROI.yMin,
        personRoiYMax: this.selectedPersonROI.yMax,
        inputSource: this.configData?.rtsp,
        camera: this.configData?.camMacAdd,
        status: this.isChecked,
        defaultResolution:
          this.dimensions.dimensionWidth +
          "," +
          this.dimensions.dimensionHeight,
      });
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (
      this.selectedProductROI.xMin == undefined ||
      this.selectedProductROI.xMax == undefined ||
      this.selectedProductROI.yMin == undefined ||
      this.selectedProductROI.yMax == undefined ||
      this.selectedPersonROI.xMax == undefined ||
      this.selectedPersonROI.xMin == undefined ||
      this.selectedPersonROI.yMax == undefined ||
      this.selectedPersonROI.yMin == undefined
    )
      return;
    if (
      this.selectedProductROI.xMin <= 0 ||
      this.selectedProductROI.xMax <= 0 ||
      this.selectedProductROI.yMin <= 0 ||
      this.selectedProductROI.yMax <= 0 ||
      this.selectedPersonROI.xMax <= 0 ||
      this.selectedPersonROI.xMin <= 0 ||
      this.selectedPersonROI.yMax <= 0 ||
      this.selectedPersonROI.yMin <= 0
    )
      return;
    if (
      this.oosUseCaseForm.get("capacity")?.value == undefined ||
      this.oosUseCaseForm.get("capacity")?.value <= 0 ||
      this.oosUseCaseForm.get("upperLimitPerc")?.value == undefined ||
      this.oosUseCaseForm.get("upperLimitPerc")?.value <= 0
    )
      return;

    if (!this.useCaseIdentifier) {
      this.useCaseIdentifier = this.configData?.useCaseIdentifier;
    }
    if (this.selectedUCConfig) {
      this.oosUseCaseForm.patchValue({
        tenant: this.configData?.useCase?.tenantId,
        store: this.configData?.useCase?.storeId,
        usecaseId: this.configData?.useCase?.usecaseId,
        cameraId: this.selectedUCConfig.cameraId,
        deviceId: this.selectedUCConfig.deviceId,
        inputSource: this.selectedUCConfig.inputSource,
        camera: this.selectedUCConfig.camera,
        defaultResolution:
          this.selectedDefaultResolutionZeroIndex +
          "," +
          this.selectedDefaultResolutionOneIndex,
        status: this.isChecked,
        productRoiXMin: this.selectedProductROI.xMin,
        productRoiXMax: this.selectedProductROI.xMax,
        productRoiYMin: this.selectedProductROI.yMin,
        productRoiYMax: this.selectedProductROI.yMax,
        personRoiXMin: this.selectedPersonROI.xMin,
        personRoiXMax: this.selectedPersonROI.xMax,
        personRoiYMin: this.selectedPersonROI.yMin,
        personRoiYMax: this.selectedPersonROI.yMax,
      });
    } else {
      this.oosUseCaseForm.patchValue({
        tenant: this.configData?.tenantId,
        store: this.configData?.storeId,
        usecaseId: this.configData?.useCaseUId,
        cameraId: this.configData?.camUId,
        deviceId: this.configData?.deviceId,
        status: this.isChecked,
        productRoiXMin: this.selectedProductROI.xMin,
        productRoiXMax: this.selectedProductROI.xMax,
        productRoiYMin: this.selectedProductROI.yMin,
        productRoiYMax: this.selectedProductROI.yMax,
        personRoiXMin: this.selectedPersonROI.xMin,
        personRoiXMax: this.selectedPersonROI.xMax,
        personRoiYMin: this.selectedPersonROI.yMin,
        personRoiYMax: this.selectedPersonROI.yMax,
        inputSource: this.configData?.rtsp,
        camera: this.configData?.camMacAdd,
        defaultResolution:
          this.dimensions.dimensionWidth +
          "," +
          this.dimensions.dimensionHeight,
      });
    }
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 5000);
    const ucConfigUID = this.configData?.useCase?.ucConfigUID;
    const useCaseType = this.configData?.useCase?.usecaseIdentifier;
    const upperlimitval = this.oosUseCaseForm.controls["upperLimitPerc"].value;
    if (
      !this.selectedProductROI.xMin &&
      !this.selectedProductROI.xMax &&
      !this.selectedProductROI.yMin &&
      !this.selectedProductROI.yMax
    ) {
      this.configService.getFileUploadStatus(true);
    }

    if (ucConfigUID && upperlimitval <= 100) {
      this.configService
        .updateUseCaseMapping(
          ucConfigUID,
          useCaseType,
          this.oosUseCaseForm.value
        )
        .subscribe(
          (resp: any) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess("UseCase Updated successfully.");
              this.isLoading = false;
              this.configService.setActiveItem(false);
              this.updatedUseCase.emit();
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else if (upperlimitval <= 100) {
      this.configService
        .createUseCaseMapping(this.useCaseIdentifier, this.oosUseCaseForm.value)
        .subscribe(
          (resp) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess("UseCase Added successfully.");
              this.isLoading = false;
              this.configService.setActiveItem(false);
              this.updatedUseCase.emit();
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    }

    this.configService.resetROIConfigurations();
    
  }

  ngOnDestroy() {
    this.roiConfigurationsSubscription.unsubscribe();
  }
}
