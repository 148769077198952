import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { CaseManagementService } from "src/app/services/case-management.service";
import Swal from "sweetalert2";
import filetype from "file-type";
import { CommonService } from "src/app/services/common.service";
import { AlertMessagesService } from "src/app/services/alert-messages.service";

@Component({
  selector: "app-attachment-tab",
  templateUrl: "./attachment-tab.component.html",
  styleUrls: ["./attachment-tab.component.scss"]
})

export class AttachmentTabComponent implements OnInit, OnDestroy {
  attachmentData: any = [];
  attachmentDataTemp: any = [];
  @Input() selectedTask: any;
  @Output() fileUploadEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileDeleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef;
  disabledUploadButton: boolean = true;
  showFileErrorCounter: number = 0;
  fileAllowedExtensionsList: string[] = [
    "audio/*", "video/*", "image/*", "text/csv", "text/plain", "image/svg+xml",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    "application/vnd.openxmlformats-officedocument.presentationml.template",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "application/vnd.openxmlformats-officedocument.presentationml.slide",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.oasis.opendocument.spreadsheet-template",
    "application/vnd.oasis.opendocument.image",
    "application/vnd.oasis.opendocument.image-template",
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.oasis.opendocument.presentation-template",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.ms-excel",
    "application/pdf",
    "application/onenote",
    "application/msword",
    "application/mp4"
  ];
  fileAllowedExtensionsString: string;
  remainingFileSize: number = 0;
  totalFileSizeLimit: number = 500;
  fileCategoryCounter: number = 0;
  showSuccessCounter: number = 0;
  showErrorCounter: number = 0;
  showDragEnter: boolean = false;
  fileStatusData: any;
  fileStatusDataList: any = [];
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';
  successImgIcon = "../../../../assets/icons/success-icon.svg";
  errorImgIcon = '../../../../assets/icons/confirmationnew.svg';

  constructor(private caseManagementService: CaseManagementService, private alertMessagesService: AlertMessagesService, private commonService: CommonService) { }

  ngOnInit(): void {
    this.fileAllowedExtensionsString = this.fileAllowedExtensionsList.join(',');
    this.getAttachmentData();
  }

  ngOnDestroy(): void {
    /// setting the data in subscription service since we need to show progress when in other pages
    const fileCount = this.attachmentData?.filter((obj: any) => obj['localUpload'] == true)?.length;
    let fileObj: any = { toShowProgress: true };
    fileObj.fileData = this.fileStatusData?.fileData;
    fileObj.caseId = this.fileStatusData?.caseId;
    if (fileCount == 0) {
      fileObj.canWindowTabClosed = true;
    } else {
      fileObj.canWindowTabClosed = false;
    }
    const index = this.fileStatusDataList.findIndex((item: any) => {
      return item.caseId == this.fileStatusData?.caseId;
    });
    if( index != -1 ) {
      this.fileStatusDataList[index] = fileObj;
    } else if( this.fileStatusData?.caseId ) {
      this.fileStatusDataList.push(fileObj);
    }
    this.commonService.setCaseFileUploadProgress(this.fileStatusDataList);
  }

  getAttachmentData() {
    const attachmentData = (this.selectedTask?.tabs_data?.attachment?.length > 0) ? [...this.selectedTask?.tabs_data?.attachment] : [];
    this.attachmentData = [];
    this.attachmentDataTemp = [];
    this.attachmentData = attachmentData.filter((obj: any) => obj['group'] == 1);
    this.calculateFileSizeRemaining();
    this.commonService.caseFileUploadProgress$.subscribe(data => {
      if (data?.length > 0) {
        this.fileStatusDataList = data;
        const index = this.fileStatusDataList.findIndex((item: any) => {
          return item.caseId == this.selectedTask.id;
        });
        if( index != -1 ) {
          this.fileStatusData = this.fileStatusDataList[index];
          const fileCount = this.fileStatusData?.fileData?.filter((obj: any) => obj['localUpload'])?.length;
          const fileFailedCount = this.fileStatusData?.fileData?.filter((obj: any) => obj['fileUploadFailed'])?.length;
          if ( this.fileStatusData?.caseId == this.selectedTask?.id && (fileCount > 0 || fileFailedCount > 0) ) {
            this.attachmentData = (this.fileStatusData.fileData?.length > 0) ? [...this.fileStatusData.fileData] : this.attachmentData;
            this.calculateFileSizeRemaining();
          }
        }
      } else {
        this.fileStatusDataList = [];
      }
    });
  }

  calculateFileSizeRemaining() {
    let totalSize = 0;
    for (let i = 0; i < this.attachmentData.length; i++) {
      if( !this.attachmentData[i]?.hideFileFromUI ) {
        if (this.attachmentData[i]?.localUpload == true) {
          totalSize += this.attachmentData[i]?.uploadedFile?.size ?? 0;
        } else {
          totalSize += this.attachmentData[i]?.file_size_bytes ?? 0;
        }
      }
    }
    this.remainingFileSize = parseFloat((this.totalFileSizeLimit - totalSize / (1000 * 1000)).toFixed(2));
    return this.remainingFileSize;
  }

  readFileAsyncFootage(selectedFiles: File) {
    return new Promise((resolve, reject) => {
      const file: File = selectedFiles;
      // Read the file content
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const attachmentObject = {
          name: file.name,
          file_name: file.name,
          fileMimeType: file.type, // Content type like "image/jpeg", "video/mp4", "application/pdf", etc.
          content: e.target.result, // The file content as a data URL
          uploadedFile: file,
          group: 1,
          localUpload: true,
          file_size_bytes: file.size ?? 0,
          isSameFile: false,
          sameFileIndexRef: -1,
          realMimeType: '',
          uploadFileInprogress: false,
          uploadFileComplete: false,
          showTemporarilyComplete: false,
          fileUploadFailed: false,
          deleteFileInprogress: false,
          hideFileFromUI: false
        };

        /// code for checking actual file mime type since user can change the extensions so we have to check actual file mime type
        const blob: any = await filetype.fromBuffer(e.target.result);
        attachmentObject.realMimeType = blob?.mime;
        /// code for checking actual file mime type since user can change the extensions so we have to check actual file mime type
        /// modifying mimetype ex. as "image/*" from "image/png" since we are allowing files of type like "audio/*" "video/*" in our list fileAllowedExtensionsList
        const modifiedMimeType = attachmentObject?.realMimeType?.replace(/\/[^/]+$/, '/*');
        /// here checking complete realmime type for "application/pdf", "text/plain" etc. 
        const fullRealMimeType = attachmentObject?.realMimeType;
        /// here currently we are bypassing mime type for "text/plain", "text/csv", "image/svg+xml" etc. we need to work on this
        const fileMimeType = attachmentObject?.fileMimeType;
        if ((attachmentObject.fileMimeType === attachmentObject.realMimeType && (this.fileAllowedExtensionsList.includes(fullRealMimeType) || this.fileAllowedExtensionsList.includes(modifiedMimeType))) ||
          (!attachmentObject?.realMimeType && this.fileAllowedExtensionsList.includes(fileMimeType))) {
          /// Append the attachment object to the JSON attachment array
          /// here spliting and joining since file name may have spaces and sometimes from backend one of the space is getting removed
          /// so the validation is failing ui side so added this temporary removing space and then comparing logic
          const existingIndex = this.attachmentData.findIndex((existingItem: any) => {
            return attachmentObject?.file_name?.toLowerCase()?.replace(/\s/g, '') == existingItem?.file_name?.toLowerCase()?.replace(/\s/g, '')
          });
          if (existingIndex != -1) {
            attachmentObject.isSameFile = true;
            attachmentObject.sameFileIndexRef = existingIndex;
            this.attachmentDataTemp.push(attachmentObject); // here putting files in temp since we need to check remaining size and show user alert before accepting file
          } else {
            this.attachmentDataTemp.push(attachmentObject); // here putting files in temp since we need to check remaining size and show user alert before accepting file
          }
          this.disabledUploadButton = false;
        } else {
          this.showFileErrorCounter++;
        }
        resolve(e);
      };
      reader.onerror = (error: any) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  async onFileChangeFootage(event: any) {
    const selectedFiles: FileList = event.target.files;
    this.showFileErrorCounter = 0;
    // Loop through the selected files and append them to the JSON attachment array
    for (let i = 0; i < selectedFiles.length; i++) {
      try {
        await this.readFileAsyncFootage(selectedFiles[i]);
      } catch (error) {
        console.error('Error reading file:', error);
      }
    }

    /// logic to check if newly uploaded files are going beyond remaining size then show user alert
    let response = await this.checkIfFilesLimitReached();
    if (response) {
      if (this.showFileErrorCounter > 0) {
        this.alertMessagesService.alertWithError("The file(s) you are trying to upload, not supported by system.");
      }
      /// logic to check if uploaded files are same as older uploaded file by name then show user alert
      let sameFileResp = await this.checkIfSameFilesUploaded();
      if (sameFileResp) {
        this.attachmentData = this.combineOldAndNewFiles();
        this.calculateFileSizeRemaining();
        this.uploadFiles();
      } else {
        this.attachmentDataTemp = [];
      }
    } else {
      this.attachmentDataTemp = [];
    }
  }

  checkIfSameFilesUploaded() {
    return new Promise((resolve) => {
      const sameFileUploadData = this.attachmentDataTemp.filter((obj: any) => (obj['isSameFile'] === true && obj['localUpload'] == true));
      if (sameFileUploadData?.length > 0) {
        // Need to show confirmation popup since there are files with same name 
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            popup: 'alert-warning-container',
            htmlContainer: 'alert-warning-content',
            confirmButton: "btn btn-submit",
            cancelButton: "btn btn-cancel",
            icon: "alert-warning-icon",
            title: "alert-warning-title",
          },
          buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
          iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
          text: "Are you sure you want to replace this file with previous file?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            resolve(true);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            resolve(false);
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  checkIfFilesLimitReached() {
    return new Promise((resolve) => {
      let newUploadedFileSize = 0;
      let newUploadedFileSizeMB = 0;
      let remainingSizeMB = 0;
      for (let i = 0; i < this.attachmentDataTemp.length; i++) {
        if (this.attachmentDataTemp[i]?.localUpload == true) {
          newUploadedFileSize += this.attachmentDataTemp[i]?.uploadedFile?.size ?? 0;
        } else {
          newUploadedFileSize += this.attachmentDataTemp[i]?.file_size_bytes ?? 0;
        }
      }
      newUploadedFileSizeMB = parseFloat((newUploadedFileSize / (1000 * 1000)).toFixed(2));
      remainingSizeMB = this.calculateFileSizeRemaining();
      if (remainingSizeMB < newUploadedFileSizeMB) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            popup: 'alert-warning-container',
            htmlContainer: 'alert-warning-content',
            icon: "alert-warning-icon",
            title: "alert-warning-title",
            closeButton: "alert-warning-close",
          },
          buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
          iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
          html: `
          <div class="popup-size-html">
            <span>Remaining ${remainingSizeMB} MB available out of ${this.totalFileSizeLimit} MB. Please select file(s) within size limit.</span>
          </div>
          <div class="browse-btn-alert d-flex align-items-center justify-content-center">
            <button id="swalAlertBrowseId" class="btn btn-submit">Browse</button>
          </div>`,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          showCloseButton: true,
          didOpen: () => {
            const htmlContent: any = swalWithBootstrapButtons.getHtmlContainer();
            htmlContent.querySelector('#swalAlertBrowseId').addEventListener('click', this.openFileBrowser.bind(this));
          }
        });
        resolve(false);
      } else {
        Swal.close();
        resolve(true);
      }
    });
  }

  checkIfAnyFileForUpload() {
    const attachmentData = this.attachmentData.filter((obj: any) => obj['localUpload'] === true);
    return (attachmentData.length == 0);
  }

  disableCheckUploadBtn() {
    if (this.checkIfAnyFileForUpload()) {
      this.disabledUploadButton = true;
    }
    this.calculateFileSizeRemaining();
  }

  removeFailedFile(index: any, attachment: any) {
    if (attachment?.fileUploadFailed) {
      this.attachmentData.splice(index, 1);
      this.disableCheckUploadBtn();
    }
  }

  removeFootageFile(index: any, attachment: any) {
    if (attachment?.localUpload) {
      this.attachmentData.splice(index, 1);
      this.disableCheckUploadBtn();
    } else {
      // need to call the api for file delete
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          popup: 'delete-file-container',
          htmlContainer: 'delete-content',
          confirmButton: "btn btn-submit",
          cancelButton: "btn btn-cancel"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({
        text: "Are you sure you want to remove this file?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removeFileFromServer(attachment, index);
        }
      });
    }
  }

  removeFileFromServer(attachment: any, index: any) {
    attachment.deleteFileInprogress = true;
    const fileFormData = new FormData();
    fileFormData.append("file_url", attachment?.url);
    fileFormData.append("slug", 'case_management');
    this.caseManagementService.deleteCaseAttachments(fileFormData).subscribe({
      next: (resp: any) => {
        this.deleteCaseAttachmentsEntry(attachment, index);
      }, error: (error: any) => {
        attachment.deleteFileInprogress = false;
        this.alertMessagesService.showToastMessage(`Error while deleting the file, please try again.`, `<img src=${this.errorImgIcon} width="34" height="34" alt="" />`);
      }
    });
  }

  deleteCaseAttachmentsEntry(attachment: any, index: any) {
    const payload = {
      'attachment_id': attachment?.id
    };
    this.caseManagementService.deleteCaseAttachmentsEntry(payload, this.selectedTask?.id).subscribe({
      next: (resp: any) => {
        attachment.deleteFileInprogress = false;
        this.attachmentData.splice(index, 1);
        this.disableCheckUploadBtn();
        this.fileDeleteEvent.emit(resp);
        this.alertMessagesService.showToastMessage(`File is deleted successfully.`, `<img src=${this.successImgIcon} width="34" height="34" alt="" />`);
      }, error: (error: any) => {
        attachment.deleteFileInprogress = false;
        this.alertMessagesService.showToastMessage(`Error while deleting the file, please try again.`, `<img src=${this.errorImgIcon} width="34" height="34" alt="" />`);
      }
    });
  }

  combineOldAndNewFiles() {
    return [...this.attachmentData, ...this.attachmentDataTemp];
  }

  reUploadFailedFile(attachment: any) {
    attachment.localUpload = true;
    if (attachment?.localUpload == true && !attachment?.uploadFileInprogress) {
      if (attachment?.group == 1) {
        this.fileCategoryCounter++;
        const formFootageGrp1Data = new FormData();
        formFootageGrp1Data.append("id", this.selectedTask.id);
        formFootageGrp1Data.append("slug", 'case_management');
        formFootageGrp1Data.append("subdir", 'attachments');
        formFootageGrp1Data.append("files", attachment?.uploadedFile); // 'file' should match the server's expected file field name
        this.disabledUploadButton = true;
        attachment['uploadFileInprogress'] = true;
        this.uploadFilesUpdateCase(formFootageGrp1Data, 1, attachment);
      }
    }
    /// setting the data in subscription service since we need to show progress when in other pages
    const fileCount = this.attachmentData?.filter((obj: any) => obj['localUpload'] == true)?.length;
    let fileObj: any = { toShowProgress: false };
    fileObj.fileData = this.attachmentData;
    fileObj.caseId = this.selectedTask?.id;
    if (fileCount == 0) {
      fileObj.canWindowTabClosed = true;
    } else {
      fileObj.canWindowTabClosed = false;
    }
    const index = this.fileStatusDataList.findIndex((item: any) => {
      return item.caseId == this.selectedTask.id;
    });
    if( index != -1 ) {
      this.fileStatusDataList[index] = fileObj;
    } else if( this.selectedTask?.id ) {
      this.fileStatusDataList.push(fileObj);
    }
    this.commonService.setCaseFileUploadProgress(this.fileStatusDataList);
  }

  uploadFiles() {
    // return if there are no files to upload to server
    if (this.checkIfAnyFileForUpload()) {
      return;
    }

    // Upload files after user confirmation
    // To disable the upload button
    this.fileCategoryCounter = 0;
    this.showErrorCounter = 0;
    this.showSuccessCounter = 0;
    this.attachmentDataTemp = [];

    for (let i = 0; i < this.attachmentData.length; i++) {
      if (this.attachmentData[i]?.localUpload == true && !this.attachmentData[i]?.uploadFileInprogress) {
        if (this.attachmentData[i]?.group == 1) {
          this.fileCategoryCounter++;
          const formFootageGrp1Data = new FormData();
          formFootageGrp1Data.append("id", this.selectedTask.id);
          formFootageGrp1Data.append("slug", 'case_management');
          formFootageGrp1Data.append("subdir", 'attachments');
          formFootageGrp1Data.append("files", this.attachmentData[i]?.uploadedFile); // 'file' should match the server's expected file field name
          this.disabledUploadButton = true;
          this.attachmentData[i]['uploadFileInprogress'] = true;
          this.uploadFilesUpdateCase(formFootageGrp1Data, 1, this.attachmentData[i]);
        }
      }
    }
    /// setting the data in subscription service since we need to show progress when in other pages
    const fileCount = this.attachmentData?.filter((obj: any) => obj['localUpload'] == true)?.length;
    let fileObj: any = { toShowProgress: false };
    fileObj.fileData = this.attachmentData;
    fileObj.caseId = this.selectedTask?.id;
    if (fileCount == 0) {
      fileObj.canWindowTabClosed = true;
    } else {
      fileObj.canWindowTabClosed = false;
    }
    const index = this.fileStatusDataList.findIndex((item: any) => {
      return item.caseId == this.selectedTask.id;
    });
    if( index != -1 ) {
      this.fileStatusDataList[index] = fileObj;
    } else if( this.selectedTask?.id ) {
      this.fileStatusDataList.push(fileObj);
    }
    this.commonService.setCaseFileUploadProgress(this.fileStatusDataList);
  }

  updateFileUploadSuccessStatus(group: number, attachmentDataObj: any, resp?: any) {
    if (group == 1) {
      attachmentDataObj.localUpload = false;
      attachmentDataObj.isSameFile = false;
      attachmentDataObj.sameFileIndexRef = -1;
      attachmentDataObj.uploadFileInprogress = false;
      attachmentDataObj.uploadFileComplete = true;
      attachmentDataObj.showTemporarilyComplete = true;
      attachmentDataObj.fileUploadFailed = false;
      setTimeout(() => {
        attachmentDataObj.showTemporarilyComplete = false;
        attachmentDataObj.uploadFileComplete = false;
      }, 3000);
      /// setting the data in subscription service since we need to show progress when in other pages
      const fileCount = this.attachmentData?.filter((obj: any) => obj['localUpload'] == true)?.length;
      /// code to update params received from api like id and url after upload
      if ( resp?.data?.id ) {
        this.updateParamsFromResponse(resp);
      }
      /// code to remove the items from array if we are replacing the files.
      if ( fileCount == 0 ) {
        // Remove items based on indices
        this.attachmentData = this.attachmentData.filter((element: any, index: any) => element['id'] && !element['hideFileFromUI'] );
        this.fileStatusData.fileData = this.attachmentData;
        this.calculateFileSizeRemaining();
      }
      let fileObj: any = {};
      fileObj.fileData = this.fileStatusData?.fileData;
      fileObj.caseId = this.fileStatusData?.caseId;
      if (fileCount == 0) {
        fileObj.toShowProgress = false;
        fileObj.canWindowTabClosed = true;
      } else {
        fileObj.canWindowTabClosed = false;
      }
      const index = this.fileStatusDataList.findIndex((item: any) => {
        return item.caseId == this.fileStatusData?.caseId;
      });
      if( index != -1 ) {
        this.fileStatusDataList[index] = fileObj;
      } else if( this.fileStatusData?.caseId ) {
        this.fileStatusDataList.push(fileObj);
      }
      this.commonService.setCaseFileUploadProgress(this.fileStatusDataList);
    }
  }

  uploadFilesUpdateCase(formData: any, group: number, attachmentDataObj: any) {
    this.caseManagementService.uploadCaseAttachments(formData).subscribe({
      next: (resp: any) => {
        let fileUploadResponse = (resp?.data?.length > 0) ? resp.data : [];
        if (fileUploadResponse.length > 0) {
          fileUploadResponse.forEach((obj: any) => { obj.group = group; });
          /// code to check if current file is replace file or not as per same name criteria we need to skip tabs_update call
          /// Actually above response fileUploadResponse should get as per some ID so that we can update db entry so that time can be updated
          if (attachmentDataObj.isSameFile && attachmentDataObj.sameFileIndexRef != -1) {
            // if its same file for replace we need to skip tabs_update call we need to remove 
            for (let i = 0; i < this.attachmentData.length; i++) {
              if ( this.attachmentData[i]?.file_name?.toLowerCase()?.replace(/\s/g, '') === attachmentDataObj?.file_name?.toLowerCase()?.replace(/\s/g, '') 
                && !this.attachmentData[i]?.id && i != attachmentDataObj.sameFileIndexRef ) {
                this.attachmentData[i]['hideFileFromUI'] = true;
              }
            }
            this.updateFileUploadSuccessStatus(group, attachmentDataObj);
            this.showSuccessCounter++;
          } else {
            this.updateCaseAttachmentsData(fileUploadResponse, group, attachmentDataObj);
          }
        }
      }, error: (error: any) => {
        // To disable the upload button
        this.disabledUploadButton = false;
        this.showErrorCounter++;
        this.showFailedUploadFile(group, attachmentDataObj);
      }
    });
  }

  updateCaseAttachmentsData(fileUploadResponse: any, group: any, attachmentDataObj: any) {
    let payload = {
      "tabs_data": {
        "attachment": fileUploadResponse
      }
    };
    this.caseManagementService.updateCaseInfoTabsDetails(payload, this.selectedTask.id).subscribe({
      next: (resp: any) => {
        this.fileUploadEvent.emit(resp);
        this.updateFileUploadSuccessStatus(group, attachmentDataObj, resp);
        this.showSuccessCounter++;
      }, error: (error: any) => {
        // To disable the upload button
        this.disabledUploadButton = false;
        this.showErrorCounter++;
        this.showFailedUploadFile(group, attachmentDataObj);
      }
    });
  }

  updateParamsFromResponse(resp: any) {
    if (resp?.data?.tabs_data?.attachment?.length > 0) {
      let newResponse = resp?.data?.tabs_data?.attachment;
      for (let i = 0; i < newResponse.length; i++) {
        const index = this.attachmentData.map((item: any) => { return item?.file_name?.toLowerCase()?.replace(/\s/g, '') })?.indexOf(newResponse[i]?.file_name?.toLowerCase()?.replace(/\s/g, ''));
        if (index != -1) {
          this.attachmentData[index]['id'] = newResponse[i]['id'];
          this.attachmentData[index]['url'] = newResponse[i]['url'];
          this.attachmentData[index]['hideFileFromUI'] = false;
        }
      }
    }
  }
  showFailedUploadFile(group: number, attachmentDataObj: any, resp?: any) {
    if (group == 1) {
      attachmentDataObj.fileUploadFailed = true;
      attachmentDataObj.localUpload = false;
      attachmentDataObj.uploadFileInprogress = false;
      attachmentDataObj.uploadFileComplete = true;
      attachmentDataObj.showTemporarilyComplete = true;
      setTimeout(() => {
        attachmentDataObj.showTemporarilyComplete = false;
        attachmentDataObj.uploadFileComplete = false;
      }, 3000);
      /// setting the data in subscription service since we need to show progress when in other pages
      const fileCount = this.attachmentData?.filter((obj: any) => obj['localUpload'] == true)?.length;
      let fileObj: any = {};
      fileObj.fileData = this.fileStatusData?.fileData;
      fileObj.caseId = this.fileStatusData?.caseId;
      if (fileCount == 0) {
        fileObj.canWindowTabClosed = true;
      } else {
        fileObj.canWindowTabClosed = false;
      }
      const index = this.fileStatusDataList.findIndex((item: any) => {
        return item.caseId == this.fileStatusData?.caseId;
      });
      if( index != -1 ) {
        this.fileStatusDataList[index] = fileObj;
      } else if( this.fileStatusData?.caseId ) {
        this.fileStatusDataList.push(fileObj);
      }
      this.commonService.setCaseFileUploadProgress(this.fileStatusDataList);
    }
  }

  openFileBrowser() {
    this.fileInput?.nativeElement?.click();
  }

  onDragOver(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showDragEnter = true;
  }

  onDragEnter(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    // Add styling or visual feedback when the drag enters the drop zone
    this.showDragEnter = true;
  }

  onDragLeave(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    // Remove styling or visual feedback when the drag leaves the drop zone
    this.showDragEnter = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.selectedTask?.status?.allow_case_update == false) {
      return;
    }
    const files = event?.dataTransfer?.files ?? [];
    this.showDragEnter = false;
    if (files.length > 0) {
      const event = { target: { files: files } };
      this.onFileChangeFootage(event);
    }
  }

  clearValueToAllowSameFile() {
    this.fileInput.nativeElement.value = null;
  }

}
