import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
// import { AuthService } from 'src/app/shared/services/auth.service';
import { LayoutService } from "src/app/services/layout.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { ProfileResetPasswordComponent } from "./profile-reset-password/profile-reset-password.component";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AlertMessagesService } from "src/app/services/alert-messages.service";

interface IProfile {
  employeeID: string | null;
  designation: string;
  department: string;
  joinDate: string;
  emailID: string | null;
  status: string;
  lastName: string | null;
  firstName: string | null;
}
@Component({
  selector: "app-profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: ["./profile-page.component.scss"],
})
export class ProfilePageComponent {
  @ViewChild("zoomer") zoomer: ElementRef<HTMLInputElement>;
  @ViewChild("profileImageView") profileImageView: ElementRef<HTMLElement>;

  employeeId: string = "";
  profile: IProfile = {
    employeeID: "",
    designation: "--",
    department: "--",
    joinDate: "--",
    emailID: "",
    status: "Active",
    lastName: "",
    firstName: "",
  };
  profileData: any;
  selectedImage: any;
  defaultImage: boolean = true;
  updateProfile: boolean = false;
  removeProfile: boolean = false;
  removeBanner: boolean = false;
  processing: Boolean = false;
  defaultTenantId: any = localStorage.getItem("tenantId");
  email: any = localStorage.getItem("email");
  userId: any;
  tenantId: any;
  userInfo: any;
  profileImageFile: any;
  firstLastName: string;
  rolename: string;
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';
  profileBannerFile: any;
  hovering: boolean = false;
  closeResult: string;
  updatedOrgs: any;
  previousImage: any;
  isProfileChanged: boolean = false;
  isExpanded: boolean = false;
  selectedBanner: any;
  bannerBase64: any;
  storesAssigned: any;

  zoomLevel: number = 1;
  isDragging: boolean = false;
  startX: number = 0;
  startY: number = 0;
  imageLeft: number = 0;
  imageTop: number = 0;

  constructor(
    private layoutService: LayoutService,
    private configurationService: ConfigurationService,
    private modalService: NgbModal,
    private alertMessagesService: AlertMessagesService
  ) {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.userInfo = jwt_decode(accessToken);
        this.userId = this.userInfo.login_uid;
  }
  ngOnInit() {
    const pageTitle = "My Profile";
    this.layoutService.setPageTitle(pageTitle);
    this.tenantId = this.defaultTenantId;
    this.selectedImage = "../../../../assets/user.png";
    this.getProfileData();
  }
  openResetPasswordModal() {
    const options: NgbModalOptions = {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      ProfileResetPasswordComponent,
      options
    );
  }
  getProfileData() {
    this.configurationService
      .getProfileData(this.userId, this.tenantId)
      .subscribe((data: any) => {
        this.profileData = data.data;
        this.selectedImage = this.profileData?.profilePictureBase64;
        this.rolename = this.profileData?.roleName;
        if (this.selectedImage === null) {
          // this.firstLastName = this.profileData?.userName
          //   .split(" ")
          //   .map((word: any) => word.charAt(0))
          //   .join("");
          this.selectedImage = "../../../../assets/user.png";
        } else {
          this.removeProfile = true;
        }
        this.storesAssigned = this.isExpanded
          ? this.profileData?.storename
          : this.profileData?.storename?.slice(0, 8);
        this.selectedBanner = this.profileData?.profileBannerBase64;
        if(this.selectedBanner != null){
          this.removeBanner = true;
        }
      });
  }
  isStoreUser() {
    if (this.rolename == "store-user") {
      return true;
    }
    return false;
  }

  uploadProfileBanner() {
    this.processing = true;
    this.configurationService
      .uploadProfileBanner(this.profileBannerFile, this.tenantId, this.userId)
      .subscribe((resp: any) => {
        this.processing = false;
        if (resp.status === true) {
          this.removeBanner = true;
          this.selectedBanner = this.bannerBase64;
        } else {
        this.alertMessagesService.alertWithError("");
        }
      });
  }

  adjustZoom(event: any) {
    this.zoomLevel = this.zoomer.nativeElement.valueAsNumber;
    this.profileImageView.nativeElement.style.webkitTransform = `scale(${this.zoomLevel})`;
    this.profileImageView.nativeElement.style.transform = `scale(${this.zoomLevel})`;
  }

  cancelUpdateImage() {
    this.isProfileChanged = false;
    this.selectedImage = this.previousImage;
  }

  toggleExpansion() {
    this.isExpanded = !this.isExpanded;
    this.storesAssigned = this.isExpanded
      ? this.profileData?.storename
      : this.profileData?.storename?.slice(0, 8);
  }

  isValidImageExtension(extension: string): boolean {
    const validExtensions = ["png", "jpg", "jpeg", "gif", "bmp", "webp", "svg"];
    if (!extension || !validExtensions.includes(extension.toLowerCase())) {
      this.alertMessagesService.alertWithError("File Not Supported");
      return false;
    }
    return true;
  }

  onBannerChange(event: any) {
    const bannerImage: File = event.target.files[0];
    if (
      bannerImage &&
      this.isValidImageExtension(bannerImage.type.split("/")[1])
    ) {
      if (bannerImage.size > 12 * 1024 * 1024) {
        console.error("File size exceeds 12 MB");
        this.alertMessagesService.alertWithError("File size exceeds 12 MB");
        return; // Stop further execution
      }

      this.profileBannerFile = bannerImage;
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target?.result as string;
        this.bannerBase64 = base64String;
      };
      reader.readAsDataURL(bannerImage);
      event.target.value = "";
      this.uploadProfileBanner();
    } else {
      console.error("Invalid file type, please select an image file");
    }
  }

  onFileChange(event: any) {
    const profileImage: File = event.target.files[0];
    if (
      profileImage &&
      this.isValidImageExtension(profileImage.type.split("/")[1])
    ) {
      if (profileImage.size > 12 * 1024 * 1024) {
        console.error("File size exceeds 12 MB");
        this.alertMessagesService.alertWithError("File size exceeds 12 MB");
        return; // Stop further execution
      }
      this.isProfileChanged = true;
      this.updateProfile = true;
      this.removeProfile = true;
      this.previousImage = this.selectedImage;
      this.profileImageFile = profileImage;
      this.defaultImage = false;
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target?.result as string;
        this.selectedImage = base64String;
      };
      reader.readAsDataURL(profileImage);
      event.target.value = "";
    } else {
      console.error("Invalid file type, please select an image file");
    }
  }

  removeProfileData() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      title: "Are you sure?",
      text: "Please confirm removal of image",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.processing = true;
        this.configurationService
          .removeProfileImage()
          .subscribe(async (resp: any) => {
            if (resp.status === true) {
              this.processing = false;
              this.getProfileData();
              const response = await this.alertMessagesService.alertWithSuccessConfirmAction("Profile Image Removed successfully.");
              if (response == true) {
                this.selectedImage = "";
                this.updateProfile = false;
                this.removeProfile = false;
              }
            }
          });
      }
    });
  }

  removeBannerData(){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      title: "Are you sure?",
      text: "Please confirm removal of cover",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.processing = true;
        this.configurationService
          .removeProfileBanner()
          .subscribe(async (resp: any) => {
            this.processing = false;
            if (resp.status === true) {
              this.getProfileData();
              const response = await this.alertMessagesService.alertWithSuccessConfirmAction("Profile Banner Removed Successfully.");
              if (response == true) {
                this.removeBanner = false;
                this.selectedBanner = '';
              }
            }
          });
      }
    });
  }

  postProfileImage(imageUrl: any, userId: string) {
    this.configurationService.postProfileData(imageUrl, userId)
      .subscribe(async (resp: any) => {
        this.processing = false;
        if (resp.status === true) {
          this.getProfileData();
          const response = await this.alertMessagesService.alertWithSuccessConfirmAction("Profile Image Uploaded successfully.");
          if (response == true) {
            this.updateProfile = false;
          }
        }
      });
  }

  updateProfileImage() {
    this.processing = true;
    this.configurationService
      .uploadProfileImage(this.profileImageFile, this.tenantId, this.userId)
      .subscribe((resp: any) => {
        let imageUrl = resp?.data?.[0]?.url;
        if (imageUrl) {
          const imageurl = { profile_pic_url: imageUrl };
          this.postProfileImage(imageurl, this.userId);
        }
      });
      this.isProfileChanged = false
  }
}
