import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-add-device",
  templateUrl: "./add-device.component.html",
  styleUrls: ["./add-device.component.scss"],
})
export class AddDeviceComponent implements OnInit {
  @Input() filteredOrgs: any[] = [];
  @Input() deviceData: any;
  @ViewChild("autoOrgDrop") autoOrgDrop: any;
  @ViewChild("autoStoreDrop") autoStoreDrop: any;
  @ViewChild("autoDeviceDrop") autoDeviceDrop: any;
  deviceID: any;

  isVisible: boolean = false;
  isEditing: boolean = false;                  
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  processing: boolean = false;
  keyword = "name";
  placeholderOrg = "Select the Organization";
  isExist: boolean = false;
  isMacExist: boolean =false;
  checkDevice: any;
  checkMac: any;


  placeholderstore = "Select the store";
  placeholderdeviceType = "Select the deviceType";

  deviceTypeName: any;
  deviceType: any;
  pageSize: number = this.configurationService.pageSize;
  currentPage: number = 1;
  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: any;
  selectedTenantName: string;
  selectedDeviceId: any;
  selectedDeviceTypeId: any;
  selectedDeviceTypeName: string;
  selectedStoreId: string;
  selectedStoreName: string;
  selectedDeviceName: string;
  addDeviceForm: FormGroup;
  errorMessageDisplay: string;

  orgData: any[] = [];

  storeData: any[] = [];
  filteredStores: any[] = [];
  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  loggedUser: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private alertMessagesService: AlertMessagesService,
    private configurationService: ConfigurationService    
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.addDeviceForm = this.fb.group({
      tenantId: ["", [Validators.required]],
      storeId: ["", [Validators.required]],
      deviceName: ["", [Validators.required,this.trimmedValueValidator]],
      deviceTypeId: ["", [Validators.required]],
      deviceDescription: ["", [Validators.required]],
      deviceMacAddress: ["", [Validators.required,this.trimmedValueValidator]],
      deviceIPAddress: ["", [Validators.required,this.trimmedValueValidator]],
    });  
  }
  get fc() {
    return this.addDeviceForm.controls;
  }

  ngOnInit() {
    this.assignDefaultValues();
    this.configByRole();
  }

  configByRole() {
    if (this.loggedUser == "csm") {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      this.filterOrgData();
    }
    if (this.loggedUser == "org-admin") {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
      this.selectedTenantId = this.defaultTenantId;
      this.getStoreData(this.selectedTenantId);
    }
  }
  trimmedValueValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;
    const trimmedValue = value.trim();
    if (trimmedValue === "") {
      return { emptyValue: true };
    }
    return null;
  }

  assignDefaultValues() {
    if (this.deviceData) {
      this.isEditing = !this.isEditing;
      this.addDeviceForm.patchValue({
        deviceDescription: this.deviceData?.description,
        deviceMacAddress: this.deviceData?.macAddress,
        deviceIPAddress: this.deviceData?.ipAddress,
        deviceName: this.deviceData?.deviceName,
      });
      this.selectedTenantId = this.deviceData?.tenantID;
      this.selectedTenantName = this.deviceData?.tenantName;
      this.selectedStoreId = this.deviceData?.storeID;
      this.selectedStoreName = this.deviceData?.storeName;
      this.selectedDeviceTypeId = this.deviceData?.deviceTypeID;
      this.selectedDeviceTypeName = this.deviceData?.deviceTypeName;
      this.selectedDeviceId = this.deviceData?.deviceID;
      this.checkDevice = this.deviceData?.deviceName;
      this.checkMac = this.deviceData?.macAddress;
    }
    this.getDeviceType();
  }

  toggleOrgPanel(e: any): void {
    e.stopPropagation();
    this.autoOrgDrop.open();
  }

  toggleStorePanel(e: any): void {
    e.stopPropagation();
    this.autoStoreDrop.open();
  }

  toggleDevicePanel(e: any): void {
    e.stopPropagation();
    this.autoDeviceDrop.open();
  }

  filterOrgData() {
    this.filteredOrgs.map((org: any) => (org.name = `${org.tenantName}`));
    this.filteredOrgs = this.filteredOrgs.filter(
      (orgData: any) => orgData.status === true
    );
  }
  changeOrganization(event: any) {
    this.selectedTenantId = event.tenantId;
    if (this.selectedTenantId) {
      this.getStoreData(this.selectedTenantId);
    } else {
      this.getStoreData(this.defaultTenantId);
    }
  }
  clearOrganization() {
    this.selectedTenantId = "";
    this.selectedStoreName = "";
    this.clearStore();
  }

  getStoreData(tenantId: any) {
    let defaultStatus : string = 'Active';
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((data: any) => {
        this.storeData = data.stores;
        this.storeData = data.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.storeData.map((store: any) => (store.name = `${store.storeName}`));
        this.filteredStores = this.storeData.filter(
          (storeData: any) => storeData.status === true
        );
      });
  }
  changeStore(event: any) {
    this.selectedStoreId = event.storeId;
    this.selectedStoreName = event.storeName;
  }

  clearStore() {
    this.selectedStoreId = "";
    this.selectedStoreName = "";
    this.clearDeviceType();
  }

  getDeviceType() {
    this.configurationService.getDeviceType().subscribe((data: any) => {
      this.deviceType = data.data;
      this.deviceType.map((device: any) => (device.name = `${device.name}`));
    });
  }

  changeDeviceType(event: any) {
    this.selectedDeviceTypeId = event.deviceTypeId;
    this.selectedDeviceName = event.name;
  }

  clearDeviceType() {
    this.selectedDeviceTypeId = "";
    this.selectedDeviceTypeName = "";
    // this.selectedDeviceName = "";
    // this.selectedDeviceId = "";
  }

  deviceNameCheckUnique() {
    // this.isExist = false;
    // if (!this.isEditing) {
      this.configurationService
        .getDeviceNameUnigue(this.checkDevice)
        .subscribe((resp: any) => {
          if (resp === true) {
            this.isExist = true;
          } else {
            this.isExist = false;
          }
        });
    // }
  }
  deviceMacCheckUnique() {
    if (!this.isEditing) {
      this.configurationService
        .getDeviceMacUnigue(this.checkMac)
        .subscribe((resp: any) => {
          if (resp === true) {
            this.isMacExist = true;
          } else {
            this.isMacExist = false;
          }
        });
    }
  }
  
  onSubmit() {
    this.isSubmitted = true;
    if(
      this.addDeviceForm.get("deviceIPAddress")?.value.trim() == "" ||
      this.addDeviceForm.get("deviceMacAddress")?.value.trim() == "" ||
      this.addDeviceForm.get("deviceName")?.value.trim() == "" ||
      this.selectedDeviceTypeId== ""
    ){
      return;
    }
    this.addDeviceForm.patchValue({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId,
      deviceTypeId: this.selectedDeviceTypeId,
    });

    if (this.deviceData) {
      this.addDeviceForm.patchValue({
        deviceID: this.deviceData?.deviceID,
        storeId: this.deviceData?.storeID,
        deviceTypeId: this.selectedDeviceTypeId,
      });
    }

    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 5000);
    if (this.deviceData?.storeID) {
      
      this.processing = true;
      this.configurationService
        .updateNewDevice(this.selectedDeviceId, this.addDeviceForm.value)
        .subscribe(
          (resp: any) => {
            this.processing = false;
            if (resp.status === true) {
              this.activeModal.close();
              this.alertMessagesService.alertWithSuccess(resp.message);
              this.isLoading = false;
            } else {
              this.errorMessageDisplay = resp.message;
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else {
      if(!this.isExist || !this.isMacExist){
        this.configurationService
        .createNewDevice(this.addDeviceForm.value)
        .subscribe(
          (resp: any) => {
            this.processing = false;
            if (resp.status === true) {
              this.activeModal.close();
              this.alertMessagesService.alertWithSuccess(resp.message);
              this.isLoading = false;
            } else {
              this.errorMessageDisplay = resp.message;
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
      }
  }
  }
}
