import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-circular-notification',
  templateUrl: './circular-notification.component.html',
  styleUrls: ['./circular-notification.component.scss']
})

export class CircularNotificationComponent {
  @Input() count: number = 0;
  @Output() notificationClickedEvent: EventEmitter<any> = new EventEmitter<any>();

  notificationClicked() {
    this.notificationClickedEvent.emit();
  }

}
