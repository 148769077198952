import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DatePipe } from "@angular/common";
import jwt_decode from "jwt-decode";
import { ConfigurationService } from "src/app/services/configuration.service";
import { CaseManagementService } from "src/app/services/case-management.service";

@Component({
  selector: "app-investigation-tab",
  templateUrl: "./investigation-tab.component.html",
  styleUrls: ["./investigation-tab.component.scss"],
})

export class InvestigationTabComponent {
  investigationData: any[] = [];
  customEntry: string = "";
  customNotes: any[] = [];
  customEntryData: any;
  customInvestigationData: any[] = [];
  @Input() selectedTask: any;
  investgationCustomData: any[] = [];
  userInfo: any;
  userId: any;
  defaultTenantId: any = localStorage.getItem("tenantId");
  profileData: any;
  @Output() investigationSubmitEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private configurationService: ConfigurationService,
    private caseManagementService: CaseManagementService
  ) {}

  ngOnInit(): void {
    this.getInvestigationData();
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.userInfo = jwt_decode(accessToken);
    this.userId = this.userInfo.login_uid;
    this.getLoginUserData();
  }

  getLoginUserData() {
    this.configurationService
      .getProfileData(this.userId, this.defaultTenantId)
      .subscribe((data: any) => {
        this.profileData = data.data;
      });
  }

  checkEntryValue() {
    this.customEntry.trim() === " ";
  }

  addInvestigation(): void {
    const datePipe = new DatePipe("en-US");
    const currentDateTime = new Date();
    const formattedDateTime = datePipe.transform(
      currentDateTime,
      "MMMM dd, yyyy hh:mm a"
    );
    const customEntryData = {
      user : {
        profile_pic: this.profileData?.profilePictureBase64 ?? "",
        name: this.profileData?.userName ?? '',
      },
      created_at: formattedDateTime,
      note: this.customEntry,
    };
    this.investgationCustomData.unshift(customEntryData);

    let payload = {
      "tabs_data": {
        "investigation": {
          "note": this.customEntry,
          "user": {
            "id": this.profileData?.userId ?? '',
            "name": this.profileData?.userName ?? '',
            "profile_pic": this.profileData?.profilePictureBase64 ?? ""
          }
        }
      }
    };
    this.customEntry = "";
    this.caseManagementService
      .updateCaseInfoTabsDetails(payload, this.selectedTask.id)
      .subscribe((resp: any) => {
        this.investigationSubmitEvent.emit(resp);
      });
  }

  getInvestigationData() {
    this.investgationCustomData = this.selectedTask?.tabs_data?.investigation ?? [];
  }
}
