import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor() { }

  isUserAuthorized(actions: string[], permissions: string[]): boolean {
    return permissions?.some(permission => actions?.includes(permission));
  }

}
