<div class="modal-header">
  <h4 class="modal-title">{{deviceData?.deviceID ? "Edit Device" : "Add Device"}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form [formGroup]="addDeviceForm" (ngSubmit)="onSubmit()" class="device-form">

    <div class="device-form-group mb-4" *ngIf="isCSAdmin">
      <div class="auto-dropdown">
        <label for="orgName">Organization Name: <sup>*</sup></label>
        <ng-autocomplete [data]="filteredOrgs" [searchKeyword]="keyword" (selected)="changeOrganization($event)"
          (inputCleared)='clearOrganization()' [placeholder]="placeholderOrg" [itemTemplate]="itemTemplateStaticOrg"
          class="form-control form-autocomplete" [(ngModel)]="selectedTenantName"
          [ngClass]="{'is-invalid': isSubmitted && !selectedTenantName}" [notFoundTemplate]="notFoundTemplateOrg"
          #autoOrgDrop [ngModelOptions]="{standalone: true}" [disabled]="isEditing">
        </ng-autocomplete>

        <input type="text" id="orgName" name="orgName" formControlName="tenantId" class="form-control"
          placeholder="Organization Name" required="true" [(ngModel)]="selectedTenantName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible">

        <span class="dropdown-down" (click)="toggleOrgPanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticOrg let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateOrg let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>

        <div *ngIf="addDeviceForm.get('tenantId')?.hasError('required')" class="invalid-feedback">
          Organization is required
        </div>

      </div>
    </div>

    <div class="device-form-group mb-4">
      <div class="auto-dropdown">
        <label for="storeName">Store Name:<sup>*</sup></label>
        <ng-autocomplete [data]="filteredStores | orderBy:'storeName'" [searchKeyword]="keyword"
          (selected)="changeStore($event)" (inputCleared)='clearStore()' [placeholder]="placeholderstore"
          [itemTemplate]="itemTemplateStaticStore" class="form-control form-autocomplete"
          [(ngModel)]="selectedStoreName" [notFoundTemplate]="notFoundTemplateStore" #autoStoreDrop
          [ngModelOptions]="{standalone: true}" [disabled]="isEditing"
          [ngClass]="{'is-invalid': isSubmitted && !selectedStoreName}">
        </ng-autocomplete>

        <input type="text" id="storeId" name="storeId" formControlName="storeId" class="form-control"
          placeholder="Store Name" required="true" [(ngModel)]="selectedStoreName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible">

        <span class="dropdown-down" (click)="toggleStorePanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticStore let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateStore let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>

        <div *ngIf="addDeviceForm.get('storeId')?.hasError('required')" class="invalid-feedback">
          Store is required
        </div>
      </div>
    </div>

    <div class="device-form-group mb-4">
      <label for="deviceName">Enter Device Name:<sup>*</sup></label>
      <input type="text" id="deviceName" formControlName="deviceName" class="form-control" 
        placeholder="Device Name" (input)="deviceNameCheckUnique()" [(ngModel)]="checkDevice"
        required="true" [ngClass]="{'is-invalid': isSubmitted && fc['deviceName'].errors}"
         [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing">
        <p class="isExistvalid" *ngIf="isExist" [hidden]="!isExist">Device Name Already Exist</p>
      <div *ngIf="isSubmitted && fc['deviceName'].errors" class="invalid-feedback">
        <div *ngIf="fc['deviceName'].errors['required'] || fc['deviceName'].errors['emptyValue']">Device Name is required</div>
      </div>
    </div>

    <div class="device-form-group mb-4">
      <div class="auto-dropdown">
        <label for="deviceType">Select The Device Type:<sup>*</sup></label>
        <ng-autocomplete [data]=" deviceType | orderBy:'name'" [searchKeyword]="keyword"
          (selected)="changeDeviceType($event)" (inputCleared)='clearDeviceType()' [placeholder]="placeholderdeviceType"
          [itemTemplate]="itemTemplateDeviceType" class="form-control form-autocomplete"
          [(ngModel)]="selectedDeviceTypeName" [ngModelOptions]="{standalone: true}"
          [ngClass]="{'is-invalid': isSubmitted && !selectedDeviceTypeName}"
          [notFoundTemplate]="notFoundTemplateDeviceType" #autoDeviceDrop>
        </ng-autocomplete>
        <input type="text" id="deviceTypeId1" name="deviceTypeId1" formControlName="deviceTypeId" class="form-control"
          placeholder="Device Type" required="true" [(ngModel)]="selectedDeviceTypeName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible">
        <span class="dropdown-down" (click)="toggleDevicePanel($event)"></span>
        <ng-template #itemTemplateDeviceType let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateDeviceType let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addDeviceForm.get('deviceTypeId')?.hasError('required')" class="invalid-feedback">
          Device Type is required
        </div>
      </div>
    </div>
    <div class="device-form-group mb-4">
      <label for="deviceDescription">Enter Device Description:</label>
      <textarea resize="false" rows="2" id="deviceDescription" formControlName="deviceDescription" class="form-control"
        placeholder="Device Description"> </textarea>
    </div>
    <div class="device-form-group mb-4">
      <label for="deviceMacAddress1">Enter MAC Address:<sup>*</sup></label>
      <input type="text" id="macAddress" formControlName="deviceMacAddress" class="form-control"
        placeholder="MAC Address" required="true" (input)="deviceMacCheckUnique()"
         [(ngModel)]="checkMac" 
        [ngClass]="{'is-invalid': isSubmitted && fc['deviceMacAddress'].errors}">
        <p class="isExistvalid" *ngIf="isMacExist" [hidden]="!isMacExist">Device Mac Address Already Exist</p>
      <div *ngIf="isSubmitted && fc['deviceMacAddress'].errors" class="invalid-feedback">
        <div *ngIf="fc['deviceMacAddress'].errors['required'] || fc['deviceMacAddress'].errors['emptyValue']">MAC Address is required</div>
      </div>
    </div>

    <div class="device-form-group mb-4">
      <label for="deviceIPAddress1">Enter Device IP Address:<sup>*</sup></label>
      <input type="text" id="deviceIPAddress1" formControlName="deviceIPAddress" class="form-control"
        placeholder="Device IP Address" required="true"
        [ngClass]="{'is-invalid': isSubmitted && fc['deviceIPAddress'].errors}">
      <div *ngIf="addDeviceForm.get('deviceIPAddress')?.hasError('required') || addDeviceForm.get('deviceIPAddress')?.hasError('emptyValue')" class="invalid-feedback">
        Device IP Address is required
      </div>
    </div>
    <div class="device-form-group mb-4">
      <p *ngIf="errorMessageDisplay" class="error-display">{{errorMessageDisplay}}</p>
    </div>
    <button type="submit" class="btn btn-add" [disabled]="isLoading || isExist || isMacExist">Submit</button>
  </form>
  <div class="spinner-outer" *ngIf="processing">
    <div class="loading-spinner"></div>
  </div>
</div>