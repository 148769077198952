<div class="modal-header">
  <h4 class="modal-title">{{ rolesData?.role_id ? 'Edit Role' : 'Add Role' }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">

  <form [formGroup]="addRoleForm" autocomplete="off" (ngSubmit)="submitForm()" class="stores-form">
    <div class="row">
      <div class="col-4">
        <div class="stores-form-group mb-4">
          <div class="auto-dropdown">
            <label for="orgName">Organization Name: </label>
            <ng-autocomplete [data]="filteredOrgs | orderBy:'tenantName'" [searchKeyword]="keyword"
              (selected)="changeOrganization($event)" [placeholder]="placeholderOrg"
              [itemTemplate]="itemTemplateStaticOrg" class="form-control" [(ngModel)]="selectedTenantName"
              [ngClass]="{'is-invalid': isSubmitted && fc['tenantId'].errors && !selectedTenantName}"
              [notFoundTemplate]="notFoundTemplateOrg" #autoOrgDrop [ngModelOptions]="{standalone: true}"
              (inputCleared)='clearOrganization()' [disabled]="isEditing">
            </ng-autocomplete>

            <input type="text" id="orgName" name="orgName" formControlName="tenantId" class="form-control"
              placeholder="Organization Name" required="true" [(ngModel)]="selectedTenantName" [readonly]="isEditing"
              [tabindex]="isEditing ? -1 : null" [disabled]="isEditing" *ngIf="isVisible" autocomplete="off">
            <span class="dropdown-down" (click)="toggleOrgPanel($event)" *ngIf="!isEditing"></span>
            <ng-template #itemTemplateStaticOrg let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>
            <ng-template #notFoundTemplateOrg let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
            <div *ngIf="addRoleForm.get('tenantId')?.hasError('required')" class="invalid-feedback">
              Organization Name is required
            </div>
          </div>
        </div>
        <div class="stores-form-group mb-4">
          <label for="roleName1">Role Name: <sup>*</sup> </label>
          <input type="text" id="roleName1" name="roleName" formControlName="roleName" [value]="rolesData?.roleId"
            class="form-control" placeholder="Role Name" required="true" [ngClass]="{'is-invalid': isSubmitted && fc['roleName'].errors}" autocomplete="off"
            >     
          <div *ngIf="addRoleForm.get('roleName')?.hasError('required')" class="invalid-feedback">
            Role Name is required
          </div>
        </div>
        <div class="stores-form-group mb-4" >
          <div class="auto-dropdown">
            <label for="rollName">Inherit Properities of roles</label>
            <ng-autocomplete [data]="rollsList | orderBy:'role_name'" [searchKeyword]="keyword"
              (selected)="changeRole($event)"  [placeholder]="placeholderRole" [itemTemplate]="itemTemplateStaticOrg"
              class="form-control" [(ngModel)]="selectedRollName" (inputCleared)='clearInheritedRole()'
              [ngClass]="{'is-invalid': isSubmitted && fc['role_id'].errors && !selectedRollName}"
              [notFoundTemplate]="notFoundTemplateOrg" #autoOrgDrop [ngModelOptions]="{standalone: true}"
              >
            </ng-autocomplete>

            <input type="text" id="rollName" name="rollName" formControlName="role_id" class="form-control"
              placeholder="Role Name" required="true" [(ngModel)]="selectedRollName" [readonly]="isEditing"
              [tabindex]="isEditing ? -1 : null"  *ngIf="isVisible" autocomplete="off">
            <span class="dropdown-down" (click)="toggleOrgPanel($event)" *ngIf="!isEditing"></span>
            <ng-template #itemTemplateStaticOrg let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>
            <ng-template #notFoundTemplateOrg let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
            <div *ngIf="addRoleForm.get('role_id')?.hasError('required')" class="invalid-feedback">
              Role is required
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="stores-form-group mb-4 pt-3">
          <label for="roleName1" class="">Resources <sup>*</sup></label>
          <input type="checkbox" [checked]="isAllResourcesSelected" (change)="selectAllResources($event)">
          Select/Deselect All
          <div class="border-box">
            <div *ngFor="let item of resourceList;">
              <div>
                <input type="checkbox" [checked]="item.isAllActionsSelected"
                  [indeterminate]="item.isActionsPartiallySelected" (change)="changeResource($event,item)">
                <span (click)="selectResource($event, item)">{{item.resource_name}}</span>
              </div>
              <!-- <br> -->
            </div>
          </div>
          
        </div>
      </div>
      <div class="col-4">
        <div class="stores-form-group mb-4 pt-3">
          <label for="roleName1" class="">Actions <sup>*</sup></label>
          <input type="checkbox" [checked]="actionList.isAllActionsSelected" (change)="selectAllActions($event)">
          Select/Deselect All
          <div class="border-box">
            <div *ngFor="let item of actionList?.actionsjson?.actionsList">
              <input type="checkbox" [checked]="item.selected" (change)="selectAction($event,item)">
              {{item.action_name}} <br>
            </div>
          </div>          
        </div>
        <div *ngIf="!isAnyResourceSelected" style="color: red;">
          Please select atleast one resource
        </div>
      </div>
      <div class="col-4">
        <div>
          <button type="submit"  class="btn btn-add">{{btnName}}</button>
        </div>
      </div>
    </div>
    <!-- <div>
      <button type="submit" class="btn btn-add">Create Role</button>
    </div> -->
  </form>
  <div class="spinner-outer" *ngIf="processing">
    <div class="loading-spinner"></div>
  </div>
</div>