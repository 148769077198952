<ag-grid-angular 
    class="ag-theme-alpine"
    style="width: 100%; height: 600px;"
    [rowData]="org" 
    [columnDefs]="colDefs"
    (gridReady)="onGridReady($event)"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    [paginationPageSizeSelector]="paginationPageSizeSelector"
    [defaultColDef]="defaultColDef"
    [rowHeight]="rowHeight"
></ag-grid-angular>