<div class="modal-header">
  <h4 class="modal-title">{{orgData?.tenantId ? "Edit Organization" : "Add Organization"}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form [formGroup]="addOrgForm" (ngSubmit)="submitForm()" class="org-form">

    <div class="organisation-form-group mb-4">
      <label for="orgName">Organization Name: <sup>*</sup></label>
      <input type="text" id="orgName" name="orgName" formControlName="tenantName" [value]="orgData?.tenantId"
        class="form-control" placeholder="Organization Name" required="true" [(ngModel)]="checkOrg"
        [ngClass]="{'is-invalid': isSubmitted && fc['tenantName'].errors || isExist}" [readonly]="isEditing"
        [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" (keyup)="orgNameCheckUnique()"
        autocomplete="off">
      <p class="isExistvalid" *ngIf="isExist">Organization Name Already Exist</p>
      <div *ngIf="addOrgForm.get('tenantName')?.hasError('required')" class="invalid-feedback">
        Organization Name is required
      </div>
    </div>

    <div class="organisation-form-group mb-4">
      <label for="address1">Address 1<sup>*</sup></label>
      <input type="text" id="address1" name="address1" formControlName="orgAddressL1" class="form-control"
        placeholder="Address1" [ngClass]="{'is-invalid': isSubmitted && fc['orgAddressL1'].errors}" autocomplete="off">
      <div *ngIf="addOrgForm.get('orgAddressL1')?.hasError('required')" class="invalid-feedback">
        Address is required
      </div>
    </div>

    <div class="organisation-form-group mb-4">
      <label for="address2">Address 2</label>
      <input type="text" id="address2" name="address2" formControlName="orgAddressL2" class="form-control"
        placeholder="Address2" autocomplete="off">
    </div>

    <div class="organisation-form-group custom-icon mb-4">
      <div class="auto-dropdown">
        <label for="countryId">Country: <sup>*</sup></label>

        <ng-autocomplete [data]="countriesList | orderBy:'countryName'" [searchKeyword]="keyword"
          (selected)="changeCountry($event)" [placeholder]="placeholderCountry"
          [itemTemplate]="itemTemplateStaticCountry" class="form-control form-autocomplete"
          [(ngModel)]="selectedCountryName"
          [ngClass]="{'is-invalid': isSubmitted && fc['countryUid'].errors && !selectedCountryName}"
          [notFoundTemplate]="notFoundTemplateCountry" #autoCountryDrop (inputCleared)="clearCountry()"
          [ngModelOptions]="{standalone: true}" autocomplete="off">
        </ng-autocomplete>
        <input type="text" id="countryId" name="countryId" formControlName="countryUid" class="form-control"
          placeholder="Country Name" required="true" [(ngModel)]="selectedCountryName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" autocomplete="off">
        <span class="dropdown-down" (click)="toggleCountryPanel($event)"></span>
        <ng-template #itemTemplateStaticCountry let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateCountry let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addOrgForm.get('countryUid')?.hasError('required')" class="invalid-feedback">
          Country is required
        </div>
      </div>
    </div>

    <div class="organisation-form-group custom-icon mb-4">
      <div class="auto-dropdown">
        <label for="stateId" value="state">State: <sup>*</sup></label>
        <ng-autocomplete [data]="statesList | orderBy:'stateName'" [searchKeyword]="keyword"
          (selected)="changeState($event)" [placeholder]="placeholderState" [itemTemplate]="itemTemplateStaticState"
          class="form-control form-autocomplete" [(ngModel)]="selectedStateName"
          [ngClass]="{'is-invalid': isSubmitted && fc['stateUid'].errors && !selectedStateName}"
          [notFoundTemplate]="notFoundTemplateState" #autoStateDrop (inputCleared)="clearState()"
          [ngModelOptions]="{standalone: true}" autocomplete="off">
        </ng-autocomplete>
        <input type="text" id="stateId" name="stateId" formControlName="stateUid" class="form-control"
          placeholder="State Name" required="true" [(ngModel)]="selectedStateName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" autocomplete="off">
        <span class="dropdown-down" (click)="toggleStatePanel($event)"></span>
        <ng-template #itemTemplateStaticState let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateState let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addOrgForm.get('stateUid')?.hasError('required')" class="invalid-feedback">
          State is required
        </div>
      </div>
    </div>

    <div class="organisation-form-group custom-icon mb-4">
      <div class="auto-dropdown">
        <label for="cityId">City: <sup>*</sup></label>
        <ng-autocomplete [data]="cityList | orderBy:'cityName'" [searchKeyword]="keyword"
          (selected)="changeCity($event)" [placeholder]="placeholderCity" [itemTemplate]="itemTemplateStaticCity"
          class="form-control form-autocomplete" [(ngModel)]="selectedCityName"
          [ngClass]="{'is-invalid': isSubmitted && fc['cityUid'].errors && !selectedCityName} "
          [notFoundTemplate]="notFoundTemplateCity" #autoCityDrop [ngModelOptions]="{standalone: true}"
          (inputCleared)="clearCity()" autocomplete="off">
        </ng-autocomplete>
        <input type="text" id="cityId" name="cityId" formControlName="cityUid" class="form-control"
          placeholder="City Name" required="true" [(ngModel)]="selectedCityName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible" autocomplete="off">
        <span class="dropdown-down" (click)="toggleCityPanel($event)"></span>
        <ng-template #itemTemplateStaticCity let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateCity let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addOrgForm.get('cityUid')?.hasError('required')" class="invalid-feedback">
          City is required
        </div>
      </div>
    </div>

    <div class="organisation-form-group mb-4">
      <label for="email">Business Email 1: <sup>*</sup></label>
      <input type="email" id="email" name="email" required="true" formControlName="orgEmail1" class="form-control"
        placeholder="Business Email 1" [ngClass]="{'is-invalid': isSubmitted && fc['orgEmail1'].errors}" appNoUppercase
        autocomplete="off">
      <div *ngIf="addOrgForm.get('orgEmail1')?.hasError('required')" class="invalid-feedback">
        Email Id is required
      </div>
      <div *ngIf="addOrgForm.get('orgEmail1')?.hasError('pattern')" class="invalid-feedback">
        Please enter a valid email address
      </div>
    </div>

    <div class="organisation-form-group mb-4">
      <label for="email2">Business Email 2:</label>
      <input type="email" id="email2" name="email2" formControlName="orgEmail2" class="form-control"
        placeholder="Business Email 2" [ngClass]="{'is-invalid': isSubmitted && fc['orgEmail2'].errors}"
         appNoUppercase autocomplete="off">
        <div *ngIf="addOrgForm.get('orgEmail2')?.hasError('pattern')" class="invalid-feedback">
          Please enter a valid email address
        </div>
    </div>

    <div class="organisation-form-group mb-4">
      <label for="contact1">Business Phone 1 <sup>*</sup></label>

      <div class="country-box">
        <select class="icon-arrow country-code" id="countryCodeId2" formControlName="countryCodeId"
          (change)="changeCountryCode($event)" [(ngModel)]="selectedCountryCode"
          [ngClass]="{'is-invalid': isSubmitted && fc['countryCodeId'].errors}">
          <option *ngIf="!isEditing" [disabled]="true" [selected]="true" value="">-Country-</option>
          <option *ngFor="let code of countryCodeList" [selected]="true" [value]="code.countryUID">
            {{code.countryCode}}
          </option>
        </select>
        <input type="text" id="contact1" name="contact1" required="true" formControlName="orgPhoneNo1"
          class="contact-input form-control" placeholder="Business Phone 1" [maxlength]="10" [minlength]="10" size="10"
          [ngClass]="{'is-invalid': isSubmitted && fc['orgPhoneNo1'].errors}" [appNumericOnly]="true">
        <div
          *ngIf="addOrgForm.get('countryCodeId')?.hasError('required') || addOrgForm.get('orgPhoneNo1')?.hasError('required')"
          class="invalid-feedback">
          Phone Number with Country code is required
        </div>
        <div
          *ngIf="addOrgForm.get('orgPhoneNo1')?.hasError('minLength') && addOrgForm.get('orgPhoneNo1')?.value?.length > 0"
          class="invalid-feedback">
          Phone Number should be valid
        </div>
      </div>

    </div>

    <div class="organisation-form-group mb-4">
      <label for="contact2">Business Phone 2</label>
      <input type="text" id="contact2" name="contact2" formControlName="orgPhoneNo2" class="form-control"
        placeholder=" Business Phone 2" [maxlength]="10" [minlength]="10" size="10" [appNumericOnly]="true"
        autocomplete="off">
    </div>


    <div class="user-form-group custom-icon mb-3">
      Status:
      <label class="toggle-button">
        <div class="shadow-input" *ngIf="isEditing === false"></div>
        <input [disabled]="isEditing === false" class="form-check-input" type="checkbox" formControlName="status"
          [(ngModel)]="isChecked">
        <span class="slider round"></span>
      </label>
    </div>
    <button type="submit" class="btn btn-add">Submit</button>
  </form>
  <div class="spinner-outer" *ngIf="processing">
    <div class="loading-spinner"></div>
  </div>
</div>