import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "secondsToMinutes",
})
export class SecondsToMinutesPipe implements PipeTransform {
  transform(value: number): string {
    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);
    const minutesDisplay = minutes > 0 ? `${this.padZero(minutes)}m` : "0m";
    const secondsDisplay = seconds > 0 ? ` ${this.padZero(seconds)}s` : " 0s";

    return `${minutesDisplay}${secondsDisplay}`;
  }
  private padZero(value: number): string {
    return value.toString().padStart(2, "0");
  }
}

@Pipe({
  name: "secondsToHours",
})
export class SecondsToHoursPipe implements PipeTransform {
  transform(value: number): string {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = value % 60;

    return `${hours}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  private padZero(value: number): string {
    return value.toString().padStart(2, "0");
  }
}

@Pipe({
  name: "resolutionTime",
})
export class ResolutionTimePipe implements PipeTransform {
  transform(value: number): string {
    const seconds = Math.floor(value);
    return `${this.padZero(seconds)}`;
  }
  private padZero(value: number): string {
    return value.toString().padStart(2, "0");
  }
}
