<form [formGroup]="oosUseCaseForm" (ngSubmit)="onSubmit()" class="usecase-config-form">
  <div class="product-config">
    <h6 class="mb-3">Product ROI Dimensions</h6>
    <div class="d-flex justify-content-between mb-4">
      <div class="dimension-input usecase-form-group">
        <label for="xMin">X Min: <sup>*</sup></label>
        <input type="number" id="xMin" name="xMin" required="true" [(ngModel)]="selectedProductROI.xMin"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="X Min" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.xMin}">
        <div *ngIf="isSubmitted && !selectedProductROI.xMin" class="invalid-feedback">
          X Min value Required
        </div>
      </div>
      <div class="dimension-input usecase-form-group">
        <label for="xMax">X Max: <sup>*</sup></label>
        <input type="number" id="xMax" name="xMax" required="true" [(ngModel)]="selectedProductROI.xMax"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="X Max" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.xMax}">
        <div *ngIf="isSubmitted && !selectedProductROI.xMax" class="invalid-feedback">
          X Max value Required
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-4">
      <div class="dimension-input usecase-form-group">
        <label for="yMin">Y Min: <sup>*</sup></label>
        <input type="number" id="yMin" name="yMin" required="true" [(ngModel)]="selectedProductROI.yMin"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Y Min" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.yMin}">
        <div *ngIf="isSubmitted && !selectedProductROI.yMin" class="invalid-feedback">
          Y Min value Required
        </div>
      </div>
      <div class="dimension-input usecase-form-group">
        <label for="yMax">Y Max: <sup>*</sup></label>
        <input type="number" id="yMax" name="yMax" required="true" [(ngModel)]="selectedProductROI.yMax"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Y Max" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedProductROI.yMax}">
        <div *ngIf="isSubmitted && !selectedProductROI.yMax" class="invalid-feedback">
          Y Max value Required
        </div>
      </div>
    </div>
  </div>

  <div class="person-config">
    <h6 class="mb-3">Person ROI Dimensions</h6>
    <div class="d-flex justify-content-between mb-4">
      <div class="dimension-input usecase-form-group">
        <label for="xMin">X Min: <sup>*</sup></label>
        <input type="number" id="xMin" name="xMin" required="true" [(ngModel)]="selectedPersonROI.xMin"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="X Min" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedPersonROI.xMin}">
        <div *ngIf="isSubmitted && !selectedPersonROI.xMin" class="invalid-feedback">
          X Min value Required
        </div>
      </div>
      <div class="dimension-input usecase-form-group">
        <label for="xMax">X Max: <sup>*</sup></label>
        <input type="number" id="xMax" name="xMax" required="true" [(ngModel)]="selectedPersonROI.xMax"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="X Max" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedPersonROI.xMax}">
        <div *ngIf="isSubmitted && !selectedPersonROI.xMax" class="invalid-feedback">
          X Max value Required
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-4">
      <div class="dimension-input usecase-form-group">
        <label for="yMin">Y Min: <sup>*</sup></label>
        <input type="number" id="yMin" name="yMin" required="true" [(ngModel)]="selectedPersonROI.yMin"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Y Min" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedPersonROI.yMin}">
        <div *ngIf="isSubmitted && !selectedPersonROI.yMin" class="invalid-feedback">
          Y Min value Required
        </div>
      </div>
      <div class="dimension-input usecase-form-group">
        <label for="yMax">Y Max: <sup>*</sup></label>
        <input type="number" id="yMax" name="yMax" required="true" [(ngModel)]="selectedPersonROI.yMax"
          [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Y Max" disabled
          [ngClass]="{'is-invalid': isSubmitted && !selectedPersonROI.yMax}">
        <div *ngIf="isSubmitted && !selectedPersonROI.yMax" class="invalid-feedback">
          Y Max value Required
        </div>
      </div>
    </div>
  </div>

  <div class="business-config">
    <h6 class="mb-3">Business Configuration</h6>
    <div class="d-flex justify-content-between mb-4">
      <div class="usecase-form-group resolution-input">
        <label for="fullCountId">Full Count: <sup>*</sup></label>
        <input type="number" id="fullCountId" name="fullCountId" formControlName="capacity" class="form-control"
          placeholder="Full Count" [ngClass]="{'is-invalid': isSubmitted && fc['capacity'].errors}">

        <div *ngIf="oosUseCaseForm.get('capacity')?.hasError('required')" class="invalid-feedback">
          Full count is Required
        </div>
      </div>

      <div class="usecase-form-group resolution-input">
        <label for="upperLimitPercentageId">Reduction Full Count in %: <sup>*</sup></label>
        <input type="text" id="upperLimitPercentageId" name="upperLimitPercentageId" formControlName="upperLimitPerc"
          class="form-control" placeholder="Redection in full count upper limit percent"
          [ngClass]="{'is-invalid': isSubmitted && fc['upperLimitPerc'].errors || oosUseCaseForm.get('upperLimitPerc')?.hasError('digitCountExceeded')}"
          [appDigitCount]="100" maxlength="3">

        <div *ngIf="oosUseCaseForm.get('upperLimitPerc')?.hasError('required')" class="invalid-feedback">
          Reduction in Full Count in % is Required
        </div>
        <div *ngIf="oosUseCaseForm.get('upperLimitPerc')?.hasError('digitCountExceeded')" class="invalid-percentage">
          Reduction in Full Count max 100%.
        </div>
      </div>
    </div>
  </div>


  <div class="user-form-group custom-icon mb-3">
    Status:
    <label class="toggle-button">
      <div class="shadow-input"></div>
      <input class="form-check-input" type="checkbox" formControlName="status" [(ngModel)]="isChecked">
      <span class="slider round"></span>
    </label>
  </div>

  <button type="submit" class="btn btn-add" [disabled]="isLoading">Submit</button>
</form>