<div class="row">
  <section class="login">
    <div class="wrapper">
      <div class="login-form form">
        <div class="form-logo mb-3">
          <img src="../../../../assets/logo.png" alt="">
        </div>
        <!-- <h1>Login</h1> -->
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

          <div class="form-control-container">
            <div class="form-group form-relative mb-2">
              <label for="username" class="form-label">
                Username
              </label>
              <div class="login-icon">
                <input type="text" class="form-control username"
                  [ngClass]="{ 'is-invalid': isSubmitting && fc['username'].errors }" id="username"
                  placeholder="Username" formControlName="username" />
              </div>
              <div *ngIf="errorMessage" class="invalid-feedback">
                <p>Please enter a valid Username.</p>
              </div>
            </div>
          </div>

          <div class="form-control-container">
            <div class="form-group form-relative mb-2">
              <label for="password" class="form-label">Password</label>
              <div class="password-icon">
                <input type="{{ showAuthToken ? 'text' : 'password' }}" class="form-control password"
                  [ngClass]="{ 'is-invalid': isSubmitting && fc['password'].errors }" id="password"
                  placeholder="Password" formControlName="password" (input)="verifyPassword()" />
                <span (click)="togglePasswordVisibility()" class="visible-auth" id="toogleIcon">
                  <img src="../../../../../../assets/icons/hide.png" alt="visible" *ngIf="visibleIcon" />
                  <img src="../../../../../../assets/icons/show.png" alt="invisible" *ngIf="!visibleIcon" />
                </span>
              </div>
              <div *ngIf="errorMessage" class="invalid-feedback">
                <p>Please check the password.</p>
              </div>
            </div>
          </div>
          <div class="form-control-container text-center login-error" *ngIf="errorMessage">
            <p>{{errorMessage}}</p>
          </div>

          <button type="button" type="submit">Login</button>
          <div class="forgot">
            <a class="forgot-link" routerLink="/forgot-password">Forgot Password?</a>
          </div>
        </form>
        <div class="spinner-outer" *ngIf="processing">
          <div class="loading-spinner"></div>
        </div>

      </div>
    </div>
  </section>

</div>