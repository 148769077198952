import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import { EmailTemplatePreviewComponent } from "../email-template-preview/email-template-preview.component";
import { QuillEditorComponent } from 'ngx-quill';
import { DatePipe } from "@angular/common";

interface ModeOfAlert {
  alertUID: number;
  alertMode: string;
  identifier: string;
  isRecipientNeeded: boolean;
}

interface AlertMappingData {
  storeId: string;
  useCaseId: string;
  alertId: any;
  alertIdentifier: string;
  uniqueId: number;
  alertUcUID: number;
  ucNotificationWFlow: {
    threshold : number;
    order: number;
    receiver: any;
    message: any;
    notificationData: any;
    templateId: any;
  };
  status: boolean;
}

@Component({
  selector: "app-add-useralert",
  templateUrl: "./add-useralert.component.html",
  styleUrls: ["./add-useralert.component.scss"],
})
export class AddUseralertComponent implements OnInit {
  @Input() filteredOrgs: any[] = [];
  @Input() alertData: any;
  @ViewChild("autoOrgDrop") autoOrgDrop: any;
  @ViewChild("autoStoreDrop") autoStoreDrop: any;
  @ViewChild("autoUseCase") autoUseCase: any;
  @ViewChild("autoModeOfAlert") autoModeOfAlert: any;
  @ViewChild("recipientDrop") recipientDrop: any;
  @ViewChild("textareaEditor") textareaEditor: any;
  @ViewChild("subjectEditor") subjectEditor: any;
  @ViewChild(QuillEditorComponent) editor: QuillEditorComponent;
  quilleditor:any;

  deviceID: any;

  isVisible: boolean = false;
  isEditing: boolean = false;
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  isChecked: boolean = true;
  isExist: boolean = false;
  isPriorityCleared: boolean = true;

  keyword = "name";
  placeholderOrg = "Select the Organization";

  placeholderstore = "Select the store";
  placeholderdeviceType = "Select the deviceType";
  placeholderRecipient = "Select the Recipient";
  placeholderUseCase = "Select the UseCase";
  placeholderModeOfAlert = "Mode of Alert";

  deviceTypeName: any;
  deviceType: any;
  pageSize: number = this.configurationService.pageSize;
  currentPage: number = 1;
  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: any;
  selectedTenantName: string;
  selectedStoreId: string;
  selectedStoreName: any;
  selectedStoreAddress: string;
  selectedUserId: any;
  selectedUserName: string;
  selectAlertId: number;

  addAlertForm: FormGroup;
  selectedRecipientIds: any;
  selectedRecipientNames: any = "";
  selectedModeOfAlert: any = "";
  selectedUseCase: string;
  selectedPriorityLevel: any = "";
  transformedAlertData: any;
  useCaseList: any[] = [];
  orgData: any[] = [];
  storeData: any;
  filteredStores: any[] = [];
  usersData: any[] = [];
  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  visibleEmail: boolean = false;
  visiblePhone: boolean = false;
  visibleRecipient: boolean = false;
  processing: boolean = false;
  loggedUser: any;
  selectedUseCaseId: string;
  selectedUseCaseName: any;
  selectedAlertIdentifier: string;
  selectedUseCaseMessage: string = "";
  selectedEmailSubject: string = "";
  selectedEmailMessage: string = "";
  selectedSmsMessage: string = "";
  selectedThreshold: number;
  selectedAlertUcUID: any;
  userEmail: string;
  userPhoneNo: string;
  priorityLevels = [
    { id: 1, value: "Level1" },
    { id: 2, value: "Level2" },
    { id: 3, value: "Level3" },
  ];

  modeOfAlerts: ModeOfAlert[] = [];

  isMessageEditable: boolean = false;
  dynamicParamsList: any;
  isModeOfAlertEmail: boolean = false;
  isModeOfAlertSms: boolean = false;
  startPosition: number = -1;
  endPosition: number = -1;
  selectedPosition: string = '';
  showMessageError: boolean = false;
  emailSubjectRequired: boolean = false;
  emailMessageRequired: boolean = false;
  smsMessageRequired: boolean = false;
  selectedUseCaseObj: any;
  paramsError = 'Please ensure all the params inside {{}} brackets are valid.';
  emailHtml: string = `<!DOCTYPE html>
        <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title></title>
          </head>
          <body style="margin: 0; padding: 0 30px; width: 654px; background: rgb(255, 255, 255); font-family: Poppins, sans-serif;">
            <div style="background: rgb(255, 255, 255); padding: 24px 0 0; border-radius: 10px; border: 2px solid rgb(218 216 216);">
              <div style="text-align: center; padding: 0 85px 32px;">
                  <img src="https://retailsaasuat.blob.core.windows.net/$web/assets/alert-logo.svg" width="72" height="72">
              </div>
              <div style="text-align: center; padding: 0 85px 32px;">
                  <p style="margin: 0;font-size: 23px;line-height: 34.5px;font-weight: 600; color: rgb(0, 0, 0); width: 348px; margin: auto;">{{emailSubWithPlaceholders}}</p>
              </div>
              
              <div style="text-align: center; padding: 0 85px; background: rgb(244, 244, 244);">
                <div style="padding: 40px 19px; font-size: 16px;line-height: 24px;font-weight: 400; color: rgb(0, 0, 0);">
                  {{emailMessageWithPlaceholders}}
                </div>
              </div>
              <div style="text-align: center; padding: 32px 85px 0;">
                <a href="{{appOrigin}}" target="_blank"> 
                  <img src="https://mockups.centific.com/pitaya-ai/logo/logo/" width="116" height="38">
                </a>
                <p style="font-size: 16px;line-height: 24px;font-weight: 400; color: rgb(175, 170, 170);">©2024 Centific. All Rights Reserved.</p>
              </div>
            </div>
        </body>
      </html>`;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private alertMessagesService: AlertMessagesService,
    private configurationService: ConfigurationService,
    private modalService: NgbModal
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.addAlertForm = this.fb.group({
      tenantId: ["", [Validators.required]],
      storeId: ["", [Validators.required]],
      useCaseId: ["", [Validators.required]],
      modeOfAlert: ["", [Validators.required]],
      order: ["", [Validators.required]],
      receiver: ["", [Validators.required]],
      threshold:[],
      message: [""],
      emailSubject: [{ value:'', disabled: true}],
      emailMessage: [{ value:'', disabled: true}],
      smsMessage: [{ value:'', disabled: true}],
      status: [""],
    });
  }
  get fc() {
    return this.addAlertForm.controls;
  }

  ngOnInit(): void {
    this.assignDefaultValues();
    this.configByRole();
  }

  configByRole() {
    if (this.loggedUser === "csm") {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      this.filterOrgData();
    }
    if (this.loggedUser === "org-admin") {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
      this.selectedTenantId = this.defaultTenantId;
      this.getStoreData(this.selectedTenantId);
    }
  }

  assignDefaultValues() {
    if (this.alertData) {
      this.isLoading = true; 
      this.addAlertForm.patchValue({
        message: this.alertData?.message,
        order: this.alertData?.order,
        modeOfAlert: this.alertData?.alertMode,
        emailSubject: this.alertData?.notificationData?.subject ?? '',
        emailMessage: this.alertData?.notificationData?.message ?? '',
        smsMessage: this.alertData?.notificationData?.message ?? ''
      });
      this.isEditing = !this.isEditing;
      this.selectedTenantId = this.alertData?.tenantID;
      this.selectedTenantName = this.alertData?.tenantName;
      this.selectedStoreId = this.alertData?.storeId;
      this.selectedStoreName = this.alertData?.storeName;
      this.selectAlertId = this.alertData?.alertUID;
      this.selectedAlertUcUID = this.alertData?.alertUcUID;
      this.selectedModeOfAlert = this.alertData?.alertMode;
      this.selectedRecipientIds = this.alertData?.userIds;
      this.selectedRecipientNames = this.alertData?.userIds;
      this.selectedUseCaseId = this.alertData?.useCaseId;
      this.selectedUseCaseName = this.alertData?.useCaseName;
      this.selectedPriorityLevel = this.alertData?.order;
      this.selectedUseCaseMessage = this.alertData?.message;
      this.selectedEmailSubject = this.replaceHtmlTagsFromText(this.alertData?.notificationData?.subject ?? '');
      this.selectedEmailMessage = this.alertData?.notificationData?.message ?? '';
      this.selectedSmsMessage = this.replaceHtmlTagsFromText(this.alertData?.notificationData?.message ?? '');
      this.selectedThreshold = this.alertData?.threshold;
      this.selectedAlertIdentifier = this.alertData?.alertIdentifier;
      this.isChecked = this.alertData?.status;
      this.getUseCaseList();
      this.getUsersData(this.selectedStoreId);
      this.getStoreDataForAddress(this.selectedTenantId);

      if (
        this.selectedAlertIdentifier == "SMS" ||
        this.selectedAlertIdentifier == "Email"
      ) {
        this.visibleRecipient = true;
        this.isModeOfAlertEmail = (this.selectedAlertIdentifier == "Email") ? true : false; 
        this.isModeOfAlertSms = (this.selectedAlertIdentifier == "SMS") ? true : false;
      } else {
        this.visibleRecipient = false;
        this.isModeOfAlertEmail = false; 
        this.isModeOfAlertSms = false;
      }
    }
  }

  getStoreDataForAddress(tenantId: any) {
    let defaultStatus : string = 'Active';
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((data: any) => {
        let filteredStores = data?.stores?.filter(
          (storeData: any) => (storeData.status === true && this.selectedStoreId == storeData.storeId)
        );
        this.selectedStoreAddress = ( filteredStores?.length > 0) ? filteredStores[0]['siteAddressL1'] : '';
      });
  }

  toggleOrgPanel(e: any): void {
    e.stopPropagation();
    this.autoOrgDrop.open();
  }
  toggleStorePanel(e: any): void {
    e.stopPropagation();
    this.autoStoreDrop.open();
  }

  toggleUseCasePanel(e: any): void {
    e.stopPropagation();
    this.autoUseCase.open();
  }

  toggleModeOfAlertPanel(e: any): void {
    e.stopPropagation();
    this.autoModeOfAlert.open();
  }

  toggleRecipientPanel(e: any): void {
    e.stopPropagation();
    this.recipientDrop.open();
  }

  filterOrgData() {
    this.filteredOrgs.map((org: any) => (org.name = `${org.tenantName}`));
    this.filteredOrgs = this.filteredOrgs.filter(
      (orgData: any) => orgData.status === true
    );
  }
  changeOrganization(event: any) {
    this.selectedTenantId = event.tenantId;
    this.isPriorityCleared = true;
    this.getStoreData(this.selectedTenantId);
  }

  alertCheckUnique() {
    this.isExist = false;
    if( !this.selectedModeOfAlert ) {
      return;
    }
    this.configurationService
      .alertCheckUnique(
        this.selectedStoreId,
        this.selectedModeOfAlert,
        this.selectedUseCaseId,
        this.selectedPriorityLevel
      )
      .subscribe((resp: any) => {
        if (resp === true) {
          this.isExist = true;
        } else {
          this.isExist = false;
        }
      });
  }

  getStoreData(tenantId: any) {
    let defaultStatus : string = 'Active';
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((data: any) => {
        this.storeData = data.stores;
        this.storeData = data.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.storeData.map((store: any) => (store.name = `${store.storeName}`));
        this.filteredStores = this.storeData.filter(
          (storeData: any) => storeData.status === true
        );
      });
  }

  changeStore(event: any) {
    this.selectedStoreId = event.storeId;
    this.selectedStoreName = event.storeName;
    this.selectedStoreAddress = event.siteAddressL1;
    this.isPriorityCleared = true;
    this.selectedPriorityLevel = "";
    this.getUseCaseList();
    this.getUsersData(this.selectedStoreId);
    this.getAlertModes(this.selectedStoreId);
  }

  getAlertModes(storeId: any) {
    this.configurationService
      .getAlertModesByStore(storeId)
      .subscribe((resp: any) => {
        this.modeOfAlerts = resp.data;
        this.modeOfAlerts.map((mode: any) => (mode.name = `${mode.alertMode}`));
      });
  }
  clearStores() {
    this.storeData = [];
    this.selectedStoreName = "";
    this.selectedStoreAddress = "";
    this.useCaseList = [];
    this.isPriorityCleared = true;
    this.selectedUseCaseName = "";
    this.selectedPriorityLevel = "";
    this.selectedModeOfAlert = "";    
    this.selectedUseCaseMessage = "";
    this.selectedEmailSubject = "";
    this.selectedEmailMessage = "";
    this.selectedSmsMessage = "";
    this.clearAlertModes();
    this.clearUsecases();
  }

  clearUsecases(){
    this.isPriorityCleared = true;
    this.selectedUseCaseName = "";
    this.selectedPriorityLevel = "";
    this.selectedModeOfAlert = "";
    this.selectedUseCaseMessage = "";
    this.selectedEmailSubject = "";
    this.selectedEmailMessage = "";
    this.selectedSmsMessage = "";
    this.clearAlertModes();
  }

  clearAlertModes() {
    this.modeOfAlerts = [];
    this.selectedModeOfAlert = "";
    this.usersData = [];
    this.selectedRecipientNames = [];
    this.isExist = false;
    this.isModeOfAlertEmail = false; 
    this.isModeOfAlertSms = false;
    this.getAlertModes(this.selectedStoreId);
    this.getUsersData(this.selectedStoreId);
  }

  getUsersData(storeId: any) {
    this.configurationService
      .getAlertUsersData(storeId, this.currentPage, this.pageSize)
      .subscribe((data: any) => {
        this.usersData = data?.data;
      });
  }

  changeRecipient(event: any) {
    this.selectedRecipientIds = event;
  }

  getUseCaseList() {
    this.configurationService.getAllUseCaseData().subscribe((resp: any) => {
      this.useCaseList = resp.data;
      this.useCaseList.map((useCase: any) => {
        useCase.name = `${useCase.useCaseName}`
        if( useCase?.useCaseName === this.alertData?.useCaseName ) {
          this.selectedUseCaseId = useCase.useCaseUID;
          this.selectedUseCaseObj = useCase;
        }
      });
      if( this.selectedUseCaseId && this.selectedTenantId ) {
        this.makeSubjectMessageRequired();
        this.getUseCaseDynamicParams();
      } else {
        // since submit button will be disable as soon as popup opens and enables after fetching dynamic params data in side method
        // so if it doesnot go inside the method we should enable the submit button
        this.isLoading = false; 
      }
    });
  }
  changeUseCase(event: any) {
    this.isPriorityCleared = true;
    this.selectedUseCaseId = event.useCaseUID;
    this.selectedUseCaseName = event.useCaseName;
    this.selectedUseCaseMessage = event.defaultMessage;
    this.selectedUseCaseObj = event;
    this.selectedEmailSubject = this.replaceHtmlTagsFromText(event?.defaultEmail?.subject ?? '');
    this.selectedEmailMessage = event?.defaultEmail?.message ?? '';
    this.selectedSmsMessage = this.replaceHtmlTagsFromText(event?.defaultEmail?.smsmessage ?? '');
    // this.selectedModeOfAlert = ""
    this.alertCheckUnique();
    if( this.selectedUseCaseId && this.selectedTenantId ) {
      this.getUseCaseDynamicParams();
    } else {
      // since submit button will be disable as soon as popup opens and enables after fetching dynamic params data in side method
      // so if it doesnot go inside the method we should enable the submit button
      this.isLoading = false; 
    }
  }

  changePriorityLevel() {
    this.isPriorityCleared = true;
    this.selectedUseCaseMessage = "";
    this.selectedEmailSubject = "";
    this.selectedEmailMessage = "";
    this.selectedSmsMessage = "";
    this.clearAlertModes();
    // this.selectedPriorityLevel = event.target.value;
  }

  changeModeOfAlert(event: any) {
    this.selectedModeOfAlert = event.alertUID;
    this.selectedAlertIdentifier = event.identifier;
    this.alertCheckUnique();
    if (
      this.selectedAlertIdentifier == "SMS" ||
      this.selectedAlertIdentifier == "Email"
    ) {
      this.visibleRecipient = true;
      this.isModeOfAlertEmail = (this.selectedAlertIdentifier == "Email") ? true : false; 
      this.isModeOfAlertSms = (this.selectedAlertIdentifier == "SMS") ? true : false;
    } else {
      this.visibleRecipient = false;
      this.isModeOfAlertEmail = false; 
      this.isModeOfAlertSms = false;
    }
    this.makeSubjectMessageRequired();
    this.selectedEmailSubject = this.replaceDynamicParamsInTextValueToKey(this.selectedEmailSubject); // ex. here we need change {{storename}} to {{Store Name}}
    this.selectedEmailMessage = this.replaceDynamicParamsInTextValueToKey(this.selectedEmailMessage); // ex. here we need change {{storename}} to {{Store Name}}
    this.selectedSmsMessage = this.replaceDynamicParamsInTextValueToKey(this.selectedSmsMessage); // ex. here we need change {{storename}} to {{Store Name}}
  }

  makeSubjectMessageRequired() {
    if( this.isModeOfAlertEmail == true ) {
      this.emailSubjectRequired = true;
      this.emailMessageRequired = true;
      this.smsMessageRequired = false;
      this.addAlertForm.controls['emailSubject'].setValidators(Validators.required);
      this.addAlertForm.controls['emailMessage'].setValidators(Validators.required);
      this.addAlertForm.controls['smsMessage'].clearValidators();
    } else if ( this.isModeOfAlertSms == true ) {
      this.emailSubjectRequired = false;
      this.emailMessageRequired = false;
      this.smsMessageRequired = true;
      this.addAlertForm.controls['emailSubject'].clearValidators();
      this.addAlertForm.controls['emailMessage'].clearValidators();
      this.addAlertForm.controls['smsMessage'].setValidators(Validators.required);
    } else {
      this.emailSubjectRequired = false;
      this.emailMessageRequired = false;
      this.smsMessageRequired = false;
      this.addAlertForm.controls['emailSubject'].clearValidators();
      this.addAlertForm.controls['emailMessage'].clearValidators();
      this.addAlertForm.controls['smsMessage'].clearValidators();
    }
    this.selectedEmailSubject = this.replaceHtmlTagsFromText(( !this.selectedEmailSubject ) ? this.selectedUseCaseObj?.defaultEmail?.subject ?? '' : this.selectedEmailSubject);
    this.selectedEmailMessage = ( !this.selectedEmailMessage ) ? this.selectedUseCaseObj?.defaultEmail?.message ?? '' : this.selectedEmailMessage;
    this.selectedSmsMessage = this.replaceHtmlTagsFromText(( !this.selectedSmsMessage ) ? this.selectedUseCaseObj?.defaultEmail?.smsmessage ?? '' : this.selectedSmsMessage);
  }

  onSubmit() {
    this.isSubmitted = true;
    if ( this.selectedUseCaseName == "" || this.selectedModeOfAlert == "" 
      || (this.visibleRecipient == true && this.selectedRecipientNames == "")
      || ( this.isModeOfAlertEmail == true && (!this.selectedEmailSubject || !this.selectedEmailMessage) )
      || ( this.isModeOfAlertSms == true && !this.selectedSmsMessage ) ) {
      return;
    }

    let isTemplateCreated = false;
    let templateId = "";
    let templateAttributes = {};
    let notificationData = null;
    if( this.isModeOfAlertEmail == true ) {
      notificationData = {
        subject: this.replaceDynamicParamsInTextKeyToValue(this.selectedEmailSubject),
        message: this.replaceDynamicParamsInTextKeyToValue(this.selectedEmailMessage),
      }
      if( this.checkIfMessageInValid(this.selectedEmailSubject) || this.checkIfMessageInValid(this.selectedEmailMessage) ) {
        return;
      }
    } else if ( this.isModeOfAlertSms == true ) {
      notificationData = {
        message: this.replaceDynamicParamsInTextKeyToValue(this.selectedSmsMessage),
      }
      if( this.checkIfMessageInValid(this.selectedSmsMessage) ) {
        return;
      }
    } else {
      notificationData = {};
    }
    this.isLoading = true;
    if (this.alertData) {
      let transformedData: AlertMappingData = {
        storeId: this.selectedStoreId,
        useCaseId: this.selectedUseCaseId,
        alertId: this.selectedModeOfAlert.alertUID,
        alertIdentifier: this.selectedAlertIdentifier,
        uniqueId: this.alertData?.uniqueId,
        alertUcUID: this.alertData?.alertUcUID,
        ucNotificationWFlow: {
          threshold : this.selectedThreshold,
          order: this.selectedPriorityLevel,
          receiver: this.selectedRecipientIds,
          message: (this.selectedUseCaseMessage) ? this.selectedUseCaseMessage : null,
          notificationData: notificationData,
          templateId: templateId,
        },
        status: this.isChecked,
      };
      this.transformedAlertData = transformedData;
    } else {
      const transformedData: AlertMappingData = {
        storeId: this.selectedStoreId,
        alertId: this.selectedModeOfAlert.alertUID,
        alertIdentifier: this.selectedAlertIdentifier,
        useCaseId: this.selectedUseCaseId,
        uniqueId: this.alertData?.uniqueId,
        alertUcUID: this.alertData?.alertUcUID,
        ucNotificationWFlow: {
          threshold: this.selectedThreshold,
          order: this.selectedPriorityLevel,
          receiver: this.selectedRecipientIds,
          message: (this.selectedUseCaseMessage) ? this.selectedUseCaseMessage : null,
          notificationData: notificationData,
          templateId: templateId,
        },
        status: this.isChecked,
      };
      this.transformedAlertData = transformedData;
    }

    if(this.selectedAlertIdentifier === "Email") {
      let notificationPayload = {
          name: "Pitaya Alert",
          notification_type: "email",
          content_type: "html",
          template: {
            body: this.replaceEmailSubjectMessage(this.emailHtml),
            subject: this.replaceDynamicParamsInTextKeyToValue(this.selectedEmailSubject)
          },
          template_attributes: {
            body: this.extractWordsInBraces(this.replaceDynamicParamsInTextKeyToValue(this.selectedEmailMessage)),
            subject: this.extractWordsInBraces(this.replaceDynamicParamsInTextKeyToValue(this.selectedEmailSubject))
          },
          is_active: this.isChecked
      }
      this.configurationService
        .createNotifTemplate(notificationPayload)
        .subscribe(
          (resp: any) => {
            if(resp.message === "Success") {
              isTemplateCreated = true
              templateId = resp.data.id;
              templateAttributes = resp.data.template_attributes; 
              this.transformedAlertData.ucNotificationWFlow.templateId = templateId;
              this.transformedAlertData.ucNotificationWFlow.notificationData = templateAttributes; 
              if (this.alertData?.alertUID) {
                const uniqueId = this.alertData?.uniqueId;
                this.configurationService
                  .updateUserAlert(
                    this.selectedAlertUcUID,
                    uniqueId,
                    this.transformedAlertData
                  )
                    .subscribe(
                    (resp: any) => {
                      this.processing = false;
                      if (resp.status === true) {
                        this.activeModal.close();
                        this.alertMessagesService.alertWithSuccess(resp.message);
                        this.isLoading = false;
                      }
                    },
                    (error) => {
                      this.isLoading = false;
                    }
                  );
              } else {
                if(!this.isExist){
                  this.configurationService
                  .createUserAlert(this.transformedAlertData)
                  .subscribe(
                    (resp: any) => {
                      this.processing = false;
                      if (resp.status === true) {
                        this.activeModal.close();
                        this.alertMessagesService.alertWithSuccess(resp.message);
                        this.isLoading = false;
                      }
                    },
                    (error) => {
                      this.isLoading = false;
                    }
                  );
                } else {
                  this.isLoading = false;
                }
              } 
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else {
        if (this.alertData?.alertUID) {
          const uniqueId = this.alertData?.uniqueId;
          this.configurationService
            .updateUserAlert(
              this.selectedAlertUcUID,
              uniqueId,
              this.transformedAlertData
            )
              .subscribe(
              (resp: any) => {
                this.processing = false;
                if (resp.status === true) {
                  this.activeModal.close();
                  this.alertMessagesService.alertWithSuccess(resp.message);
                  this.isLoading = false;
                }
              },
              (error) => {
                this.isLoading = false;
              }
            );
        } 
        else {
          if(!this.isExist){
            this.configurationService
            .createUserAlert(this.transformedAlertData)
            .subscribe(
              (resp: any) => {
                this.processing = false;
                if (resp.status === true) {
                  this.activeModal.close();
                  this.alertMessagesService.alertWithSuccess(resp.message);
                  this.isLoading = false;
                }
              },
              (error) => {
                this.isLoading = false;
              }
            );
          } else {
            this.isLoading = false;
          }
        } 
    }


  
  }


  checkValuesExist(valuesToCheck: any, arrayOfObjects: any) {
    for (let value of valuesToCheck) {
        if (!arrayOfObjects?.some((obj:any) => obj.key === value)) {
            return false;
        }
    }
    return true;
  }

  checkIfMessageInValid(inputText: string) {
    const matches = inputText.match(/{{([^{}]+)}}/g);
    const textsInsideBrackets = (matches && matches?.length > 0) ? matches?.map(match => match.slice(2, -2)) : [];
    if( textsInsideBrackets?.length > 0 ) {
      const result = this.checkValuesExist(textsInsideBrackets, this.dynamicParamsList);
      if( result == true ) {
        return false;
      } else {
        this.showMessageError = true;
        return true;
      }
    }
    return false;
  }

  messageFieldEdit() {
    this.isMessageEditable = !this.isMessageEditable;
    if( this.isMessageEditable ) {
      this.addAlertForm.controls['emailSubject'].enable();
      this.addAlertForm.controls['emailMessage'].enable();
      this.addAlertForm.controls['smsMessage'].enable();
    } else {
      this.addAlertForm.controls['emailSubject'].disable();
      this.addAlertForm.controls['emailMessage'].disable();
      this.addAlertForm.controls['smsMessage'].disable();
    }
  }

  replaceDynamicParamsInTextKeyToValue(inputString: string) {
    if( !inputString ) {
      return inputString;
    }
    const replacedString = inputString?.replace(/\{\{([^{}]+)\}\}/g, (match, p1) => {
      const placeholder = this.dynamicParamsList?.find((item:any) => item.key === p1);
      return placeholder ? `{{${placeholder.value}}}` : match;
    });
    return replacedString;
  }

  replaceDynamicParamsInTextValueToKey(inputString: string) {
    if( !inputString ) {
      return inputString;
    }
    const replacedString = inputString?.replace(/\{\{([^{}]+)\}\}/g, (match, p1) => {
        const placeholder = this.dynamicParamsList?.find((item:any) => item.value === p1);
        return placeholder ? `{{${placeholder.key}}}` : match;
    });
    return replacedString;
  }

  getUseCaseDynamicParams() {
    this.isLoading = true;
    this.configurationService.getUseCaseDynamicParams(this.selectedTenantId, this.selectedUseCaseId).subscribe({
      next: (resp: any) => {
        let response = (resp?.data?.length > 0) ? resp.data[0] : {};
        this.dynamicParamsList = Object.entries(response).map(([key, value]) => ({
          key,
          value
        }));
        this.selectedEmailSubject = this.replaceDynamicParamsInTextValueToKey(this.selectedEmailSubject); // ex. here we need change {{storename}} to {{Store Name}}
        this.selectedEmailMessage = this.replaceDynamicParamsInTextValueToKey(this.selectedEmailMessage); // ex. here we need change {{storename}} to {{Store Name}}
        this.selectedSmsMessage = this.replaceDynamicParamsInTextValueToKey(this.selectedSmsMessage); // ex. here we need change {{storename}} to {{Store Name}}
        this.isLoading = false;
      }, error: (error: any) => {
        this.isLoading = false;
      }
    });
  }

  quilleditorClicked() {
    this.quilleditor = this.editor.quillEditor;
    const range = this.quilleditor.getSelection();
    if (range) {
      this.startPosition = range.index;
      this.endPosition = range.index + range.length;
    } else {
      this.startPosition = this.quilleditor.getSelection(true).index;
      this.endPosition = this.quilleditor.getSelection(true).index;
    }
    this.selectedPosition = 'selectedEmailMessage';
  }

  editorSubjectClicked() {
    let editor = this.subjectEditor.nativeElement;
    this.startPosition = editor.selectionStart;
    this.endPosition = editor.selectionEnd;
    this.selectedPosition = 'selectedEmailSubject';
  }

  editorTextAreaClicked() {
    let editor = this.textareaEditor.nativeElement;
    this.startPosition = editor.selectionStart;
    this.endPosition = editor.selectionEnd;
    this.selectedPosition = 'selectedSmsMessage';
  }

  replaceParamsAtPosition(text: string, replacement: string, startPosition: number, endPosition: number) {
    text = this.replaceHtmlTagsFromText(text);
    const newText = text.substring(0, startPosition) + ` {{${replacement}}}` + text.substring(endPosition + 1);
    return newText;
  }

  onParamSelect(event: any, item: any) {
    if( this.startPosition != -1 && this.endPosition != -1 && this.selectedPosition == 'selectedSmsMessage' ) {
      this.selectedSmsMessage = this.replaceParamsAtPosition(this.selectedSmsMessage, item?.key, this.startPosition, this.endPosition);
    }
    if( this.startPosition != -1 && this.endPosition != -1 && this.selectedPosition == 'selectedEmailSubject' ) {
      this.selectedEmailSubject = this.replaceParamsAtPosition(this.selectedEmailSubject, item?.key, this.startPosition, this.endPosition);
    }
    if( this.startPosition != -1 && this.endPosition != -1 && this.selectedPosition == 'selectedEmailMessage' ) {
      this.selectedEmailMessage = this.replaceParamsAtPosition(this.selectedEmailMessage, item?.key, this.startPosition, this.endPosition);
    }
  }

  openEmailPreviewPopup() {
    const modelRef = this.modalService.open(EmailTemplatePreviewComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "email-template-preview",
      backdrop: "static",
      keyboard: false,
      size: 'lg'
    });
    const datePipe = new DatePipe("en-US");
    const currentDateTime = new Date();
    const formattedDateTime = datePipe.transform(
      currentDateTime,
      "MMMM dd, yyyy hh:mm a"
    );
    const emailData = {
      emailSubject: this.selectedEmailSubject,
      emailMessage: this.selectedEmailMessage,
      'Event Time': formattedDateTime,
      'Camera Name': 'Aisle - Cosmetics',
      'Usecase Name': (this.selectedUseCaseName?.useCaseName) ? this.selectedUseCaseName.useCaseName : this.selectedUseCaseName,
      'Store Name': (this.selectedStoreName?.storeName) ? this.selectedStoreName.storeName : this.selectedStoreName,
      'Store Location': this.selectedStoreAddress,
      'Threshold': ( this.selectedThreshold ) ? `${this.selectedThreshold}%` : `5%`
    };
    modelRef.componentInstance.emailData = emailData;
    modelRef.result.then((result) => {},(reason) => {});
  }

  replaceHtmlTagsFromText(text: string) {
    return text.replace(/<[^>]+>/g, "");
  }

  replacePreviousMsg() {
    if( this.alertData?.alertUID ) {
      this.selectedEmailSubject = this.replaceHtmlTagsFromText(( this.alertData?.notificationData?.subject ) ? this.alertData?.notificationData?.subject ?? '' : this.selectedUseCaseObj?.defaultEmail?.subject ?? '');
      this.selectedEmailMessage = ( this.alertData?.notificationData?.message ) ? this.alertData?.notificationData?.message ?? '' : this.selectedUseCaseObj?.defaultEmail?.message ?? '';
      this.selectedSmsMessage = this.replaceHtmlTagsFromText(( this.alertData?.notificationData?.message ) ? this.alertData?.notificationData?.message ?? '' : this.selectedUseCaseObj?.defaultEmail?.smsmessage ?? '');
    } else {
      this.selectedEmailSubject = this.replaceHtmlTagsFromText(this.selectedUseCaseObj?.defaultEmail?.subject ?? '');
      this.selectedEmailMessage = this.selectedUseCaseObj?.defaultEmail?.message ?? '';
      this.selectedSmsMessage = this.replaceHtmlTagsFromText(this.selectedUseCaseObj?.defaultEmail?.smsmessage ?? '');
    }
    this.selectedEmailSubject = this.replaceDynamicParamsInTextValueToKey(this.selectedEmailSubject);
    this.selectedEmailMessage = this.replaceDynamicParamsInTextValueToKey(this.selectedEmailMessage);
    this.selectedSmsMessage = this.replaceDynamicParamsInTextValueToKey(this.selectedSmsMessage);
  }

  
  extractWordsInBraces(inputString: string) {
    const regex = /{{(.*?)}}/g;
    let matches = [];
    let match;
    
    while (match = regex.exec(inputString)) {
      matches.push(match[1].trim());
    }

    return matches;
  }

  replaceEmailSubjectMessage(emailHtml: string) {
    // Regular expression to find {{emailSubject}} and {{emailMessage}}
    const regex = /\{\{emailSubject\}\}|\{\{cameraLocation\}\}|\{\{eventDateTime\}\}|\{\{emailMessage\}\}|\{\{appOrigin\}\}|\{\{emailMessageWithPlaceholders\}\}|\{\{emailSubWithPlaceholders\}\}|/g;

    // Replace placeholders with actual values
    emailHtml = emailHtml.replace(regex, (match) => {
      switch (match) {
        case '{{emailSubWithPlaceholders}}':
          return this.replaceDynamicParamsInTextKeyToValue(this.selectedEmailSubject); 
        case '{{emailMessageWithPlaceholders}}':
          return this.replaceDynamicParamsInTextKeyToValue(this.selectedEmailMessage); 
        default:
          return match;
      }
    });
    return emailHtml;
  }


}
