import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import Swal from "sweetalert2";

export interface ModeOfAlert {
  alertUID: number;
  alertMode: string;
  identifier: string;
  isRecipientNeeded: boolean;
}

@Component({
  selector: "app-add-stores",
  templateUrl: "./add-stores.component.html",
  styleUrls: ["./add-stores.component.scss"],
})
export class AddStoresComponent implements OnInit {
  @Input() filteredOrgs: any[];
  @Input() storeData: any;
  @ViewChild("autoOrgDrop") autoOrgDrop: any;
  @ViewChild("autoCountryDrop") autoCountryDrop: any;
  @ViewChild("autoStateDrop") autoStateDrop: any;
  @ViewChild("autoCityDrop") autoCityDrop: any;
  @ViewChild("autoZipDrop") autoZipDrop: any;
  @ViewChild("autoTimeZoneDrop") autoTimeZoneDrop: any;

  isEditing: boolean = false;
  countriesList: any;
  statesList: any;
  cityList: any;
  zonesList: any;
  timeZonesList: any;
  zipList: any;
  isStoreExist: boolean = false;
  checkStore: any;
  errorMessage: boolean = false;
  isCSAdmin: boolean = false;
  providedErrorMessage: string = "";
  pageSize: number = this.configurationService.pageSize;
  currentPage: number = 1;
  addStoreForm: FormGroup;
  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: any;
  selectedTenantName: string;
  selectedStoreId: any;
  selectedCountryId: any = "";
  selectedCountryName: string;
  selectedStateId: any = "";
  selectedStateName: string;
  selectedCityId: any = "";
  selectedCityName: any;
  selectedCountryCode: any = "";
  selectedCountryCodeId: any = "";
  selectedOpenHours: any = "";
  selectedClosedHours: any = "";
  selectedZipCode: any = "";
  selectedTimeZoneId: any = "";
  selectedTimeZoneName: any = "";

  // selectedStorePhone: number;
  selectedPhoneNumber1: number;
  selectedPhoneNumber2: number;
  isSubmitted: boolean = false;
  isVisible: boolean = false;
  validPhoneNo: boolean = false;

  authTokenEntry: string = "";
  showAuthToken: boolean = false;
  processing: boolean = false;

  keyword = "name";
  placeholderOrg = "Select the Organization";
  placeholderCountry = "Enter the Country Name";
  placeholderState = "Enter the State Name";
  placeholderCity = "Enter the City Name";
  placeholderZip = "Enter the Zip Code";
  placeholderTimezone = "Select The Timezone";

  loggedUser: any;
  orgData: any;
  isChecked: boolean = true;
  isAlertChecked: boolean = false;
  isAuthTokenVisible: boolean = false; // Property to track visibility of authToken input
  selectedModeOfAlert: any[] = [];
  hyperionValue: string;
  modeOfAlerts: ModeOfAlert[] = [];
  activeModeAlerts: any;
  hypeToken: any;
  selectedModeOfAlertId: any;
  isAtLeastOneAlertChecked: boolean = false;
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';
  tokenValidated =
    "We will deassociate Auth token, you will not receive hyperion alerts";
  deassociatedToken: boolean = false;
  countryCodeList: any = [
    // { countryCodeId: 1, countryValue: "(India) +91 " },
    // { countryCodeId: 2, countryValue: "(US) +1" },
  ];
  operatingHours = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];

  anyCheckboxSelected: boolean;
  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private alertMessagesService: AlertMessagesService,
    private configurationService: ConfigurationService
  ) {
    this.addStoreForm = this.fb.group({
      tenantId: ["", [Validators.required]],
      storeName: ["", [Validators.required]],
      siteAddressL1: ["", [Validators.required, Validators.max(100)]],
      siteAddressL2: ["", [Validators.max(100)]],
      cityUid: [null, [Validators.required]],
      stateUid: [null, [Validators.required]],
      countryUid: [null, [Validators.required]],
      zipCode: [null, [Validators.required]],
      timeZoneId: [null, [Validators.required]],
      countryCodeId: [null, [Validators.required]],
      sitePhoneNo1: [
        null,
        [
          Validators.required,
          this.phoneNumberMinLengthValidator(10),
          Validators.maxLength(10),
          Validators.pattern("^[0-9]{10}$"),
        ],
      ],
      startTime: ["", [Validators.required]],
      endTime: ["", [Validators.required]],
      alertMode: [null, [Validators.required]],
      sitePhoneNo2: [
        null,
        [
          this.phoneNumberMinLengthValidator(10),
          Validators.maxLength(10),
          Validators.pattern("^[0-9]{10}$"),
        ],
      ],
      status: [false],
      authToken: [""],
    });
    this.loggedUser = localStorage.getItem("userRole");
  }
  get fc() {
    return this.addStoreForm.controls;
  }

  ngOnInit() {
    this.assignDefaultValues();
    this.configByRole();
  }

  configByRole() {
    if (this.loggedUser == "csm") {
      this.isCSAdmin = true;
      this.filterOrgData();
    }
    if (this.loggedUser == "org-admin") {
      this.isCSAdmin = false;
      this.selectedTenantId = this.defaultTenantId;
    }
  }

  assignDefaultValues() {
    if (this.storeData) {
      this.isEditing = !this.isEditing;
      this.addStoreForm.patchValue({
        siteAddressL1: this.storeData?.siteAddressL1,
        alertMode: this.storeData?.alertMode,
        siteAddressL2: this.storeData?.siteAddressL2,
        sitePhoneNo1: this.storeData?.sitePhoneNo1,
        sitePhoneNo2: this.storeData?.sitePhoneNo2,
        startTime: this.storeData?.startTime,
        endTime: this.storeData?.endTime,
      });
      this.selectedTenantId = this.storeData?.tenantId;
      this.selectedTenantName = this.storeData?.tenantName;
      this.selectedStoreId = this.storeData?.storeId;
      this.checkStore = this.storeData?.storeName;
      this.selectedCountryId = this.storeData?.countryUid;
      this.selectedCountryName = this.storeData?.countryName;
      this.selectedStateId = this.storeData?.stateUid;
      this.selectedStateName = this.storeData?.stateName;
      this.selectedCityId = this.storeData?.cityUid;
      this.selectedCityName = this.storeData?.cityName;
      // if (this.storeData?.timezoneId === 0) {
      //   this.selectedTimeZoneId = null;
      //   this.selectedTimeZoneName = "";
      // } else {
      //   this.selectedTimeZoneId = this.storeData?.timezoneId;
      //   this.selectedTimeZoneName = this.storeData?.standardName;
      // }
      this.selectedTimeZoneId = this.storeData?.timezoneId;
      this.selectedTimeZoneName = this.storeData?.standardName;
      this.selectedCountryCodeId = this.storeData?.countryCodeUid;
      this.selectedZipCode = this.storeData?.zipCode;
      this.selectedOpenHours = this.storeData?.startTime;
      this.selectedClosedHours = this.storeData?.endTime;
      this.isChecked = this.storeData?.status;
      this.activeModeAlerts = this.storeData?.alertModeList;
      this.authTokenEntry = this.storeData?.authToken;
      const alertChecked = this.storeData.alertModeList.map(
        (item: any) => item.alertUID
      );
      this.selectedModeOfAlert.push(...alertChecked);
      this.isAlertIdentifierHypExists();
    }
    this.getCountries();
    this.getTimeZoneList();
    this.getAlertModes();
  }

  filterOrgData() {
    this.filteredOrgs?.map((org: any) => (org.name = `${org.tenantName}`));
    this.filteredOrgs = this.filteredOrgs?.filter(
      (orgData: any) => orgData.status === true
    );
  }

  toggleOrgPanel(e: any): void {
    e.stopPropagation();
    this.autoOrgDrop.open();
  }

  changeOrganization(event: any) {
    this.selectedTenantId = event.tenantId;
  }

  getCountries() {
    this.configurationService.getCountriesData().subscribe((resp: any) => {
      this.countriesList = resp.data;
      this.countryCodeList = resp.data;
      this.countriesList.map(
        (country: any) => (country.name = `${country.countryName}`)
      );
    });
  }
  toggleCountryPanel(e: any): void {
    e.stopPropagation();
    this.autoCountryDrop.open();
  }

  changeCountry(event: any) {
    this.selectedCountryId = event.countryUID;
    this.getStatesList(this.selectedCountryId);
    this.selectedStateName = "";
    this.selectedCityName = "";
    this.selectedZipCode = "";
  }

  clearCountry() {
    this.selectedCountryName = "";
    this.selectedCountryId = null;
    this.selectedStateName = "";
    this.selectedStateId = null;
    this.cityList = [];
    this.selectedCityName = "";
    this.selectedCityId = null;
    this.zipList = [];
    this.selectedZipCode = "";
  }

  getStatesList(selectedCountryId: number) {
    this.configurationService
      .getStatesData(selectedCountryId)
      .subscribe((resp: any) => {
        this.statesList = resp.data;
        this.statesList.map(
          (state: any) => (state.name = `${state.stateName}`)
        );
      });
  }

  toggleStatePanel(e: any): void {
    e.stopPropagation();
    this.autoStateDrop.open();
  }

  changeState(event: any) {
    this.selectedStateId = event.stateUID;
    this.getCitiesList(this.selectedCountryId, this.selectedStateId);
    this.selectedCityName = "";
    this.selectedZipCode = "";
  }

  clearState() {
    this.selectedStateName = "";
    this.selectedStateId = null;
    this.selectedCityName = "";
    this.selectedCityId = null;
    this.zipList = [];
    this.selectedZipCode = "";
    this.getStatesList(this.selectedCountryId);
  }

  getCitiesList(selectedCountryId: number, selectedStateId: number) {
    this.configurationService
      .getCitiesData(selectedCountryId, selectedStateId)
      .subscribe((resp: any) => {
        this.cityList = resp.data;
        this.cityList.map((city: any) => (city.name = `${city.cityName}`));
      });
  }
  toggleCityPanel(e: any): void {
    e.stopPropagation();
    this.autoCityDrop.open();
  }

  changeCity(event: any) {
    this.selectedCityId = event.cityUID;
    this.selectedCityName = event.cityName;
    this.getZipList(this.selectedStateId);
    this.selectedZipCode = "";
  }

  clearCity() {
    this.selectedCityId = null;
    this.selectedCityName = "";
    this.getCitiesList(this.selectedCountryId, this.selectedStateId);
    this.selectedZipCode = "";
  }

  getZipList(selectedStateId: number) {
    this.configurationService
      .getZipData(selectedStateId)
      .subscribe((resp: any) => {
        this.zipList = resp.data;
        this.zipList.map((zip: any) => (zip.name = `${zip.zipCode}`));
      });
  }
  toggleZipPanel(e: any): void {
    e.stopPropagation();
    this.autoZipDrop.open();
  }

  changeZipCode(event: any) {
    this.selectedZipCode = event.zipCode.toString();
    this.getTimeZoneList();
  }

  clearZipCode() {
    this.selectedZipCode = "";
    this.getZipList(this.selectedStateId);
  }

  getTimeZoneList() {
    this.configurationService.getTimezoneData().subscribe((resp: any) => {
      this.timeZonesList = resp.data;
      this.timeZonesList.map(
        (timezone: any) => (timezone.name = `${timezone.standardname}`)
      );
    });
  }

  toggleTimeZonePanel(e: any): void {
    e.stopPropagation();
    this.autoTimeZoneDrop.open();
  }

  changeTimeZoneCode(event: any) {
    this.selectedTimeZoneId = event.timezoneid;
    this.selectedTimeZoneName = event.standardname;
  }

  clearTimeZoneCode() {
    this.selectedTimeZoneName = "";
    this.selectedTimeZoneId = null;
    this.getTimeZoneList();
  }

  changeCountryCode(event: any) {
    this.selectedCountryCodeId = event.target.value;
  }
  changeOpenHours(event: any) {
    this.selectedOpenHours = event.target.value;
  }

  changeClosedHours(event: any) {
    this.selectedClosedHours = event.target.value;
  }

  getAlertModes() {
    this.configurationService.getAlertModes().subscribe((resp: any) => {
      this.modeOfAlerts = resp.data;
    });
  }

  updateAnyCheckboxSelected() {
    this.anyCheckboxSelected = this.activeModeAlerts.length === 0;
  }

  isAlertSelected(alert: any) {
    return this.activeModeAlerts?.some(
      (item: any) => item.identifier === alert.identifier
    );
  }

  openHypironCheck() {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      title: "Are you sure?",
      text: "Do you want to deassociated with hyperion?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.authTokenEntry = "";
        this.alertMessagesService.alertWithSuccess("We will deassociate Auth token, you will not receive hyperion alerts");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.getAlertModes();
        this.isAuthTokenVisible = true;
      }
    });
  }

  changeModeOfAlert(event: any, mode: ModeOfAlert) {
    if (event.target && event.target.checked) {
      this.selectedModeOfAlert.push(mode.alertUID);
    } else {
      const index = this.selectedModeOfAlert.findIndex(
        (selectedModeOfAlert) => selectedModeOfAlert === mode.alertUID
      );
      if (index >= 0) {
        this.selectedModeOfAlert.splice(index, 1);
      }
    }
    if (mode.identifier === "Hyp") {
      this.isAuthTokenVisible = event.target.checked;
      if (
        !event.target.checked &&
        this.authTokenEntry &&
        this.authTokenEntry != null
      ) {
        this.openHypironCheck();
      }
    }
  }

  isAlertIdentifierHypExists() {
    const activeMode = this.activeModeAlerts?.some(
      (alert: any) => alert.identifier === "Hyp"
    );
    if (activeMode === true) {
      this.isAuthTokenVisible = !this.isAuthTokenVisible;
    } else {
      this.isAuthTokenVisible = false;
    }
  }

  togglePasswordVisibility() {
    this.showAuthToken = !this.showAuthToken;
  }

  storeNameCheckUnique() {
    this.configurationService
      .getStoreNameUnigue(this.checkStore, this.selectedTenantId)
      .subscribe((resp: any) => {
        if (resp.status === false) {
          this.isStoreExist = false;
        } else {
          this.isStoreExist = true;
        }
      });
  }

  phoneNumberMinLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (typeof control?.value === "string") {
        const phoneNumber = control.value.replace(/\D/g, ""); // Remove non-numeric characters
        if (phoneNumber.length < minLength) {
          return { minLength: true };
        }
      }
      return null;
    };
  }

  submitForm() {
    this.isSubmitted = true;
    this.processing = true;

    const selectedStoreId = this.storeData?.storeId;
    this.addStoreForm.patchValue({
      tenantId: this.selectedTenantId,
      cityName: this.selectedCityName,
      cityUid: this.selectedCityId,
      countryUid: this.selectedCountryId,
      stateUid: this.selectedStateId,
      timeZoneId: this.selectedTimeZoneId,
      alertMode: this.selectedModeOfAlert,
      startTime: this.selectedOpenHours,
      endTime: this.selectedClosedHours,
      authToken: this.authTokenEntry,

      zipCode: this.selectedZipCode.zipCode,
    });

    if (this.storeData) {
      this.addStoreForm.patchValue({
        tenantId: this.selectedTenantId,
        cityName: this.storeData?.cityName,
        cityUid: this.selectedCityId,
        countryUid: this.selectedCountryId,
        // countryCode
        stateUid: this.selectedStateId,
        timeZoneId: this.selectedTimeZoneId,
        alertMode: this.selectedModeOfAlert,
        startTime: this.selectedOpenHours,
        endTime: this.selectedClosedHours,
        authToken: this.authTokenEntry,
        zipCode: this.selectedZipCode.zipCode || this.selectedZipCode,
      });
    }

    Object.keys(this.addStoreForm.controls).forEach((controlName) => {
      const control = this.addStoreForm.get(controlName);
      // Check for validation errors
      if (control?.invalid) {
        this.processing = false;
      }
    });

    if (this.storeData?.tenantId && this.addStoreForm.valid) {
      if (!this.isStoreExist) {
        this.configurationService
          .updateStore(selectedStoreId, this.addStoreForm.value)
          .subscribe(
            (resp: any) => {
              if (resp.status === true) {
                this.processing = false;
                this.activeModal.close();
                this.alertMessagesService.alertWithSuccess("Store Updated successfully.");
              }
            },
            (error) => {
              this.processing = false;
            }
          );
      }
    } else if (this.addStoreForm.valid) {
      if (!this.isStoreExist) {
        this.configurationService
          .createStore(this.addStoreForm.value)
          .subscribe(
            (resp: any) => {
              if (resp.status === true) {
                this.processing = false;
                this.activeModal.close();
                this.alertMessagesService.alertWithSuccess("Store Added successfully.");
              }
            },
            (error) => {
              this.processing = false;
            }
          );
      }
    } else {
      return;
    }
  }
  authCheckEmpty() {
    this.errorMessage = false;
    if (
      this.isEditing &&
      this.authTokenEntry == "" &&
      this.storeData?.authToken
    ) {
      this.deassociatedToken = true;
    } else {
      this.deassociatedToken = false;
    }
  }
}
