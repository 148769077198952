import { Directive, HostListener, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[appNumericOnly]",
})
export class NumericOnlyDirective {
  @Input() appNumericOnly: boolean = true; // Make sure it's of type boolean

  constructor(private el: ElementRef) {}

  @HostListener("input", ["$event"]) onInputChange(event: Event): void {
    if (this.appNumericOnly) {
      const input = event.target as HTMLInputElement;
      const sanitizedValue = input.value.replace(/[^0-9]/g, "");
      input.value = sanitizedValue;
    }
  }
}
