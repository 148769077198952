<form [formGroup]="addUsecaseForm" class="usecase-form">
  <div class="d-flex justify-content-left">
    <div class="usecase-form-group mb-4 me-3" *ngIf="isCSAdmin">
      <div class="auto-dropdown">
        <label for="orgName">Organization Name: <sup>*</sup></label>
        <ng-autocomplete [data]="filteredOrgs" [searchKeyword]="keyword" (selected)="changeOrganization($event)"
          [placeholder]="placeholderOrg" [itemTemplate]="itemTemplateStaticOrg" class="form-control form-autocomplete"
          [(ngModel)]="selectedTenantName" [ngClass]="{'is-invalid': isSubmitted && fc['tenantId'].errors}"
          [notFoundTemplate]="notFoundTemplateOrg" #autoOrgDrop [ngModelOptions]="{standalone: true}"
          [disabled]="isEditing" (inputCleared)="clearOrg()">
        </ng-autocomplete>

        <input type="text" id="orgName" name="orgName" formControlName="tenantId" class="form-control"
          placeholder="Organization Name" required="true" [(ngModel)]="selectedTenantName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible">

        <span class="dropdown-down" (click)="toggleOrgPanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticOrg let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateOrg let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>

        <div *ngIf="addUsecaseForm.get('tenantId')?.hasError('required')" class="invalid-feedback">
          Organization is required
        </div>
      </div>
    </div>

    <div class="usecase-form-group mb-4 me-3">
      <div class="auto-dropdown">
        <label for="storeName">Store Name: <sup>*</sup></label>

        <ng-autocomplete [data]="filteredStores | orderBy:'storeName'" [searchKeyword]="keyword"
          (selected)="changeStore($event)" [placeholder]="placeholderStore" [itemTemplate]="itemTemplateStaticStore"
          class="form-control form-autocomplete" [(ngModel)]="selectedStoreName"
          [notFoundTemplate]="notFoundTemplateStore" #autoStoreDrop [ngModelOptions]="{standalone: true}"
          [disabled]="isEditing" [ngClass]="{'is-invalid': isSubmitted && fc['storeId'].errors}"
          (inputCleared)="clearStore()">
        </ng-autocomplete>

        <input type="text" id="storeName" name="storeName" formControlName="storeId" class="form-control"
          placeholder="Store Name" required="true" [(ngModel)]="selectedStoreName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible">

        <span class="dropdown-down" (click)="toggleStorePanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticStore let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateStore let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addUsecaseForm.get('storeId')?.hasError('required')" class="invalid-feedback">
          Store field is required
        </div>
      </div>
    </div>

    <div class="usecase-form-group mb-4 me-3">
      <div class="auto-dropdown">
        <label for="deviceName">Device Name: <sup>*</sup></label>

        <ng-autocomplete [data]="deviceList | orderBy:'deviceName'" [searchKeyword]="keyword"
          (selected)="changeDevice($event)" [placeholder]="placeholderDevice" [itemTemplate]="itemTemplateStaticDevice"
          class="form-control form-autocomplete" [(ngModel)]="selectedDeviceName"
          [notFoundTemplate]="notFoundTemplateDevice" #autoDeviceDrop [ngModelOptions]="{standalone: true}"
          [disabled]="isEditing" [ngClass]="{'is-invalid': isSubmitted && fc['deviceID'].errors}" 
          (inputCleared)="clearDeviceType()">
        </ng-autocomplete>

        <input type="text" id="deviceName" name="deviceName" formControlName="deviceID" class="form-control"
          placeholder="Device Name" required="true" [(ngModel)]="selectedDeviceName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible">

        <span class="dropdown-down" (click)="toggleDevicePanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticDevice let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateDevice let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>

        <div *ngIf="addUsecaseForm.get('deviceID')?.hasError('required')" class="invalid-feedback">
          Device field is required
        </div>
      </div>
    </div>

    <div class="usecase-form-group mb-4 me-3">
      <div class="auto-dropdown">
        <label for="Camname">Cam Name: <sup>*</sup></label>
        <ng-autocomplete [data]="camList | orderBy:'CamName'" [searchKeyword]="keyword"
          (selected)="changeCamera($event)" [placeholder]="placeholderCamName" [itemTemplate]="itemTemplateStaticCam"
          class="form-control form-autocomplete" [(ngModel)]="selectedCamName" [notFoundTemplate]="notFoundTemplateCam"
          #autoCamDrop [ngModelOptions]="{standalone: true}"
          [ngClass]="{'is-invalid': isSubmitted && fc['camUId'].errors}" [disabled]="isEditing">
        </ng-autocomplete>

        <input type="text" id="CamName" name="CamName" formControlName="camUId" class="form-control"
          placeholder="Cam Name" required="true" [(ngModel)]="selectedCamName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible"
          [ngClass]="{'is-invalid': isSubmitted && fc['CamId'].errors}">

        <span class="dropdown-down" (click)="toggleCamPanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticCam let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateCam let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addUsecaseForm.get('camUId')?.hasError('required')" class="invalid-feedback">
          Camera field is required
        </div>

      </div>
    </div>

    <div class="usecase-form-group mb-4">
      <div class="auto-dropdown">
        <label for="usecasename">Usecase Name: <sup>*</sup></label>
        <ng-autocomplete [data]="useCaseList | orderBy:'useCaseName'" [searchKeyword]="keyword"
          (selected)="changeUseCase($event)" [placeholder]="placeholderUseCase"
          [itemTemplate]="itemTemplateStaticUseCase" class="form-control form-autocomplete"
          [(ngModel)]="selectedUseCaseName" [notFoundTemplate]="notFoundTemplateUseCase" #autoUseCaseDrop
          [ngModelOptions]="{standalone: true}" [ngClass]="{'is-invalid': isSubmitted && fc['useCaseUId'].errors}"
          (inputCleared)="clearUseCaseData()" [disabled]="isEditing">
        </ng-autocomplete>

        <input type="text" id="useCaseName" name="useCaseName" formControlName="useCaseUId" class="form-control"
          placeholder="Usecase Name" required="true" [(ngModel)]="selectedUseCaseName" [readonly]="isEditing"
          [tabindex]="isEditing ? -1 : null" [class.disabled]="isEditing" *ngIf="isVisible">

        <span class="dropdown-down" (click)="toggleUseCasePanel($event)" *ngIf="!isEditing"></span>
        <ng-template #itemTemplateStaticUseCase let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplateUseCase let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <div *ngIf="addUsecaseForm.get('useCaseUId')?.hasError('required')" class="invalid-feedback">
          Use Case field is required
        </div>
        <div *ngIf="usecasemapExist" class="isExistvalid">
          Use Case already exists
        </div>
      </div>
    </div>
  </div>

  <p class="required-data" *ngIf="visibleDevice">Please Select The Organization / Store / Device / Camera and Use Case
  </p>
  <div *ngIf="dwellDataVisible || peopleDataVisible || oosDataVisible || peopleStoreMovementDataVisible || violenceDataVisible">
    <div class="row" *ngIf="!usecasemapExist">
      <div class="col-6">
        <h4 class="sub-header">{{dataTitle}} Configurations</h4>
        <div class="dwell-data" *ngIf="dwellDataVisible">
          <app-dwell [configData]="useCaseROIData" (updatedUseCase)="getUpdatedUsecaseData()"></app-dwell>
        </div>
        <div class="people-data" *ngIf="peopleDataVisible">
          <app-people-counter [configData]="useCaseROIData" (updatedUseCase)="getUpdatedUsecaseData()"></app-people-counter>
        </div>
        <div class="oos-data" *ngIf="oosDataVisible">
          <app-out-of-stock [configData]="useCaseROIData" (updatedUseCase)="getUpdatedUsecaseData()"></app-out-of-stock>
        </div>
        <div class="people-store-movement-data" *ngIf="peopleStoreMovementDataVisible">
          <app-people-store-movement [configData]="useCaseROIData" (updatedUseCase)="getUpdatedUsecaseData()"></app-people-store-movement>
        </div>
        <div class="violence-data" *ngIf="violenceDataVisible">
          <app-violence [configData]="useCaseROIData" (updatedUseCase)="getUpdatedUsecaseData()"></app-violence>
        </div>
      </div>
      <div class="col-6" *ngIf="!peopleStoreMovementDataVisible && !violenceDataVisible">
        <app-usecase-config [configData]="useCaseROIData"></app-usecase-config>
      </div>      
    </div>
  </div>
</form>