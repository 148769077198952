<div class="overview-setup">
  <div class="d-flex justify-content-end tab-content">
    <select class="selected-item" [(ngModel)]="selectedStatus" (change)="onStatusChange()">
      <option value="Active" selected>Active</option>
      <option value="Inactive">Inactive</option>
    </select>

    
    <select class="selected-item" [(ngModel)]="selectedTenantId" (change)="changeOrg($event)" *ngIf="isCSAdmin">
      <option value="" [selected]="!selectedTenantIdDropdown">All Organizations</option>
      <option *ngFor="let org of filteredOrgs" value={{org.tenantId}} [selected]="org.tenantId === selectedTenantId">{{org.tenantName}}</option>
    </select>


    <select class="selected-item" (change)="changeStore($event)" [(ngModel)]="selectedStoreId">
      <option [selected]="!selectedStoreIdDropdown" value="" selected>All Stores</option>
      <option *ngFor="let store of filteredStores" value={{store.storeId}} [selected]="store.storeId === selectedStoreId">{{store.storeName}}</option>
    </select>
    <button class="btn btn-add" (click)="addUserData()">+ Add Users</button>
  </div>
</div>

<div class="ag-grid">
  <app-user-grid [rowData]="usersData" (gridActionEvent)="handleActionEvent($event)"></app-user-grid>
</div> 