import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ROI } from "../usecase-config/usecase-config.component";
import { Subscription } from "rxjs";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: "app-dwell",
  templateUrl: "./dwell.component.html",
  styleUrls: ["./dwell.component.scss"],
})
export class DwellComponent implements OnInit {
  @Input() public configData: any;
  @Output() updatedUseCase: EventEmitter<void> = new EventEmitter<void>();
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  dwellUseCaseForm: FormGroup;
  selectedProductROI: ROI = {
    type: "ProductROI",
    xMin: 0,
    yMin: 0,
    xMax: 0,
    yMax: 0,
  };

  roiConfigurations: ROI[] = [];
  filteredRoiConfigurations: ROI[] = [];
  public roiConfigurationsSubscription: Subscription;
  public roiDimensionsSubscription: Subscription;

  productROIConfig: any;
  dimensions: any;
  baseValue: 0;
  selectedUseCaseInfo: any;
  selectedUCConfig: any;
  useCaseConfigId: any;
  useCaseIdentifier: any;
  isChecked: boolean = true;

  constructor(
    private fb: FormBuilder,
    private configService: ConfigurationService,
    private alertMessagesService: AlertMessagesService,
    public activeModal: NgbActiveModal
  ) {
    this.dwellUseCaseForm = this.fb.group({
      tenant: ["", Validators.required],
      store: ["", Validators.required],
      camera: [""],
      xMin: [null, [Validators.required]],
      xMax: [null, [Validators.required]],
      yMin: [null, [Validators.required]],
      yMax: [null, [Validators.required]],
      cameraId: ["", Validators.required],
      deviceId: ["", Validators.required],
      usecaseId: ["", Validators.required],
      status: [true],
      dwellAlertInterval: [null, [Validators.required]],
      dwellThr: [null, [Validators.required]],
      inputSource: [""],
    });
    this.fetchConfigData();
  }

  ngOnInit(): void {
    this.selectedUseCaseInfo = this.configData.useCase;
    if (this.selectedUseCaseInfo) {
      this.getUseCaseConfigData();
    }
    this.refreshROIData();
  }

  refreshROIData() {
    this.selectedProductROI.xMin = this.baseValue;
    this.selectedProductROI.yMin = this.baseValue;
    this.selectedProductROI.xMax = this.baseValue;
    this.selectedProductROI.yMax = this.baseValue;
  }

  getUseCaseConfigData() {
    this.useCaseConfigId = this.selectedUseCaseInfo.ucConfigUID;
    this.useCaseIdentifier = this.selectedUseCaseInfo.usecaseIdentifier;
    this.configService
      .getUseCaseConfigData(this.useCaseConfigId, this.useCaseIdentifier)
      .subscribe((resp: any) => {
        this.selectedUCConfig = resp.data;
        this.fetchUseCaseConfigData();
      });
  }

  fetchConfigData() {
    this.roiConfigurationsSubscription = this.configService
      .getROIConfigurations()
      .subscribe((configurations: ROI[]) => {
        this.roiConfigurations = configurations;
        this.filterRoiConfigurations();
      });

    this.roiDimensionsSubscription = this.configService
      .getImageDimensions()
      .subscribe((dimensions: any) => {
        this.dimensions = dimensions;
      });
  }

  filterRoiConfigurations() {
    const modifiedRois = [];
    for (const roi of this.roiConfigurations) {
      modifiedRois.push({
        ...roi,
        xMin: roi.xMin * 2,
        yMin: roi.yMin * 2,
        xMax: roi.xMax * 2,
        yMax: roi.yMax * 2,
      });
    }

    this.filteredRoiConfigurations = modifiedRois;
    this.productROIConfig = this.filteredRoiConfigurations[0];

    this.selectedProductROI.xMin = this.productROIConfig?.xMin;
    this.selectedProductROI.yMin = this.productROIConfig?.yMin;
    this.selectedProductROI.xMax = this.productROIConfig?.xMax;
    this.selectedProductROI.yMax = this.productROIConfig?.yMax;
  }

  fetchUseCaseConfigData() {
    if (this.selectedUCConfig) {
      this.dwellUseCaseForm.patchValue({
        tenant: this.selectedUCConfig.tenant,
        store: this.selectedUCConfig.store,
        usecaseId: this.selectedUCConfig.usecaseId,
        cameraId: this.selectedUCConfig.cameraId,
        deviceId: this.selectedUCConfig.deviceId,
        inputSource: this.selectedUCConfig.inputSource,
        camera: this.selectedUCConfig.camera,
        dwellAlertInterval: this.selectedUCConfig.settings.DWELL_ALERT_INTERVAL.value,
        dwellThr: this.selectedUCConfig.settings.DWELL_THR.value,
      });
      this.selectedProductROI.xMin = this.selectedUCConfig?.settings.X_MAX.value;
      this.selectedProductROI.yMin = this.selectedUCConfig?.settings.X_MIN.value;
      this.selectedProductROI.xMax = this.selectedUCConfig?.settings.Y_MAX.value;
      this.selectedProductROI.yMax = this.selectedUCConfig?.settings.Y_MIN.value;
      this.isChecked = this.configData?.useCase?.status;
    } else {
      this.dwellUseCaseForm.patchValue({
        tenant: this.configData.tenantId,
        store: this.configData.storeId,
        usecaseId: this.configData.useCaseUId,
        cameraId: this.configData.camUId,
        deviceId: this.configData.deviceId,
        xMin: this.selectedProductROI.xMin,
        xMax: this.selectedProductROI.xMax,
        yMin: this.selectedProductROI.yMin,
        yMax: this.selectedProductROI.yMax,
        inputSource: this.configData.rtsp,
        camera: this.configData.camMacAdd,
        status: this.isChecked,
      });
    }
  }
  get fc() {
    return this.dwellUseCaseForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (
      this.selectedProductROI.xMin == undefined ||
      this.selectedProductROI.xMax == undefined ||
      this.selectedProductROI.yMin == undefined ||
      this.selectedProductROI.yMax == undefined ||
      this.selectedProductROI.xMin <= 0 ||
      this.selectedProductROI.xMax <= 0 ||
      this.selectedProductROI.yMin <= 0 ||
      this.selectedProductROI.yMax <= 0
    )
      return;

    if (
      this.dwellUseCaseForm.get("dwellAlertInterval")?.value == undefined ||
      this.dwellUseCaseForm.get("dwellAlertInterval")?.value <= 0 ||
      this.dwellUseCaseForm.get("dwellThr")?.value == undefined ||
      this.dwellUseCaseForm.get("dwellThr")?.value <= 0
    )
      return;

    if (this.selectedUCConfig) {
      this.dwellUseCaseForm.patchValue({
        tenant: this.configData?.useCase?.tenantId,
        store: this.configData?.useCase?.storeId,
        usecaseId: this.configData?.useCase?.usecaseId,
        cameraId: this.selectedUCConfig.cameraId,
        deviceId: this.selectedUCConfig.deviceId,
        inputSource: this.selectedUCConfig.inputSource,
        camera: this.selectedUCConfig.camera,
        status: this.isChecked,
        xMin: this.selectedProductROI.xMin,
        xMax: this.selectedProductROI.xMax,
        yMin: this.selectedProductROI.yMin,
        yMax: this.selectedProductROI.yMax,
      });
    } else {
      this.dwellUseCaseForm.patchValue({
        tenant: this.configData?.tenantId,
        store: this.configData?.storeId,
        usecaseId: this.configData?.useCaseUId,
        cameraId: this.configData?.camUId,
        deviceId: this.configData?.deviceId,
        inputSource: this.configData.rtsp,
        camera: this.configData.camMacAdd,
        status: this.isChecked,
        xMin: this.selectedProductROI.xMin,
        xMax: this.selectedProductROI.xMax,
        yMin: this.selectedProductROI.yMin,
        yMax: this.selectedProductROI.yMax,
      });
      this.useCaseIdentifier = this.configData?.useCaseIdentifier;
    }

    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 5000);
    const ucConfigUID = this.configData?.useCase?.ucConfigUID;
    const useCaseType = this.configData?.useCase?.usecaseIdentifier;
    if (
      !this.selectedProductROI.xMin &&
      !this.selectedProductROI.xMax &&
      !this.selectedProductROI.yMin &&
      !this.selectedProductROI.yMax
    ) {
      this.configService.getFileUploadStatus(true);
    }
    if (ucConfigUID) {
      this.configService
        .updateUseCaseMapping(
          ucConfigUID,
          useCaseType,
          this.dwellUseCaseForm.value
        )
        .subscribe(
          (resp: any) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess("UseCase Updated successfully.");
              this.isLoading = false;
              this.configService.setActiveItem(false);
              this.updatedUseCase.emit();
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else {
      this.configService
        .createUseCaseMapping(
          this.useCaseIdentifier,
          this.dwellUseCaseForm.value
        )
        .subscribe(
          (resp) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess("UseCase Added successfully.");
              this.isLoading = false;
              this.configService.setActiveItem(false);
              this.updatedUseCase.emit();
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    }
    
  }

  ngOnDestroy() {
    this.roiConfigurationsSubscription.unsubscribe();
  }
}
