<div class="heat-map-container">

    <!-- <div class="heat-map-header">
        Store Heatmap
    </div> -->

    <div class="heat-map-body">
        <div class="heat-map-content d-flex align-items-center justify-content-between">
            <div class="content-left d-flex align-items-center justify-content-between">
                <label class="heading">Camera</label>
                <div class="dropdown">
                    <select class="camera-values" [(ngModel)]="selectedCamera" (change)="selectCamera($event)">
                        <option value="" disabled hidden>Select</option>
                        <option *ngFor="let camera of cameraData" [value]="camera.camUID">{{camera.camName}}</option>
                    </select>
                </div>
            </div>
            <div class="content-right d-flex align-items-center justify-content-between">
                <div class="heading">Source</div>
                <div class="store-time">
                    <span title="{{(storeName)?storeName+' | ':''}}{{currentTime | date:'medium'}}"><span *ngIf="storeName">{{storeName}} | </span>{{currentTime | date:"medium"}}</span>
                </div>
            </div>
        </div>

        <div class="container-image">
            <canvas #canvas></canvas>
        </div>

        <div class="heat-map-content-bottom d-flex align-items-center justify-content-start" [ngClass]="{'legends-show': colorArrayLegends?.length}">
            <div>Low Traffic</div>
            <div class="color-boxes d-flex">
                <ng-container *ngFor="let colors of colorArrayLegends">
                    <div class="boxes" [style.background]="colors.color"></div>
                </ng-container>
            </div>
            <div>High Traffic</div>
        </div>

    </div>

    <!-- <div class="heat-map-footer footer-border"></div> -->
</div>