import { Component, OnInit, Input } from "@angular/core";
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/services/auth.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ActivatedRoute } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
 
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  @Input() public resetData: any;
  isSubmitting: Boolean = false;
  error: Boolean = false;
  privateIPAddress: string = "000.000.000.000";
  currentDate = new Date();
  newPassword: string;
  confirmPassword: string;
  message: string;
  showAuthToken: boolean = false;
  showAuthToken2: boolean = false;
  resetTokenId: string;
  matchPasswords: Boolean = false;
  valid : Boolean;
  resetForm: FormGroup;
 
  constructor(
    private router: Router,
    private configService: ConfigurationService,
    private http: HttpClient,
    private route: ActivatedRoute,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private alertMessagesService: AlertMessagesService
  ){ this.resetForm = this.fb.group({
    newPassword: ["", [Validators.required]],
    confirmPassword: ["", [Validators.required]],
  });
  
 
  }
  get fc() {
    return this.resetForm.controls;
  }
 
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.resetTokenId = params["id"];
    });
  }
  togglePasswordVisibility() {
    this.showAuthToken = !this.showAuthToken;
 
  }
  togglePasswordVisibility2() {
    this.showAuthToken2 = !this.showAuthToken2;
  }
  comparePasswords() {
    if(!this.newPassword && !this.confirmPassword){
      this.valid=false;
      this.message= "Please enter a password"
    }
    else if (this.newPassword === this.confirmPassword && this.newPassword !== "") {
      if ( this.newPassword.length < 8){
        this.message = "Your password must contain more than 8 characters.";
        this.valid = false;
      }
      else{
      this.matchPasswords = true;
      this.message="";
      this.valid = true;
      }
    }
    else  {
      this.isSubmitting = false;
      this.valid = false;
      this.message = "Passwords do not match.";
    }
  }
 
  removeMessage(){
    this.message=""
  }
 
 
  submitForm(): void {
    this.isSubmitting = true;
    const data = {
      requestTokenId: this.resetTokenId,
      password: this.newPassword,
      usedIP: this.privateIPAddress,
      date: this.currentDate,
    };
    this.comparePasswords();
    if (this.valid) {
      this.configService.resetPassword(data).subscribe(async (resp: any) => {
        if (resp.status === true) {
          this.activeModal.close();
          const response = await this.alertMessagesService.alertWithSuccessConfirmAction(resp.detail);            
          if ( response == true ) {
            this.router.navigate(["/login"]);
          }

        } else {
          this.message = "Please recheck the details";
        }
      },      
      (error) => {
        this.alertMessagesService.alertWithError(error.error.detail);
      });
    } 
  }
 
}