<div class="modal-body modal-heatmap">
    <div class="heat-map-popup-container">
        <div class="heat-map-header d-flex justify-content-between align-items-center">
            <div>Store Heatmap</div>
            <div class="icon-minus" (click)="activeModal.dismiss('Cross click')"></div>
        </div>
        <div class="heat-map-popup-content">
            <app-heat-map [heatMapDataSet]="heatMapDataSet" [colorArrayLegends]="colorArrayLegends" [cameraData]="cameraData" 
            [selectedCamera]="selectedCamera" [heatMapCameraImage]="heatMapCameraImage"
            [storeName]="storeName" [popUpView]="true" [currentTime]="currentTime" 
            (selectedCameraValue)="selectedCameraValue($event)"></app-heat-map>
        </div>
        <div class="heat-map-footer footer-border"></div>
    </div>
</div>