
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { Subscription } from "rxjs";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: "app-violence",
  templateUrl: "./violence.component.html",
  styleUrls: ['./violence.component.scss'],

})
export class ViolenceComponent implements OnInit {
  @Input() configData: any;  
  @Output() updatedUseCase: EventEmitter<void> = new EventEmitter<void>();
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  violenceUseCaseForm: FormGroup;
  selectedDecisionMethod: string = "Thresholding"; // Default selected Decision Method
  decisionMethod: string[] = ["Thresholding", "Averaging"];
  isDisplayThresholding: boolean = true;
  selectedUseCaseInfo: any;
  selectedUCConfig: any;
  useCaseConfigId: any;
  useCaseIdentifier: any;
  isChecked: boolean = true;
  isChecked1: boolean = false;
  isChecked2: boolean = false;
  skipframe: string = "";
  ifps: string;
  windows: string;

  constructor(
    private fb: FormBuilder,
    private configService: ConfigurationService,
    private alertMessagesService: AlertMessagesService,
    public activeModal: NgbActiveModal
  ) {
    this.violenceUseCaseForm = this.fb.group({
      tenant: ["", Validators.required],
      store: ["", Validators.required],
      camera: ["", Validators.required],
      cameraId: ["", Validators.required],
      deviceId: ["", Validators.required],
      usecaseId: ["", Validators.required],
      skipframes: [
        "",
        [Validators.required, this.floatCountValidator(1)],
      ],
      fps:  [
        "",
        [Validators.required, this.digitCountValidator(30)],
      ],
   
      windowsize: [null, [Validators.required]],
      decisionMethod: [],
      modLevel: [
        "",
        [Validators.required, this.floatCountValidator(1)],
      ],
      highLevel: [
        "",
        [Validators.required, this.floatCountValidator(1)],
      ],
      highLevelFreq: [null, [Validators.required]],
      modLevelFreq: [null, [Validators.required]],
      avgThreshold: [
        "",
        [Validators.required, this.floatCountValidator(1)],
      ],
      status: [true],
      inputSource: [""],
      savevideo: [false],
      offline: [false],
    });
  }
  get fc() {
    return this.violenceUseCaseForm.controls;
  }

  ngOnInit(): void {
    this.selectedUseCaseInfo = this.configData.useCase;
    if (this.selectedUseCaseInfo) {
      this.getUseCaseConfigData();
    }
  }

  digitCountValidator(maxValue: number) {
    return (control: any) => {
      const value = parseFloat(control.value);
      return isNaN(value) || value <= maxValue
        ? null
        : { digitCountExceeded: true };
    };
  }

  floatCountValidator(maxValue: number) {
    return (control: any) => {
      const value = parseFloat(control.value);
      return isNaN(value) || value <= maxValue
        ? null
        : { floatCountExceeded: true };
    };
  }

  changeDecisionMethod(event: any) {
    if (event.target.value == "Thresholding") {
      this.isDisplayThresholding = true;
    } else {
      this.isDisplayThresholding = false;
    }    
  }
 
  getUseCaseConfigData() {
    this.useCaseConfigId = this.selectedUseCaseInfo.ucConfigUID;
    this.useCaseIdentifier = this.selectedUseCaseInfo.usecaseIdentifier;
    this.configService
      .getUseCaseConfigData(this.useCaseConfigId, this.useCaseIdentifier)
      .subscribe((resp: any) => {
        this.selectedUCConfig = resp.data;
        this.fetchUseCaseConfigData();
      });
  }

  fetchUseCaseConfigData() {
    if (this.selectedUCConfig) {

      if (this.selectedUCConfig.settings.DECISION_METHOD.value.averaging) {
        this.selectedDecisionMethod = "Averaging"
        this.isDisplayThresholding = false;
        this.violenceUseCaseForm.patchValue({
          tenant: this.selectedUCConfig.tenant,
          store: this.selectedUCConfig.store,
          usecaseId: this.selectedUCConfig.usecaseId,
          cameraId: this.selectedUCConfig.cameraId,
          deviceId: this.selectedUCConfig.deviceId,
          inputSource: this.selectedUCConfig.inputSource,
          camera: this.selectedUCConfig.camera,
          skipframes: this.selectedUCConfig.settings.SKIP_FRAMES.value,
          fps: this.selectedUCConfig.settings.FPS.value,
          windowsize: this.selectedUCConfig.settings.WINDOW_SIZE.value,
          avgThreshold: this.selectedUCConfig.settings.DECISION_METHOD.value.averaging?.avgThreshold,
        })
      }
      else {
        this.violenceUseCaseForm.patchValue({
          tenant: this.selectedUCConfig.tenant,
          store: this.selectedUCConfig.store,
          usecaseId: this.selectedUCConfig.usecaseId,
          cameraId: this.selectedUCConfig.cameraId,
          deviceId: this.selectedUCConfig.deviceId,
          inputSource: this.selectedUCConfig.inputSource,
          camera: this.selectedUCConfig.camera,
          skipframes: this.selectedUCConfig.settings.SKIP_FRAMES.value,
          fps: this.selectedUCConfig.settings.FPS.value,
          windowsize: this.selectedUCConfig.settings.WINDOW_SIZE.value,

          highLevel: this.selectedUCConfig.settings.DECISION_METHOD.value.thresholding?.highLevel,
          modLevel: this.selectedUCConfig.settings.DECISION_METHOD.value.thresholding?.modLevel,
          highLevelFreq: this.selectedUCConfig.settings.DECISION_METHOD.value.thresholding?.highLevelFreq,
          modLevelFreq: this.selectedUCConfig.settings.DECISION_METHOD.value.thresholding?.modLevelFreq,

        });
      }

      this.isChecked = this.configData?.useCase?.status;           
      this.isChecked1 = this.selectedUCConfig.settings.SAVE_VIDEO.value;
      this.isChecked2 = this.selectedUCConfig.settings.OFFLINE.value;

    }
    else {
      this.violenceUseCaseForm.patchValue({
        tenant: this.configData?.tenantId,
        store: this.configData?.storeId,
        usecaseId: this.configData?.useCaseUId,
        cameraId: this.configData?.camUId,
        deviceId: this.configData?.deviceId,
        inputSource: this.configData?.rtsp,
        camera: this.configData?.camMacAdd,
        highLevel: this.selectedUCConfig.highLevel,
        modLevel: this.selectedUCConfig.modLevel,
        highLevelFreq: this.selectedUCConfig.highLevelFreq,
        modLevelFreq: this.selectedUCConfig.modLevelFreq,
        avgThreshold: this.selectedUCConfig.avgThreshold,
        skipframes: this.selectedUCConfig.skipframes,
        fps: this.selectedUCConfig.fps,
        status: this.isChecked,
        savevideo: this.isChecked1,
        offline: this.isChecked2,
      });
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.violenceUseCaseForm.get('fps')?.value == undefined || this.violenceUseCaseForm.get('fps')?.value > 30 ||
      this.violenceUseCaseForm.get('windowsize')?.value == undefined || this.violenceUseCaseForm.get('windowsize')?.value <= 0 ||
      this.violenceUseCaseForm.get('skipframes')?.value == undefined || this.violenceUseCaseForm.get('skipframes')?.value > 1
    ) return;
    if (this.selectedDecisionMethod == 'Averaging') {
      if (this.violenceUseCaseForm.get('avgThreshold')?.value == undefined ||
           this.violenceUseCaseForm.get('avgThreshold')?.value > 1 || 
            this.violenceUseCaseForm.get('avgThreshold')?.value == "") {
        return;
      }
    }else if (this.violenceUseCaseForm.get('highLevel')?.value == undefined || this.violenceUseCaseForm.get('highLevel')?.value > 1 ||
      this.violenceUseCaseForm.get('modLevel')?.value == undefined || this.violenceUseCaseForm.get('modLevel')?.value > 1 ||
      this.violenceUseCaseForm.get('highLevelFreq')?.value == undefined || this.violenceUseCaseForm.get('highLevelFreq')?.value <= 0 ||
      this.violenceUseCaseForm.get('modLevelFreq')?.value == undefined || this.violenceUseCaseForm.get('modLevelFreq')?.value <= 0) {
      return;
    }

    if (this.selectedUCConfig) {
      this.violenceUseCaseForm.patchValue({
        tenant: this.configData?.useCase?.tenantId,
        store: this.configData?.useCase?.storeId,
        usecaseId: this.configData?.useCase?.usecaseId,
        cameraId: this.selectedUCConfig.cameraId,
        deviceId: this.selectedUCConfig.deviceId,
        inputSource: this.selectedUCConfig.inputSource,
        camera: this.selectedUCConfig.camera,
        skipframes: this.skipframe,
        fps: this.ifps,
        windowsize: this.windows,
        status: this.isChecked,
        savevideo: this.isChecked1,
        offline: this.isChecked2,
      });
    } else {
      this.violenceUseCaseForm.patchValue({
        tenant: this.configData?.tenantId,
        store: this.configData?.storeId,
        usecaseId: this.configData?.useCaseUId,
        cameraId: this.configData?.camUId,
        deviceId: this.configData?.deviceId,
        inputSource: this.configData.rtsp,
        camera: this.configData.camMacAdd,
        skipframes: this.skipframe,
        ifps: this.ifps,
        windowsize: this.windows,
        status: this.isChecked,
      });
      this.useCaseIdentifier = this.configData?.useCaseIdentifier;
    }

    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 5000);
    const ucConfigUID = this.configData?.useCase?.ucConfigUID;
    const useCaseType = this.configData?.useCase?.usecaseIdentifier;
    this.configService.getFileUploadStatus(true);

    if (ucConfigUID) {
      this.configService
        .updateUseCaseMapping(
          ucConfigUID,
          useCaseType,
          this.violenceUseCaseForm.value
        )
        .subscribe(
          (resp: any) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess("UseCase Updated successfully.");
              this.isLoading = false;
              this.configService.setActiveItem(false);
              this.updatedUseCase.emit();
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else {
      this.configService
        .createUseCaseMapping(
          this.useCaseIdentifier,
          this.violenceUseCaseForm.value
        )
        .subscribe(
          (resp) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess("UseCase Added successfully.");
              this.isLoading = false;
              this.configService.setActiveItem(false);
              this.updatedUseCase.emit();
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    }
    
  }
}