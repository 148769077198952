import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  QueryList,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { LayoutService } from "src/app/services/layout.service";
import { StoreInsightService } from "src/app/services/store-insight.service";
import Swal from "sweetalert2";
import { CaseManagementService } from "src/app/services/case-management.service";
import { ViewportScroller } from "@angular/common";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import jwt_decode from "jwt-decode";
import { AuthorizationService } from "src/app/services/authorization.service";

@Component({
  selector: "app-case-management",
  templateUrl: "./case-management.component.html",
  styleUrls: ["./case-management.component.scss"],
})
export class CaseManagementComponent implements OnInit, OnDestroy {
  closeResult = "";
  countriesList: any;
  statesList: any;
  zipCodesList: any;
  storesList: any;

  defaultCountry: string = "All";
  defaultState: string = "All";
  defaultZip: string = "All";
  defaultStore: string = "All";
  selectedStore: any = "D1E524A2-4406-43F7-A968-58542834C5C3";

  country_id: number;
  state_id: number;
  zip_code: number;
  store_id: number;

  subscribedSolutions: any[] = [];
  filteredSolutions: any[] = [];
  caseManagementData: any[] = [];
  statusList: any[] = [];
  selectedStatus: string;
  storeDetails: any[] = [];
  tableData: any[] = [];
  selectedTask: any;
  selectedScenario: string;
  pageSize = 20;
  currentPage = 1;
  jsonData: any;
  totalItems: number = 0;
  selectedTaskID: any;
  @ViewChild("scrollContainer") scrollContainersRef: QueryList<ElementRef>;
  isFetchingData: boolean = false;
  pageInfo: any;
  showDefaultMessage: boolean = true;
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';
  private autoRefereshCaseListInterval: any;
  loggedUser: any;
  decodedToken: any;

  constructor(
    private layoutService: LayoutService,
    private storeInsightService: StoreInsightService,
    private router: Router,
    private caseManagementService: CaseManagementService,
    private viewportScroller: ViewportScroller,
    private alertMessagesService: AlertMessagesService,
    private authorizationService: AuthorizationService
  ) { 
    this.loggedUser = localStorage.getItem("userRole");
  }

  ngOnInit(): void {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.cases, ['list', '*']) ) {
      const pageTitle = "Case Management";
      this.layoutService.setPageTitle(pageTitle);
      this.getCountries();
      this.statusList = [];
      this.tableData = [];
      this.currentPage = 1;
      this.getCaseManagementList();
      this.getCaseStatusList();
      this.autoRefresh();
    } else {
      this.router.navigateByUrl('/unauthorized');
    }
  }

  ngOnDestroy() {
    clearInterval(this.autoRefereshCaseListInterval);
  }

  // Auto Refresh the case lists
  autoRefresh(): void {
    this.autoRefereshCaseListInterval = setInterval(() => {
      this.autoRefreshCaseData();
    }, 15000);
  }

  autoRefreshCaseData() {
    const currentPage = 1; // current page always will be 1 since all new data will be sorted by desc from backend
    this.caseManagementService
      .getCaseManagementList(this.pageSize, currentPage)
      .subscribe({
        next: (resp: any) => {
          const newItemsFromApi = resp?.data?.length > 0 ? resp.data : [];
          const newItems = newItemsFromApi.filter((newItem: any) => {
            return !this.tableData.some(
              (existingItem) => newItem.id === existingItem.id
            );
          });
          this.tableData =
            newItems?.length > 0
              ? [...newItems, ...this.tableData]
              : this.tableData;
          this.pageInfo = resp?.page_info;
        },
        error: (error: any) => { },
      });
  }

  hasMoreItems(): boolean {
    this.totalItems = this.pageInfo?.count;
    return this.tableData.length < this.totalItems;
  }

  onScroll(event: Event, scrollContainer: HTMLElement) {
    const scrollTop = scrollContainer.scrollTop;
    const scrollHeight = scrollContainer.scrollHeight;
    const clientHeight = scrollContainer.clientHeight;
    const scrollEndOffset = 1;
    const isScrollAtBottom =
      scrollTop + clientHeight >= scrollHeight - scrollEndOffset;
    const isScrollHeightReached = scrollTop + clientHeight === scrollHeight;

    if (
      isScrollAtBottom &&
      isScrollHeightReached &&
      !this.isFetchingData &&
      this.hasMoreItems()
    ) {
      this.isFetchingData = true;
      const remainingItems = this.pageInfo?.count - this.tableData.length;
      const itemsToLoad = Math.min(remainingItems, this.pageSize);
      if (itemsToLoad <= this.pageSize && itemsToLoad != 0) {
        this.currentPage++;
        this.getCaseManagementList();
      }
    }
  }

  getCaseManagementList() {
    this.showDefaultMessage = true;
    this.caseManagementService
      .getCaseManagementList(this.pageSize, this.currentPage)
      .subscribe({
        next: (resp: any) => {
          const newItemsFromApi = resp?.data?.length > 0 ? resp.data : [];
          const newItems = newItemsFromApi
            .map((newItem: any) => {
              const existingIndex = this.tableData.findIndex(
                (existingItem) => newItem.id === existingItem.id
              );
              if (existingIndex !== -1) {
                this.tableData[existingIndex] = newItem;
              }
              return newItem;
            })
            .filter((newItem: any) => {
              return !this.tableData.some(
                (existingItem) => newItem.id === existingItem.id
              );
            });
          this.tableData =
            newItems?.length > 0
              ? [...this.tableData, ...newItems]
              : this.tableData;
          this.isFetchingData = false;
          this.showDefaultMessage = false;
          this.pageInfo = resp?.page_info;
        },
        error: (error: any) => {
          this.isFetchingData = false;
          this.showDefaultMessage = false;
        },
      });
  }

  getCaseStatusList() {
    this.caseManagementService.getCaseStatusList().subscribe((resp: any) => {
      this.statusList = resp?.data?.length > 0 ? resp.data : [];
      this.statusList.sort((a, b) => (a["position"] > b["position"] ? 1 : -1));
    });
  }

  getCountries() {
    this.storeInsightService.getCountriesData().subscribe((resp: any) => {
      this.countriesList = resp.data;
    });
  }

  // Selected country Function
  getStatesList(event: any) {
    const selectedCountry = event.target.value;
    if (event.target.value === "All") {
      this.defaultState = "All";
      this.defaultZip = "All";
      this.defaultStore = "All";
      this.statesList = [];
      this.zipCodesList = [];
      this.storesList = [];
      return;
    }
    if (selectedCountry > "0") {
      this.defaultState = "All";
      this.defaultZip = "All";
      this.defaultStore = "All";
      this.statesList = [];
      this.zipCodesList = [];
      this.storesList = [];
      this.storeInsightService
        .getStatesData(selectedCountry)
        .subscribe((resp: any) => {
          this.statesList = resp.data;
        });
    }
  }

  // Selected state Function
  getZipCodesList(event: any) {
    const selectedState = event.target.value;
    if (event.target.value === "All") {
      this.defaultZip = "All";
      this.defaultStore = "All";
      this.zipCodesList = [];
      this.storesList = [];
      return;
    }
    if (selectedState > "0") {
      this.defaultZip = "All";
      this.defaultStore = "All";
      this.zipCodesList = [];
      this.storesList = [];
      this.storeInsightService
        .getZipCodeData(selectedState)
        .subscribe((resp: any) => {
          this.zipCodesList = resp.data;
        });
    }
  }

  // Selected state Function
  getStoresList(event: any) {
    const selectedZip = event.target.value;
    if (event.target.value === "All") {
      this.defaultStore = "All";
      this.storesList = [];
      return;
    }
    if (selectedZip > "0") {
      this.defaultStore = "All";
      this.storesList = [];
      // this.storesList = this.storeDetails.filter(
      //   (item: any) => item.zipcode == selectedZip
      // );
      this.storeInsightService
        .getStoresData(selectedZip)
        .subscribe((resp: any) => {
          this.storesList = resp.data;
        });
    }
  }

  // Selected store Function
  fetchStoreData(event: any) {
    // Here need to integrate actual api afterwards
    // this.selectedStore = event.target.value;
    // this.tableData = this.caseManagementData.filter(
    //   (item: any) => item.datamartId == this.selectedStore
    // );
  }

  selectStatus(selectedItem: any, event: any) {
    const selectedstatus = event.target.value;
    const oldStatus = selectedItem?.status;
    this.selectedTaskID = selectedItem.id;
    const selectedStatusObj = this.statusList.find(
      (obj) => obj["slug_name"] === selectedstatus
    );
    selectedItem.status = selectedStatusObj;
    let successMessage = "Your changes are saved successfully.";
    // "allow_case_update" flag is newly introduced for status like "closed" and "invalidate" and "not-a-case"
    // will have false value so that based on that we will show confirmation alert.
    if (selectedStatusObj?.allow_case_update == false) {
      let confirmMessage;
      if (selectedstatus == "closed") {
        confirmMessage =
          'Are you sure you want to update the incident status to "Closed"? A closed case cannot be reopened and will be available as view only.';
        successMessage = "Case is closed successfully.";
      } else if (selectedstatus == "invalidate") {
        confirmMessage =
          "Please confirm this as an Invalid Incident. Once confirmed this incident will no more be a case.";
      } else if (selectedstatus == "not-a-case") {
        confirmMessage = "Please confirm that the incident is not a case.";
      } else {
        confirmMessage =
          "You have made some changes to the case details. Do you want to save the changes?";
      }

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          popup: 'alert-warning-container',
          htmlContainer: 'alert-warning-content',
          confirmButton: "btn btn-submit",
          cancelButton: "btn btn-cancel",
          icon: "alert-warning-icon",
          title: "alert-warning-title",

        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({
        iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
        text: confirmMessage,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateCaseStatus(
            selectedstatus,
            successMessage,
            oldStatus,
            selectedItem
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          const caseIndex = this.tableData.findIndex(
            (cases: any) => cases.id === selectedItem.id
          );
          this.tableData[caseIndex].status = oldStatus;          
        } else {
          const caseIndex = this.tableData.findIndex(
            (cases: any) => cases.id === selectedItem.id
          );
          this.tableData[caseIndex].status = oldStatus;
        }
      });
    } else {
      this.updateCaseStatus(
        selectedstatus,
        successMessage,
        oldStatus,
        selectedItem
      );
    }
  }

  updateCaseStatus(
    selectedstatus: string,
    successMessage: string,
    oldStatus: any,
    selectedItem: any
  ) {
    var payload: any = {};
    payload.status = selectedstatus;
    this.caseManagementService
      .updateCaseInfoDetails(payload, this.selectedTaskID)
      .subscribe({
        next: (resp: any) => {
          selectedItem.status = resp?.data?.status;
          this.alertMessagesService.alertWithSuccess(successMessage);

        },
        error: (error: any) => {
          const caseIndex = this.tableData.findIndex(
            (cases: any) => cases.id === selectedItem.id
          );
          this.tableData[caseIndex].status = oldStatus;
          this.alertMessagesService.alertWithError("Some error occured while updating status.");
        },
      });
  }

  getCaseInfoPage(task: any) {
    this.layoutService.setCaseInfoData(task);
    this.router.navigate(["/case-management", task.id]);
  }

  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
