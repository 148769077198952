<form [formGroup]="peoplestoremovementUseCaseForm" (ngSubmit)="onSubmit()" class="usecase-config-form">   
  <div class="d-flex justify-content-between mb-4">
    <div class="people-dimension-input usecase-form-group">
      <label for="payloaddelivery">Pay Load Delivery Interval: <sup>*</sup></label>
      <input type="number" id="payloaddelivery" name="payloaddelivery" formControlName="payLoadDeliveryInterval"
        class="form-control" placeholder="Enter Pay Load Delivery Interval Value"
        [ngClass]="{'is-invalid': isSubmitted && fc['payLoadDeliveryInterval'].errors}">
      <div *ngIf="peoplestoremovementUseCaseForm.get('payLoadDeliveryInterval')?.hasError('required')" class="invalid-feedback">
        PayLoad Delivery Interval is Required
      </div>
    </div>

    <div class="people-dimension-input usecase-form-group">
      <label for="FPS">FPS: <sup>*</sup></label>
      <input type="number" id="fps" name="fps" formControlName="fps"
        class="form-control" placeholder="Enter FPS Value"
        [ngClass]="{'is-invalid': isSubmitted && fc['fps'].errors}">
      <div *ngIf="peoplestoremovementUseCaseForm.get('fps')?.hasError('required')" class="invalid-feedback">
        FPS is Required
      </div>
    </div>
  </div>
  <div class="user-form-group custom-icon mb-3">
    Status:
    <label class="toggle-button">
      <div class="shadow-input"></div>
      <input class="form-check-input" type="checkbox" formControlName="status" [(ngModel)]="isChecked">
      <span class="slider round"></span>
    </label>
  </div>
  <button type="submit" class="btn btn-add" [disabled]="isLoading">Submit</button>
</form>