<div class="overview-setup">
  <div class="d-flex justify-content-end tab-content store-container">
    <select class="selected-item" [(ngModel)]="selectedStatus" (change)="onStatusChange()">
      <option value='Active' selected>Active</option>
      <option value='Inactive'>Inactive</option>
    </select>

    <select class="selected-item" [(ngModel)]="selectedTenantId" (change)="changeOrg($event)" *ngIf="isCSAdmin">
      <option value="" [selected]="!selectedTenantIdDropdown">All Organizations</option>
      <option *ngFor="let org of filteredOrgs" [value]="org.tenantId" [selected]="org.tenantId === selectedTenantId">
        {{ org.tenantName }}
      </option>
    </select>

    <button class="btn btn-add" (click)="addStoresData()">+ Add Store</button>
  </div>
</div>

<div class="ag-grid">
  <app-store-grid [rowData]="storesData" (gridActionEvent)="handleActionEvent($event)"></app-store-grid>
</div> 